import { StaffApi } from "../../rtkApi";
import { API_METHODS, END_POINTS, STAFF_END_POINT } from "../../rtkConstant";

const StaffMutation = StaffApi.injectEndpoints({
  endpoints: (builder) => ({
    editStaff: builder.mutation({
      query: (apiArgs) => ({
        url: STAFF_END_POINT.EDIT_STAFF_BY_ID,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    editProfile: builder.mutation({
      query: (apiArgs) => ({
        url: STAFF_END_POINT.EDIT_PROFILE_BY_ID,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    updateStaff: builder.mutation({
      query: (apiArgs) => ({
        url: STAFF_END_POINT.UPDATE_STAFF_BY_ID + "/" + apiArgs.params,
        method: API_METHODS.PUT,
        body: apiArgs.payload,
      }),
    }),
    addStaff: builder.mutation({
      query: (apiArgs) => ({
        url: STAFF_END_POINT.ADD_STAFF,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
  }),
});
export const { useEditProfileMutation, useEditStaffMutation, useAddStaffMutation, useUpdateStaffMutation } =
  StaffMutation;

import { Input, Spinner } from "reactstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAdminPrescriptionDeliveryMethodMutation } from "rtk";

function PrescriptionDeliveryMethod({ id, notesMessage = "", setCustomError, setCustomSuccess }) {
  const [showSave, setShowSave] = useState(false);
  const [note, setNote] = useState(() => notesMessage ?? "");
  const [updateNotes, { error: notesError, isSuccess: notesSuccess, isLoading: notesLoading }] =
  useAdminPrescriptionDeliveryMethodMutation();

  const errorMessage = useMemo(() => notesError?.data?.message || "", [notesError?.data?.message]);

  useEffect(() => {
    if (notesSuccess) {
      setCustomSuccess("Delivery Method updated successfully");
      setCustomError('');
      setShowSave(false);
    } else if (errorMessage) {
      setCustomError(errorMessage);
      setCustomSuccess('');
    }
  }, [errorMessage, notesSuccess, setCustomError]);

  const checkInput = useCallback(() => setShowSave(note !== notesMessage), [notesMessage, note]);

  const renderSaveBtn = useMemo(() => {
    if (showSave) {
      return (
        <button
          disabled={notesLoading}
          className="yellowButton mt-1"
          style={{ fontsize: "8px" }}
          onClick={() => updateNotes({ prescriptionId: id, message: note, pageName: "AdminPendingPrescription" })}
        >
          {notesLoading ? (setCustomSuccess(''), setCustomError(''), <Spinner size="sm" />) : "Save"}
        </button>
      );
    }
  }, [id, note, notesLoading, showSave, updateNotes]);

  return (
    <>
      <Input
        style={{ width: "100px", fontSize: "12px", borderColor: errorMessage ? "red" : "" }}
        type="text"
        defaultValue={notesMessage}
        // onBlur={checkInput}
        onChange={(e) => {
          setNote(e.target.value);
          if (!showSave) {
            setShowSave(true);
          }
        }}
      />
      {renderSaveBtn}
    </>
  );
}

export default React.memo(PrescriptionDeliveryMethod);

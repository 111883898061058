import React, { useEffect, useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { userInfoSelector } from "selectors";
import GRHLogo from "../assets/images/logo.svg";
import "../assets/styles/loggedInNavbar.css";

function SuperAdminNavbar() {
  const userData = useSelector(userInfoSelector);

  return (
    <>
      <div className="topbar">
        <NavDropdown
          title={
            <p className="m-0 navLink">
              <img
                src={
                  userData?.profilePictureUrl ||
                  "https://placekitten.com/g/30/30"
                } // Placeholder image URL
                alt="Profile"
                style={{ width: "30px", height: "30px", borderRadius: "50%" }}
              />{" "}
              {`${userData?.firstName} ${userData?.lastName}`}{" "}
              <i className="mx-1 fas fa-angle-down"></i>
            </p>
          }
          id="nav-dropdown"
          className="navDD navLink profileDD"
        >
          <LinkContainer
            className="navLink navDDLink"
            to="/profile"
            activeClassName="activeLinkDark"
          >
            <Nav.Link>Profile</Nav.Link>
          </LinkContainer>
          <NavDropdown.Divider />
          <LinkContainer
            className="navLink navDDLink"
            to="/admins"
            activeClassName="activeLinkDark"
          >
            <Nav.Link>Admins</Nav.Link>
          </LinkContainer>
          <NavDropdown.Divider />

          <LinkContainer
            className="navLink navDDLink"
            to="/logout"
            activeClassName="activeLink"
          >
            <Nav.Link>
              <i className="mx-1 logoutIcon fas fa-sign-out-alt"></i> Logout
            </Nav.Link>
          </LinkContainer>
        </NavDropdown>
      </div>
      <Navbar collapseOnSelect expand="md" variant="dark" className="navbar">
        <Navbar.Brand id="navbarBrand" href="/" className="navbarBrand">
          <img src={GRHLogo} alt="logo" className="navLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-content" className="navbarToggle">
          <i className="navbarToggleIcon fa fa-fw fa-bars" />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-content" className="navbarCollapse">
          <Nav className="ml-auto navbarContent">
            {/* TODO: Add links */}
            {/* HOME */}
            <LinkContainer
              className="navLink"
              to="/"
              activeClassName={
                window.location.pathname === "/" ? "activeLink" : ""
              }
            >
              <Nav.Link><i className="fa fa-home navicons"></i> Home</Nav.Link>
            </LinkContainer>
            {/* PAITENTS */}
            <LinkContainer
              className="navLink"
              to="/patients"
              activeClassName={
                window.location.pathname.substring(
                  window.location.pathname.lastIndexOf("/") + 1
                ) === "patients"
                  ? "activeLink"
                  : ""
              }
            >
              <Nav.Link><i className="fa fa-user navicons"></i> Patients</Nav.Link>
            </LinkContainer>
            {/* PRESCRIPTIONS */}
            <LinkContainer
              className="navLink"
              to="/pending-prescriptions"
              activeClassName="activeLink"
            >
              <Nav.Link><i className="fa fa-file navicons"></i> Prescriptions</Nav.Link>
            </LinkContainer>
            {/* INVOICES */}
            <LinkContainer
              className="navLink"
              to="/invoices"
              activeClassName="activeLink"
            >
              <Nav.Link><i className="fas fa-file-invoice-dollar navicons"></i> Invoices</Nav.Link>
            </LinkContainer>
            {/* CLINICS */}
            <LinkContainer
              className="navLink"
              to="/clinics"
              activeClassName="activeLink"
            >
              <Nav.Link><i className="fa fa-clinic-medical navicons"></i> Clinics</Nav.Link>
            </LinkContainer>
            {/* PRODUCTS */}
            <LinkContainer
              className="navLink"
              to="/products"
              activeClassName="activeLink"
            >
              <Nav.Link><i className="fa fa-box-open navicons"></i> Products</Nav.Link>
            </LinkContainer>

            {/* <LinkContainer
              className="navLink"
              to="/messages"
              activeClassName="activeLink"
            >
              <Nav.Link>
                <i className="fas fa-envelope"></i>
                <span
                  style={{
                    color: "red",
                    fontSize: "15px",
                    fontWeight: "800px",
                    marginTop: "-5px",
                    padding: "0px",
                    position: "absolute",
                  }}
                >
                  <strong>{messageCount}</strong>
                </span>
              </Nav.Link>
            </LinkContainer> */}

            <NavDropdown
              title={
                <p className="m-0 navLink">
                  <i className="navUserCircle fas fa-user-circle"></i>{" "}
                  {`${userData?.firstName} ${userData?.lastName}`}{" "}
                  <i className="mx-1 fas fa-angle-down"></i>
                </p>
              }
              id="nav-dropdown"
              className="navDD navLink profileDD"
            >
              <LinkContainer
                className="navLink navDDLink"
                to="/profile"
                activeClassName="activeLinkDark"
              >
                <Nav.Link>Profile</Nav.Link>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer
                className="navLink navDDLink"
                to="/admins"
                activeClassName="activeLinkDark"
              >
                <Nav.Link>Admins</Nav.Link>
              </LinkContainer>
              <NavDropdown.Divider />

              <LinkContainer
                className="navLink navDDLink"
                to="/logout"
                activeClassName="activeLink"
              >
                <Nav.Link>
                  <i className="mx-1 logoutIcon fas fa-sign-out-alt"></i> Logout
                </Nav.Link>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default React.memo(SuperAdminNavbar);

import StateDD from "components/dropdowns/stateDD";
import React from "react";
import { Modal, Row, Label, Input, Col, Alert } from "reactstrap";
import CreditCardInput from "react-credit-card-input";
import useConfirmCreditCardModal from "./index.hook";
import Spinner from "components/spinner";
import "./index.scss";
import ActionModal from "../actionModal";
import ClinicCreditCardBillingDD from "components/dropdowns/clinicCreditCardsBillingDD";
const ConfirmCreditCardModal = (props) => {
  const {
    defaultForClinic,
    payNow,
    confirmModal,
    setConfirmModal,
    setNewCardMetaData,
    encrypt,
    billTo,
    setBillTo,
    setDefaultForClinic,
    newCardMetaData,
    error,
    clinicId,
    invoiceId,
    isNewCard,
    setCreditCard,
    setNewCard,
    creditCardModal,
    closeModal,
    closeConfirmPaymentModal,
    selectedCreditCard,
    addNewCreditCard,
    setSelectedCreditCard,
    newCardModal,
    setNewCardModal,
    showSpinner,
    setShowSpinner,
    selectCompleteRowData,
    selectRxIds,
    selectClinicLocationIds,
    credit,
    setCredit,
    creditRequested,
    setCreditRequested,
    creditApplied,
    setCreditApplied,
    resetCreditApplied,
    setCreditUsed,
    creditUsed,
    paymentAction,
    clinicLocationsInfo,
    setClinicLocationsInfo,
    selectedTotalAmount,
    updateTotalAmountToBeCharged,
    setTotalCreditUsed,
    totalCreditUsed,
    requestedCreditsForClinicLocations,
    setRequestedCreditsForClinicLocations,
    selectedClinicLocationId,
    setSelectedClinicLocationId,
    updateInfo,
    encryptedBillingInfo,
    setEncryptedBillingInfo,
    setCreditCardWithClinicLocationId,
    selectNewCardLocation,
    setSelectNewCardLocation,
    updateNewCardCheck,
    closeAddNewCardModal,
    closeCreditModal,
  } = useConfirmCreditCardModal(props);

  return (
    <Modal isOpen={creditCardModal} scrollable={true} size="md" className="custom-modal-style" centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Confirm Payment Method</h5>
        <button
          type="button"
          onClick={() => {
            closeCreditModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Row></Row>
      {showSpinner ? <Spinner /> : null}
      <div className="modal-body">
        <div>
          {props.error ? (
            <Alert color="danger" className="text-center mx-2" isOpen={props.error}>
              {props.error}
            </Alert>
          ) : null}
        </div>
        <div className="modal-body">
          <form id="paymentForm" onSubmit={paymentAction}>
            <div>
              <h4>Total Amount to Pay: ${props.payFullBalance ? props.totalBalanceDue : selectedTotalAmount} </h4>
              <hr></hr>
              {clinicLocationsInfo?.map((a, i) => {
                return (
                  <>
                    <div>
                      {" "}
                      {/* <i
                        onClick={(e) => {
                          if (edit) setEdit(false);
                          else setEdit(true);
                          // updateInfo(e, a.id);
                          // if (edit) {
                          //   setEdit(true);
                          // } else {
                          //   setEdit(false);
                          // }
                        }}
                        className={"fas fa-edit medText pointer"}
                        style={{ fontSize: "18px", margin: "0 10px", float: "right" }}
                      /> */}
                      <br></br>
                      <p>Clinic Location Name: {a.locationName}</p>
                      <p>Clinic Location ID: {a.id}</p>
                    </div>
                    {!isNewCard?.filter((b) => b.locationId === a.id)[0]?.newCard ? (
                      <ClinicCreditCardBillingDD
                        readyOnly={false}
                        locationID={a.id}
                        creditRequested={creditRequested}
                        setCreditCard={setCreditCard}
                        clinicId={props.clinicId}
                        name={`clinicLocationsBillingInfo[` + i + `]['billingInfo']`}
                        required={true}
                      />
                    ) : (
                      <div>
                        {newCardMetaData?.length > 0
                          ? newCardMetaData?.filter((b) => b.locationId === a.id)[0]?.data
                          : null}
                        <span
                          aria-hidden="true"
                          style={{ color: "red", paddingLeft: "50px", cursor: "pointer" }}
                          onClick={() => {
                            setSelectedCreditCard("");
                            updateNewCardCheck(a.id, false);
                          }}
                        >
                          &times;
                        </span>
                      </div>
                    )}
                    {/* <Row className="text-left w-100 mt-3">
                      <Col>
                        <input
                          className="mx-2"
                          type="checkbox"
                          onChange={(e) => {
                            setDefaultForClinic(e.target.checked);
                          }}
                          checked={defaultForClinic}
                        />
                        <span>Set this card as default for the Clinic</span>
                      </Col>
                    </Row> */}
                    {props?.instructions ? <p className="mx-2 redText">{props.instructions}</p> : null}
                    <Row className="text-center w-100 mt-3">
                      <Col>
                        <button
                          className="same-size-button pinkButton my-1"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectNewCardLocation({
                              clinicLocationId: a.id,
                              clinicLocationName: a.locationName,
                              creditRequested: creditRequested,
                            });
                            setNewCardModal(true);
                          }}
                        >
                          ADD NEW CARD
                        </button>
                      </Col>
                    </Row>
                    <hr></hr>
                  </>
                );
              })}
              <br></br> <br></br>
              {/* <p>Total Credit Used: {totalCreditUsed}</p> */}
              <p>
                Total Amount To Be Charged{" "}
                {props.payFullBalance
                  ? (props.totalBalanceDue - totalCreditUsed).toFixed(2)
                  : (selectedTotalAmount - totalCreditUsed).toFixed(2)}
              </p>
              <Row className="text-center w-100">
                <Col>
                  <button type="submit" className="same-size-button blueButton">
                    PAY NOW
                  </button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
        <ActionModal
          showModal={confirmModal}
          closeModal={closeConfirmPaymentModal}
          onClickNo={closeConfirmPaymentModal}
          actionText={"Yes"}
          onClickYes={payNow}
          question={"Are you sure?"}
        />
        <NewCreditCardModal
          isNewCard={isNewCard}
          modalOpen={newCardModal}
          props={props}
          addNewCreditCard={addNewCreditCard}
          setModalOpen={setNewCardModal}
          setSelectNewCardLocation={setSelectNewCardLocation}
          selectNewCardLocation={selectNewCardLocation}
        />
      </div>
    </Modal>
  );
};

export default ConfirmCreditCardModal;
const NewCreditCardModal = (item) => {
  const {
    modalOpen,
    setModalOpen,
    addNewCreditCard,
    props,
    isNewCard,
    setSelectNewCardLocation,
    selectNewCardLocation,
  } = item;
  return (
    <Modal isOpen={modalOpen} scrollable={true} size="md" centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add New Credit Card</h5>
        <button
          type="button"
          onClick={() => {
            setModalOpen(false);
            setSelectNewCardLocation("");
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {selectNewCardLocation?.clinicLocationId ? (
        <div className="modal-body">
          <form
            id={"addNewCreditCardForm" + selectNewCardLocation?.clinicLocationId}
            className="form-horizontal px-sm-4 px-0"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (selectNewCardLocation?.clinicLocationId) {
                addNewCreditCard(e, selectNewCardLocation?.clinicLocationId, selectNewCardLocation?.creditRequested);
              }
              setSelectNewCardLocation("");
            }}
          >
            <Row>
              <div className="mb-4">
                <Label htmlFor="cardHolderName" className="form-label">
                  Card Holder Name <span className="requiredStar">*</span>
                </Label>
                <Input
                  defaultValue={""}
                  name="cardHolderName"
                  className="form-control"
                  type="text"
                  required={modalOpen ? true : false}
                />
              </div>
              <div className="mb-4">
                <Label htmlFor="creditCardNumber" className="form-label">
                  Card Number <span className="requiredStar">*</span>
                </Label>
                <CreditCardInput
                  fieldClassName="form-control"
                  cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                    <input {...props} defaultValue={""} name="cvv" />
                  )}
                  cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                    <input
                      {...props}
                      defaultValue={""}
                      name="cardExpirationDate"
                      inputClassName="form-control"
                      required={modalOpen ? true : false}
                    />
                  )}
                  cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                    <input
                      {...props}
                      defaultValue={""}
                      name="creditCardNumber"
                      inputClassName="form-control"
                      required={modalOpen ? true : false}
                    />
                  )}
                  required={modalOpen ? true : false}
                />
              </div>

              <div className="mb-4">
                <Label htmlFor="cardHolderAddressLine1" className="form-label">
                  Address line 1 <span className="requiredStar">*</span>
                </Label>
                <Input
                  defaultValue={""}
                  name="cardHolderAddressLine1"
                  className="form-control"
                  type="textarea"
                  required={modalOpen ? true : false}
                />
              </div>
              <div className="mb-4">
                <Label htmlFor="cardHolderAddressLine2" className="form-label">
                  Address line 2
                </Label>
                <Input defaultValue={""} name="cardHolderAddressLine2" className="form-control" type="textarea" />
              </div>
              <div className="mb-4">
                <Label htmlFor="cardHolderCity" className="form-label">
                  City <span className="requiredStar">*</span>
                </Label>
                <Input
                  defaultValue={""}
                  name="cardHolderCity"
                  className="form-control"
                  type="text"
                  required={modalOpen ? true : false}
                />
              </div>
              <StateDD defaultValue={""} name="cardHolderState" required={modalOpen ? true : false} />
              <div className="mb-4">
                <Label htmlFor="cardHolderZipcode" className="form-label">
                  Zip code <span className="requiredStar">*</span>
                </Label>
                <Input
                  defaultValue={""}
                  name="cardHolderZipcode"
                  className="form-control"
                  required={modalOpen ? true : false}
                />
              </div>
            </Row>
            <Row className="flexJCAC">
              {!isNewCard?.filter((b) => b.locationId === selectNewCardLocation?.clinicLocationId)[0]?.newCard ? (
                <button className="pinkButton" type="submit">
                  Add Credit Card
                </button>
              ) : null}
            </Row>
          </form>
        </div>
      ) : null}
    </Modal>
  );
};

import React, { useEffect, useState } from "react";
import useAddProductModal from "./index.hook";
import {
  FormGroup,
  Label,
  Modal,
  Row,
  Container,
  Col,
  Card,
  CardBody,
  CardFooter,
  Alert,
  Input,
} from "reactstrap";
import ProductFamilyDD from "components/dropdowns/productFamilyDD";
import SubcategoryOneDD from "components/dropdowns/subcategoryOneDD";
import Spinner from "components/spinner";
import axiosConfig, { errorResponse } from "../../../../utils/axiosConfig";
import { cookies } from "utils";
const AddProductModal = (props) => {
  const { clinics, categories } = props;

  const {
    success,
    setSuccess,
    showSpinner,
    setShowSpinner,
    showModal,
    isEdit,
    closeModal,
    error,
    subcategory1DefaultVal,
    watch,
    singleProduct,
    sigOptions,
    priceGroups,
    priceDosages,
    setPriceDosages,
    // addMore,
    // removeRow,
    setSingleProduct,
    onSubmit,
    register,
    handleSubmit,
    errors,
    fileError,
    setFileError,
    onFileChangeHandler,
    categoryDefaultVal,
    coldShippedDefaultVal,
    medicalAccessoriesDefaultVal,
    controlledSubstanceDefaultVal,
    genderDefaultVal,
    clinicsDefaultVal,
    productImageDefaultVal
  } = useAddProductModal(props);
  const [imageUploading, setImageUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [productImageUploadedURL, setProductImageUploadedURL] = useState(null);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewUrl(event.target.result);
      };
      reader.readAsDataURL(file);
      var uploadImageHeader = {
        headers: {
          Authorization: "Bearer " + cookies.get("user_token"),
          "Content-Type": "multipart/form-data;",
        },
      };
      let formData = new FormData();
        formData.append("file", file);
        setImageUploading(true)
        await axiosConfig
          .post("uploads/uploadFile", formData, uploadImageHeader)
          .then(async (response) => {
            console.log(response);
            const updatedProductImageURL =  response.data.accessUrl;
            setPreviewUrl(updatedProductImageURL);
            setProductImageUploadedURL(updatedProductImageURL);
            setImageUploading(false);
            // this.profileImageUpdated.current = false;
            // this.setState({ profileImageRemoteUrl: updatedProfileImageURL });
          })
          .catch((error) => {
            setImageUploading(false);
            setFileError(
              "An error occurred while trying to upload product image. " +
                error?.response?.data?.message
            );
          });
      
    }
  };
  const fileInputLabelStyle = {
    border: "1px solid #ccc",
    display: "inline-block",
    padding: "6px 12px",
    cursor: "pointer",
  };

  const fileInputStyle = {
    // display: "none",
  };
  const imagePreviewStyle = {
    marginTop: "20px",
    maxWidth: "100%",
    textAlign: "center",
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "200px",
    margin: "0 auto",
    display: "block",
  };
  return (
    <Modal
      isOpen={showModal}
      size="lg"
      centered={true}
      className="largeHeightmodal modal-content "
    >
      <Container>
        <form
          id="registerForm"
          className="form-horizontal product-form mt-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <button
            type="button"
            onClick={() => closeModal()}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          {showSpinner ? <Spinner /> : null}

          <div className="modal-body flexJCAC flex-column text-center">
            <Row className="justify-content-center">
              <h3>{isEdit ? "Edit Product" : "Add Product"}</h3>
            </Row>
            <Card>
                <CardBody className="pt-0">
                  <hr />
                  <Row className={"mt-4 mb-4"}>
                    <Col md={12}>
                      <FormGroup>
                        <Label htmlFor={"file"} className="form-label">
                          {"Upload Product Image "}
                          <span className="requiredStar">*</span>
                        </Label>
                        <input
                          name={"file"}
                          className={`form-control`}
                          type={"file"}
                          ref={register({ required: (!previewUrl && !productImageUploadedURL && !productImageDefaultVal) })}
                          accept={".png, .jpg, jpeg"}
                          onChange={(e) =>
                            {onFileChangeHandler(e.target.files[0])
                              if(!fileError){
                                handleFileChange(e);
                              }
                            }
                          }
                        />
                        {previewUrl ?
                        (imageUploading ? (
                          <Spinner />
                        ) : null): null}
                        {productImageUploadedURL || productImageDefaultVal ? 
                        <div style={imagePreviewStyle}>
                          <img
                            src={productImageUploadedURL || productImageDefaultVal}
                            alt="Product Preview"
                            style={imageStyle}
                          />
                        </div>
                        : null}
                        {fileError && (
                          <span
                            className={"error-product-required"}
                          >{`Only .png, .jpg and .jpeg files are allowed.`}</span>
                        )}
                        <input type="hidden" name="productImage" value={previewUrl || productImageUploadedURL || productImageDefaultVal} ref={register} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={12}>
                      <InputFieldCustom
                        errors={errors}
                        name={"name"}
                        label={"Product name"}
                        register={register}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <ProductFamilyDD
                        categoryDefaultVal={categoryDefaultVal}
                        name={"category"}
                        label={"Select Category"}
                        register={register}
                        required={true}
                        categories={categories}
                      />
                    </Col>
                    <Col md={6}>
                      <SelectDropdownGender
                        selectDefaultVal={genderDefaultVal}
                        errors={errors}
                        name={"gender"}
                        label={"Select Gender"}
                        register={register}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <PriceDosages
                    setPriceDosages={setPriceDosages}
                    priceDosages={priceDosages}
                    register={register}
                    required={true}
                    errors={errors}
                    // addMore={addMore}
                    // removeRow={removeRow}
                  />

                  <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <SelectDropdownClinic
                        selectDefaultVal={clinicsDefaultVal}
                        errors={errors}
                        name={"clinic"}
                        label={"Clinic"}
                        register={register}
                        required={true}
                        clinics={clinics}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    {" "}
                    <h5 className={"mt-4"} style={{ textAlign: "left" }}>
                      Product Detail
                    </h5>
                    <hr></hr>
                    <Col md={12}>
                      <InputFieldCustom
                        errors={errors}
                        name={"description"}
                        label={"Description"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"startsworkingin"}
                        label={"Starts working in"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"lastsupto"}
                        label={"Lasts upto"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={12}>
                      <InputFieldCustom
                        errors={errors}
                        name={"details"}
                        label={"Details"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                </CardBody>
              <CardFooter>
                <div className={"product-card-footer"}>
                  <button onClick={() => closeModal()}>Cancel</button>
                  <button
                    type={"submit"}
                    className={"pinkButton"}
                    disabled={fileError}
                  >
                    {isEdit ? "Save" : "Add"}
                  </button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </form>
      </Container>
    </Modal>
  );
};

export default AddProductModal;

const PriceDosages = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    required = false,
    type = "text",
    errors = "",
    priceDosages,
    setPriceDosages
  } = props;
  
  const handleDosageChange = (index, field, value) => {
    const updatedDosageInputs = [...priceDosages];
    updatedDosageInputs[index][field] = value;
    setPriceDosages(updatedDosageInputs);
  };

  const addAnotherAddress = () => {
    setPriceDosages((prevAddresses) => [
      ...prevAddresses,
      {
        _id: '',
        unitPrice: '',
        dosage: ''
      }
    ])
  };

  const cancelAddress = (index) => {
    // console.log('remove',index,priceDosages.filter((el,i) => i !== index ))
    setPriceDosages((prevAddresses) => prevAddresses.filter((el, i) => i !== index));
  };

  return (<>
  <div>
      <h4 className='semiBold mt-1'>Price & Dosages</h4>
      {priceDosages?.map((address, i) => {
      return (
        <div id={`priceDosages-` + (address?._id || i)} key={i}>
         <Row className={"mt-2 mb-2"}>
         <Col md={4}>
           <InputFieldCustom
             defaultValue={address?.unitPrice}
             errors={errors}
             name={"priceDosage["+(address?._id || i)+"][unitPrice]"}
             label={"Retail price"}
             placeholder={"10"}
             register={register}
             required={true}
             handleDosageChange={(e) => handleDosageChange(i, 'unitPrice', e.target.value)}
           />
         </Col>
         <Col md={4}>
           <InputFieldCustom
             defaultValue={address?.dosage}
             errors={errors}
             name={"priceDosage["+(address?._id || i)+"][dosage]"}
             label={"Dosage"}
             placeholder={"5mg"}
             register={register}
             required={true}
             handleDosageChange={(e) => handleDosageChange(i, 'dosage', e.target.value)}
           />
         </Col>
        <Col md={4} className='align-self-end'>
          <button
            type='button'
            className='redText'
            onClick={(e) => {
              e.preventDefault();
              cancelAddress(i);
            }}
          >
            X
          </button>
          </Col>
        </Row>
      </div>
      )})}
      <button 
      type='button'
      className='pinkButton mt-1'
      onClick={() => addAnotherAddress()}>
        Add
      </button>
    </div>
  </>);
};

const InputFieldCustom = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    required = false,
    type = "text",
    errors = "",
    defaultValue = "",
    handleDosageChange
  } = props;
  let isRequiredError = false;

  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <input
      defaultValue={defaultValue}
        name={name}
        placeholder={placeholder || ""}
        className={`form-control`}
        type={type}
        ref={register({ required })}
        onChange={handleDosageChange}
      />
      {isRequiredError && (
        <span
          className={"error-product-required"}
        >{`${label} should not empty.`}</span>
      )}
    </FormGroup>
  );
};

const SelectDropdownGender = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    selectDefaultVal = "",
    required = false,
    type = "text",
    errors = null,
  } = props;

  const isRequiredError =
    !!errors &&
    !!errors[name] &&
    !!errors[name].type &&
    errors[name].type === "required" &&
    required;
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <select
        name={name}
        value={selectDefaultVal}
        ref={register({ required })}
        style={{
          borderRadius: "5px",
          paddingBottom: "0px",
          width: "100%",
          height: "40px",
          borderColor: "#CDCDCD",
        }}
      >
        <option value="" disabled selected>
          {"Select"}
        </option>
        <option value="1">{"Male"}</option>
        <option value="2">{"Female"}</option>
      </select>
      {isRequiredError && (
        <span
          className={"error-product-required"}
        >{`${label} should not empty.`}</span>
      )}
    </FormGroup>
  );
};

const SelectDropdownClinic = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    selectDefaultVal = "",
    required = false,
    clinics = [],
    errors = null,
  } = props;
  const isRequiredError =
    !!errors &&
    !!errors[name] &&
    !!errors[name].type &&
    errors[name].type === "required" &&
    required;
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>

      <select
        multiple
        name={name}
        value={selectDefaultVal}
        ref={register({ required })}
        style={{
          borderRadius: "5px",
          paddingBottom: "0px",
          width: "100%",
          height: "100px",
          borderColor: "#CDCDCD",
        }}
      >
        <option value="" disabled selected>
          {"Select"}
        </option>
        {clinics?.map((v, i) => {
          return (
            <option key={i} value={v.id}>
              {v.businessName}
            </option>
          );
        })}
      </select>
      {isRequiredError && (
        <span
          className={"error-product-required"}
        >{`${label} should not empty.`}</span>
      )}
    </FormGroup>
  );
};

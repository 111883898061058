import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap"
import Lottie from '../../components/lottie';

class Page404 extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Page Not Found | Get Royal Health</title>
          </MetaTags>
          <Container fluid className="text-center">
            <Lottie name="404" height={300} width={(window.innerWidth > 576) ? (500) : (300)} loop={true} />
            <p>Oops! Looks like this page does not exist.</p>
            <button className="pinkButton mt-3" onClick={() => {this.props.history.goBack()}}>Go Back</button>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Page404

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { IsLoggedSelector } from "selectors";
import { isLoggedIn } from "../utils";

const AuthRoute = ({ component: Component, ...rest }) => {
  const loginSelector = useSelector(IsLoggedSelector);
  const cookieLogin = isLoggedIn();

  const isLogin = useMemo(() => loginSelector && cookieLogin, [cookieLogin, loginSelector]);

  return <Route {...rest} render={(props) => (isLogin ? <Redirect to="/" /> : <Component {...props} />)} />;
};

export default AuthRoute;

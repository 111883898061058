export const PRESCRIPTION_TYPES = {
  pending: 'pending',
  history: 'history',
  archive: 'archive',
};

export const PRESCRIPTION_URL = {
  [PRESCRIPTION_TYPES.pending]: '/pending-prescriptions',
  [PRESCRIPTION_TYPES.history]: '/prescription-history',
  [PRESCRIPTION_TYPES.archive]: '/prescription-archive',
};

export const PRESCRIPTION_CARD_LABELS = {
  [PRESCRIPTION_TYPES.pending]: 'Prescriptions',
  [PRESCRIPTION_TYPES.history]: 'RX WORKFLOW',
  [PRESCRIPTION_TYPES.archive]: 'Prescriptions',
};

export const PRESCRIPTION_BUTTONS = {
  [PRESCRIPTION_TYPES.pending]: {
    type: PRESCRIPTION_TYPES.pending,
    url: PRESCRIPTION_URL[PRESCRIPTION_TYPES.pending],
    label: 'Order in Process',
  },
  // [PRESCRIPTION_TYPES.history]: {
  //   type: PRESCRIPTION_TYPES.history,
  //   url: PRESCRIPTION_URL[PRESCRIPTION_TYPES.history],
  //   label: 'RX Workflow',
  // },
  [PRESCRIPTION_TYPES.archive]: {
    type: PRESCRIPTION_TYPES.archive,
    url: PRESCRIPTION_URL[PRESCRIPTION_TYPES.archive],
    label: 'Completed Orders',
  },
};

export const PRESCRIPTION_META_LABELS = {
  [PRESCRIPTION_TYPES.pending]: {
    label: 'Prescriptions | Get Royal Health',
  },
  [PRESCRIPTION_TYPES.history]: {
    label: 'Prescriptions | RX Workflow',
  },
  [PRESCRIPTION_TYPES.archive]: {
    label: 'Prescriptions Archive | RX Workflow',
  },
};

export const RX_STATUS = ['NEW ORDER - RTR', 'CUSTOM ORDER - RTR', 'HOLD - C', 'HOLD - M', 'HOLD - PP', 'HOLD - REFILL - REMAINING*', 'HOLD - REFILL - REAUTH', 'HOLD - FTS', 'CHECK STOCK', 'RTC', 'TASKED', 'LOT REWORK', 'IN PROCESS', 'IN BIN - RTD', 'DISPENSED - RTV', 'RX VERIFIED - RTP', 'RX VERIFIED - STORAGE', 'RX VERIFIED - FRIDGE', 'HOLD - PD', 'RTP', 'PACK VERIFIED - RTS', 'RECEIVED', 'CANCELLED'];

export const RX_STATUS_PEND = [
  'Hold',
  'In Process',
  'Received',
  'Cancelled'
];

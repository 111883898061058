/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./assets/scss/theme.scss";
import "./assets/styles/datatables.scss";
import "./assets/styles/global.css";
import AuthRoute from "./routes/authRoute";
import PrivateRoute from "./routes/privateRoute";

//Common
import Page404 from "./screens/common/page404";
import NoAccessScreen from "./screens/common/pageInaccessible";

// Auth screens
import Login from "./screens/Authentication/Login";
import ForgetPassword from "./screens/Authentication/forgotPassword";
import LogOut from "./screens/Authentication/logout";
import RecoverPassword from "./screens/Authentication/RecoverPassword";

import VerifyEmail from "./screens/Authentication/verifyEmail";
import { refreshTokenCookies } from "utils";
import SuperAdmin from "./superAdminComponents";
import Admin from "./adminComponents.js";


const App = () => {
  useEffect(() => {
    refreshTokenCookies();
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {/* auth screens */}
          <Route path="/login" component={Login} exact />
          <Route path="/logout" component={LogOut} exact />
          <AuthRoute path="/forgot-password" component={ForgetPassword} exact />
          <AuthRoute path="/reset-password/:token" component={RecoverPassword} exact />
          <Route path="/verify-email/:token" component={VerifyEmail} exact />

          <Route path="/access-denied" component={NoAccessScreen} exact />


          {/* clinic */}
          <PrivateRoute
            path="/clinics"
            superAdminComponent={SuperAdmin.ClinicsList}
            adminComponent={Page404}
            exact
          />
          <PrivateRoute
            path="/add-clinic"
            superAdminComponent={SuperAdmin.AddClinic}
            adminComponent={Page404}
            exact
          />

          <PrivateRoute
            path="/clinic-products/:id"
            superAdminComponent={SuperAdmin.ClinicProducts}
            adminComponent={Page404}
            exact
          />

          <PrivateRoute
            path="/clinic-profile/:id"
            superAdminComponent={SuperAdmin.ClinicProfile}
            adminComponent={Admin.ClinicProfile}
            exact
          />

          <PrivateRoute
            path="/edit-clinic/:id"
            superAdminComponent={SuperAdmin.EditClinic}
            adminComponent={Admin.EditClinic}
            exact
          />

          {/* invoices */}
          <PrivateRoute
            path="/invoices"
            superAdminComponent={SuperAdmin.InvoiceList}
            adminComponent={Page404}
            exact
          />
          <PrivateRoute
            path="/invoice/:id"
            superAdminComponent={SuperAdmin.InvoiceDetail}
            adminComponent={Page404}
            exact
          />
          
          {/* patients */}
          <PrivateRoute
            path="/patients"
            superAdminComponent={SuperAdmin.PatientsList}
            adminComponent={Page404}
            exact
          />
          
          <PrivateRoute
            path="/edit-patient/:id"
            superAdminComponent={SuperAdmin.ManagePatient}
            adminComponent={Page404}
            exact
          />
          
          <PrivateRoute
            path="/patient-profile/:id"
            superAdminComponent={SuperAdmin.PatientProfile}
            adminComponent={Page404}
            exact
          />

          {/* products */}
          <PrivateRoute
            path="/products"
            superAdminComponent={SuperAdmin.Products}
            adminComponent={Page404}
            exact
          />

          {/* main screens */}
          <PrivateRoute
            path="/profile"
            superAdminComponent={SuperAdmin.Profile}
            adminComponent={Admin.Profile}
            exact
          />
          <PrivateRoute
            path="/admin-profile/:id"
            superAdminComponent={SuperAdmin.AdminProfile}
            exact
          />
          <PrivateRoute
            path="/edit-profile/:id"
            superAdminComponent={SuperAdmin.EditAdmin}
            adminComponent={Admin.EditAdmin}
            exact
          />
          <PrivateRoute
            path="/dashboard"
            superAdminComponent={SuperAdmin.Dashboard}
            adminComponent={Admin.Dashboard}
            exact
          />
          <PrivateRoute
            path="/add-admin"
            superAdminComponent={SuperAdmin.AddAdmin}
            exact
          />
          <PrivateRoute
            path="/admins"
            superAdminComponent={SuperAdmin.AdminsList}
            exact
          />
          <PrivateRoute
            path="/"
            superAdminComponent={SuperAdmin.Dashboard}
            adminComponent={Admin.Dashboard}
            exact
          />

          <PrivateRoute
            path="/pending-prescriptions"
            superAdminComponent={SuperAdmin.PrescriptionsSection}
            adminComponent={Admin.PrescriptionsSection}
            exact
          />
          <PrivateRoute
            path="/prescription-history"
            superAdminComponent={SuperAdmin.PrescriptionsSection}
            adminComponent={Page404}
            exact
          />
          <PrivateRoute
            path="/prescription-archive"
            superAdminComponent={SuperAdmin.PrescriptionsSection}
            adminComponent={Page404}
            exact
          />

          <Route path="*" component={Page404} exact />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;

import { sortBy } from "lodash";
import { AdminApi } from "../../rtkApi";
import { API_METHODS, ADMIN_END_POINT, CACHE_TAGS } from "../../rtkConstant";

const AdminQuery = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminList: builder.query({
      query: () => ADMIN_END_POINT.GET_ALL_STAFF_IN_CLINIC,
      transformResponse: (response) => sortBy(response, ["family"]),
    }),
    getAdmin: builder.query({
      query: (apiArgs) => ({
        url: ADMIN_END_POINT.GET_ADMIN + "/" + apiArgs.params,
        method: API_METHODS.GET,
      }),
    }),
    getAllAdmin: builder.query({
      keepUnusedDataFor: 0.1,
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINT.GET_ALL_ADMINS,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            
            return response;
          }
        } catch {}
      },
    }),
  }),
});
export const { useLazyGetAdminListQuery, useLazyGetAdminQuery, useLazyGetAllAdminQuery } = AdminQuery;

import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import Spinner from "../../../components/spinner";
import PrimaryModal from "../../../components/primaryModal";
import { USER_ROLES } from "utils";
import { filter, first } from "lodash";
import { useLazyClinicAllDataQuery } from "rtk/rtkEndpoints/ClinicProfile";
import { useEditClinicMutation } from "rtk/rtkEndpoints/ClinicProfile/ClinicProfileMutation";
import NavBar from "components/NavBar";
import { useDeleteClinicMutation } from "rtk/rtkEndpoints/Clinics";

const ClinicProfile = (props) => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [clinic, setClinic] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);


  const [
    fetchClinicData,
    { data: clinicData, isFetching: isFetching, isError: isClinicDataError, error: clinicDataError },
  ] = useLazyClinicAllDataQuery();
  const [editClinicData, { isLoading: isEditLoading, isError: isEditError, error: editError }] =
    useEditClinicMutation();
  const [deleteClinic, { isLoading: isDeleteLoading, isError: isDeleteError, error: deleteError }] =
  useDeleteClinicMutation();

  const showLoading = useMemo(
    () => isFetching || isDeleteLoading || isEditLoading,
    [isFetching, isDeleteLoading, isEditLoading]
  );
  const showError = useMemo(() => isClinicDataError[isClinicDataError]);
  const errorMessage = useMemo(() => clinicDataError?.data?.message, [clinicDataError?.data?.message]);

  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);

  const clinicSide = userRole?.toLowerCase().includes("clinic");
  const condition = userRole === USER_ROLES.CLINIC_SUPER_USER;
  const [clinicId, setClinicId] = useState(userInfo?.clinicId);
  const access =
    !clinicSide &&
    (userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.PHARMACIST || userRole === USER_ROLES.RX_TECH);

  //var locationInfo = [];
  useEffect(() => {
    getClinicInfo();
  }, []);

  const getClinicInfo = async () => {
    let arg = {
      params: clinicId ? clinicId : props.match.params.id,
    };
    const response = await fetchClinicData(arg);
    console.log(response)
    setClinic({
      ...response.data,
      clinicLocationId: first(filter(response.data?.clinicLocations || [], "isPrimaryLocation"))?.id,
    });
  };

  function closeDeleteModal() {
    setDeleteModal(false);
  }

  const handleDelete = async () => {
    setDeleteModal(false);
    setShowSpinner(true);
    let arg = {
      // payload: {
        clinicId: clinic.id,
        userId: userInfo.id,
      // },
    };
    const response = await deleteClinic(arg);
    if (response?.data) {
      props.history.push("/clinics");
    }
  };

  

  return (
    <React.Fragment>
      <NavBar />
      {showLoading ? <Spinner /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Clinic Profile | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          {showError ? (
            <Alert color="danger" className="text-center">
              {errorMessage}
            </Alert>
          ) : null}
          <h4 className="p-3 text-muted">CLINIC PROFILE</h4>
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft text-center py-5">
                  <h1>
                    {/* <i className="fas fa-user text-dark"></i> */}
                  </h1>
                  <h2 className="text-dark mb-5">{clinic.businessName}</h2>

                  {clinicSide ||
                  userRole === USER_ROLES.ADMIN_SUPER_USER  ? (
                    <p>
                      <Link to={"/edit-clinic/" + clinic.id} className="w-20 pinkButton">
                        Edit Clinic Info
                      </Link>
                    </p>
                  ) : null}
                  <br></br>
                </div>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Clinic Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col xs={4} sm={3} md={6} className="semiBold">
                      <p>Business Name</p>
                    </Col>
                    <Col xs={4} sm={3} md={6}>
                      <p>{clinic.businessName}</p>
                    </Col>
                    <hr></hr>
                  </Row>
                  <p className="text-center">
                    {userRole === USER_ROLES.ADMIN_SUPER_USER ||
                    userRole === USER_ROLES.PHARMACIST ||
                    userRole === USER_ROLES.RX_TECH ? (
                      <Link
                        to="#"
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                        className="w-30 text-center redButton"
                      >
                        Delete Clinic
                      </Link>
                    ) : null}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle>Contact Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col xs={2} sm={3} md={3} className="semiBold">
                      <p>Email Address</p>
                      <p>Office Phone</p>
                      <p>Address Line 1</p>
                      <p>Address Line 2</p>
                      <p>City</p>
                      <p>State</p>
                      <p>Zip Code</p>
                      {/* <p>Fax</p> */}
                      {clinic.referredBy && <p>Referred By:</p>}
                    </Col>
                    <Col xs={7} sm={9} md={9}>
                      <p>{clinic.email}</p>
                      <p>{clinic.officePhone}</p>
                      <p>{clinic.addressLine1}</p>
                      <p>{clinic.addressLine2 ? clinic.addressLine2 : "-"}</p>
                      <p>{clinic.city}</p>
                      <p>{clinic.state}</p>
                      <p>{clinic.zipcode}</p>
                      {/* <p>{clinic.fax ?? "-"}</p> */}
                      {clinic.referredBy && <p style={{ textTransform: "capitalize" }}>{clinic.referredBy}</p>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                <Col>
                  <Link to={'/products'}
                  >
                    <Card>
                      <CardBody className="text-center p-5">
                        <i className="fas fa-pills medText fa-3x mb-3" />
                        <h4>Products</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col>
                
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <PrimaryModal
        question="Are you sure you want to delete this clinic?"
        showModal={deleteModal}
        closeModal={closeDeleteModal}
        onClickYes={handleDelete}
      />
    </React.Fragment>
  );
};

export default ClinicProfile;

import { Input, Spinner } from "reactstrap";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAdminPrescriptionNoteMutation } from "rtk";

function PrescriptionNotes({ id, notesMessage = "", setCustomError }) {
  const [showSave, setShowSave] = useState(false);
  const [note, setNote] = useState(() => notesMessage ?? "");
  const [updateNotes, { error: notesError, isSuccess: notesSuccess, isLoading: notesLoading }] =
    useAdminPrescriptionNoteMutation();

  const errorMessage = useMemo(() => notesError?.data?.message || "", [notesError?.data?.message]);

  useEffect(() => {
    if (notesSuccess) {
      setShowSave(false);
    } else if (errorMessage) {
      setCustomError(errorMessage);
    }
  }, [errorMessage, notesSuccess, setCustomError]);

  const checkInput = useCallback(() => setShowSave(note !== notesMessage), [notesMessage, note]);

  const renderSaveBtn = useMemo(() => {
    if (showSave) {
      return (
        <button
          disabled={notesLoading}
          className="yellowButton mt-1"
          style={{ fontsize: "8px" }}
          onClick={() => updateNotes({ prescriptionId: id, message: note, pageName: "AdminPendingPrescription" })}
        >
          {notesLoading ? <Spinner size="sm">...</Spinner> : "Save"}
        </button>
      );
    }
  }, [id, note, notesLoading, showSave, updateNotes]);

  return (
    <>
      <Input
        style={{ width: "150px", fontSize: "12px" }}
        type="textarea"
        defaultValue={notesMessage}
        onBlur={checkInput}
        onChange={(e) => {
          setNote(e.target.value);
          if (!showSave) {
            setShowSave(true);
          }
        }}
      />
      {renderSaveBtn}
    </>
  );
}

export default React.memo(PrescriptionNotes);

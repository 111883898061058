import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Modal, Row } from "reactstrap";
import Lottie from "./lottie";
import { Link } from "react-router-dom";
export default function TextModal(props) {
  const { redirection = true } = props;
  const [complete, setComplete] = useState(false);

  return (
    <Modal isOpen={props.showModal} size="md" centered={true} className="smallHeightmodal">
      <>
        <div>
          <button
            type="button"
            onClick={() => {
              props.closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body flexJCAC flex-column text-center">
            <h3 className="m-3">Transaction Detail</h3>
            <p>{props.textInfo}</p>{" "}
          </div>
        </div>
      </>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Cookies from 'universal-cookie';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';

const PriceGroupDD = (props) => {
  const [priceGroups, setPriceGroups] = useState([]);

  const [priceGroup, setPriceGroup] = useState(props.defaultValue);

  useEffect(() => {
    setPriceGroup(props.defaultValue);
  }, [props.defaultValue]);
  useEffect(() => {
    setPriceGroup(props.defaultValue);
    axiosConfig
      .get(
        'admin/productDiscounts/getCurrentPriceGroups',

        {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
          },
        }
      )
      .then(async (response) => {
        var priceGroupsList = [];

        await response.data.forEach((priceGroup, i) => {
          priceGroupsList.push(
            <option key={priceGroup + i} value={priceGroup}>
              {priceGroup}
            </option>
          );
        });

        setPriceGroups(priceGroupsList);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, []);

  const changePriceGroup = (pG) => {
    setPriceGroup(pG);
    props.changePriceGroup(props.clinicId, pG);
  };

  return (
    <p>
      <span className='mx-3'>Price Group</span>
      <span>
        <select type='select' name={props.name} onChange={(e) => changePriceGroup(e.target.value)} required={props.required} style={{ padding: '2px', borderRadius: '5px', height: '30px', borderColor: '#CDCDCD' }} value={priceGroup}>
          <option value='' disabled>
            Select
          </option>

          {priceGroups}
        </select>
      </span>
    </p>
  );
};

export default PriceGroupDD;

//Dashboard screens
import Dashboard from "./screens/admin/Dashboard/dashboard";
import { PatientsList } from "screens/admin/Patients";
import { ClinicsList } from "screens/admin/Clinics";
import AddAdmin from "screens/admin/Dashboard/addAdmin";
import AdminsList from "screens/admin/Admins/list";

import AddClinic from "./screens/admin/Clinics/AddClinic.jsx";
import { InvoiceList } from "./screens/common/Invoices/InvoiceList";
import { InvoiceDetail } from "./screens/common/Invoices/InvoiceDetail";
import EditClinic from "./screens/admin/Clinic/edit";
import { ClinicProfile } from "./screens/common/Clinic";
import AdminProfile from "screens/admin/Admins/adminProfile";
import Profile from "screens/admin/Admins/profile";
import EditAdmin from "screens/admin/Admins/edit";
import { PrescriptionsSection, AdminPendingPrescription } from "./screens/admin/Prescriptions";
import { ProductListing as Products } from "./screens/admin/Products/index";
import { ClinicProductList as ClinicProducts } from "./screens/admin/Products/ClinicProductList";
const Admin = {
  Dashboard,
  PatientsList,
  // AddAdmin,
  ClinicsList,
  // AdminsList,
  AdminPendingPrescription,
  InvoiceList,
  InvoiceDetail,
  AddClinic,
  ClinicProfile,
  EditClinic,
  // AdminProfile,
  Profile,
  EditAdmin,
  PrescriptionsSection,
  Products,
  ClinicProducts,
};

export default Admin;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Alert } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import PrimaryModal from "../../../components/primaryModal";
import moment from "moment";
import axios from "axios";
import { ROWS_LIMIT, USER_ROLES } from "utils";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Iconify from "../../../components/iconify";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAdminListing } from "./Hook";
import { useDeleteAdminMutation, useLazyGetAllAdminQuery } from "rtk";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import AlertMessage from "../Prescriptions/components/alert_message";
import { ListingSearch } from "components";

const AdminsList = () => {
  // const { clinicId = "" } = useParams();
  // const { state: { isPrescription = false, clinicLocationId = "" } = {} } =
  //   useLocation();
  const [error, setError] = useState("");
  // const [patientId, setPatientId] = useState("");
  const [filteredData, setFilterData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  // const [showSpinner, setShowSpinner] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [disableFilter, setDisableFilter] = useState(true);
  const [apiParam, setApiParam] = useState();
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: filteredData.length,
    custom: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const isClinic = userRole.toLowerCase().includes("clinic");

  const {
    sorting,
    adminId,
    rowLimit,
    tablePage,
    showSpinner,
    errorMessage,
    totalRecords,
    admins,
    successMessage,
    searchKeyword,
    setSorting,
    clearFilter,
    applyFilter,
    refetchList,
    adminAction,
    updateKeyword,
    changeRowLimit,
    toggleAdminModal,
    handleChangePage,
    selectedRow,
    setSelectedRow,
  } = useAdminListing({
    listingQuery: useLazyGetAllAdminQuery,
    listingName: "GetAdminsList",
    listingMutation: useDeleteAdminMutation,
  });

  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // const [
  //   deleteMutate,
  //   { isLoading, isError: isDeleteError, error: deleteError, isSuccess },
  // ] = useDeletePatientMutation();

  // const showSpinner = useMemo(
  //   () => showLoading || isLoading,
  //   [ isLoading,
  //     showLoading]
  // );
  // const successMessage = useMemo(
  //   () => isSuccess ?? "",
  //   [isSuccess]
  // );
  // const combinedErrorMessage = useMemo(
  //   () => errorMessage || isDeleteError || deleteError,
  //   [errorMessage || isDeleteError || deleteError]
  // );
  // useEffect(()=>{
  //   if(isSuccess)
  //     refetchList();
  // },[isSuccess])

  const RenderActionButtons = ({ original }) => {
    return new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenu(e);
            setSelectedRow(original);
          }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </>
    ) : (
      // <span className={"button-product-action-container"}>
      //   <i
      //     className="fas fa-edit medText"
      //     onClick={() => setEditProduct(original)}
      //   />
      //   <i
      //     className="fas fa-trash redText"
      //     onClick={() => setDeleteProduct(original)}
      //   />
      // </span>
      <span>Disabled</span>
    );
  };

  const AdminName = ({ row }) => {
    return (
      row.firstName +
      " " +
      (row.middleName ? row.middleName + " " : "") +
      row.lastName
    );
  };
  const VerifiedLabel = ({ cellContent }) => {
    return cellContent.isVerified ? (
      <Iconify icon="mdi:account-check" sx={{ mr: 2 }} />
    ) : (
      <Iconify icon="mdi:account-clock-outline" sx={{ mr: 2 }} />
    );
  };

  const RoleLabel = ({ cellContent }) => {
    return cellContent == "AdminSuperUser" ? "Super Admin" : "Admin";
  };

  const tableHeader = useMemo(
    () => [
      {
        text: "Admin Name",
        dataField: "id",
        formatter: (cellContent, row) => <AdminName row={row} />,
      },
      { text: "Email", dataField: "email" },
      {
        text: "Role",
        dataField: "role",
        formatter: (cellContent, row) => (
          <RoleLabel cellContent={cellContent} />
        ),
      },
      {
        text: "Created",
        dataField: "created",
        formatter: (cellContent, row) =>
          moment(cellContent, "YYYY-MM-DD").format("MM/DD/YYYY"),
      },
      {
        text: "Verified",
        dataField: "isVerified",
        formatter: (cellContent, row) => <VerifiedLabel cellContent={row} />,
      },
      {
        text: "",
        dataField: "action",
        formatter: (cellContent, row) => <RenderActionButtons original={row} />,
      },
    ],
    []
  );

  //     page: 1,
  //     sizePerPage: 10,
  //     showOrderButton: false,
  //     showSpinner: true,
  //     confirmDeleteModal: false,
  //     deleteAdminId: "",
  //     error: "",
  //     data: [],
  //     tableHeaders: [
  //       { text: "id", dataField: "id", sort: true, hidden: true },
  //       { text: "Admin Name", dataField: "adminName", sort: true },
  //       { text: "Email", dataField: "email", sort: true },
  //       { text: "Role", dataField: "role", sort: true },
  //       { text: "Date created", dataField: "created", sort: true },
  //       {
  //         text: "Verified",
  //         dataField: "verified",
  //         sort: true,
  //         headerStyle: () => {
  //           return { width: "80px", textAlign: "text-center" };
  //         },
  //       },
  //       {
  //         text: "Actions",
  //         dataField: "actions",
  //         headerStyle: () => {
  //           return { width: "80px", textAlign: "center" };
  //         },
  //         formatter: (cellContent, row) => (
  //           <>
  //             <Link to={"/admin-profile/" + row.id} className="pinkButton">
  //               Profile
  //             </Link>
  //             {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ||
  //             row.id === new Cookies().get("user_id") ? (
  //               <Link to={"/edit-profile/" + row.id}>
  //                 <i className="fas fa-edit medText mx-3" style={{ fontSize: "16px" }}></i>
  //               </Link>
  //             ) : null}
  //             {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER &&
  //               row.id !== new Cookies().get("user_id") && (
  //                 <Link
  //                   to="#"
  //                   onClick={() => {
  //                     this.setState({ deleteAdminId: row.id, confirmDeleteModal: true });
  //                   }}
  //                 >
  //                   <i className="fas fa-trash redText" style={{ fontSize: "18px" }}></i>
  //                 </Link>
  //               )}
  //           </>
  //         ),
  //       },
  //     ],
  //     keyword: "",
  //     disableFilter: true,
  //   };
  // }

  // deleteAdmin() {
  //   this.setState({
  //     showSpinner: true,
  //     confirmDeleteModal: false,
  //   });

  //   axiosConfig
  //     .delete("admin/account/deleteAdminByAdminId", {
  //       headers: {
  //         Authorization: "Bearer " + new Cookies().get("user_token"),
  //       },
  //       data: {
  //         adminId: this.state.deleteAdminId,
  //         userId: new Cookies().get("user_id"),
  //       },
  //     })
  //     .then(async (response) => {
  //       await this.setState({
  //         showSpinner: false,
  //         error: "",
  //         data: this.state.data.filter((row) => row.id !== this.state.deleteAdminId),
  //         deleteAdminId: "",
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         showSpinner: false,
  //         error: "An error occurred while trying to delete the patient!",
  //       });
  //       errorResponse(error);
  //     });
  // }

  // closeConfirmDeleteModal() {
  //   this.setState({ confirmDeleteModal: false });
  // }
  // axiosConfig
  //   .post(
  //     "/admin/account/getAdmins",
  //     {
  //       userId: new Cookies().get("user_id"),
  //       keyword: keyword,
  //       limit: 20,
  //       currentPage: 1,
  //     },
  //     {
  //       headers: {
  //         Authorization: "Bearer " + new Cookies().get("user_token"),
  //       },
  //     }
  //   )
  //   .then(async (response) => {
  //     var dataList = [];
  //     await response?.data?.admins?.forEach((admin) => {
  //       dataList.push({
  //         id: admin.id,
  //         adminName: admin.firstName + " " + (admin.middleName ? admin.middleName + " " : "") + admin.lastName,
  //         email: admin.email,
  //         role:
  //           admin.role === USER_ROLES.PHARMACIST
  //             ? "Admin"
  //             : admin.role === USER_ROLES.ADMIN_SUPER_USER
  //             ? "Super User"
  //             : admin.role === USER_ROLES.RX_TECH
  //             ? "RX Tech"
  //             : admin.role === USER_ROLES.SALES_REP
  //             ? "Sales Rep"
  //             : "",
  //         created: moment(admin.created, "YYYY-MM-DD").format("MM/DD/YYYY"),
  //         verified: admin.isVerified ? (
  //           <i className="fas fa-user-check greenText" style={{ fontSize: "16px" }} />
  //         ) : (
  //           <i className="fas fa-user-times redText" style={{ fontSize: "16px" }} />
  //         ),
  //       });
  //     });

  return (
    <React.Fragment>
      {showSpinner ? <Spinner /> : null}
      <SuperAdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Admins | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4">Admins</Typography>

            {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
              <Link to="/add-admin">
                <Button
                  variant="contained"
                  color="inherit"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Admin
                </Button>
              </Link>
            ) : null}
          </Stack>

          <AlertMessage msg={successMessage} />
          <AlertMessage msg={errorMessage} isError={true} />
          <Row>
            <Col xs="12">
              <Card className="listing-card">
                <ListingSearch
                  searchKeyword={searchKeyword}
                  applySearch={applyFilter}
                  clearFilter={clearFilter}
                  updateKeyword={updateKeyword}
                  leftChildren={
                    <Tooltip title="Refresh">
                      <Button variant="contained" onClick={refetchList}>
                        <RefreshIcon style={{ width: 20, height: 20 }} />
                      </Button>
                    </Tooltip>
                  }
                />
                <CardBody>
                  <React.Fragment>
                    {/* <Row className="justify-content-between px-3 my-3">
                        <Row className="my-4 px-sm-3 px-1">
                          <Col xs={8} sm={9} md={4} lg={2}>
                            <div className="tableSearch d-flex">
                              <i className="bx bx-search-alt search-icon flexJCAC" />
                              <input
                                className="borderless mx-2"
                                onChange={(e) => {
                                  this.filterChanged(e.target.value);
                                  this.clearSearch(e.target.value);
                                }}
                                value={this.state.keyword}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.getAllAdmins(this.state.keyword);
                                  }
                                }}
                                placeholder="Search table"
                              />
                            </div>
                          </Col>
                          <Col xs={4} sm={3} md={4} lg={2}>
                            <button
                              disabled={this.state.disableFilter}
                              className="yellowButton"
                              onClick={() => {
                                this.getAllAdmins(this.state.keyword);
                              }}
                            >
                              Search
                            </button>
                          </Col>
                          {!this.state.disableFilter && (
                            <Col xs={12} sm={1} lg={1}>
                              <p
                                disabled={this.state.disableFilter}
                                className="blueText"
                                style={{
                                  fontSize: 14,
                                  cursor: "pointer",
                                  paddingTop: 8,
                                  marginLeft: 10,
                                }}
                                onClick={() => {
                                  this.clearFilter();
                                  this.setDisableFilter(true);
                                }}
                              >
                                Clear
                              </p>
                            </Col>
                          )}
                        </Row>
                      </Row> */}
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive"
                          style={{ minHeight: "300px" }}
                        >
                          <BootstrapTable
                            responsive
                            keyField={"id"}
                            bordered={false}
                            striped={false}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            columns={tableHeader}
                            data={admins}
                          />

                          {admins.length === 0 ? (
                            <p className="w-100 text-center m-5">
                              No Admins data found!
                            </p>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Popover
                      open={!!open}
                      anchorEl={open}
                      onClose={handleCloseMenu}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      PaperProps={{
                        sx: { width: 140 },
                      }}
                    >
                      <Link to={"/admin-profile/" + selectedRow?.id}>
                        <MenuItem onClick={() => handleCloseMenu()}>
                          <Iconify icon="eva:eye-fill" sx={{ mr: 2 }} />
                          Profile
                        </MenuItem>
                      </Link>
                      <Divider />
                      <Link to={"/edit-profile/" + selectedRow?.id}>
                        <MenuItem onClick={() => handleCloseMenu()}>
                          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                          Edit
                        </MenuItem>
                      </Link>
                      {selectedRow?.role != USER_ROLES.ADMIN_SUPER_USER &&
                      <MenuItem
                        onClick={() => (
                          toggleAdminModal(selectedRow?.id), handleCloseMenu()
                        )}
                        sx={{ color: "error.main" }}
                      >
                        <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
                        Delete
                      </MenuItem>}
                    </Popover>
                    <Row className="align-items-md-center mt-3 px-3">
                      <div className="text-center ms-auto">
                        <TablePagination
                          component="div"
                          showFirstButton
                          showLastButton
                          page={tablePage}
                          count={totalRecords}
                          rowsPerPage={rowLimit}
                          rowsPerPageOptions={ROWS_LIMIT}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={changeRowLimit}
                          style={{ alignItems: "center", marginBottom: 0 }}
                        />
                      </div>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <PrimaryModal
        question="Are you sure you want to delete this admin?"
        showModal={Boolean(adminId)}
        closeModal={toggleAdminModal}
        onClickYes={adminAction}
      />
    </React.Fragment>
  );
};

export default AdminsList;

/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import GRHLogo from "assets/images/logo.svg";
import classNames from "classnames";
import { usePrintPage } from "components/Hooks";
import Spinner from "components/spinner";
import { DialogContext } from "context";
import { decrypt } from "cryptofunc";
import { capitalize, filter, find, flatten, includes, map, some } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useAdminAcknowledgePrescriptionMutation,
  useGetClinicReviewPrescriptionQuery,
} from "rtk";
import AlertMessage from "screens/admin/Prescriptions/components/alert_message";
import { userRoleSelector } from "selectors";
import { ALLOWED_ACTION_USERS, USERS_EDIT_PRESCRIPTION } from "utils";
import { dateFormatterMountainTime } from "utils/constants";

function OrderViewModal({
  showAcknowledge = false,
  isReview = false,
  prescriptionId = "",
  listingName = "adminPending",
  handleClose,
}) {
  const acknowledgeRef = useRef();
  const history = useHistory();
  const { showDialog, closeDialog } = useContext(DialogContext);
  const userRole = useSelector(userRoleSelector);
  const [
    acknowledgeMutate,
    {
      isSuccess: acknowledgeSuccess,
      isLoading: acknowledgeLoading,
      error: acknowledgeError,
    },
  ] = useAdminAcknowledgePrescriptionMutation();

  const {
    data: invoiceData,
    isFetching,
    error,
  } = useGetClinicReviewPrescriptionQuery(
    { prescriptionId },
    { skip: !prescriptionId }
  );

  const { componentRef, printLoading, printModal } = usePrintPage(
    invoiceData?.invoiceNumber
  );
  const isBulkInvoice = useMemo(
    () => invoiceData?.subPrescriptions?.length > 0,
    [invoiceData?.subPrescriptions?.length]
  );

  const decryptedBillingInfo = useMemo(() => {
    let info = {};
    if (invoiceData?.billingDetails?.creditCardNumber) {
      info.creditCardNumber = decrypt(invoiceData?.billingDetails?.creditCardNumber);
      info.cardExpirationDate = invoiceData?.billingDetails?.cardExpirationDate;
    }
    return info;
  }, [invoiceData?.billingDetails?.creditCardNumber]);

  const allowedAcknowledge = useMemo(
    () => includes(ALLOWED_ACTION_USERS, userRole),
    [userRole]
  );
  const isAllowedEditingPrescription = useMemo(
    () => some(USERS_EDIT_PRESCRIPTION, (role) => role === userRole),
    [userRole]
  );
  const shippingCost = useMemo(
    () => invoiceData?.shippingCost,
    [invoiceData?.shippingCost]
  );
  const showLoading = useMemo(
    () => acknowledgeLoading || printLoading,
    [acknowledgeLoading, printLoading]
  );
  const errorMessage = useMemo(
    () => acknowledgeError?.data?.message || error?.data?.message,
    [acknowledgeError?.data?.message, error?.data?.message]
  );
  const showActionModal = useMemo(
    () => allowedAcknowledge && showAcknowledge,
    [allowedAcknowledge, showAcknowledge]
  );
  const showActionButton = useMemo(() => {
    if (isReview) {
      return isAllowedEditingPrescription;
    } else {
      return true;
    }
  }, [isAllowedEditingPrescription, isReview]);
  const disableActions = useMemo(
    () => isFetching || showLoading || printLoading,
    [isFetching, printLoading, showLoading]
  );

  const prescriptionTableColumns = useMemo(
    () => (
      <TableHead>
        <TableRow>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
            Medication
          </TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
            RX Number
          </TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
            Quantity
          </TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
            Unit Price ($)
          </TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
            Discounted Price ($)
          </TableCell>
          <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
            Total Price ($)
          </TableCell>

          {/* {isReview && (
            <>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Diagnosis</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Weight</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Unit Price($)</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Discounted Price($)</TableCell>
              <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>Total Price($)</TableCell>
            </>
          )} */}
        </TableRow>
      </TableHead>
    ),
    [isReview]
  );

  useEffect(() => {
    if (acknowledgeSuccess) {
      toggleConfirmationModal();
      handleClose?.();
    }
  }, [acknowledgeSuccess]);

  const closeModal = useCallback(() => {
    handleClose?.();
  }, [handleClose]);

  const prescriptionAcknowledgeAction = useCallback(() => {
    acknowledgeMutate({
      action: acknowledgeRef.current,
      prescriptionId,
      pageName: "AdminPendingPrescription",
    });
  }, [acknowledgeMutate, prescriptionId]);

  const toggleConfirmationModal = useCallback(
    (acknowledge = "") => {
      acknowledgeRef.current = acknowledge;
      if (acknowledge) {
        showDialog({
          question: `Are you sure you want to ${acknowledge?.toLowerCase()} this prescription?`,
          actionText: acknowledge,
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: prescriptionAcknowledgeAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [prescriptionAcknowledgeAction, showDialog]
  );

  const actionClick = useCallback(() => {
    if (isReview) {
      history.push({
        pathname: "/edit-prescription/" + prescriptionId,
        state: {
          isReview: true,
          side: listingName,
          prescriptionStatus: invoiceData?.prescriptionStatus,
          shippingCost1: shippingCost,
        },
      });
    } else {
      printModal();
    }
  }, [
    history,
    invoiceData?.prescriptionStatus,
    isReview,
    listingName,
    prescriptionId,
    printModal,
    shippingCost,
  ]);

  const approveAction = useCallback(
    (e) => {
      e.preventDefault();
      toggleConfirmationModal("Approve");
    },
    [toggleConfirmationModal]
  );

  const disapproveAction = useCallback(
    (e) => {
      e.preventDefault();
      toggleConfirmationModal("Disapprove");
    },
    [toggleConfirmationModal]
  );

  const renderColumnItem = useCallback(
    (title = "", value = "", rowCol = 4, rightCol = 4, leftCol = 8) => (
      <Grid container item xs={rowCol}>
        <Grid item xs={rightCol}>
          <strong>{`${title}:`} </strong>
        </Grid>
        <Grid item xs={leftCol}>
          {value}
        </Grid>
      </Grid>
    ),
    []
  );

  // const getClinicAddress = useCallback(
  //   (invoiceDetail) => (
  //     <>
  //       {invoiceDetail?.clinicLocationDetails.locationName}
  //       <br />
  //       {invoiceDetail?.clinicLocationDetails.addressLine1 + ","}
  //       <br />
  //       {invoiceDetail?.clinicLocationDetails.addressLine2}
  //       <br />
  //       {`${invoiceDetail?.clinicLocationDetails.city}, ${invoiceDetail?.clinicLocationDetails.state}`},
  //       <br />
  //       {invoiceDetail?.clinicLocationDetails.zipcode}
  //     </>
  //   ),
  //   []
  // );

  // const clinicInfo = useCallback(
  //   (invoiceDetail) => (
  //     <>
  //       <Divider>Clinic & Prescriber Information</Divider>
  //       <Grid container spacing={1}>
  //         {/* {renderColumnItem("Clinic Name", invoiceDetail?.clinicDetails?.businessName, 8, 2, 0)}
  //         {renderColumnItem("Clinic Address", getClinicAddress(invoiceDetail), 8, 2, 0)} */}
  //         <Grid container item>
  //           {/* {renderColumnItem(
  //             "Clinic Phone",
  //             invoiceDetail?.clinicLocationDetails?.officeContactPhone ||
  //               invoiceDetail?.clinicLocationDetails?.contactNumber,
  //             8,
  //             2,
  //             0
  //           )}
  //           {renderColumnItem("Clinic Fax", invoiceDetail?.clinicLocationDetails?.officeContactFax, 4, 3, 0)} */}
  //         </Grid>
  //         {renderColumnItem(
  //           "Prescriber Name",
  //           `${invoiceDetail?.providerDetails?.firstName ?? ""} ${invoiceDetail?.providerDetails?.lastName ?? ""}`,
  //           8,
  //           2,
  //           0
  //         )}
  //         <Grid container item>
  //           {renderColumnItem("NPI #", invoiceDetail?.providerDetails?.npi, 8, 2, 0)}
  //           {renderColumnItem("DEA #", invoiceDetail?.providerDetails?.dea ?? "-", 4, 3, 0)}
  //         </Grid>
  //         <Grid container item>
  //           {renderColumnItem("State License #", invoiceDetail?.providerDetails?.medicalLicenseNumber ?? "-", 8, 2, 0)}
  //           {renderColumnItem("License State", invoiceDetail?.providerDetails?.medicalLicenseStatus ?? "-", 4, 3, 0)}
  //         </Grid>
  //       </Grid>
  //     </>
  //   ),
  //   [renderColumnItem]
  // );

  const billingInfo = useCallback(() => {
    // if (!isReview) {
    //   return null;
    // }
    return (
      <>
        {/* <Divider>Billing Information</Divider> */}
        <Grid container spacing={1}>
          <Grid container item>
            {renderColumnItem("Billed To", invoiceData?.billTo, 12, 3, 0)}
            {/* {renderColumnItem(
              "Card holder",
              decryptedBillingInfo?.cardHolderName ?? "",
              4,
              3,
              0
            )} */}
            {renderColumnItem(
              "Credit Card #",
              `**** **** **** ${decryptedBillingInfo?.creditCardNumber?.substring(
                decryptedBillingInfo?.creditCardNumber.length - 4
              )}`,
              12,
              3,
              0
            )}
            {renderColumnItem(
              "Expiration Date",
              decryptedBillingInfo?.cardExpirationDate,
              12,
              3,
              0
            )}
            {renderColumnItem(
              "Billing Address",
              [
                invoiceData?.billingInfo?.addressLine1,
                invoiceData?.billingInfo?.addressLine2,
                invoiceData?.billingInfo?.state,
                invoiceData?.billingInfo?.city,
                invoiceData?.billingInfo?.zipcode
              ].join(" "),
              12,
              3,
              0
            )}
          </Grid>
          {/* {renderColumnItem(
            "NET 30",
            capitalize(invoiceData?.isNet30Approved?.toString()) ?? "-",
            8,
            2,
            0
          )} */}
        </Grid>
      </>
    );
  }, [
    decryptedBillingInfo?.cardExpirationDate,
    // decryptedBillingInfo?.creditCard?.cardHolderName,
    decryptedBillingInfo?.creditCardNumber,
    invoiceData?.billTo,
    invoiceData?.isNet30Approved,
    isReview,
    renderColumnItem,
  ]);

  const patientInfo = useCallback(
    (patientId, invoiceDetail) => {
      // patients => for single invoice ,   patientsDetail => for bulk invoice
      const patientList =
        invoiceDetail?.patients ?? invoiceDetail?.patientsDetail ?? [];
      const patientInfo = find(patientList, { id: patientId });
      const patientAddress = patientInfo?.addresses?.[0];
      console.log(patientInfo);
      return (
        <>
          {/* <Divider className="my-3">Patient Information</Divider> */}
          <Grid container spacing={1}>
            <Grid container item>
              {renderColumnItem(
                "Patient Name",
                `${invoiceData?.patientName} `,
                12,
                3,
                0
              )}
              {/* {renderColumnItem("DOB", patientInfo?.dateOfBirth ?? "-", 4, 3, 0)} */}
            </Grid>
            {/* {renderColumnItem(
              "Patient Address",
              `${patientAddress?.addressLine1 ?? ""} ${patientAddress?.addressLine2 ?? "-"}, ${
                patientAddress?.city ?? "-"
              }, ${patientAddress?.state ?? "-"}, ${patientAddress?.zipcode ?? "-"}`,
              8,
              2,
              0
            )} */}
            {/* <Grid container item>
              {renderColumnItem("Phone Number", patientInfo?.phoneNumber || patientInfo?.cellPhone || "-", 8, 2, 0)}
              {renderColumnItem("Email", patientInfo?.email ?? "-", 4, 3, 0)}
            </Grid> */}
          </Grid>
        </>
      );
    },
    [renderColumnItem]
  );

  const renderDeliveryInfo = useCallback(() => {
    const shippingInfo = invoiceData?.shippingInfo;
    const isPickup = shippingInfo?.shippingMethod
      ?.toLowerCase()
      .includes("pickup");
    return (
      <>
        {renderColumnItem(
          "Delivery Method",
          shippingInfo?.shippingMethod,
          12,
          3,
          0
        )}
        {!isPickup &&
          renderColumnItem(
            "Delivery Address",
            <>
              <i>Ship To:</i> {shippingInfo?.shipTo}
              <br />
              {shippingInfo?.shippingAddress?.addressLine1}
              <br />
              {shippingInfo?.shippingAddress?.addressLine2 ?? "-"}
              <br />
              {shippingInfo?.shippingAddress?.city},{" "}
              {shippingInfo?.shippingAddress?.state}
              <br />
              {shippingInfo?.shippingAddress?.zipcode}
            </>,
            12,
            1.35,
            0
          )}
      </>
    );
  }, [invoiceData?.shippingInfo, renderColumnItem]);

  const renderInvoiceBillingInfo = useCallback(
    (invoiceDetail) => {
      if (!isReview) {
        return null;
      }
      return (
        <>
          <Grid container className="mt-3" rowSpacing={1}>
            {Boolean(invoiceDetail?.creditRequested) && (
              <>
                <Grid item xs={3} md={2} />
                <Grid item xs={3} md={2} className="text-start" />
                <Grid item md={4} className="d-none d-md-block" />

                <Grid item xs={3} md={2} className="text-end">
                  Credit Used
                </Grid>
                <Grid item xs={3} md={2} className="text-end">
                  <strong>
                    $ {parseFloat(invoiceDetail?.creditRequested).toFixed(2)}
                  </strong>
                </Grid>
              </>
            )}

            <Grid item xs={3} md={2}>
              Priority Order
            </Grid>
            <Grid item xs={3} md={2} className="text-start">
              <strong>
                {invoiceDetail?.shippingInfo?.isRushOrder ? "Yes" : "No"}
              </strong>
            </Grid>
            <Grid item md={4} className="d-none d-md-block" />

            <Grid item xs={3} md={2} className="text-end">
              Sub Total
            </Grid>
            <Grid item xs={3} md={2} className="text-end">
              <strong>
                ${" "}
                {isBulkInvoice
                  ? parseFloat(invoiceDetail?.subTotal).toFixed(2)
                  : parseFloat(
                      invoiceDetail?.totalAmount -
                        (invoiceDetail?.shippingCost ?? 0) -
                        (invoiceDetail?.shippingInfo?.rushOrderCost ?? 0)
                    ).toFixed(2)}
              </strong>
            </Grid>
          </Grid>
          {!isBulkInvoice && (
            <Grid container className="my-2">
              <Grid item xs={3} md={2}>
                Shipping Method
              </Grid>
              <Grid item xs={3} md={2} className="text-start">
                <strong>{invoiceDetail?.shippingInfo?.shippingMethod}</strong>
              </Grid>
              <Grid item md={4} className="d-none d-md-block" />

              <Grid item xs={3} md={2} className="text-end">
                Shipping
              </Grid>
              <Grid item xs={3} md={2} className="text-end">
                <strong>${invoiceDetail?.shippingCost ?? "0"}</strong>
              </Grid>
            </Grid>
          )}
          {invoiceDetail?.shippingInfo?.isRushOrder ? (
            <>
              <Grid container>
                <Grid item xs={3} md={2}>
                  Signature Required
                </Grid>
                <Grid item xs={3} md={2} className="text-start">
                  <strong>
                    {invoiceDetail?.shippingInfo?.isSignatureRequired
                      ? "Yes"
                      : "No"}
                  </strong>
                </Grid>
                <Grid item md={4} className="d-none d-md-block" />
                <Grid item xs={3} md={2} className="text-end">
                  Priority Order Fee
                </Grid>
                <Grid item xs={3} md={2} className="text-end">
                  <strong>
                    $ {invoiceDetail?.shippingInfo?.rushOrderCost}
                  </strong>
                </Grid>
              </Grid>
              {!isBulkInvoice && (
                <Grid container className="mt-2" justifyContent={"flex-end"}>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>Total</strong>
                  </Grid>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>
                      $ {parseFloat(invoiceDetail?.totalAmount).toFixed(2)}
                    </strong>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid container>
              <Grid item xs={3} md={2}>
                Signature Required
              </Grid>
              <Grid item xs={3} md={2} className="text-start">
                <strong>
                  {invoiceDetail?.shippingInfo?.isSignatureRequired
                    ? "Yes"
                    : "No"}
                </strong>
              </Grid>
              {!isBulkInvoice && (
                <>
                  <Grid item md={4} className="d-none d-md-block" />
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>Total</strong>
                  </Grid>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>
                      $ {parseFloat(invoiceDetail?.totalAmount).toFixed(2)}
                    </strong>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </>
      );
    },
    [isReview, isBulkInvoice]
  );

  const renderInvoiceTable = useCallback(
    (invoiceTableData, invoiceRxPadProducts = [], invoiceDetail) => {
      return (
        <>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                {prescriptionTableColumns}
                <TableBody>
                  {map(invoiceTableData, (tableProduct) => {
                    const actualProduct = find(invoiceDetail?.products, {
                      productId: tableProduct?.productId,
                    });
                    return (
                      <TableRow key={`${actualProduct?._id}`}>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {actualProduct?.name ?? "-"}
                          {/* <Typography>
                            <b>Sig:</b> {actualProduct?.sig?.toUpperCase() ?? "-"}
                          </Typography> */}
                        </TableCell>
                        <TableCell
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            textAlign: "center",
                          }}
                        >
                          {actualProduct?.rxNumber ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            textAlign: "center",
                          }}
                        >
                          {actualProduct?.quantity ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            textAlign: "center",
                          }}
                        >
                          {actualProduct?.unitPrice ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            textAlign: "center",
                          }}
                        >
                          {actualProduct?.discountedPrice ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            textAlign: "right",
                          }}
                        >
                          {parseFloat(
                            actualProduct?.unitPrice * actualProduct?.quantity
                          ).toFixed(2)}
                          {/* {actualProduct?.last_office_visit_timestamp
                            ? moment(actualProduct.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
                            : "-"} */}
                        </TableCell>
                        {/* {isReview && (
                          <>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.patientDiagnosis ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.weight ?? "-"}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.unitPrice}
                            </TableCell>
                            <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                              {actualProduct?.discountedPrice}
                            </TableCell>
                            <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>
                              {parseFloat(actualProduct?.discountedPrice * actualProduct?.quantity).toFixed(2)}
                            </TableCell>
                          </>
                        )} */}
                      </TableRow>
                    );
                  })}
                  {invoiceRxPadProducts?.map((customProduct) => {
                    return (
                      <TableRow key={`${customProduct?._id}`}>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {customProduct?.name ?? "-"}
                          {/* <Typography>
                            <b>Sig:</b> {customProduct?.sig?.toUpperCase() ?? "-"}
                          </Typography> */}
                        </TableCell>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {customProduct?.quantity ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {customProduct?.refills ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {customProduct?.daySupply ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {customProduct?.reasonForCompoundedMedication ?? "-"}
                        </TableCell>
                        <TableCell
                          style={{ paddingRight: "4px", paddingLeft: "4px" }}
                        >
                          {customProduct?.last_office_visit_timestamp
                            ? moment(
                                customProduct.last_office_visit_timestamp,
                                "YYYY-MM-DD"
                              ).format("MM/DD/YYYY")
                            : "-"}
                        </TableCell>
                        {isReview && (
                          <>
                            <TableCell
                              style={{
                                paddingRight: "4px",
                                paddingLeft: "4px",
                              }}
                            >
                              {customProduct?.patientDiagnosis ?? "-"}
                            </TableCell>
                            <TableCell
                              style={{
                                paddingRight: "4px",
                                paddingLeft: "4px",
                              }}
                            >
                              {customProduct?.weight ?? "-"}
                            </TableCell>
                            <TableCell
                              style={{
                                paddingRight: "4px",
                                paddingLeft: "4px",
                              }}
                            >
                              {customProduct?.unitPrice}
                            </TableCell>
                            <TableCell
                              style={{
                                paddingRight: "4px",
                                paddingLeft: "4px",
                              }}
                            >
                              {customProduct?.discountedPrice}
                            </TableCell>
                            <TableCell
                              style={{
                                paddingRight: "0px",
                                paddingLeft: "4px",
                              }}
                            >
                              {parseFloat(
                                customProduct?.discountedPrice *
                                  customProduct?.quantity
                              ).toFixed(2)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {renderInvoiceBillingInfo(invoiceDetail)}
          {/* <Grid item xs={12} className="mt-5">
            <strong> Order Notes</strong>
          </Grid> */}
          {/* <Divider variant="fullWidth" className="my-1" /> */}
          {/* <Typography>{invoiceData?.shippingInfo?.orderNotes ?? "-"}</Typography> */}
          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <Grid item>
              {/* {!isReview && (
                <>
                  <strong>Priority Order Fee: </strong> &nbsp; {invoiceDetail?.shippingInfo?.isRushOrder ? "Yes" : "No"}
                  <Divider />
                </>
              )} */}
            </Grid>
            <Grid item>
              {/* <img src={invoiceDetail?.signatureFileUrl} alt="Signature" width={180} /> */}
              <p>
                {/* <strong>Signed by:</strong>
                {` ${invoiceDetail?.providerDetails?.firstName ?? ""} ${
                  invoiceDetail?.providerDetails?.lastName ?? ""
                } `}
                <br /> */}
                {/* <strong>On: </strong> {dateFormatterMountainTime(invoiceDetail?.prescriptionDate)} */}
              </p>
              <Divider />
            </Grid>
          </Grid>
        </>
      );
    },
    [isReview, invoiceData?.shippingInfo?.orderNotes, prescriptionTableColumns]
  );

  const renderInvoiceView = useCallback(
    (invoiceDetail) => {
      const patientId = invoiceDetail?.patientId ?? '';
      let invoiceProducts = invoiceDetail?.products;
      let invoiceRxPadProducts = invoiceDetail?.rxPadProducts || [];
      // const clinicView = clinicInfo(invoiceDetail);
      const billingView = billingInfo(invoiceDetail);
      const deliveryView = renderDeliveryInfo();

      // return map(patientIds, (patientId) => {
        // Following condition for find the product detail according to patientProducts.
        if (invoiceDetail?.patientsProducts > 0) {
          const filteredPatient = filter(invoiceDetail?.patientsProducts, {
            patientId,
          });
          invoiceProducts = flatten(
            map(filteredPatient, (filteredPatient) =>
              map(filteredPatient?.products, (patientProduct) =>
                find(invoiceDetail.products, {
                  productId: patientProduct.productId,
                })
              )
            )
          );
        }
        return (
          <div key={patientId} className="page-break">
            <Card
              sx={{ boxShadow: 5 }}
              className={classNames({
                "my-3": isBulkInvoice || patientId.length > 1,
              })}
            >
              <CardContent>
                <Grid container rowSpacing={2}>
                  <Grid item xs={12}>
                    <img src={GRHLogo} alt="logo" height="40" />
                  </Grid>
                  <Divider className="my-3"></Divider>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      Get Royal Health
                      <br />
                      <br />
                      200 Moore drive,
                      <br />
                      Bloomfield,
                      <br />
                      Michigan
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      alignItems={"right"}
                      style={{ textAlign: "right" }}
                    >
                      Invoice Number: {invoiceData?.invoiceNumber}
                      <br />
                      Invoice Date:{" "}
                      {moment(invoiceData?.orderDate).format("YYYY-MM-DD")}
                    </Grid>
                  </Grid>
                  <Divider className="my-3"></Divider>
                  {/* {clinicView} */}
                  {/* <Divider className="my-3"></Divider> */}
                  <Grid container item spacing={1}>
                    <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid container item>
                        {renderColumnItem(
                          "Patient Name",
                          `${invoiceData?.patientName} `,
                          12,
                          3,
                          0
                        )}
                        {/* {renderColumnItem("DOB", patientInfo?.dateOfBirth ?? "-", 4, 3, 0)} */}
                      </Grid>
                      {/* {renderColumnItem(
                        "Patient Address",
                        `${patientAddress?.addressLine1 ?? ""} ${patientAddress?.addressLine2 ?? "-"}, ${
                          patientAddress?.city ?? "-"
                        }, ${patientAddress?.state ?? "-"}, ${patientAddress?.zipcode ?? "-"}`,
                        8,
                        2,
                        0
                      )} */}
                      {/* <Grid container item>
                        {renderColumnItem("Phone Number", patientInfo?.phoneNumber || patientInfo?.cellPhone || "-", 8, 2, 0)}
                        {renderColumnItem("Email", patientInfo?.email ?? "-", 4, 3, 0)}
                      </Grid> */}
                    </Grid>
                      {/* {patientInfo(patientId, invoiceDetail)} */}
                    </Grid>
                    <Grid item xs={6}>
                      {billingView}
                    </Grid>
                  </Grid>
                  <Divider className="my-3"></Divider>
                  <Grid container item spacing={1}>
                    <Grid item xs={12}>
                      {renderColumnItem('Delivery Method',invoiceData?.deliveryMethod,12, 2, 0)}
                    </Grid>
                    <Grid item xs={12}>
                      {renderColumnItem('Tracking Number',invoiceData?.trackingNumber,12, 2, 0)}
                    </Grid>
                  </Grid>
                  <Divider className="my-3"></Divider>
                  <strong>Prescription Order</strong>
                  {isReview && (
                    <Grid container spacing={1}>
                      {deliveryView}
                    </Grid>
                  )}
                  {renderInvoiceTable(
                    invoiceProducts,
                    invoiceRxPadProducts,
                    invoiceDetail
                  )}
                </Grid>
                <Divider variant="fullWidth" className="my-3" />
                <Grid item spacing={1} style={{ textAlign: "right" }}>
                  Shipping Cost ($): 0.00
                  <br />
                  Sub Total: {parseFloat(invoiceDetail?.subTotal).toFixed(2)}
                  <br />
                  Total Amount ($):{" "}
                  {invoiceDetail?.products
                    .reduce(
                      (prevValue, currValue) =>
                        (prevValue ?? 0) +
                        (currValue?.quantity ?? 0) *
                          (currValue?.unitPrice ?? 0),
                      0.0
                    )
                    .toFixed(2)}
                </Grid>
                <Divider variant="fullWidth" className="my-3" />
              </CardContent>
            </Card>
          </div>
        );
      // });
    },
    [isBulkInvoice, patientInfo, renderDeliveryInfo, renderInvoiceTable]
  );

  const renderInvoice = useMemo(() => {
    // if (isBulkInvoice) {
    //   return map(invoiceData?.subPrescriptionList, (subPrescription) => (
    //     <React.Fragment key={subPrescription.id}>
    //       {renderInvoiceView(subPrescription)}
    //     </React.Fragment>
    //   ));
    // }
    return renderInvoiceView(invoiceData);
  }, [invoiceData, isBulkInvoice, renderInvoiceView]);

  return (
    <Dialog
      open={Boolean(prescriptionId)}
      onClose={closeModal}
      scroll="paper"
      fullWidth
      maxWidth="xl"
    >
      {showLoading && <Spinner />}
      <DialogTitle variant="h3">
        {isReview ? "Review Prescription" : "Rx Order"}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className="mt-1" ref={componentRef}>
            <AlertMessage isError msg={errorMessage} />
            {renderInvoice}
          </Box>
        )}
      </DialogContent>
      <DialogActions className="justify-content-between">
        <Box>
          {showActionModal && (
            <>
              {/* <Button
                disabled={disableActions}
                variant="contained"
                className="greenButton"
                style={{ marginRight: 5 }}
                onClick={approveAction}
              >
                Approve Prescription
              </Button>
              <Button disabled={disableActions} variant="contained" className="redButton" onClick={disapproveAction}>
                Disapprove Prescription
              </Button> */}
            </>
          )}
        </Box>
        {showActionButton && (
          <Button
            disabled={disableActions}
            variant="contained"
            className="pinkButton"
            onClick={actionClick}
          >
            {isReview ? "Edit" : "Print"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(OrderViewModal);

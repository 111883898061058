import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { useAdminUpdateRxOrderInputMutation } from "rtk";
import { userRoleSelector } from "selectors";
import { USER_ROLES } from "utils";

function PrescriptionOrderInput({ id, rxOrderInput = false, setCustomError }) {
  const userRole = useSelector(userRoleSelector);
  const [updateOrderInput, { error: orderInputError, isLoading: orderInputLoading }] =
    useAdminUpdateRxOrderInputMutation();

  useEffect(() => {
    if (orderInputError) {
      setCustomError?.(orderInputError);
    }
  }, [orderInputError, setCustomError]);

  const updateRxInput = useCallback((e) => {
    e.stopPropagation();
    updateOrderInput({ prescriptionIds: [id], rxOrderInput: e.target.checked });
  }, []);

  const loading = useMemo(() => {
    if (orderInputLoading) {
      return (
        <Spinner size="sm" color="primary" style={{ marginLeft: "0.5rem" }}>
          ...
        </Spinner>
      );
    } else {
      return <span style={{ width: "1.5rem" }} />;
    }
  }, [orderInputLoading]);

  return (
    <div className="d-flex align-items-center">
      <label className="switch mb-0">
        {userRole === USER_ROLES.ADMIN_SUPER_USER ||
        userRole === USER_ROLES.PHARMACIST ||
        userRole === USER_ROLES.RX_TECH ? (
          <input type="checkbox" id={"rx-order-input-" + id} onChange={updateRxInput} defaultChecked={rxOrderInput} />
        ) : (
          <input type="checkbox" id={"rx-order-input-" + id} checked={rxOrderInput} />
        )}
        <span className="slider round"></span>
      </label>
      {loading}
    </div>
  );
}
export default React.memo(PrescriptionOrderInput);

import { useEffect, useState } from "react";
import { encrypt, decrypt } from "cryptofunc";
import { first } from "lodash";
import Cookies from "universal-cookie";
import axiosConfig, { errorResponse } from "utils/axiosConfig";
import "./index.scss";
import { useLazyClinicAllDataQuery } from "rtk";
const useConfirmCreditCardModal = (props) => {
  const {
    clinicId,
    invoiceId,
    creditCardModal,
    closeModal,
    selectCompleteRowData,
    selectedTotalAmount,
  } = props;
  const [selectedCreditCard, setSelectedCreditCard] = useState("");
  const [newCardMetaData, setNewCardMetaData] = useState([]);
  const [newCardModal, setNewCardModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isNewCard, setNewCard] = useState([]);
  const [billTo, setBillTo] = useState("clinic");
  const [defaultForClinic, setDefaultForClinic] = useState(false);
  const [error, setError] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectClinicLocationIds, setSelectedClinicLocationIds] = useState(false);
  const [selectRxIds, setSelectedRxIds] = useState(false);
  const [creditRequested, setCreditRequested] = useState("");
  const [creditApplied, setCreditApplied] = useState(0);
  const [credit, setCredit] = useState(0);
  const [creditUsed, setCreditUsed] = useState(false);
  const [clinicLocationsInfo, setClinicLocationsInfo] = useState([]);
  const [requestedCreditsForClinicLocations, setRequestedCreditsForClinicLocations] = useState([]);
  const [totalAmountToBeCharged, setTotalAmountToBeCharged] = useState(props.selectedTotalAmount);
  const [totalCreditUsed, setTotalCreditUsed] = useState(0);
  const [
    fetchClinicData,
    { data: clinicData, isFetching: isFetching, isError: isClinicDataError, error: clinicDataError },
  ] = useLazyClinicAllDataQuery();
  const [creditCardWithClinicLocationId, setCreditCardWithClinicLocationId] = useState([]);
  const [encryptedBillingInfo, setEncryptedBillingInfo] = useState("");
  const [selectedClinicLocationId, setSelectedClinicLocationId] = useState("");
  const [selectNewCardLocation, setSelectNewCardLocation] = useState("");

  useEffect(() => {
    if (clinicId) {
      let arg = {
        params: clinicId,
      };
      fetchClinicData(arg);
    }

    // if (creditCardModal === true) {
    //   let arg = {
    //     params: clinicId,
    //   };

    // }
  }, [clinicId]);

  useEffect(() => {
    // console.log("---CREDIT START---");
    // console.log("CREDIT and CARD REQUESTED: ", creditRequested);
    // console.log("SELECTED CREDIT CARD: ", selectedCreditCard);
    // console.log("Clinic Location: ", selectedClinicLocationId);
    //console.log("=PREV CREDIT UPDATED= ", creditRequested);
    let creditLocInfo = creditCardWithClinicLocationId;
    //console.log(creditLocInfo);
    const indexToUpdate = creditLocInfo.findIndex(
      (location) => location.clinicLocationId === creditRequested?.clinicLocationId
    );
    if (indexToUpdate === -1) {
    } else {
      // If the object is present in the original array, update it
      creditLocInfo[indexToUpdate].creditRequested = creditRequested?.creditReq ?? 0;
      //console.log("CREDIT UPDATED: ", creditLocInfo);
      if (creditLocInfo[indexToUpdate].encryptedBillingInfo) {
        let info11 = creditLocInfo[indexToUpdate].encryptedBillingInfo;
        let decryptedInfo = decrypt(info11);
        //console.log("DECRYPTED INFO: ", decryptedInfo);
      }
    }
    setCreditCardWithClinicLocationId(creditLocInfo);
    let tCr = 0;
    for (let i = 0; i < creditLocInfo.length; i++) {
      tCr += parseFloat(creditLocInfo[i].creditRequested);
    }
    setTotalCreditUsed(tCr);
  }, [creditRequested, clinicId]);

  useEffect(() => {
    if (clinicId) {
      let arg = {
        params: clinicId,
      };
      fetchClinicData(arg);
    }
  }, [creditRequested]);

  const updateInfo = (e, id) => {
    var serialize = require("form-serialize");
    var form = document.getElementById("refillForm");
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });
    var form = document.getElementById("refillForm");
  };

  const updateTotalAmountToBeCharged = (credit) => {
    let totalAmount = totalAmountToBeCharged - credit;
    setTotalAmountToBeCharged(totalAmount);
  };

  const paymentAction = (e) => {
    e.preventDefault();
    setConfirmModal(true);
  };

  const saveInfo = (e) => {
    e.preventDefault();
    setConfirmModal(true);
  };

  useEffect(() => {
    getCreditInfo();
    setError("");
  }, [clinicId, invoiceId, creditCardModal]);

  useEffect(() => {
    if (creditCardModal) {
      const clinicLocationIds = new Set();
      const prescriptionIds = new Set();
      let clinicLocationIdsList = [];
      let prescriptionIdsList = [];
      let filteredLocations = [];
      let isNewCardList = [];
      if (props.payFullBalance) {
        filteredLocations = clinicData?.clinicLocations;
      } else {
        if (selectCompleteRowData?.length > 0) {
          for (const item of selectCompleteRowData) {
            clinicLocationIds.add(item.clinicLocationId);
            prescriptionIds.add(item.id);
          }
          //clinicLocationIds, prescriptionIds contains unique Ids.
          clinicLocationIdsList = Array.from(clinicLocationIds);
          prescriptionIdsList = Array.from(prescriptionIds);
          setSelectedRxIds(prescriptionIdsList);
          setSelectedClinicLocationIds(clinicLocationIdsList);

          filteredLocations = clinicData?.clinicLocations?.filter((location) =>
            clinicLocationIdsList.includes(location.id)
          );
        }
      }
      filteredLocations?.forEach((a) => isNewCardList.push({ locationId: a.id, newCard: false }));
      setNewCard(isNewCardList);
      setClinicLocationsInfo(filteredLocations);

      // console.log(selectCompleteRowData, clinicLocationIdsList, prescriptionIdsList);
    }
  }, [selectCompleteRowData, clinicData, selectedTotalAmount, creditCardModal]);

  const updateNewCardCheck = (clinicLocationId = "", bool = false) => {
    //New Card Check Update
    let newCardList = isNewCard;
    const indexToUpdateNewCard = isNewCard.findIndex((isNew) => isNew.locationId === clinicLocationId);
    newCardList[indexToUpdateNewCard].newCard = bool;
    // console.log("NEW CARD: ", newCardList);
    setNewCard(newCardList);
    //setNewCardMetaData(newCardMetaData?.filter((location) => location.locationId !== clinicLocationId));
    //
  };

  async function setCreditCard(card, billTo, clinicLocationId = "", creditRequested = "") {
    if (clinicLocationsInfo?.length > 0 && isNewCard?.length > 0) {
      setBillTo(billTo);
      setSelectedCreditCard(card);
      //New Card Check Update
      let newCardList = isNewCard;
      const indexToUpdateNewCard = isNewCard.findIndex((isNew) => isNew.locationId === clinicLocationId);
      newCardList[indexToUpdateNewCard].newCard = false;
      setNewCard(newCardList);
      //
      let creditEntry = {
        encryptedBillingInfo: encrypt({
          creditCardOwner: billTo,
          isNewCardUsed: false,
          creditCard: card,
        }),
        clinicLocationId: clinicLocationId,
        creditRequested: creditRequested.creditReq,
      };
      // console.log("---SET CARD START---");
      // console.log("Credit CardNumber: ", card.creditCardNumber);
      // console.log("Credit Requested: ", creditRequested.creditReq ?? 0);
      // console.log("Clinic Location Id: ", clinicLocationId);
      // console.log("Before Adding this above Card: ", creditCardWithClinicLocationId);
      // console.log("---------");
      let creditCardClinicInfoList = creditCardWithClinicLocationId;
      // Find the index of the object to update or insert
      const indexToUpdate = creditCardWithClinicLocationId.findIndex(
        (location) => location.clinicLocationId === creditEntry.clinicLocationId
      );
      //console.log("FIND INDEX?? ", indexToUpdate);
      if (indexToUpdate === -1) {
        // If the object is not present in the original array, insert it
        const objectToInsert = {
          clinicLocationId: creditEntry.clinicLocationId,
          encryptedBillingInfo: creditEntry.encryptedBillingInfo,
          creditRequested: creditRequested.creditReq ?? 0,
        };
        //console.log("Credit_Clinic_Location_List: ", creditCardClinicInfoList);
        creditCardClinicInfoList.push(objectToInsert);
      } else {
        // If the object is present in the original array, update it
        creditCardClinicInfoList[indexToUpdate].encryptedBillingInfo = creditEntry.encryptedBillingInfo;
        creditCardClinicInfoList[indexToUpdate].creditRequested = creditEntry?.creditRequested?.creditReq ?? 0;
      }
      // console.log("Credit_Clinic_Location_List: ", creditCardClinicInfoList);
      // console.log("---CARD END--");
      setCreditCardWithClinicLocationId(creditCardClinicInfoList);
    }
  }
  async function getCreditInfo() {
    if (!props.payFullBalance && creditCardModal === true) {
      setShowSpinner(true);
      try {
        await axiosConfig
          .post(
            "invoices/getInvoiceDetailByInvoiceId",
            {
              invoiceId: first(invoiceId),
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            }
          )
          .then(async (response) => {
            let decryptedBillingInfo = await decrypt(response.data.encryptedBillingInfo);
            setSelectedCreditCard(decryptedBillingInfo.creditCard);
          });
      } catch (error) {
        errorResponse(error);
      } finally {
        setShowSpinner(false);
      }
    } else if (props.payFullBalance && creditCardModal) {
      setSelectedCreditCard("");
    }
  }

  const payNow = () => {
    if (props.payFullBalance) {
      props.payNow(
        [],
        true,
        defaultForClinic,
        selectedCreditCard
          ? encrypt({
              creditCardOwner: billTo,
              isNewCardUsed: isNewCard,
              creditCard: selectedCreditCard,
            })
          : "",
        "",
        "",
        "",
        "",
        creditCardWithClinicLocationId
      );
    } else {
      props.payNow(
        invoiceId,
        false,
        defaultForClinic,
        selectedCreditCard
          ? encrypt({
              creditCardOwner: billTo,
              isNewCardUsed: isNewCard,
              creditCard: selectedCreditCard,
            })
          : "",
        "",
        "",
        "",
        "",
        creditCardWithClinicLocationId
      );
    }
    closeConfirmPaymentModal();
  };

  async function addNewCreditCard(e, clinicLocationId, creditReq1) {
    //New Card Check Update
    let newCardList = isNewCard;
    const indexToUpdateNewCard = isNewCard.findIndex((isNew) => isNew.locationId === clinicLocationId);
    newCardList[indexToUpdateNewCard].newCard = true;
    setNewCard(newCardList);
    //

    var creditRequested = {
      creditReq: creditReq1,
    };
    var serialize = require("form-serialize");
    var form = serialize(e.target, { hash: true });
    var serialize = require("form-serialize");
    var form = document.getElementById("addNewCreditCardForm" + clinicLocationId);
    var obj = serialize(form, { hash: true });
    var card = {
      creditCardNumber: obj.creditCardNumber.replace(/\s/g, ""),
      cardHolderName: obj.cardHolderName,
      cvv: obj.cvv,
      cardExpirationDate: obj.cardExpirationDate.replace(/\s/g, ""),
      cardHolderAddressLine1: obj.cardHolderAddressLine1,
      cardHolderAddressLine2: obj.cardHolderAddressLine2 ? obj.cardHolderAddressLine2 : "",
      cardHolderState: obj.cardHolderState,
      cardHolderCity: obj.cardHolderCity,
      cardHolderZipcode: obj.cardHolderZipcode,
    };
    setBillTo(billTo);
    setSelectedCreditCard(card);
    let creditEntry = {
      encryptedBillingInfo: encrypt({
        creditCardOwner: billTo,
        isNewCardUsed: true,
        creditCard: card,
      }),
      clinicLocationId: clinicLocationId,
      creditRequested: creditRequested.creditReq,
    };
    // console.log("---SET CARD START---");
    // console.log("Credit CardNumber: ", card.creditCardNumber);
    // console.log("Credit Requested: ", creditRequested.creditReq ?? 0);
    // console.log("Clinic Location Id: ", clinicLocationId);
    // console.log("Before Adding this above Card: ", creditCardWithClinicLocationId);
    // console.log("---------");
    let creditCardClinicInfoList = creditCardWithClinicLocationId;
    // Find the index of the object to update or insert
    const indexToUpdate = creditCardWithClinicLocationId.findIndex(
      (location) => location.clinicLocationId === creditEntry.clinicLocationId
    );
    //console.log("FIND INDEX?? ", indexToUpdate);
    if (indexToUpdate === -1) {
      // If the object is not present in the original array, insert it
      const objectToInsert = {
        clinicLocationId: creditEntry.clinicLocationId,
        encryptedBillingInfo: creditEntry.encryptedBillingInfo,
        creditRequested: creditRequested.creditReq ?? 0,
      };
      //console.log("Credit_Clinic_Location_List: ", creditCardClinicInfoList);
      creditCardClinicInfoList.push(objectToInsert);
    } else {
      // If the object is present in the original array, update it
      creditCardClinicInfoList[indexToUpdate].encryptedBillingInfo = creditEntry?.encryptedBillingInfo;
      creditCardClinicInfoList[indexToUpdate].creditRequested = creditEntry?.creditRequested?.creditReq ?? 0;
    }
    // console.log("Credit_Clinic_Location_List: ", creditCardClinicInfoList);
    // console.log("---CARD END--");
    setCreditCardWithClinicLocationId(creditCardClinicInfoList);

    let newMetaCardList = newCardMetaData;
    const indexToUpdateMeta = newCardMetaData?.findIndex((location) => location.locationId === clinicLocationId);
    let newCardInfo =
      obj.cardHolderName +
      " - card ending in " +
      card.creditCardNumber?.substring(card.creditCardNumber.length - 4, card.creditCardNumber.length);
    if (indexToUpdateMeta === -1) {
      // If the object is not present in the original array, insert it
      let metaData = {
        locationId: clinicLocationId,
        data: newCardInfo,
      };
      //console.log("Credit_Clinic_Location_List: ", creditCardClinicInfoList);
      newMetaCardList.push(metaData);
    } else {
      // If the object is present in the original array, update it
      creditCardClinicInfoList[indexToUpdateMeta].data = newCardInfo;
    }

    setNewCardModal(false);
  }
  const closeConfirmPaymentModal = () => {
    setConfirmModal(false);
  };

  const closeCreditModal = () => {
    setSelectedCreditCard("");
    setShowSpinner(false);
    setBillTo("clinic");
    setDefaultForClinic(false);
    setClinicLocationsInfo([]);
    setEncryptedBillingInfo("");
    setSelectedClinicLocationId("");
    setRequestedCreditsForClinicLocations([]);
    setClinicLocationsInfo([]);
    setCreditRequested("");
    setSelectNewCardLocation("");

    //Clearing New Card Info
    setNewCard("");
    setNewCardMetaData([]);
    setNewCardModal(false);
    setCreditCardWithClinicLocationId([]);
    setError("");
    props.setError("");
    closeModal();
  };

  return {
    clinicId,
    invoiceId,
    creditCardModal,
    closeModal,
    selectedCreditCard,
    setNewCardMetaData,
    setSelectedCreditCard,
    newCardModal,
    setNewCardModal,
    showSpinner,
    setShowSpinner,
    addNewCreditCard,
    newCardMetaData,
    isNewCard,
    setNewCard,
    setCreditCard,
    defaultForClinic,
    setDefaultForClinic,
    error,
    billTo,
    setBillTo,
    encrypt,
    payNow,
    closeConfirmPaymentModal,
    confirmModal,
    setConfirmModal,
    selectRxIds,
    selectClinicLocationIds,
    credit,
    setCredit,
    creditRequested,
    setCreditRequested,
    creditApplied,
    setCreditApplied,
    setCreditUsed,
    creditUsed,
    paymentAction,
    clinicLocationsInfo,
    setClinicLocationsInfo,
    selectedTotalAmount,
    updateTotalAmountToBeCharged,
    totalCreditUsed,
    setTotalCreditUsed,
    requestedCreditsForClinicLocations,
    setRequestedCreditsForClinicLocations,
    selectedClinicLocationId,
    setSelectedClinicLocationId,
    updateInfo,
    selectedClinicLocationId,
    setSelectedClinicLocationId,
    encryptedBillingInfo,
    setEncryptedBillingInfo,
    setCreditCardWithClinicLocationId,
    selectNewCardLocation,
    setSelectNewCardLocation,
    updateNewCardCheck,
    closeCreditModal,
  };
};
export default useConfirmCreditCardModal;

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Col, Container, Row, UncontrolledAlert } from "reactstrap";
import { useAdminDashboardCountQuery } from "rtk";
import "../../../assets/styles/dashboard.css";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import Spinner from "../../../components/spinner";
import { BarChart } from "./BarChart";
import AppWidgetSummary from "./app-widget-summary";
import { Grid } from "@mui/material";
import AppWebsiteVisits from "./app-website-visits";
import AppCurrentVisits from "./app-current-visits";
import AppOrderTimeline from "./app-order-timeline";
import { BAR_COLORS } from "utils/constants";
import { useHistory } from "react-router-dom";

function Dashboard() {
  const { isFetching, data, isError, error } = useAdminDashboardCountQuery();
  const history = useHistory();
  const [activePatients, setActivePatients] = useState(0);
  const [pendingPrescriptions, setPendingPrescriptions] = useState(0);
  const [completedPrescriptions, setCompletedPrescriptions] = useState(0);
  const [pieChart, setPieChart] = useState([]);
  const [labelList, setLabelList] = useState([]);
  const [seriesList, setSeriesList] = useState([])
  useEffect(() => {
    if (data) {
      let pi = [];
      data?.rxStatusCounts?.forEach((res) => {
        pi.push({ label: res?.rxStatus, value: res?.count });
      });
      setPieChart(pi)      

      const transformedData = data?.monthlyPrescriptions.length > 0 ? Object.keys(data?.monthlyPrescriptions[0].statuses)?.map((status) => ({
        name: status,
        data: data?.monthlyPrescriptions.map((entry) => entry?.statuses[status]),
        color: BAR_COLORS[status]
      })): [];

      const monthList = data?.monthlyPrescriptions?.map((entry) => {
          const dateObject = new Date(entry.month);
          const monthYearString = dateObject.toLocaleString('en-US', { month: 'short', year: 'numeric' });
          return monthYearString;
      });
    console.log(transformedData)
      setSeriesList(transformedData)
      setLabelList(monthList)
      setActivePatients(data?.activePatients)
      setCompletedPrescriptions(data?.completedPrescriptions)
      setPendingPrescriptions(data?.pendingPrescriptions)
    }
  }, [data]);

  return (
    <React.Fragment>
      {isFetching ? <Spinner /> : null}
      <SuperAdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          
          {isError ? (
            <UncontrolledAlert color="danger" className="text-center">
              {`An error occurred while trying to fetch details:  ${error}`}
            </UncontrolledAlert>
          ) : null}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Patients"
                total={activePatients}
                onClick={() => 
                  history.push({
                    pathname: '/patients',
                    state: { activeTab: 2 },
                  })
                }
                color="success"
                icon={<img alt="icon" src="/assets/glass/ic_glass_users.png" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Pending Prescriptions"
                total={pendingPrescriptions}
                onClick={() => 
                  history.push({
                    pathname: '/pending-prescriptions',
                    state: { activeTab: 2 },
                  })
                }
                color="success"
                icon={<img alt="icon" src="/assets/glass/pending_prescription.png" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Completed Prescription"
                total={completedPrescriptions}
                onClick={() => 
                  history.push({
                    pathname: '/prescription-archive',
                    state: { activeTab: 2 },
                  })
                }
                color="success"
                icon={<img alt="icon" src="/assets/glass/completed_prescription.png" />}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <AppWebsiteVisits
                title="Monthly Prescriptions History"
                subheader=""
                chart={{
                  labels: labelList,
                  series: seriesList,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Total Prescriptions"
                chart={{
                  series: pieChart,
                }}
              />
            </Grid>
          </Grid>
          {/* <Row className="p-1">
            <Col sm={12} md={12} lg={12}>
              <Row className="dashboardCardAdmin m-0">
                <Link to="/patients" className="dashboardCardContentAdmin text-muted">
                  <i className="fas fa-users whiteText activePatients"></i>
                  <div>
                    <p>Active Patients</p>
                    <p className="number">{data?.activePatients || "-"}</p>
                  </div>
                </Link>
                <Link to="/pending-prescriptions" className="dashboardCardContentAdmin text-muted">
                  <i className="fas fa-clock whiteText pendingPresciptions"></i>
                  <div>
                    <p>Pending Prescriptions</p>
                    <p className="number">{data?.pendingPrescriptions || "-"}</p>
                  </div>
                </Link>
                <Link to="/prescription-archive" className="dashboardCardContentAdmin text-muted">
                  <i className="fas fa-check-circle greenBg whiteText completedPresciptions"></i>
                  <div>
                    <p>Completed Prescriptions</p>
                    <p className="number">{data?.completedPrescriptions || "-"}</p>
                  </div>
                </Link>
                {/* <Link
                  to={{
                    pathname: "/clinics",
                    state: {
                      defaultPendingButton: "blueButton",
                    },
                  }}
                  className="dashboardCardContentAdmin text-muted"
                >
                  <i className="fas fa-clinic-medical yellowText"></i>
                  <div>
                    <p>Clinics awaiting Approval</p>
                    <p className="number">{data?.clinicAwaitingApprovals || "-"}</p>
                  </div>
                </Link> * /}
              </Row>
              <Row className="dashboardCard m-0">
                <div className="dashboardCardContent largeCard" style={{ overflow: "hidden", height: "600px" }}>
                  <p>Medication Statuses</p>
                  <BarChart rxData={data?.rxStatusCounts} hide={false} />
                </div>
              </Row>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;

import moment from "moment";
import moment2 from "moment-timezone";
import { SHIP_METHODS } from "./StatusConstant";
import { decrypt } from "cryptofunc";

const dateFormatterMountainTime = (time) => {
  var val = moment(time);
  var day = +val.format("DD");
  var month = +val.format("MM");
  if (
    (month === 11 && day <= 6) ||
    (month === 3 && day >= 14) ||
    (month > 3 && month < 11)
  ) {
    return `${moment2
      .tz(val.subtract(60, "m"), "America/Denver")
      .format("YYYY-MM-DD hh:mmA")} [MDT]`;
  } else {
    return `${moment2
      .tz(val, "America/Denver")
      .format("YYYY-MM-DD hh:mmA")} [MST]`;
  }
};

const getCardNumber = (billingInfo = "") => {
  if (billingInfo) {
    const obj = decrypt(billingInfo);
    return `${
      obj?.creditCard?.cardHolderName
    } - card ending in ${obj?.creditCard?.creditCardNumber?.substring(
      obj?.creditCard?.creditCardNumber?.length - 4,
      obj?.creditCard?.creditCardNumber?.length
    )}`;
  }
  return "-";
};

const BAR_COLORS = {
  "Inprocess": "#DE3163",
  "Hold": "#FF4300",
  "Cancelled": "#ff8400",
  "Complete": "#9FE2BF",
  "Pending": "#6495ED",
};

const mapShippingMethod = (meth) => {
  return SHIP_METHODS.filter((a) => a.value === meth)[0]?.label;
};
const mapShippingMethodValue = (label) => {
  return SHIP_METHODS.filter((a) => a.label === label)[0]?.value;
};
const IS_EDIT_RX = window?.location?.pathname.includes("edit-prescription");
const IS_NEW_RX = window?.location?.pathname.includes("edit-prescription");

const DCATEGORIES = {
  1: "Erectile Dysfunction",
  2: "Hair Loss",
  3: "Anxiety & Depression",
  4: "Early Climax",
  5: "Skincare",
  6: "Sexual Wellness",
  7: "HRT",
};
const DGENDERS = {
  1: "Male",
  2: "Female",
};

export {
  dateFormatterMountainTime,
  getCardNumber,
  BAR_COLORS,
  mapShippingMethod,
  mapShippingMethodValue,
  IS_EDIT_RX,
  IS_NEW_RX,
  DCATEGORIES,
  DGENDERS,
};

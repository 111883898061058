const USER_ROLES = {
  ADMIN_SUPER_USER: "AdminSuperUser",
  ADMIN: 'Admin'
};

const ALLOWED_ACTION_USERS = [USER_ROLES.ADMIN_SUPER_USER];

const USERS_EDIT_PRESCRIPTION = [
  USER_ROLES.ADMIN_SUPER_USER,
];

export { USER_ROLES, USERS_EDIT_PRESCRIPTION, ALLOWED_ACTION_USERS };

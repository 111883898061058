import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSortBy, useTable } from "react-table";
import AdminNavbar from "../../../../components/AdminNavbar";
import { Card, CardBody, Col, Container, Row, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { PRODUCT_STATUS } from "../constants";
import useClinicProductList from "./Hook/useClinicProducts";
import ProductDiscountModal from "../product_discount";
import Spinner from "components/spinner";
import PaginationComponent from "react-reactstrap-pagination";
import { USER_ROLES } from "utils";
import Cookies from "universal-cookie";
import PriceGroupDD from "components/dropdowns/priceGroupDD";
import AlertMessage from "screens/admin/Prescriptions/components/alert_message";
const ClinicProductList = (props) => {
  const {
    productList,
    onApply,
    clinic,
    error,
    success,
    showTierModal,
    changePriceGroup,
    setShowTierModal,
    showBulkModal,
    setShowBulkModal,
    handlePageChange,
    searchString,
    setSearchString,
    onSearchHandler,
    clinicId,
    sizePerPage,
    page,
    setPage,
    pagination,
    showSpinner,
    showLoading,
    showError,
    showSuccess,
    successMessage,
    userInfo,
    userRole,
    data,
    loadMore,
    handleChange,
    dropdownOpen,
    toggle,
    ROWS_LIMIT,
    rowLimit,
  } = useClinicProductList(props);

  const RenderActionButtons = ({ original }) => {
    return (
      <span className={"clinic-product-action-container"}>
        <i className="fas fa-edit blueText" />
        <i className="fas fa-trash redText" />
      </span>
    );
  };

  const columns = React.useMemo(
    () =>
      data?.products
        ? [
            {
              Header: "Product name",
              accessor: "name",
            },
            {
              Header: "Common Name",
              accessor: "commonName",
            },
            {
              Header: "Category",
              accessor: "family",
            },
            {
              Header: "Sub-Category 1",
              accessor: "subCategory1",
            },
            {
              Header: "Sub-Category 2",
              accessor: "subCategory2",
            },
            {
              Header: "Unit price",
              accessor: "unitPrice",
            },
            {
              Header: "Discount price",
              accessor: "discountedPrice",
              disableSortBy: true,
              Cell: ({ row: { original } }) => <RenderDiscountInput original={original} onApply={onApply} />,
            },
          ]
        : [],
    [data?.products]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: data?.products },
    useSortBy
  );

  return (
    <>
      <AdminNavbar />
      <div className="page-content">
        <Container fluid>
          {showError ? <AlertMessage msg={error} isError={true} /> : null}
          {showSuccess ? <AlertMessage msg={successMessage} /> : null}
          <div className={"clinic-products-header"}>
            {showLoading ? <Spinner /> : null}
            <button className={"btn"} onClick={() => props.history.goBack()}>
              Back
            </button>
            <h4 className="p-3 text-muted" style={{ textTransform: "capitalize" }}>{`${
              !!data?.clinic
                ? `${data?.clinic.referredBy ? `${data?.clinic.referredBy} - ` : ""} ${data?.clinic.businessName}`
                : ""
            } Products`}</h4>
            {userRole === USER_ROLES.ADMIN_SUPER_USER ? <div></div> : null}
          </div>
          <Row>
            <Col md={6}></Col>
            <Col md={4}> </Col>
            <Col md={2}>
              {" "}
              {userRole === USER_ROLES.ADMIN_SUPER_USER ? (
                <div>
                  <PriceGroupDD
                    defaultValue={data?.clinic.priceGroup ? data?.clinic.priceGroup : ""}
                    clinicId={data?.clinic?.id}
                    changePriceGroup={changePriceGroup}
                  />
                </div>
              ) : null}
            </Col>
          </Row>
          <div className="my-4 d-flex align-items-center flex-row ">
            <p className="mx-3 mt-3">Rows: </p>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
              <DropdownToggle caret color="primary">
                {rowLimit} <i className="fas fa-angle-down" />
              </DropdownToggle>
              <DropdownMenu>
                {ROWS_LIMIT.map((size) => (
                  <DropdownItem key={size} onClick={handleChange}>
                    {size}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <Row className="justify-content-between px-3 my-3">
                  <div className="search-box m-3">
                    {/*
                    Code for Later use
                    <div>
                      <input value={searchString} type='text' placeholder='Search table' className='tableSearchInput' onChange={(e) => setSearchString(e.target.value)} />
                      <i className='bx bx-search-alt search-icon' />
                      <button type='button' onClick={onSearchHandler} className={'product-search-button'}>
                        <i className='bx bx-search-alt search-icon' style={{ position: 'initial', lineHeight: 'inherit' }} />
                      </button>
                      {searchString.length ? <i className='fas fa-times redText product-search-icon' onClick={() => setSearchString('')}></i> : []}
                    </div>
*/}
                  </div>
                </Row>
                <CardBody>
                  <table {...getTableProps()} className={"product-table-container"}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className={"product-table-header"}
                            >
                              {column.render("Header")}
                              <span>
                                &nbsp;
                                {column.isSorted ? (
                                  <span className={column.isSortedDesc ? "dropdown" : "dropup"}>
                                    <span className="caret"></span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              const {
                                row: { original },
                              } = cell;
                              const { status = PRODUCT_STATUS.active } = original || {};
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className={`product-table-body ${
                                    status === PRODUCT_STATUS.inactive ? "product-table-inactive" : ""
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>{" "}
                  <Row className="text-center mt-3 px-3">
                    <Col>
                      <div className="text-center ms-auto">
                        {pagination?.hasNextPage ? (
                          <span style={{ cursor: "pointer" }} className="blueButton" onClick={loadMore}>
                            Load more
                          </span>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>{" "}
        </Container>
      </div>
      {(showBulkModal || showTierModal) && (
        <ProductDiscountModal
          clinicId={clinicId}
          isTierModal={showTierModal}
          title={!!data?.clinic ? data?.clinic.businessName : ""}
          showModal={showBulkModal || showTierModal}
          closeModal={() => {
            setShowBulkModal(false);
            setShowTierModal(false);
          }}
        />
      )}
    </>
  );
};

export default ClinicProductList;

const RenderDiscountInput = ({ original, onApply }) => {
  const { discountedPrice = 0, id } = original;
  const [showButton, setShowButton] = useState(false);
  const [discountLocal, setDiscountLocal] = useState(discountedPrice);

  const onApplyHandler = () => {
    onApply(id, discountLocal, () => setShowButton(false));
  };

  useEffect(() => {
    setShowButton(discountedPrice !== discountLocal);
  }, [discountLocal]);

  return (
    <div className="clinic-product-input-container input-group">
      <span>{discountLocal}</span>
      {/*new Cookies().get('user_role') === USER_ROLES.ADMIN_SUPER_USER ? <input type={'text'} value={discountLocal} className='form-control' onChange={(e) => setDiscountLocal(e.target.value)} /> : <input type={'text'} value={discountLocal} className='form-control' />}
      {showButton && parseFloat(discountLocal.toString()) > 0.0 && (
        <div className='input-group-append'>
          <button className='btn btn-outline-secondary' type='button' onClick={onApplyHandler}>
            Apply
          </button>
        </div>
      )*/}
    </div>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import useProductList from "./index.hook";
import SuperAdminNavbar from "../../../../components/SuperAdminNavbar";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import PrimaryModal from "../../../../components/primaryModal";
import { useTable, useSortBy } from "react-table";
import { PRODUCT_STATUS } from "../constants";
import Switch from "react-switch";
import { AddCategoryModal, AddProductModal } from "../index";
import AddPriceGroupModal from "../add_price_group";
import Spinner from "../../../../components/spinner";
import "../index.scss";
import { ROWS_LIMIT, USER_ROLES } from "utils";
import Cookies from "universal-cookie";

import PaginationComponent from "react-reactstrap-pagination";
import { DCATEGORIES, DGENDERS } from "utils/constants";
import { ListingSearch } from "components";
import { useChangeStatusMutation, useDeleteProductMutation, useLazyGetAllCategoriesQuery, useLazyGetAllProductsQuery } from "rtk";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Iconify from '../../../../components/iconify';
import BootstrapTable from "react-bootstrap-table-next";
import { IconButton, TablePagination, Tooltip } from "@mui/material";
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { useLazyGetApprovedClinicListQuery } from "rtk/rtkEndpoints/Clinics";
import RefreshIcon from "@mui/icons-material/Refresh";
import AlertMessage from "screens/admin/Prescriptions/components/alert_message";

const ProductListing = (props) => {
  const {
    rowLimit,
    tablePage,
    showSpinner,
    totalRecords,
    errorMessage,
    isReviewModal,
    searchKeyword,
    disableFilter,
    filterRxStatus,
    patientId,
    products,
    setPatientId,
    setCustomError,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    toggleDetailModal,
    updateFilterRxStatus,
    updateFilterValue,
    //
    clinicListing,
    categories,
    success,
    setSuccess,
    addProduct,
    setAddProduct,
    productId,
    setProductId,
    editProduct,
    setEditProduct,
    changeStatusHandler,
    deleteProductHandler,
    setDataBack,
    selectedRow,
    setSelectedRow,
    toggleProductModal,
    productAction,
    successMessage
  } = useProductList({
    listingQuery: useLazyGetAllProductsQuery,
    listingClinicsQuery: useLazyGetApprovedClinicListQuery,
    listingCategoriesQuery: useLazyGetAllCategoriesQuery,
    listingName: "ProductsList",
    changeStatusMutation: useChangeStatusMutation,
    listingMutation: useDeleteProductMutation,
  });
  // console.log('clinics>>>>',clinicListing)
  // const {
  //   productList,
  //   clinicList,
  //   categoriesList,
  //   success,
  //   setSuccess,
  //   deleteProductHandler,
  //   loadMore,
  //   deleteProduct,
  //   addPriceGroup,
  //   setAddPriceGroup,
  //   reload,
  //   setDeleteProduct,
  //   editProduct,
  //   setEditProduct,
  //   addProduct,
  //   setAddProduct,
  //   changeStatusHandler,
  //   setDataBack,
  //   searchString,
  //   setSearchString,
  //   onSearchHandler,
  //   showSpinner,
  //   sizePerPage,
  //   pagination,
  //   handlePageChange,
  //   ROWS_LIMIT,
  //   toggle,
  //   dropdownOpen,
  //   handleChange,
  //   rowLimit,
  //   priceGroups,
  //   addCategory,
  //   setAddCategory,
  //   editCategory,
  //   setEditCategory,
  //   updateKeyword,
  //   clearFilter,
  //   applyFilter,
  //   searchKeyword
  // } = useProductList(props);

  // const successMessage = useMemo(
  //   () => isSuccess ?? "",
  //   [isSuccess]
  // );
  // const combinedErrorMessage = useMemo(
  //   () => errorMessage || isDeleteError || deleteError,
  //   [errorMessage || isDeleteError || deleteError]
  // );
  // useEffect(()=>{
  //   if(isSuccess)
  //     refetchList();
  // },[isSuccess])

  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const RenderStatusToggle = ({ original }) => {
    var { id, isActive: status = true } = original || {};

    return new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
      <span className={"product-status-container"}>
        <label className="switch">
          <input
            type="checkbox"
            id={"rx-order-input-" + id}
            onChange={(e) => {
              e.stopPropagation();

              changeStatusHandler(id, e.target.checked);
            }}
            defaultChecked={status}
          />
          <span className="slide round"></span>
        </label>
      </span>
    ) : (
      <span className={"product-status-container"}>
        <label className="switch">
          <input type="checkbox" id={"rx-order-input-" + id} checked={status} />
          <span className="slide round"></span>
        </label>
      </span>
    );
  };

  const RenderActionButtons = ({ original }) => {
    return new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
      <>
      <IconButton onClick={(e) => {handleOpenMenu(e); setSelectedRow(original);}}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
      </>
      // <span className={"button-product-action-container"}>
      //   <i
      //     className="fas fa-edit medText"
      //     onClick={() => setEditProduct(original)}
      //   />
      //   <i
      //     className="fas fa-trash redText"
      //     onClick={() => setDeleteProduct(original)}
      //   />
      // </span>
    ) : (
      <span>Disabled</span>
    );
  };

  const columns = React.useMemo(() => {
    var headerList = [
      {
        Header: "Status",
        accessor: "isActive", // accessor is the "key" in the data
        Cell: ({ row: { original } }) => (
          <RenderStatusToggle original={original} />
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <RenderActionButtons original={original} />
        ),
      },

      {
        Header: "Category",
        accessor: (data) => {
          if (data) {
            const { category } = data;
            if (category) {
              return category?.name;
              // return DCATEGORIES[category];
            }
          }
          return "";
        },
      },
      {
        Header: "Gender",
        accessor: (data) => {
          if (data) {
            const { gender } = data;
            if (gender) {
              return DGENDERS[gender];
            }
          }
          return "";
        },
      },
      // {
      //   Header: "Sub Category 1",
      //   accessor: "subCategory1",
      // },
      // {
      //   Header: "Sub Category 2",
      //   accessor: "subCategory2",
      // },
      // {
      //   Header: "Common Name",
      //   accessor: "commonName",
      // },
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Image",
        accessor: "productImage",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <div>
            {original.productImage ? 
            <img src={original.productImage} style={{maxWidth: '60px', maxHeight: '60px', height: '100%', width: '100%', objectFit: 'cover'  }} />
            : '-'}
          </div>
        ),
      },
      // {
      //   Header: "Retail price",
      //   accessor: "unitPrice",
      // },
    ];

    // productList[0]?.sigOptions?.forEach((sig, i) => {
    //   headerList.push(
    //     {
    //       Header: `Sig${i + 1} Title`,
    //       accessor: (data) => {
    //         if (data) {
    //           const { sigOptions } = data;
    //           if (sigOptions) {
    //             if (sigOptions[i]) {
    //               return sigOptions[i]?.title?.toUpperCase() || "";
    //             }
    //           }
    //         }
    //         return "";
    //       },
    //     },

    //     {
    //       Header: `Sig${i + 1}`,
    //       accessor: (data) => {
    //         if (data) {
    //           const { sigOptions } = data;
    //           if (sigOptions) {
    //             if (sigOptions[i]) {
    //               return sigOptions[i]?.description?.toUpperCase() || "";
    //             }
    //           } else return "";
    //         }
    //         return "";
    //       },
    //     }
    //   );
    // });

    // priceGroups?.forEach((p, i) => {
    //   if (p) {
    //     headerList.push({
    //       Header: p,
    //       accessor: (data) => {
    //         if (data) {
    //           const { priceGroups } = data;
    //           return priceGroups?.filter((value1) => value1.groupName === p)[0]?.price;
    //         } else return "";
    //       },
    //     });
    //   }
    // });
    // headerList.push(
    //   {
    //     Header: "Medical Accessories",
    //     accessor: "medicalAccessories",
    //   },

    //   {
    //     Header: "Cold Shipped",
    //     accessor: "coldShipped",
    //   }
    // );
    // headerList.push({
    //   Header: "Controlled Substance",
    //   accessor: "controlledSubstance",
    // });

    return headerList;
  }, [products]);

  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //   useTable({ columns, data: products }, useSortBy);

  

  const tableHeader = useMemo(
    () => [
      {text: "Status", dataField: 'isActive', formatter: (cellContent, row) => <RenderStatusToggle original={row} />},
      { text: "Image", dataField: "productImage",
      formatter: (cellContent, row) => (<>
    {cellContent ? 
            <img src={cellContent} style={{maxWidth: '60px', maxHeight: '60px', height: '100%', width: '100%', objectFit: 'cover'  }} />
            : '-'}
    </>) },
      { text: "Name", dataField: "name", },
      { text: "Category", dataField: "category.name", },
      { text: "Gender", dataField: "gender",
      formatter: (cellContent, row) => (<>{DGENDERS[cellContent]}</>) },
      {text: "", dataField: 'action', formatter: (cellContent, row) => <RenderActionButtons original={row} />},
    ],
    []
  );

  return (
    <>
    {showSpinner && <Spinner />}
      <SuperAdminNavbar />
      <div className="page-content">
        <Container fluid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Products</Typography>

        <Button onClick={() => setAddProduct(true)} variant="contained" color="inherit" startIcon={<Iconify icon="eva:plus-fill" />}>
          New Product
        </Button>
      </Stack>
      <AlertMessage msg={successMessage} />
          <AlertMessage msg={errorMessage} isError={true} />
          {/* <div className={"clinic-products-header"}>
            {showSpinner ? <Spinner /> : null}
            <button className={"btn"} onClick={() => props.history.goBack()}>
              Back
            </button>

            {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
              <div>
                <button className={"pinkButton justify-content-end m-3"} onClick={() => setAddCategory(true)}>
                  Add Category
                </button>
                <button
                  className={"pinkButton justify-content-end m-3"}
                  onClick={() => setAddProduct(true)}
                >
                  Add Products
                </button>
              </div>
            ) : null}
          </div> */}
          <Row>
            <Col xs="12">
              <Card className="listing-card">
              <ListingSearch
                      searchKeyword={searchKeyword}
                      applySearch={applyFilter}
                      clearFilter={clearFilter}
                      updateKeyword={updateKeyword}
                      leftChildren={
                        <Tooltip title="Refresh">
                          <Button variant="contained" onClick={refetchList}>
                            <RefreshIcon style={{ width: 20, height: 20 }} />
                          </Button>
                        </Tooltip>
                      }
                    />
                {/* <Row className="justify-content-between px-3 my-3">
                  <div className="search-box m-3">
                    <div>
                      <input
                        type="text"
                        placeholder="Search table"
                        className="tableSearchInput"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onSearchHandler();
                          }
                        }}
                        onChange={(e) => {
                          searchString.current = e.target.value;
                          if (e.target.value === "") {
                            return onSearchHandler();
                          }
                        }}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <button
                        type="button"
                        onClick={onSearchHandler}
                        className={"product-search-button"}
                      >
                        <i
                          className="bx bx-search-alt search-icon"
                          style={{ position: "initial", lineHeight: "inherit" }}
                        />
                      </button>
                      {searchString.length ? (
                        <i
                          className="fas fa-times redText product-search-icon"
                          onClick={() => {
                            searchString.current = "";

                            return onSearchHandler();
                          }}
                        ></i>
                      ) : (
                        []
                      )}
                    </div>
                  </div>
                </Row> */}

                <CardBody>
                <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive"
                          style={{ minHeight: "300px" }}
                        >
                          <BootstrapTable
                            responsive
                            keyField={"id"}
                            bordered={false}
                            striped={false}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            columns={tableHeader}
                            data={products}
                          />
                          {/* {console.log(rowSelection, "ssss")}
                          {console.log(patients, "patients")} */}
                          {products.length === 0 ? (
                            <p className="w-100 text-center m-5">
                              No products data found!
                            </p>
                          ) : null}
                        </div>
                        {/* {console.log(patients)} */}
                      </Col>
                    </Row>
                    <Popover
                    open={!!open}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      sx: { width: 140 },
                    }}
                  >
                    <MenuItem onClick={() =>(setEditProduct(selectedRow),handleCloseMenu())}>
                      <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                      Edit
                    </MenuItem>

                    <MenuItem onClick={() =>(setProductId(selectedRow?.id),handleCloseMenu())} sx={{ color: 'error.main' }}>
                      <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
                      Delete
                    </MenuItem>
                  </Popover>
                    <Row className="align-items-md-center mt-3 px-3">
                      <div className="text-center ms-auto">
                          <TablePagination
                            component="div"
                            showFirstButton
                            showLastButton
                            page={tablePage}
                            count={totalRecords}
                            rowsPerPage={rowLimit}
                            rowsPerPageOptions={ROWS_LIMIT}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={changeRowLimit}
                            style={{ alignItems: "center", marginBottom: 0 }}
                          />
                      </div>
                    </Row>
                  {/* {showSpinner && <Spinner />} */}
                  {/* <div className="my-4 d-flex align-items-center flex-row ">
                    <p className="mx-3 mt-3">Rows: </p>
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      direction="down"
                    >
                      <DropdownToggle caret color="dark">
                        {rowLimit} <i className="fas fa-angle-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {ROWS_LIMIT.map((size) => (
                          <DropdownItem key={size} onClick={handleChange}>
                            {size}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div> */}
                  {/* <div className="table-responsive">
                    <table
                      {...getTableProps()}
                      className={""}
                      style={{ overflowY: "scroll", width: "100%" }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                className={"product-table-header"}
                              >
                                {column.render("Header")}
                                <span>
                                  &nbsp;
                                  {column.isSorted ? (
                                    <span
                                      className={
                                        column.isSortedDesc
                                          ? "dropdown"
                                          : "dropup"
                                      }
                                    >
                                      <span className="caret"></span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                const {
                                  row: { original },
                                } = cell;
                                const { status = PRODUCT_STATUS.active } =
                                  original || {};
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    className={`product-table-body ${
                                      status === PRODUCT_STATUS.inactive
                                        ? "product-table-inactive"
                                        : ""
                                    }`}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div> */}

                  {/* {!showSpinner && !products.length && (
                    <span className={"empty-record-table"}>
                      No record available.
                    </span>
                  )} */}
                </CardBody>

                {/* <Row className="align-items-md-center mt-3 px-3">
                  <div className="text-center ms-auto">
                    {pagination.hasNextPage ? (
                      <span
                        style={{ cursor: "pointer" }}
                        className="blueButton"
                        onClick={(e) => {
                          e.preventDefault();
                          loadMore();
                        }}
                      >
                        Load more
                      </span>
                    ) : null}
                  </div>
                </Row> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* {!!deleteProduct && ( */}
        <PrimaryModal
          redirection={false}
          question={`Are you sure you want to delete this  product?`}
          showModal={Boolean(productId)}
          closeModal={toggleProductModal}
          onClickYes={productAction}
          // showModal={!!deleteProduct}
          // closeModal={() => setDeleteProduct(null)}
          // onClickYes={() => deleteProductHandler(deleteProduct?.id)}
        />
      {/* )} */}
      {(addProduct || editProduct) && (
        <AddProductModal
          products={products}
          clinics={clinicListing}
          categories={categories}
          showModal={addProduct || !!editProduct}
          setSuccess={setSuccess}
          product={editProduct}
          isEdit={!!editProduct}
          setData={setDataBack}
          closeModal={() => {
            setAddProduct(false);
            setEditProduct(null);
          }}
          // priceGroups={priceGroups}
        />
      )}
      {/* {(addCategory || editCategory) && (
        <AddCategoryModal
          showModal={addCategory || !!editCategory}
          setSuccess={setSuccess}
          category={editCategory}
          isEdit={!!editCategory}
          closeModal={() => {
            setAddCategory(false);
            setEditCategory(null);
          }}
        />
      )} */}
      {/* {addPriceGroup && (
        <AddPriceGroupModal
          showModal={addPriceGroup}
          setData={setDataBack}
          setSuccess={setSuccess}
          closeModal={() => {
            setAddPriceGroup(false);
          }}
        />
      )} */}
    </>
  );
};

export default ProductListing;

import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import AdminNavbar from "./AdminNavbar";
import SuperAdminNavbar from "./SuperAdminNavbar";

const NavBar = () => {
  const userRole = useSelector(userRoleSelector);
  return userRole?.toLowerCase().includes("adminsuperuser") ? <SuperAdminNavbar /> : <AdminNavbar />;
};
export default NavBar;

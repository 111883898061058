import { InvoiceApi } from "../../rtkApi";
import { API_METHODS, END_POINTS, providesList } from "../../rtkConstant";

const InvoiceDetailQuery = InvoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceDetail: builder.query({
      keepUnusedDataFor: 0.1,
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {

        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const response = await baseQuery({
              url: END_POINTS.GET_INVOICE_DETAIL,
              method: API_METHODS.POST,
              body: apiArgs,
            });
            return response;
          }
        } catch {}
      },
      // providesTags: (result) => providesList(result.prescriptions),
      forceRefetch({ currentArg, previousArg }) {
        console.log({ currentArg, previousArg });
        return currentArg !== previousArg;
      },
    }),
    getDefaultCreditCard: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.GET_DEFAULT_CARD_PRESCRIPTION_ID + "/" + apiArgs.prescriptionId,
        method: API_METHODS.GET,
      }),
    }),
  }),
});
export const { useGetInvoiceDetailQuery, useLazyGetInvoiceDetailQuery, useLazyGetDefaultCreditCardQuery } =
  InvoiceDetailQuery;

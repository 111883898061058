import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, CACHE_TAGS } from "../rtkConstant";
import { baseQuery } from "./BaseApi";

const DashboardApi = createApi({
  reducerPath: "DashboardApi",
  tagTypes: [CACHE_TAGS.DASHBOARD],
  baseQuery: baseQuery,
  // baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
});

export default DashboardApi;

import { NOTES } from "components/PaymentGrid/PaymentGridConstants";
import { filter, forEach, includes, keys, set, some } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  useAdminPayNowInvoiceMutation,
  useClinicPayNowMutation,
  useGetPaymentStatusesQuery,
  useLazyGetExportInvoiceListByClinicIdQuery,
  useLazyGetExportInvoiceListQuery,
  useLazyGetInvoiceListQuery,
} from "rtk";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ROWS_LIMIT } from "utils";
import { PAY_NOW_STATUS } from "utils/StatusConstant";

const useInvoiceList = () => {
  const [apiParam, setApiParam] = useState({ currentPage: 1, limit: ROWS_LIMIT[1] });
  const [sorting, setSorting] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [totalBalanceDue, setTotalBalanceDue] = useState(0);
  const [payFullBalanceModal, setPayFullBalanceModal] = useState(false);
  const [disableFilter, setDisableFilter] = useState(true);
  const [success, setSuccess] = useState("");
  const [selectedPaymentApiMessage, setSelectedPaymentApiMessage] = useState("");
  const [transactionModal, setTransactionModal] = useState(false);
  const [confirmCreditCardModal, setConfirmCreditCardModal] = useState(false);
  const [paymentInstructions, setPaymentInstructions] = useState("");
  const [error, setError] = useState(false);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const { data: paymentData } = useGetPaymentStatusesQuery();
  const [
    fetchInvoices,
    {
      data: { pagination, prescriptions } = { pagination: {}, prescriptions: [] },
      isFetching: isInvoiceListFetching,
      isError: isInvoiceListDataError,
      error: invoiceListError,
    },
  ] = useLazyGetInvoiceListQuery();

  const [
    clinicExportInvoice,
    { isFetching: isClinicExportInvoiceFetching, isError: isClinicExportInvoiceError, error: clinicExportInvoiceError },
  ] = useLazyGetExportInvoiceListByClinicIdQuery();
  const [
    adminExportInvoice,
    { isFetching: isAdminExportInvoiceFetching, isError: isAdminExportInvoiceError, error: adminExportInvoiceError },
  ] = useLazyGetExportInvoiceListQuery();
  const [doAdminPayNow, { isLoading: isAdminPayNowLoading, isError: isAdminPayNowError, error: adminPayNowError }] =
    useAdminPayNowInvoiceMutation();
  const [doClinicPayNow, { isLoading: isClinicPayNowLoading, isError: isClinicPayNowError, error: clinicPayNowError }] =
    useClinicPayNowMutation();

  const isSelectableAll = useMemo(
    () => some(prescriptions, (invoice) => includes(PAY_NOW_STATUS, invoice.paymentStatus)),
    [prescriptions]
  );
  const rowLimit = useMemo(() => apiParam.limit, [apiParam.limit]);
  const toDate = useMemo(() => apiParam?.toDate ?? "", [apiParam.toDate]);
  const fromDate = useMemo(() => apiParam?.fromDate ?? "", [apiParam.fromDate]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const clinicSide = useMemo(() => userRole?.toLowerCase().includes("clinic"), [userRole]);
  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const filteredPaymentStatus = useMemo(() => apiParam?.paymentStatus ?? "", [apiParam?.paymentStatus]);
  const totalRecords = useMemo(() => (pagination?.totalCount ?? 0),[pagination?.totalCount])//rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const paymentStatuses = useMemo(
    () => '' /*paymentData?.map((status) => <option key={status}>{status}</option>)*/,
    [paymentData]
  );
  const reactTablePaginationState = useMemo(
    () => ({ pageIndex: tablePage, pageSize: rowLimit }),
    [rowLimit, tablePage]
  );
  const selectedInvoicesIds = useMemo(() => keys(rowSelection), [rowSelection]);

  const selectedInvoices = useMemo(
    () => filter(prescriptions, (inv) => includes(selectedInvoicesIds, inv.id)),
    [prescriptions, selectedInvoicesIds]
  );
  const selectedTotalAmount = useMemo(
    () =>
      selectedInvoices
        .reduce((prevValue, currValue) => (prevValue ?? 0) + (currValue?.totalAmount ?? 0), 0.0)
        .toFixed(2),
    [selectedInvoices]
  );

  const clinicId = useMemo(
    () => userInfo?.clinicId ?? selectedInvoices?.[0]?.clinicId ?? "",
    [selectedInvoices, userInfo?.clinicId]
  );
  const showLoading = useMemo(
    () =>
      isInvoiceListFetching ||
      isAdminPayNowLoading ||
      isAdminExportInvoiceFetching ||
      isClinicExportInvoiceFetching ||
      isClinicPayNowLoading,
    [
      isInvoiceListFetching,
      isAdminExportInvoiceFetching,
      isClinicExportInvoiceFetching,
      isAdminPayNowLoading,
      isClinicPayNowLoading,
    ]
  );
  const showError = useMemo(
    () => isInvoiceListDataError || isAdminPayNowError || isAdminExportInvoiceError || isClinicExportInvoiceError,
    [isInvoiceListDataError, isAdminExportInvoiceError, isClinicExportInvoiceError, isAdminPayNowError]
  );
  const errorMessage = useMemo(
    () =>
      invoiceListError?.data.message ||
      adminPayNowError?.data?.message ||
      adminExportInvoiceError?.data?.message ||
      clinicExportInvoiceError?.data?.message ||
      clinicPayNowError?.data?.message,
    [
      invoiceListError?.data.message,
      adminExportInvoiceError?.data.message,
      clinicExportInvoiceError?.data?.message,
      clinicPayNowError?.data?.message,
      adminPayNowError?.data?.message,
    ]
  );

  const toggleInvoiceModal = useCallback((id = "") => {
    setInvoiceId(id);
  }, []);

  const applySorting = useCallback(() => {
    const sortingObj = sorting[0];

    if (sortingObj?.id) {
      setApiParam((prev) => {
        const newParams = { ...prev, sort: { [sortingObj.id]: sortingObj.desc ? -1 : 1 } };
        fetchInvoices(newParams, true);
        return newParams;
      });
    }
  }, [fetchInvoices, sorting]);

  // useEffect is important to apply sorting  with dependency applySorting
  useEffect(() => {
    applySorting();
  }, [applySorting]);

  useEffect(() => {
    if (!isInvoiceListFetching) {
      fetchInvoices(apiParam, true);
    }
  }, []);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const clearSelection = useCallback(() => {
    setRowSelection({});
  }, []);

  const refetchList = useCallback(() => {
    const body = { currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    setApiParam(body);
    fetchInvoices(body);
  }, [apiParam, fetchInvoices, rowLimit, tablePage]);

  const updateFilterValue = useCallback((keyword, value) => {
    setApiParam((prev) => ({ ...prev, [keyword]: value }));
    if (value) {
      setDisableFilter(false);
    }
  }, []);

  const updateKeyword = useCallback((e) => {
    e.preventDefault();
    setApiParam((prev) => ({ ...prev, keyword: e.target.value }));
    if (e.target.value) {
      setDisableFilter(false);
    }
  }, []);

  const updateCardAction = useCallback((rowData = {}) => {
    setConfirmCreditCardModal(true);
    setRowSelection({ [rowData.id]: true });
    setPaymentInstructions(NOTES.UPDATE_CARD);
  }, []);

  const setPayFullBalance = (bool) => {
    setPayFullBalanceModal(bool);
  };

  const setExportSuccessMessage = (msg) => {
    setSuccess(msg);
  };

  // Need to optimize the PayNet30 flow
  const payNet30 = async (
    prescriptionIds = [],
    isToPayFullBalance = false,
    isCardDefaultForClinic = false,
    encryptedBillingInfo = "",
    fromDate = "",
    toDate = "",
    paymentStatus = "",
    keyword = "",
    locationBillingInfo = []
  ) => {
    let body = {
      prescriptionIds: prescriptionIds,
      isToPayFullBalance: isToPayFullBalance,
      fromDate: fromDate ? moment(fromDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
      toDate: toDate ? moment(toDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
      paymentStatus: paymentStatus,
      keyword: keyword,
      clinicLocationsBillingInfo: locationBillingInfo,
    };
    if (isToPayFullBalance && !clinicSide) delete body.clinicLocationsBillingInfo;
    let response = "";
    if (clinicSide) response = await doClinicPayNow(body);
    else response = await doAdminPayNow(body);
    if (response?.data) {
      setPaymentSuccess(response?.data?.message);
      setSuccess(response?.data?.message);
      clearSelection();
      if (confirmCreditCardModal) closeCreditCardModal();
      closeCreditCardModal();
    }
  };

  const setPaymentSuccess = useCallback((message) => {
    setSuccess(message);
  }, []);

  const closeTransactionModal = useCallback(() => {
    setTransactionModal(false);
  }, []);

  const closeCreditCardModal = useCallback(() => {
    setConfirmCreditCardModal(false);
    setPayFullBalanceModal(false);
  }, []);

  const exportAllInvoices = useCallback(() => {
    if (clinicSide) {
      clinicExportInvoice({ clinicId: userInfo?.clinicId });
    } else {
      adminExportInvoice({fromDate: fromDate, toDate: toDate});
    }
  }, [adminExportInvoice, clinicExportInvoice, clinicSide, userInfo?.clinicId, fromDate, toDate]);

  const changeRowLimit = useCallback(
    (e) => {
      const size = Number(e.target.value);
      setApiParam((prev) => {
        const body = { ...prev, currentPage: 1, limit: size };
        fetchInvoices(body, true);
        return body;
      });
    },
    [fetchInvoices]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isInvoiceListFetching) {
        const body = { ...apiParam, currentPage: newPage + 1 };
        if (!apiParam?.keyword) delete body.keyword;
        setApiParam(body);
        fetchInvoices(body, true);
        clearSelection();
      }
    },
    [isInvoiceListFetching, apiParam, fetchInvoices, clearSelection]
  );

  const clearFilter = useCallback(
    (e) => {
      e.preventDefault();
      setApiParam((prev) => {
        fetchInvoices({ currentPage: 1, limit: prev.limit }, true);
        return { currentPage: 1, limit: prev.limit };
      });
      setDisableFilter(true);
    },
    [fetchInvoices]
  );

  const applyFilter = useCallback(
    (e) => {
      e.preventDefault();
      const body = {};
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, value);
        }
      });
      fetchInvoices(body, true);
    },
    [apiParam, fetchInvoices]
  );

  return {
    toDate,
    sorting,
    clinicId,
    fromDate,
    rowLimit,
    tablePage,
    invoiceId,
    rowSelection,
    totalRecords,
    searchKeyword,
    disableFilter,
    isSelectableAll,
    paymentStatuses,
    selectedInvoices,
    filteredPaymentStatus,
    reactTablePaginationState,
    setSorting,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    updateCardAction,
    handleChangePage,
    exportAllInvoices,
    updateFilterValue,
    toggleInvoiceModal,
    closeCreditCardModal,

    // Below is Shehroz logic
    totalBalanceDue,
    setTotalBalanceDue,
    payFullBalanceModal,
    success,
    setSuccess,
    selectedTotalAmount,
    selectedPaymentApiMessage,
    setSelectedPaymentApiMessage,
    transactionModal,
    setTransactionModal,
    confirmCreditCardModal,
    setConfirmCreditCardModal,
    showLoading,
    showError,
    errorMessage,
    setPayFullBalance,
    setExportSuccessMessage,
    payNet30,
    setPaymentSuccess,
    closeTransactionModal,
    setPaymentInstructions,
    paymentInstructions,
    prescriptions,
    error,
    setError,
  };
};

export default useInvoiceList;

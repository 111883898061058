import { ClinicApi } from "../../rtkApi";
import { API_METHODS, END_POINTS } from "../../rtkConstant";

const ClinicProfileMutation = ClinicApi.injectEndpoints({
  endpoints: (builder) => ({
    editClinic: builder.mutation({
      query: (arg) => ({
        url: END_POINTS.EDIT_CLINIC,
        method: API_METHODS.POST,
        body: arg.payload,
      }),
    }),
  }),
});

export const { useEditClinicMutation } = ClinicProfileMutation;

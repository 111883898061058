import React, { useState } from "react";

export const PortalContext = React.createContext();

function PortalProvider(props) {
  const [portal, setPortal] = useState(null);

  function setPortalContext(name = "") {
    setPortal(name);
  }

  return <PortalContext.Provider value={{ portal, setPortalContext }}>{props.children}</PortalContext.Provider>;
}

export default React.memo(PortalProvider);

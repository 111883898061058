import React, { useState, useRef, useEffect, useMemo } from "react";
import { userRoleSelector, userInfoSelector, jwtTokensSelector } from "../../../../selectors";
import { useSelector } from "react-redux";
import {
  useAddAdminMutation,
  useEditProfileMutation,
  useEditAdminMutation,
  useLazyGetAdminQuery,
  useUpdateAdminMutation,
  useUploadFileMutation,
} from "rtk";
import moment from "moment";
import { base64toFile } from "utils";

function useAdminStaff(props) {
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const authToken = useSelector(jwtTokensSelector);
  const [error, setError] = useState("");
  const [fetchAdmin, { data: user, error: adminFetchError, isError: isAdminFetchError, isFetching: isAdminFetching }] =
    useLazyGetAdminQuery();

  const [uploadMutate, { error: uploadError, isError: isUploadError, isLoading: isUploadLoading }] =
    useUploadFileMutation();

  const [
    editAdminMutate,
    { error: updateAdminError, isError: isUpdateAdminError, isLoading: isUpdateAdminLoading, isSuccess: editComplete },
  ] = useEditAdminMutation();
  
  const [
    addAdminMutate,
    { error: addAdminError, isError: isAddAdminError, isLoading: isAddAdminLoading, isSuccess: addComplete },
  ] = useAddAdminMutation();
  
  const [
    editProfileMutate,
    {
      error: editProfileError,
      isError: isEditProfileError,
      isLoading: isEditLoading,
      isSuccess: isEditProfileComplete,
    },
  ] = useEditProfileMutation();

  //Defining Errors and Loading

  //Defining Memos for Errors, Loading
  const complete = useMemo(
    () => editComplete || addComplete || isEditProfileComplete,
    [editComplete, addComplete, isEditProfileComplete]
  );

  //Defining Errors and Loading
  const errorMessage = useMemo(
    () =>
      error ||
      addAdminError?.data?.message ||
      editProfileError?.data?.message ||
      adminFetchError?.data?.message ||
      uploadError?.data?.message ||
      updateAdminError?.data?.message,
    [error, addAdminError, editProfileError, adminFetchError, uploadError, updateAdminError]
  );

  const showError = useMemo(
    () => error || isAddAdminError || isEditProfileError || isAdminFetchError || isUploadError || isUpdateAdminError,
    [error || isAddAdminError, isEditProfileError, isAdminFetchError, isUploadError, isUpdateAdminError]
  );
  
  const showLoading = useMemo(
    () => isAddAdminLoading || isEditLoading || isAdminFetching || isUploadLoading || isUpdateAdminLoading,
    [isAddAdminLoading, isEditLoading, isAdminFetching, isUploadLoading, isUpdateAdminLoading]
  );

  const updateProfile = (obj = "", id = "", profileImageUrl = "") => {
    let userObj = {
      adminId: id,
      admin: {
        firstName: obj.firstName,
        middleName: obj.middleName,
        lastName: obj.lastName,
        email: obj.email,
        //TODO:
      },
    };
    return userObj;
  };

  // async function uploadFile(e = "", signPad = "") {
  //   var file = "";
  //   const formData = new FormData();
  //   if (signPad) {
  //     file = base64toFile(signPad);
  //     formData.append("file", file, userInfo.id + "-signature");
  //   } else {
  //     file = e.target.files[0];
  //     formData.append("file", file);
  //   }

  //   let res = await uploadMutate({
  //     headerInfo: {
  //       Authorization: "Bearer " + authToken,
  //       "Content-Type": "multipart/form-data;",
  //     },
  //     payload: formData,
  //   });

  //   if (res?.data) return res.data.accessUrl;
  //   else return null;
  // }

  return {
    updateProfile,
    base64toFile,
    // uploadFile,
    fetchAdmin,
    editAdminMutate,
    addAdminMutate,
    user,
    complete,
    errorMessage,
    showError,
    showLoading,
    editProfileMutate,
    isAdminFetching,
    userInfo,
    userRole,
    setError
  };
}

export default useAdminStaff;

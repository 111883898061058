import React, { useMemo } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../dropdowns/stateDD";
import CreditCardInput from "react-credit-card-input";
import { encrypt, decrypt } from "cryptofunc";
import StepOne from "./StepOne";
const ClinicFormInfo = ({
  step,
  onlyOneUser,
  clinicAddress,
  onChange,
  setOnlyOneUser,
  upload,
  setClinicAddress,
  signatureImageUrl,
  signatureModal,
  closeSignatureModal,
  onClickSave,
  setSignatureModal,
  adminSide = false,
}) => {
  return (
    <div className="my-5 px-4">
      <StepOne
        step={step}
        onlyOneUser={onlyOneUser}
        clinicAddress={clinicAddress}
        onChange={onChange}
        setOnlyOneUser={setOnlyOneUser}
        upload={upload}
        setClinicAddress={setClinicAddress}
        signatureImageUrl={signatureImageUrl}
        signatureModal={signatureModal}
        closeSignatureModal={closeSignatureModal}
        onClickSave={onClickSave}
        setSignatureModal={setSignatureModal}
        adminSide={adminSide}
      />
    </div>
  );
};

export default ClinicFormInfo;

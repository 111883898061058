import {
  API_METHODS,
  END_POINTS,
  EXPORT_END_POINT,
  PATIENT_END_POINT,
} from "../../rtkConstant";
import { ClinicApi, PatientApi } from "../../rtkApi";
import { USER_ROLES } from "../../../utils";
import { set } from "lodash";

const PatientQuery = PatientApi.injectEndpoints({
  endpoints: (builder) => ({
    getClinicPatients: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const isClinicSide = user?.userData?.role
              ?.toLowerCase()
              .includes("clinic");
            const isSuperuser =
              user?.userData?.role === USER_ROLES.CLINIC_SUPER_USER;
            let organizedBody = {
              ...apiArgs,
              limit: apiArgs?.limit || 10,
              userId: user?.userData?.id,
            };
            let url;
            if (!isClinicSide) {
              if (apiArgs?.locationId) {
                delete organizedBody.clinicLocationId;
                // Commenting till Backend does it set(organizedBody, "clinicLocationId", apiArgs.locationId);
                set(organizedBody, "clinicId", apiArgs.locationId);
                // url = END_POINTS.CLINIC_LOCATION_PATIENT;
                url = PATIENT_END_POINT.CLINIC_PATIENT;
              }
            } else {
              if (isSuperuser) {
                delete organizedBody.clinicLocationId;
                set(organizedBody, "clinicId", user?.userData?.clinicId);
                url = PATIENT_END_POINT.CLINIC_PATIENT;
              } else {
                delete organizedBody.clinicLocationId;
                set(
                  organizedBody,
                  "clinicLocationId",
                  user?.userData?.clinicLocationId
                );
                url = PATIENT_END_POINT.CLINIC_LOCATION_PATIENT;
              }
            }

            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },

      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.currentPage === 1) {
          return newItems;
        }
        return {
          ...currentCache,
          patients: [...currentCache?.patients, ...newItems?.patients],
          pagination: newItems?.pagination,
        };
      }, // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        console.log({ currentArg, previousArg });
        return currentArg !== previousArg;
      },
    }),
    getClinicPatientsList: builder.query({
      queryFn: async (__, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const apiParams = {
              clinicId: user?.userData?.clinicId,
              userId: user?.userData?.id,
            };
            const response = await baseQuery({
              url: EXPORT_END_POINT.CLINIC_PATIENT_REPORT,
              method: API_METHODS.POST,
              body: apiParams,
              responseHandler: async (response) =>
                window.location.assign(
                  window.URL.createObjectURL(await response.blob())
                ),
            });
            return { ...response, data: response?.data || null };
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),

    getAllPatientsList: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20, userId: user?.userData?.id };
            // const organizedBody = {
            //   ...body,
            //   limit: body?.limit || 20,
            //   userId: user?.userData?.id,
            // };
            // const clinicSide = user?.userData?.role?.toLowerCase().includes("clinic");
            let url;
            url = PATIENT_END_POINT.GET_ALL_PATIENTS;
            // if (showClinicView) {
            //   // set(organizedBody, "clinicId", user?.userData?.clinicId ?? showClinicView);
            //   set(organizedBody, "userId", user?.userData?.id);
            //   url = PATIENT_END_POINT.CLINIC_PATIENT;
            // }
            let response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            let dataList = [];
            await response.data.patients.forEach((patient) => {
              dataList.push({
                id: patient.id,
                patientName:
                [patient.firstName, patient.lastName].join(' '),
                clinicName: patient.clinicName,
                clinicLocationName: patient.clinicLocationName,
                info: [
                  patient.dateOfBirth,
                  patient.sex,
                  patient.email,
                  patient.phoneNumber,
                ],
                // last_order: '-',
                last_order_info: [
                  patient.last_order_date ? patient.last_order_date : "-",
                  patient.last_order_status ? patient.last_order_status : "-",
                ],
              });
            });
            response.data.patients = dataList;
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },

      // Only have one cache entry because the arg always maps to one string
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName;
      // },
      // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems, { arg }) => {
      //   if (arg.body.currentPage === 1) {
      //     return newItems;
      //   }
      //   return {
      //     ...currentCache,
      //     patients: [...currentCache?.patients, ...newItems?.patients],
      //     pagination: newItems?.pagination,
      //   };
      // },
      // // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        console.log({ currentArg, previousArg });
        return currentArg !== previousArg;
      },
    }),
    getMedicationHistory: builder.query({
      query: (patientId) => ({
        url: `${PATIENT_END_POINT.GET_MEDICATION_HISTORY}${patientId}`,
        method: API_METHODS.GET,
      }),
    }),
    getPatientPrescriptionsHistory: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20 };
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const response = await baseQuery({
              url: "/patients/getPatientPrescriptionsByPatientId",
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: String(error),
            },
          };
        }
      },
    }),
  }),
});

export const {
  useLazyGetClinicPatientsQuery,
  useLazyGetClinicPatientsListQuery,
  useLazyGetAllPatientsListQuery,
  useLazyGetMedicationHistoryQuery,
  useLazyGetPatientPrescriptionsHistoryQuery,
} = PatientQuery;

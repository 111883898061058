import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Alert } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import AdminNavbar from "../../../components/AdminNavbar";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import PrimaryModal from "../../../components/primaryModal";
import moment from "moment";
import { USER_ROLES } from "utils";

class AdminsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sizePerPage: 10,
      showOrderButton: false,
      showSpinner: true,
      confirmDeleteModal: false,
      deleteAdminId: "",
      error: "",
      data: [],
      tableHeaders: [
        { text: "id", dataField: "id", sort: true, hidden: true },
        { text: "Admin Name", dataField: "adminName", sort: true },
        { text: "Email", dataField: "email", sort: true },
        { text: "Role", dataField: "role", sort: true },
        { text: "Date created", dataField: "created", sort: true },
        {
          text: "Verified",
          dataField: "verified",
          sort: true,
          headerStyle: () => {
            return { width: "80px", textAlign: "text-center" };
          },
        },
        {
          text: "Actions",
          dataField: "actions",
          headerStyle: () => {
            return { width: "80px", textAlign: "center" };
          },
          formatter: (cellContent, row) => (
            <>
              <Link to={"/admin-profile/" + row.id} className="pinkButton">
                Profile
              </Link>
              {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ||
              row.id === new Cookies().get("user_id") ? (
                <Link to={"/edit-profile/" + row.id}>
                  <i className="fas fa-edit medText mx-3" style={{ fontSize: "16px" }}></i>
                </Link>
              ) : null}
              {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER &&
                row.id !== new Cookies().get("user_id") && (
                  <Link
                    to="#"
                    onClick={() => {
                      this.setState({ deleteAdminId: row.id, confirmDeleteModal: true });
                    }}
                  >
                    <i className="fas fa-trash redText" style={{ fontSize: "18px" }}></i>
                  </Link>
                )}
            </>
          ),
        },
      ],
      keyword: "",
      disableFilter: true,
    };
  }

  deleteAdmin() {
    this.setState({
      showSpinner: true,
      confirmDeleteModal: false,
    });

    axiosConfig
      .delete("admin/account/deleteAdminByAdminId", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
        data: {
          adminId: this.state.deleteAdminId,
          userId: new Cookies().get("user_id"),
        },
      })
      .then(async (response) => {
        await this.setState({
          showSpinner: false,
          error: "",
          data: this.state.data.filter((row) => row.id !== this.state.deleteAdminId),
          deleteAdminId: "",
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to delete the patient!",
        });
        errorResponse(error);
      });
  }

  closeConfirmDeleteModal() {
    this.setState({ confirmDeleteModal: false });
  }

  getAllAdmins(keyword = "") {
    this.setState({
      showSpinner: true,
    });
    axiosConfig
      .post(
        "/admin/account/getAdmins",
        {
          userId: new Cookies().get("user_id"),
          keyword: keyword,
          limit: 20,
          currentPage: 1,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var dataList = [];
        await response?.data?.admins?.forEach((admin) => {
          dataList.push({
            id: admin.id,
            adminName: admin.firstName + " " + (admin.middleName ? admin.middleName + " " : "") + admin.lastName,
            email: admin.email,
            role:
              admin.role === USER_ROLES.PHARMACIST
                ? "Admin"
                : admin.role === USER_ROLES.ADMIN_SUPER_USER
                ? "Super User"
                : admin.role === USER_ROLES.RX_TECH
                ? "RX Tech"
                : admin.role === USER_ROLES.SALES_REP
                ? "Sales Rep"
                : "",
            created: moment(admin.created, "YYYY-MM-DD").format("MM/DD/YYYY"),
            verified: admin.isVerified ? (
              <i className="fas fa-user-check greenText" style={{ fontSize: "16px" }} />
            ) : (
              <i className="fas fa-user-times redText" style={{ fontSize: "16px" }} />
            ),
          });
        });

        await this.setState({
          data: dataList,
          showSpinner: false,
          error: "",
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch admins list!",
        });

        errorResponse(error);
      });
  }
  setDisableFilter(bool) {
    this.setState({
      disableFilter: bool,
    });
  }
  filterChanged(v = "") {
    this.setState({
      keyword: v,
    });
    this.setDisableFilter(v.length === 0 ? true : false);
  }

  clearSearch(keyword) {
    if (keyword === "") {
      this.filterChanged(keyword);
      this.getAllAdmins();
    }
  }
  clearFilter() {
    this.setState({
      keyword: "",
    });
    this.getAllAdmins();
  }

  componentDidMount() {
    this.getAllAdmins();
  }

  render() {
    
    return (
      <React.Fragment>
        <AdminNavbar />
        <div className="page-content">
          <MetaTags>
            <title>Admins | Get Royal Health</title>
          </MetaTags>
          <Container fluid>
            <h4 className="p-3 text-muted">ADMINS</h4>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {this.state.showSpinner ? <Spinner /> : null}
                    {this.state.error ? (
                      <Alert color="danger" className="text-center">
                        {this.state.error}
                      </Alert>
                    ) : null}

                    <React.Fragment>
                      <Row className={`px-3 my-4 justify-content-end`}>
                        {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
                          <Link to="/add-admin" className="pinkButton">
                            Add Admin
                          </Link>
                        ) : null}
                      </Row>
                      <Row className="justify-content-between px-3 my-3">
                        <Row className="my-4 px-sm-3 px-1">
                          <Col xs={8} sm={9} md={4} lg={2}>
                            <div className="tableSearch d-flex">
                              <i className="bx bx-search-alt search-icon flexJCAC" />
                              <input
                                className="borderless mx-2"
                                onChange={(e) => {
                                  this.filterChanged(e.target.value);
                                  this.clearSearch(e.target.value);
                                }}
                                value={this.state.keyword}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.getAllAdmins(this.state.keyword);
                                  }
                                }}
                                placeholder="Search table"
                              />
                            </div>
                          </Col>
                          <Col xs={4} sm={3} md={4} lg={2}>
                            <button
                              disabled={this.state.disableFilter}
                              className="yellowButton"
                              onClick={() => {
                                this.getAllAdmins(this.state.keyword);
                              }}
                            >
                              Search
                            </button>
                          </Col>
                          {!this.state.disableFilter && (
                            <Col xs={12} sm={1} lg={1}>
                              <p
                                disabled={this.state.disableFilter}
                                className="blueText"
                                style={{
                                  fontSize: 14,
                                  cursor: "pointer",
                                  paddingTop: 8,
                                  marginLeft: 10,
                                }}
                                onClick={() => {
                                  this.clearFilter();
                                  this.setDisableFilter(true);
                                }}
                              >
                                Clear
                              </p>
                            </Col>
                          )}
                        </Row>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive" style={{ minHeight: "300px" }}>
                            <BootstrapTable
                              responsive
                              keyField={"id"}
                              bordered={false}
                              striped={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              columns={this.state.tableHeaders}
                              data={this.state.data}
                            />

                            {this.state.data.length === 0 ? (
                              <p className="w-100 text-center m-5">No Admins data found!</p>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <PrimaryModal
          question="Are you sure you want to delete this admin?"
          showModal={this.state.confirmDeleteModal}
          closeModal={this.closeConfirmDeleteModal.bind(this)}
          onClickYes={this.deleteAdmin.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default AdminsList;

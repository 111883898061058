import { forEach } from "lodash";
import Cookies from "universal-cookie";
import { COOKIES_NAME } from "./cookieConstant";

const cookies = new Cookies();

const login = (id, email, firstName, lastName, isVerified, role, token, clinicID, locationID, refreshToken = "") => {
  writeCookie(COOKIES_NAME.IS_LOGGED, true, 300);
  writeCookie(COOKIES_NAME.USER_ID, id, 300);
  writeCookie(COOKIES_NAME.EMAIL, email, 300);
  writeCookie(COOKIES_NAME.FIRST_NAME, firstName, 300);
  writeCookie(COOKIES_NAME.LAST_NAME, lastName, 300);
  writeCookie(COOKIES_NAME.IS_VERIFIED, isVerified, 300);
  writeCookie(COOKIES_NAME.ROLE, role, 300);
  writeCookie(COOKIES_NAME.USER_TOKEN, token, 300);
  writeCookie(COOKIES_NAME.CLINIC_LOCATION_ID, locationID, 300);
  writeCookie(COOKIES_NAME.REFRESH_TOKEN, refreshToken, 300);
  if (clinicID) {
    writeCookie(COOKIES_NAME.CLINIC_ID, clinicID, 300);
  }
};

const logout = async () => {
  forEach(COOKIES_NAME, (name, _) => {
    cookies.remove(name, { path: "/", secure: true, sameSite: "none" });
  });
};

const refreshTokenCookies = (token = "", refreshToken = "") => {
  const previousCookies = cookies.getAll();
  forEach(previousCookies, (value, key) => {
    writeCookie(key, value, 300);
  });
  if (token) {
    writeCookie(COOKIES_NAME.USER_TOKEN, token, 300);
    writeCookie(COOKIES_NAME.REFRESH_TOKEN, refreshToken, 300);
  }
};

const isLoggedIn = () => {
  if (
    cookies.get(COOKIES_NAME.IS_LOGGED) &&
    cookies.get(COOKIES_NAME.USER_ID) &&
    cookies.get(COOKIES_NAME.EMAIL) &&
    cookies.get(COOKIES_NAME.FIRST_NAME) &&
    cookies.get(COOKIES_NAME.LAST_NAME) &&
    cookies.get(COOKIES_NAME.ROLE) &&
    cookies.get(COOKIES_NAME.CLINIC_LOCATION_ID) &&
    cookies.get(COOKIES_NAME.USER_TOKEN)
  ) {
    return true;
  }
  return false;
};

const writeCookie = (key, value, minutes) => {
  var date = new Date();

  date.setTime(date.getTime() + minutes * 60 * 1000);

  cookies.set(key, value, { path: "/", expires: date, secure: true, sameSite: "none" });

  return value;
};


function base64toFile(base64String) {
  // Extract file format and data
  const matches = base64String.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string");
  }
  const [, fileFormat, fileData] = matches;
  // Decode base64 data
  const decodedData = atob(fileData);
  // Create Uint8Array from decoded data
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }
  // Create Blob object
  const blob = new Blob([uint8Array], { type: fileFormat });
  return blob;
}

export {
  cookies,
  refreshTokenCookies,
  writeCookie,
  isLoggedIn,
  logout,
  login,
  base64toFile,
};

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Cookies from 'universal-cookie';
import axiosConfig, { errorResponse } from '../../../../utils/axiosConfig';

const useProductDiscountModal = (props) => {
  const { showModal = false, closeModal, isTierModal = false, clinicId } = props;
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const onSubmit = (data) => {
    const { discount = 0, family = '' } = data;
    setShowSpinner(true);
    if (isTierModal) {
      //tier discount api

      const body = {
        clinicId: clinicId,
        userId: new Cookies().get('user_id'),

        category: family,
        discountPercentage: parseInt(discount),
      };

      axiosConfig
        .post('admin/productDiscounts/applyTierDiscountByClinicId', body, {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
          },
        })
        .then(async (response) => {
          setComplete(true);
          setError('');
          setSuccess(response.data.message);
          setShowSpinner(false);
        })
        .catch((err) => {
          setComplete(true);
          setSuccess('');
          setError(err?.response?.data?.message);
          setShowSpinner(false);
        });
    } else {
      //bulk discount api
      const body = {
        clinicId: clinicId,
        userId: new Cookies().get('user_id'),
        discountPercentage: parseInt(discount),
        discountPrice: parseInt(discount),
      };
      axiosConfig
        .post('admin/productDiscounts/applyBulkDiscountByClinicId', body, {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
          },
        })
        .then(async (response) => {
          setComplete(true);
          setError('');
          setSuccess(response.data.message);
          setShowSpinner(false);
        })
        .catch((err) => {
          setComplete(true);
          setSuccess('');
          setError(err?.response?.data?.message);
          setShowSpinner(false);
        });
    }
  };

  return { error, complete, success, showModal, closeModal, onSubmit, register, handleSubmit, errors, showSpinner };
};

export default useProductDiscountModal;

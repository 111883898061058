import { ClinicApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, END_POINTS } from "../../rtkConstant";

const ClinicInvoiceMutation = ClinicApi.injectEndpoints({
  endpoints: (builder) => ({
    clinicPayNow: builder.mutation({
      query: (body) => ({
        url: END_POINTS.CLINIC_PAY_NOW,
        method: API_METHODS.POST,
        body,
      }),
      invalidatesTags: (result, error, { prescriptionIds = [] }) =>
        prescriptionIds.map((invoice) => ({
          type: CACHE_TAGS.INVOICE,
          id: invoice,
        })),
    }),
  }),
});
export const { useClinicPayNowMutation } = ClinicInvoiceMutation;

import React, { useState } from 'react';
import useAddPriceGroupModal from './index.hook';
import { FormGroup, Label, Modal, Row, Container, Col, Card, CardBody, CardFooter } from 'reactstrap';
import Spinner from 'components/spinner';
import Lottie from 'react-lottie';

const AddPriceGroupModal = (props) => {
  const { showModal, closeModal, reload, setReload, showSpinner, success, error, onSubmit, register, handleSubmit, errors } = useAddPriceGroupModal(props);

  return (
    <Modal isOpen={showModal} size='md' centered={true} className='smallHeightmodal add-product-modal'>
      <Container>
        <form id='registerForm' className='form-horizontal product-form mt-2' onSubmit={handleSubmit(onSubmit)}>
          <button type='button' onClick={() => closeModal()} className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>

          {success ? (
            <div className='modal-body  text-center'>
              <p className='greenText'>{success}</p>
            </div>
          ) : (
            <div className='modal-body  text-center'>
              {error ? <p className='redText'>Error: {error}</p> : null}

              {showSpinner ? <Spinner /> : null}
              <InputFieldCustom errors={errors} name={'priceGroup'} label={'Price Group'} register={register} required={true} />

              <CardFooter>
                <div className={'product-card-footer text-center'}>
                  <button onClick={() => closeModal()}>Cancel</button>
                  <button type={'submit'} className={'pinkButton'}>
                    {'Add'}
                  </button>
                </div>
              </CardFooter>
            </div>
          )}
        </form>
      </Container>
    </Modal>
  );
};

export default AddPriceGroupModal;

const InputFieldCustom = (props) => {
  const { name = '', label = '', placeholder = '', register, required = false, type = 'text', errors = null } = props;
  const isRequiredError = !!errors && !!errors[name] && !!errors[name].type && errors[name].type === 'required' && required;
  return (
    <FormGroup>
      <Label htmlFor={name} className='form-label'>
        {label} {required && <span className='requiredStar'>*</span>}
      </Label>
      <input name={name} pattern='[A-Z]{1}' maxLength={'1'} className={`form-control`} type={type} ref={register({ required })} />
      {isRequiredError && <span className={'error-product-required'}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};

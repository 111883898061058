import { Row } from "reactstrap";
const BottomNavigationSteps = ({ step, setStep, actionText, isVerified, side = "" }) => {
  return (
    <Row className="my-5 text-center pt-3">
      {step === 1 ? (
        <button className="btn btn-primary darkBg mt-3 mx-auto w-50" type="submit">
          Save
        </button>
      ) : (
        <>
          <button
            className="btn btn-outline-light mt-4 mx-auto"
            style={{ width: "40%" }}
            onClick={(e) => {
              e.preventDefault();
              setStep(1);
            }}
          >
            Back
          </button>
          <button
            disabled={!side && !isVerified}
            className="btn btn-primary darkBg mt-4 mx-auto"
            style={{ width: "40%" }}
            type="submit"
          >
            {actionText}
          </button>
        </>
      )}
    </Row>
  );
};

export default BottomNavigationSteps;

import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Container, Alert } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Spinner from '../../../components/spinner';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { dateFormatterMountainTime } from '../../../../src/utils/constants';

import PaginationComponent from 'react-reactstrap-pagination';
class PatientPrescription extends Component {
  constructor(props) {
    super(props);

    this.startDate = React.createRef();
    this.endDate = React.createRef();

    this.state = {
      patientId: this.props.pId,
      page: 1,
      sizePerPage: 10,
      keyword: '',
      fromDate: '',
      toDate: '',
      showSpinner: true,
      error: '',
      disableFilter: true,
      data: [],
      filteredData: [],
      tableHeaders: [
        { text: 'id', dataField: 'id', sort: true, hidden: true },
        {
          text: 'Date',
          dataField: 'date',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: '80px', textAlign: 'center' };
          },
        },
        {
          text: 'Created By',
          dataField: 'createdBy',
          sort: true,
          formatter: (cellContent, row) => cellContent[0],
        },
        {
          text: 'Product(s)',
          dataField: 'products',
          sort: true,
          formatter: (cellContent) =>
            cellContent.map((p, i) => (
              <>
                {cellContent[1] ? cellContent[i + 1] ? <b>{i + 1}: </b> : <b>{i + 1}: </b> : null}
                {p}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line', maxHeight: 100, height: 100 };
          },
        },
        {
          text: 'Info',
          dataField: 'info',
          sort: true,
          formatter: (cellContent) =>
            cellContent.map((p, i) => (
              <>
                {/* <b>{i + 1}: </b> */}
                Quantity:<b>{p.quantity}</b>
                {'\n'}
                DaySupply:<b>{p.daySupply}</b>
                {'\n'}
                Refills:<b>{p.refills}</b>
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
        {
          text: 'Sig',
          dataField: 'sig',
          sort: true,
          formatter: (cellContent) =>
            cellContent.map((p, i) => (
              <>
                {p}
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
        {
          text: 'Patient Diagnosis',
          dataField: 'patientDiagnosis',
          sort: true,
          formatter: (cellContent) =>
            cellContent.map((p, i) => (
              <>
                {p}
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
        {
          text: 'Compounded Medication Reason',
          dataField: 'reasonForCompoundedMedication',
          sort: true,
          formatter: (cellContent) =>
            cellContent.map((p, i) => (
              <>
                {p}
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
      ],
    };
  }
  getPrescriptionHistory(size = 10, pageNo = 1) {
    this.setState({
      page: pageNo,
      sizePerPage: size,
      showSpinner: true,
    });

    var body = {
      patientId: this.state.patientId,
      limit: size,
      currentPage: pageNo,
    };

    axiosConfig
      .post('/patients/getPatientPrescriptionsByPatientId', body, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        var dataList = [];
        await response.data.prescriptions.forEach((prescription) => {
          let products = [],
            info = [],
            sig = [],
            dx = [],
            reason = [];
          prescription.products.forEach((p) => products.push(p.name));
          prescription.products.forEach((p) => info.push({ quantity: p.quantity, daySupply: p.daySupply, refills: p.refills }));
          prescription.products.forEach((p) => sig.push(p.sig));
          prescription.products.forEach((p) => dx.push(p.patientDiagnosis));
          prescription.products.forEach((p) => reason.push(p.reasonForCompoundedMedication));
          dataList.push({
            id: prescription.id,
            date: dateFormatterMountainTime(prescription.prescriptionDate),
            createdBy: [prescription.createdByName, prescription.createdById],
            products: products,
            info: info,
            sig: sig,
            patientDiagnosis: dx,
            reasonForCompoundedMedication: reason,
          });
        });

        await this.setState({
          pagination: response.data.pagination,
          filteredData: dataList,
          showSpinner: false,
        });
        this.props?.scrollToRx();
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: 'An error occurred while trying to fetch prescription history!',
        });

        errorResponse(error);
      });
  }

  handlePageChange = (pageNo) => {
    const { sizePerPage = 10, keyword = '', fromDate, toDate } = this.state;
    this.setState({ page: pageNo });

    this.getPrescriptionHistory(sizePerPage, pageNo);
    //console.log(pageNo);
  };

  componentDidMount() {
    this.setState({ filteredData: this.state.data });
    let role = new Cookies().get('user_role');
    //let condition = role === USER_ROLES.CLINIC_SUPER_USER;
    //if (!condition) {
    this.getPrescriptionHistory(this.state.sizePerPage, this.state.page);
  }

  render() {
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.filteredData.length,
      custom: true,
    };
    const defaultSorted = [{ dataField: 'date', order: 'desc' }];
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;
    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (rowIndex % 2 == 0) {
        style.backgroundColor = '#f7faff';
        style.border = '1px solid #f7faff';
      } else {
        style.border = '1px solid #f7faff';
        style.backgroundColor = '#fff';
      }
      return style;
    };

    return (
      <React.Fragment>
        <Row>
          <Col xs='12'>
            <Card>
              <CardBody>
                {this.state.showSpinner ? <Spinner /> : null}
                {this.state.error ? (
                  <Alert color='danger' className='text-center'>
                    {this.state.error}
                  </Alert>
                ) : null}
                <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={this.state.tableHeaders} data={this.state.filteredData}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider keyField='id' columns={this.state.tableHeaders} data={this.state.filteredData} search exportCSV>
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl='12'>
                              <div className='table-responsive'>
                                <BootstrapTable keyField={'id'} responsive bordered={false} striped={false} defaultSorted={defaultSorted} classes={'table align-middle table-nowrap'} headerWrapperClasses={'thead-light'} {...toolkitProps.baseProps} {...paginationTableProps} rowStyle={rowStyle} />
                              </div>
                            </Col>
                          </Row>
                          <Row className='align-items-md-center mt-30 px-3'>
                            <Col className='inner-custom-pagination d-flex'>
                              <div className='text-md-right ms-auto'>
                                <Row className='align-items-md-center mt-3 px-3'>
                                  <Col className='inner-custom-pagination d-flex'>
                                    <div className='text-md-right ms-auto'>
                                      <PaginationComponent size='sm' totalItems={this.state.sizePerPage * this.state.pagination?.totalPages || 0} pageSize={this.state.sizePerPage} onSelect={(e) => this.handlePageChange(e)} />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PatientPrescription;

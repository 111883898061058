import { ClinicApi } from "../../rtkApi";
import { API_METHODS, END_POINTS, EXPORT_END_POINT, downloadFile } from "../../rtkConstant";

const ClinicInvoiceQuery = ClinicApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceListByClinicId: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.CLINIC_INVOICE_LIST + `${apiArgs?.metaData?.condition ? "" : "Location"}`,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    getExportInvoiceListByClinicId: builder.query({
      query: (body) => ({
        url: EXPORT_END_POINT.CLINIC_EXPORT_INVOICE,
        method: API_METHODS.POST,
        body,
        responseHandler: (response) => downloadFile(response, "invoices_list.csv"),
      }),
    }),
    mostDueInvoiceByClinicId: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.MOST_DUE_INVOICE_BY_CLINIC,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    getBalanceDueByClinicId: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.GET_BALANCE_DUE_BY_CLINIC,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    viewCurrentStatementByClinicId: builder.query({
      query: (apiArgs) => ({
        url: apiArgs.metaData.clinicSuper
          ? END_POINTS.VIEW_CURRENT_STATEMENT_BY_CLINIC
          : END_POINTS.VIEW_CURRENT_STATEMENT_BY_CLINIC_LOCATION,
        method: API_METHODS.POST,
        body: apiArgs.payload,
        responseHandler: (response) => response.text(),
      }),
    }),
    viewPaymentHistoryByClinicId: builder.query({
      query: (apiArgs) => ({
        url: apiArgs.metaData.clinicSuper
          ? END_POINTS.VIEW_PAYMENT_HISTORY_BY_CLINIC
          : END_POINTS.VIEW_PAYMENT_HISTORY_BY_CLINIC_LOCATION,
        method: API_METHODS.POST,
        body: apiArgs.payload,
        responseHandler: (response) => response.text(),
      }),
    }),
    viewPaidInvoicesByClinicId: builder.query({
      query: (apiArgs) => ({
        url: apiArgs.metaData.clinicSuper
          ? END_POINTS.VIEW_PAID_INVOICES_BY_CLINIC
          : END_POINTS.VIEW_PAID_INVOICES_BY_CLINIC_LOCATION,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
  }),
});

export const {
  useLazyGetInvoiceListByClinicIdQuery,
  useLazyGetExportInvoiceListByClinicIdQuery,
  useLazyMostDueInvoiceByClinicIdQuery,
  useLazyGetBalanceDueByClinicIdQuery,
  useLazyViewCurrentStatementByClinicIdQuery,
  useLazyViewPaidInvoicesByClinicIdQuery,
  useLazyViewPaymentHistoryByClinicIdQuery,
} = ClinicInvoiceQuery;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { useLazyGetPatientPrescriptionsHistoryQuery } from "rtk";
import Spinner from "components/spinner";
import { dateFormatterMountainTime } from "utils/constants";
import { createColumnHelper } from "@tanstack/react-table";
import { ROWS_LIMIT } from "utils";
import { MuiTableCollapse } from "components";
import BootstrapTable from "react-bootstrap-table-next";
import { getTableHeader } from "./patient-constant";
// import usePatientMedicationHistoryListing from "./Hook/usePatientMedicationHistoryListing";

const PrescriptionHistory = ({ patientId }) => {
  
  // const {
  //   rowLimit,
  //   tablePage,
  //   showLoading,
  //   totalRecords,
  //   errorMessage,
  //   isReviewModal,
  //   searchKeyword,
  //   disableFilter,
  //   filterRxStatus,
  //   // patientId,
  //   patients,
  //   setPatientId,
  //   setCustomError,
  //   applyFilter,
  //   refetchList,
  //   clearFilter,
  //   updateKeyword,
  //   changeRowLimit,
  //   handleChangePage,
  //   toggleDetailModal,
  //   updateFilterRxStatus,
  //   updateFilterValue,
  // } = usePatientMedicationHistoryListing({
  //   listingQuery: useLazyGetPatientPrescriptionsHistoryQuery,
  // });
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sorting, setSorting] = useState([]);

  useEffect(()=>{
    if(sorting)
    console.log('>>>>',sorting)
  },[sorting])

  // const showSpinner = useMemo(
  //   () => showLoading,
  //   [ showLoading]
  // );
  // const combinedErrorMessage = useMemo(
  //   () => errorMessage ,
  //   [errorMessage ]
  // );

const adminColumnHelper = createColumnHelper();

const ADMIN_ACTIONS = [
  adminColumnHelper.accessor("date", {
    header: "Date",
  }),
  adminColumnHelper.accessor("createdByName", {
    header: "Created By",
    enableSorting: false
  }),
  adminColumnHelper.accessor("products", {
    header: "Products",
    enableSorting: false,
    formatter: (cellContent) =>
            cellContent.map((p, i) => (
              <>
                {cellContent[1] ? cellContent[i + 1] ? <b>{i + 1}: </b> : <b>{i + 1}: </b> : null}
                {p}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line', maxHeight: 100, height: 100 };
          },
  }),
  adminColumnHelper.accessor("info", {
    header: "Info",
    enableSorting: false
  }),
  adminColumnHelper.accessor("sig", {
    header: "Sig",
    enableSorting: false
  }),
  adminColumnHelper.accessor("patientDiagnosis", {
    header: "Patient Diagnosis",
    enableSorting: false
  }),
  adminColumnHelper.accessor("compoundedMedicationReason", {
    header: "Compounded Medication Reason",
    enableSorting: false
  }),
];

  const [fetchList,
    {
    data: prescriptionsData,
    isFetching,
    isError,
    error,
  }] = useLazyGetPatientPrescriptionsHistoryQuery();
  const [prescriptionData, setPrescriptionData] = useState([]);
  useEffect(async ()=>{
    if(prescriptionsData){
    var dataList = [];
    
    // console.log(prescriptionsData)
    await prescriptionsData?.prescriptions.forEach((prescription) => {
      let products = [],
        info = [],
        sig = [],
        dx = [],
        reason = [];
      prescription.products.forEach((p) => products.push(p.name));
      prescription.products.forEach((p) => info.push({ quantity: p.quantity, daySupply: p.daySupply, refills: p.refills }));
      prescription.products.forEach((p) => sig.push(p.sig));
      prescription.products.forEach((p) => dx.push(p.patientDiagnosis));
      prescription.products.forEach((p) => reason.push(p.reasonForCompoundedMedication));
      dataList.push({
        id: prescription.id,
        date: dateFormatterMountainTime(prescription.created),
        createdBy: [prescription.createdByName, prescription.createdById],
        products: products,
        info: info,
        sig: sig,
        patientDiagnosis: dx,
        reasonForCompoundedMedication: reason,
      });
    });
    console.log(dataList)
    setPrescriptionData(dataList)
  }
  },[prescriptionsData]);

  useEffect(()=>{
    fetchList({
      patientId,
      limit: rowsPerPage,
      currentPage: page + 1,
    })
  },[])

  const [apiParam, setApiParam] = useState({ currentPage: 1, limit: ROWS_LIMIT[1], patientId: patientId });
  const rowLimit = useMemo(() => apiParam.limit, [apiParam.limit]);
  const totalRecords = useMemo(() => prescriptionsData?.pagination?.totalCount ?? 0, [prescriptionsData?.pagination?.totalCount]);
  const tablePage = useMemo(() => (prescriptionsData?.pagination?.currentPage ?? 1) - 1, [prescriptionsData?.pagination?.currentPage]);
  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, currentPage: newPage + 1, limit: rowLimit };
        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam]
  );
  const changeRowLimit = useCallback(
    (e) => {
      const size = Number(e.target.value);
      setApiParam((prev) => {
        const body = { ...prev, currentPage: 1, limit: size };
        fetchList(body, true);
        return body;
      });
    },
    [fetchList]
  );

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  //   setApiParam((prev) => {
  //     const newParams = { ...prev, sort: { [sortingObj.id]: sortingObj.desc ? -1 : 1 } };
  //     fetchList(newParams, true);
  //     return newParams;
  //   });
  // };
  

  const applySorting = useCallback(() => {
    const sortingObj = sorting[0];
    if (sortingObj?.id) {
      setApiParam((prev) => {
        const newParams = { ...prev, sort: { [sortingObj.id]: sortingObj.desc ? -1 : 1 } };
        fetchList(newParams, true);
        return newParams;
      });
    }
  }, [fetchList, sorting]);

  useEffect(() => {
    applySorting();
  }, [applySorting]);

  // const prescriptionData = prescriptionsData?.prescriptions || [];
  const totalCount = prescriptionsData?.pagination?.totalCount || 0;

  const reactTable = useReactTable({
    data: prescriptionData || {},
    columns: [...ADMIN_ACTIONS],
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: (row) => row.id,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: 10 ?? -1,
    state: {
      sorting,
    },
  });

  const renderHeaderCell = useCallback((header) => {
    const columnSortDirection = header.column.getIsSorted();

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={true}
      >
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() =>
            header.column.toggleSorting(columnSortDirection !== "desc")
          }
        >
          <Typography variant="tableHeader" noWrap>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  }, []);

  const tableHeader = useMemo(
    () => [
      { text: 'id', dataField: 'id', sort: true, hidden: true },
        {
          text: 'Date',
          dataField: 'date',
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: '80px', textAlign: 'center' };
          },
        },
        {
          text: 'Created By',
          dataField: 'createdBy',
          sort: true,
          formatter: (cellContent, row) => cellContent[0],
        },
        {
          text: 'Product(s)',
          dataField: 'products',
          sort: true,
          formatter: (cellContent) =>
            cellContent?.map((p, i) => (
              <>
                {cellContent[1] ? cellContent[i + 1] ? <b>{i + 1}: </b> : <b>{i + 1}: </b> : null}
                {p}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line', maxHeight: 100, height: 100 };
          },
        },
        {
          text: 'Info',
          dataField: 'info',
          sort: true,
          formatter: (cellContent) =>
            cellContent?.map((p, i) => (
              <>
                {/* <b>{i + 1}: </b> */}
                Quantity:<b>{p.quantity}</b>
                {'\n'}
                DaySupply:<b>{p.daySupply}</b>
                {'\n'}
                Refills:<b>{p.refills}</b>
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
        {
          text: 'Sig',
          dataField: 'sig',
          sort: true,
          formatter: (cellContent) =>
            cellContent?.map((p, i) => (
              <>
                {p}
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
        {
          text: 'Patient Diagnosis',
          dataField: 'patientDiagnosis',
          sort: true,
          formatter: (cellContent) =>
            cellContent?.map((p, i) => (
              <>
                {p}
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
        {
          text: 'Compounded Medication Reason',
          dataField: 'reasonForCompoundedMedication',
          sort: true,
          formatter: (cellContent) =>
            cellContent?.map((p, i) => (
              <>
                {p}
                {'\n'}
                {cellContent[i + 1] ? <hr /> : null}
              </>
            )),
          style: () => {
            return { whiteSpace: 'pre-line' };
          },
        },
    ],
    []
  );

  if (isError) {
    return (
      <div>
        {error?.data?.message ||
          "An error occurred while trying to fetch prescription history."}
      </div>
    );
  }

  

  return (
    <Paper>
      {isFetching ? <Spinner />: null}
      {/* we can use two type of tables BootstrapTable or  TableContainer */}
      <BootstrapTable
        responsive
        keyField={"id"}
        bordered={false}
        striped={false}
        classes={"table align-middle table-nowrap"}
        headerWrapperClasses={"thead-light"}
        columns={tableHeader}
        data={prescriptionData}
      />
      <TablePagination
      component="div"
      showFirstButton
      showLastButton
      page={tablePage}
      count={totalRecords}
      rowsPerPage={rowLimit}
      rowsPerPageOptions={ROWS_LIMIT}
      onPageChange={handleChangePage}
      onRowsPerPageChange={changeRowLimit}
      style={{ alignItems: "center", marginBottom: 0 }}
      />
    </Paper>
  );
};

export default PrescriptionHistory;

import React, { useEffect, useState } from "react";
import useAddProductModal from "./index.hook";
import { FormGroup, Label, Modal, Row, Container, Col, Card, CardBody, CardFooter, Alert } from "reactstrap";
import ProductFamilyDD from "components/dropdowns/productFamilyDD";
import SubcategoryOneDD from "components/dropdowns/subcategoryOneDD";
import Spinner from "components/spinner";
const AddProductModal = (props) => {

  const {clinics} = props

  const {
    success,
    setSuccess,
    showSpinner,
    setShowSpinner,
    showModal,
    isEdit,
    closeModal,
    error,
    subcategory1DefaultVal,
    watch,
    singleProduct,
    sigOptions,
    priceGroups,
    priceDosages,
    addMore,
    removeRow,
    setSingleProduct,
    onSubmit,
    register,
    handleSubmit,
    errors,
    fileError,
    setFileError,
    onFileChangeHandler,
    categoryDefaultVal,
    coldShippedDefaultVal,
    medicalAccessoriesDefaultVal,
    controlledSubstanceDefaultVal,
    genderDefaultVal,
    clinicsDefaultVal
  } = useAddProductModal(props);

  return (
    <Modal isOpen={showModal} size="lg" centered={true} className="largeHeightmodal modal-content ">
      <Container>
        <form id="registerForm" className="form-horizontal product-form mt-2" onSubmit={handleSubmit(onSubmit)}>
          <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          {error ? (
            <Alert color="danger" className="text-center">
              {error.includes("priceGroup") && error.includes("number") ? "Price Group Price must be a Number" : error}
            </Alert>
          ) : null}

          {showSpinner ? <Spinner /> : null}

          <div className="modal-body flexJCAC flex-column text-center">
            <Row className="justify-content-center">
              <h3>{isEdit ? "Edit Product" : "Add Product"}</h3>
              {/* {!isEdit && (
                <div>
                  <button className={singleProduct ? "blueButton" : ""} onClick={() => setSingleProduct(true)}>
                    Single Product
                  </button>
                </div>
              )} */}
            </Row>
            <Card>
              {singleProduct ? (
                <CardBody className="pt-0">
                  <hr />
                  <Row className={"mt-2 mb-2"}>
                    <Col md={12}>
                      <InputFieldCustom
                        errors={errors}
                        name={"name"}
                        label={"Product name"}
                        register={register}
                        required={true}
                      />
                    </Col>
                    {/* <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"productId"}
                        label={"Product ID"}
                        register={register}
                        required={true}
                      />
                    </Col> */}
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                      <Col md={6}>
                        <ProductFamilyDD
                          categoryDefaultVal={categoryDefaultVal}
                          name={"category"}
                          label={"Select Category"}
                          register={register}
                          required={true}
                        />
                      </Col>
                      <Col md={6}>
                      <SelectDropdownGender
                          selectDefaultVal={genderDefaultVal}
                          errors={errors}
                          name={"gender"}
                          label={"Select Gender"}
                          register={register}
                          required={true}
                        />
                        {/* <SubcategoryOneDD
                          subcategory1DefaultVal={subcategory1DefaultVal}
                          name={"subCategory1"}
                          label={"Select Subcategory 1"}
                          register={register}
                          required={false}
                        /> */}
                      </Col>
                    </Row>
                    <PriceDosages priceDosages={priceDosages} register={register} required={true} errors={errors}
                    addMore={addMore}
                    removeRow={removeRow}
                    /> 
                    {/* {priceDosages?.map((p, i) => {
                      return (
                        <>
                        <Row className={"mt-2 mb-2"}>
                          <Col md={4}>
                            <InputFieldCustom
                              errors={errors}
                              name={"unitPrice[" + i + "]"}
                              // name={"unitPrice"}
                              label={"Retail price"}
                              placeholder={"10"}
                              register={register}
                              required={true}
                            />
                          </Col>
                          <Col md={4}>
                            <InputFieldCustom
                              errors={errors}
                              name={"dosage[" + i + "]"}
                              // name={"dosage"}
                              label={"Dosage"}
                              placeholder={"5mg"}
                              register={register}
                              required={true}
                            />
                          </Col>
                          <Col md={4} className="align-self-end">
                            {priceDosages}
                            <button onClick={()=>{addMore()}} className="pinkButton" type="button">Add</button>
                          </Col>
                        </Row>
                          {/* <Col md={3} className="mt-2">
                            <InputFieldCustom
                              errors={errors}
                              name={"priceGroups[" + i + "].price"}
                              label={"Price Group " + priceGroups[i].groupName}
                              register={register}
                              required={true}
                            />
                          </Col> * /}
                        </>
                      );
                    })} */}
                    
                   
                    <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                    <SelectDropdownClinic
                        selectDefaultVal={clinicsDefaultVal}
                        errors={errors}
                        name={"clinic"}
                        label={"Clinic"}
                        register={register}
                        required={true}
                        clinics={clinics}
                      />
                      {/* <SubcategoryOneDD
                        subcategory1DefaultVal={subcategory1DefaultVal}
                        name={"subCategory1"}
                        label={"Select Subcategory 1"}
                        register={register}
                        required={false}
                      /> */}
                    </Col>
                  </Row>
                  {/* <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"subCategory2"}
                        label={"Sub Category 2"}
                        register={register}
                      />
                    </Col>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"commonName"}
                        label={"Common Name"}
                        register={register}
                        required={true}
                      />
                    </Col>
                  </Row> */}
                  {/* <Row className={"mt-2 mb-2"}>
                    <Col md={4}>
                      <SelectDropdownCustom
                        coldShippedDefaultVal={coldShippedDefaultVal}
                        medicalAccessoriesDefaultVal={medicalAccessoriesDefaultVal}
                        errors={errors}
                        name={"medicalAccessories"}
                        label={"Medical Accessories"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={4}>
                      <SelectDropdownCustom
                        coldShippedDefaultVal={coldShippedDefaultVal}
                        medicalAccessoriesDefaultVal={medicalAccessoriesDefaultVal}
                        errors={errors}
                        name={"coldShipped"}
                        label={"Cold Shipped"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={4}>
                      <SelectDropdownCustom
                        controlledSubstanceDefaultVal={controlledSubstanceDefaultVal}
                        errors={errors}
                        name={"controlledSubstance"}
                        label={"Controlled Substance"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row> */}
                  <Row className={"mt-2 mb-2"}>
                    {" "}
                    <h5 className={"mt-4"} style={{ textAlign: "left" }}>
                      Product Detail
                    </h5>
                    <hr></hr>
                    <Col md={12}>
                      <InputFieldCustom
                        errors={errors}
                        name={"description"}
                        label={"Description"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    </Row>
                    <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"customLabel1"}
                        label={"Label"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"customValue1"}
                        label={"Value"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    </Row>
                    <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"customLabel2"}
                        label={"Label"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"customValue2"}
                        label={"Value"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    </Row>
                    <Row className={"mt-2 mb-2"}>
                    <Col md={12}>
                      <InputFieldCustom
                        errors={errors}
                        name={"details"}
                        label={"Details"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>

                  {/* <Row className={"mt-4 mb-2"}>
                    <h5 className={"mt-4"} style={{ textAlign: "left" }}>
                      Price Groups
                    </h5>
                    <hr></hr>
                    {priceGroups?.map((p, i) => {
                      return (
                        <>
                          <Col md={3} className="mt-2">
                            <InputFieldCustom
                              errors={errors}
                              name={"priceGroups[" + i + "].price"}
                              label={"Price Group " + priceGroups[i].groupName}
                              register={register}
                              required={true}
                            />
                          </Col>
                        </>
                      );
                    })}
                  </Row> */}
                </CardBody>
              ) : (
                <CardBody>
                  <Row className={"mt-4 mb-4"}>
                    <Col md={12}>
                      <FormGroup>
                        <Label htmlFor={"file"} className="form-label">
                          {"Upload CSV file"}
                          <span className="requiredStar">*</span>
                        </Label>
                        <input
                          name={"file"}
                          className={`form-control`}
                          type={"file"}
                          ref={register({ required: true })}
                          accept={".csv"}
                          onChange={(e) => onFileChangeHandler(e.target.files[0])}
                        />
                        {fileError && <span className={"error-product-required"}>{`Only .csv file are allowed.`}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              )}
              <CardFooter>
                <div className={"product-card-footer"}>
                  <button onClick={() => closeModal()}>Cancel</button>
                  <button type={"submit"} className={"pinkButton"} disabled={fileError}>
                    {isEdit ? "Save" : "Add"}
                  </button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </form>
      </Container>
    </Modal>
  );
};

export default AddProductModal;

const PriceDosages = (props) =>{
  const { name = "", label = "", placeholder = "", register, required = false, type = "text", errors = "", priceDosages = 1, addMore, removeRow } = props;
  const fields = [];
  for (let i = 0; i < priceDosages; i++) {
    fields.push(
      <Row key={i} className={"mt-2 mb-2"}>
  <Col md={4}>
     <InputFieldCustom
      errors={errors}
      name={"unitPrice[" + i + "]"}
      // name={"unitPrice"}
      label={"Retail price"}
      placeholder={"10"}
      register={register}
      required={true}
    />
  </Col>
  <Col md={4}>
    <InputFieldCustom
      errors={errors}
      name={"dosage[" + i + "]"}
      // name={"dosage"}
      label={"Dosage"}
      placeholder={"5mg"}
      register={register}
      required={true}
    />
  </Col>
  <Col md={4} className="align-self-end">
    {i == (priceDosages-1) ?
    <button onClick={()=>{addMore()}} className="pinkButton" type="button">Add</button>:
    <button onClick={()=>{removeRow(i)}} className="redText" type="button">X</button>}
  </Col>
</Row>
    )
    
  }
  return (fields);
}

const InputFieldCustom = (props) => {
  const { name = "", label = "", placeholder = "", register, required = false, type = "text", errors = "" } = props;
  let isRequiredError = false;
  
  if (name.includes("unitPrice")) {
    var indexVal = name.match(/[^[\]]+(?=])/g);
    var indexDigit = parseInt(indexVal[0]);
    console.log(indexVal,indexDigit)
    if (!!errors && !!errors["unitPrice"]) {
      isRequiredError = !!errors && !!errors.unitPrice && !!errors.unitPrice[indexDigit] && required;
    }
  }else if (name.includes("dosage")) {
    var indexVal = name.match(/[^[\]]+(?=])/g);
    var indexDigit = parseInt(indexVal[0]);
    console.log(indexVal,indexDigit)
    if (!!errors && !!errors["dosage"]) {
      isRequiredError = !!errors && !!errors.dosage && !!errors.dosage[indexDigit] && required;
    }
  }else if (name.includes("priceGroup")) {
    var indexVal = name.match(/[^[\]]+(?=])/g);
    var indexDigit = parseInt(indexVal[0]);
    if (!!errors && !!errors["priceGroups"]) {
      isRequiredError = !!errors && !!errors.priceGroups && !!errors.priceGroups[indexDigit] && required;
    }
  } else {
    // console.log(errors)
    isRequiredError = !!errors && !!errors[`${name}`] && !!errors[`${name}`].type && required;
  }

  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <input name={name} placeholder={placeholder || ''} className={`form-control`} type={type} ref={register({ required })} />
      {isRequiredError && <span className={"error-product-required"}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};

const SelectDropdownCustom = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    medicalAccessoriesDefaultVal = "",
    coldShippedDefaultVal = "",
    controlledSubstanceDefaultVal = "",
    required = false,
    type = "text",
    errors = null,
  } = props;

  const isRequiredError =
    !!errors && !!errors[name] && !!errors[name].type && errors[name].type === "required" && required;
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <select
        name={name}
        value={
          name === "medicalAccessories"
            ? medicalAccessoriesDefaultVal
            : name === "controlledSubstance"
            ? controlledSubstanceDefaultVal
            : coldShippedDefaultVal
        }
        ref={register({ required })}
        style={{ borderRadius: "5px", paddingBottom: "0px", width: "100%", height: "40px", borderColor: "#CDCDCD" }}
      >
        <option value="" disabled selected>
          {"Select"}
        </option>
        <option value="1">{"Yes"}</option>
        <option value="0">{"No"}</option>
      </select>
      {isRequiredError && <span className={"error-product-required"}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};


const SelectDropdownGender = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    selectDefaultVal = "",
    required = false,
    type = "text",
    errors = null,
  } = props;

  const isRequiredError =
    !!errors && !!errors[name] && !!errors[name].type && errors[name].type === "required" && required;
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <select
        name={name}
        value={selectDefaultVal}
        ref={register({ required })}
        style={{ borderRadius: "5px", paddingBottom: "0px", width: "100%", height: "40px", borderColor: "#CDCDCD" }}
      >
        <option value="" disabled selected>
          {"Select"}
        </option>
        <option value="1">{"Male"}</option>
        <option value="2">{"Female"}</option>
      </select>
      {isRequiredError && <span className={"error-product-required"}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};

const SelectDropdownClinic = (props) => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    selectDefaultVal = "",
    required = false,
    clinics = [],
    errors = null,
  } = props;
  const isRequiredError =
    !!errors && !!errors[name] && !!errors[name].type && errors[name].type === "required" && required;
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      
      <select
      multiple
        name={name}
        value={selectDefaultVal}
        ref={register({ required })}
        style={{ borderRadius: "5px", paddingBottom: "0px", width: "100%", height: "100px", borderColor: "#CDCDCD" }}
      >
        <option value="" disabled selected>
          {"Select"}
        </option>
        {clinics?.map((v,i)=>{
          return <option key={i} value={v.id}>{v.businessName}</option> 
        })}
        
      </select>
      {isRequiredError && <span className={"error-product-required"}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};
import { set } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import { useAdminUpdateRefillsNumberMutation } from "rtk";

const RenderRefillsInput = ({
  refills,
  productId,
  presId,
  bulkPresId = "",
  pageName = "",
  patientId,
  setCustomError,
  setCustomSuccess
}) => {
  const [refillsNumberInput, setRefillsNumberInput] = useState(() => refills ?? "");
  const [showSave, setShowSave] = useState(false);

  const [
    updateRefillsNumber,
    {
      error: refillsNumberError,
      isSuccess: refillsNumberSuccess,
      isLoading: refillsNumberLoading,
    },
  ] = useAdminUpdateRefillsNumberMutation();

  const errorMessage = useMemo(
    () => refillsNumberError?.data?.message || "",
    [refillsNumberError?.data?.message]
  );

  useEffect(() => {
    if (refillsNumberSuccess) {
      setCustomSuccess("Refills number updated successfully");
      setCustomError("");
      setShowSave(false);
    } else if (errorMessage) {
      setCustomError(errorMessage);
      setCustomSuccess("");
    }
  }, [errorMessage, refillsNumberSuccess, setCustomError, setCustomSuccess]);

  const checkInput = useCallback(
    () => setShowSave(refillsNumberInput !== refills),
    [refills, refillsNumberInput]
  );

  const updateAction = useCallback(() => {
    if (refillsNumberInput !== refills) {
      updateRefillsNumber({
        _id: productId,
        bulkPresId,
        prescriptionId: presId,
        patientId: patientId,
        message: refillsNumberInput, // rxNumber changed message
        pageName: pageName,
      });
    }
  }, [
    bulkPresId,
    pageName,
    patientId,
    presId,
    productId,
    refills,
    refillsNumberInput,
    updateRefillsNumber,
  ]);

  const renderSaveBtn = useMemo(() => {
    if (showSave) {
      return (
        <button
          disabled={refillsNumberLoading}
          className="mx-1 "
          style={{ background: "#2C4B76", color: "white", cursor: "pointer" }}
          onClick={updateAction}
        >
          {refillsNumberLoading ? (
            setCustomError?.(''),
            setCustomSuccess?.(''),
            <Spinner size="sm" style={{ width: "0.82rem", height: "0.82rem" }} />
          ) : (
            <i className="fa fa-check" />
          )}
        </button>
      );
    } else {
      return <span style={{ width: "2.1rem" }} />;
    }
  }, [refillsNumberLoading, showSave, updateAction]);

  return (
    <>
      <input
        type="text"
        placeholder="Enter Refills Number"
        value={refillsNumberInput}
        style={{ borderColor: errorMessage ? "red" : "" }}
        // onBlur={checkInput}
        onChange={(e) => {
          setRefillsNumberInput(e.target.value);
          if (!showSave) {
            setShowSave(true);
          }
        }}
      />
      {renderSaveBtn}
    </>
  );
};
export default React.memo(RenderRefillsInput);

const { ADMIN_END_POINTS } = require("./ApiConstant");

const RX_STATUS_COMPLETED = ["RECEIVED", "CANCELLED"];

const ACTION_TO_ENDPOINT = {
  Delete: ADMIN_END_POINTS.ADMIN_PRESCRIPTION_CANCEL,
  Approve: ADMIN_END_POINTS.APPROVE_PRESCRIPTION,
  Disapprove: ADMIN_END_POINTS.DISAPPROVE_PRESCRIPTION,
};

const PAGE_TO_ENDPOINT = {
  AdminPendingPrescription: "getAdminPendingPrescription",
  AdminRxWorkflowPrescription: "adminRxWorkflowPrescription",
  AdminArchivedPrescription: "adminArchivedPrescription",
  GetApprovedClinicList: "getApprovedClinicList",
  GetPendingClinicList: "getPendingClinicList",
};

export { RX_STATUS_COMPLETED, PAGE_TO_ENDPOINT, ACTION_TO_ENDPOINT };

import { AuthApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, END_POINTS } from "../../rtkConstant";
import { login, logout } from "../../../utils";

const AuthMutation = AuthApi.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const response = await baseQuery({
            url: END_POINTS.LOGIN,
            method: API_METHODS.POST,
            body: apiArgs,
          });
          if (response.data) {
            // Necessary for previous Cookie flow
            if (response.data.isResetPassword) {
              // Redirect to a new route
              window.location.href = "/reset-password/" + response.data?.jwtToken;
            } else {
              login(
                response.data?.id,
                response.data?.email,
                response.data?.firstName,
                response.data?.lastName,
                response.data?.isVerified,
                response.data?.role,
                response.data?.jwtToken,
                response.data?.clinicId,
                response.data?.clinicLocationId,
                response.data?.refreshToken
              );
            }
          }
          return response;
        } catch {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),
    userLogout: builder.mutation({
      queryFn(_, __, ___, ____) {
        return {
          data: {
            jwtToken: undefined,
            userData: undefined,
          },
        };
      },
      async onQueryStarted(____, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            // Necessary for previous Cookie flow
            dispatch(AuthApi.util.invalidateTags([CACHE_TAGS.USER]));
            logout();
          }
        } catch (_) {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),

    resetPassword: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          let queryData = {
            url: apiArgs.isAutoGen ? END_POINTS.RESET_AUTO_GEN : END_POINTS.RESET_PASSWORD,
            method: API_METHODS.POST,
            body: apiArgs,
          };

          if (apiArgs?.isAutoGen) {
            queryData = {
              ...queryData,
              headers: {
                Authorization: `Bearer ${apiArgs.token}`,
              },
            };
          }

          const response = await baseQuery(queryData);

          return response;
        } catch {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),
    forgotPassword: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          let queryData = {
            url: END_POINTS.FORGOT_PASSWORD,
            method: API_METHODS.POST,
            body: apiArgs,
          };
          
          const response = await baseQuery(queryData);
          
          return response;
        } catch {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),
  }),
});

export const { useUserLoginMutation, useUserLogoutMutation, useResetPasswordMutation, useForgotPasswordMutation } = AuthMutation;

import { set } from "lodash";
import { USER_ROLES } from "../../../utils";
import { ClinicApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, END_POINTS } from "../../rtkConstant";

const ClinicPrescriptionQuery = ClinicApi.injectEndpoints({
  endpoints: (builder) => ({
    getClinicPrescriptionHistory: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const isSuperuser = user?.userData?.role === USER_ROLES.CLINIC_SUPER_USER;
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10 };
            let url;
            if (isSuperuser) {
              set(organizedBody, "clinicId", user?.userData?.clinicId);
              url = END_POINTS.CLINIC_PRESCRIPTION_HISTORY;
            } else {
              set(organizedBody, "clinicLocationId", user?.userData?.clinicLocationId);
              url = END_POINTS.CLINIC_LOCATION_PRESCRIPTION_HISTORY;
            }
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getClinicArchivedHistory: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { clinicId: user?.userData?.clinicId, ...apiArgs, limit: apiArgs?.limit || 10 };
            const response = await baseQuery({
              url: END_POINTS.CLINIC_ARCHIVED_HISTORY,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getClinicSubmittedPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const isSuperuser = user?.userData?.role === USER_ROLES.CLINIC_SUPER_USER;
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10 };
            let url;
            if (isSuperuser) {
              set(organizedBody, "clinicId", user?.userData?.clinicId);
              url = END_POINTS.CLINIC_SUBMITTED_PRESCRIPTION;
            } else {
              set(organizedBody, "clinicLocationId", user?.userData?.clinicLocationId);
              url = END_POINTS.CLINIC_LOCATION_SUBMITTED_PRESCRIPTION;
            }
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getClinicIncompletePrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const isSuperuser = user?.userData?.role === USER_ROLES.CLINIC_SUPER_USER;
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10 };
            let url;
            if (isSuperuser) {
              set(organizedBody, "clinicId", user?.userData?.clinicId);
              url = END_POINTS.CLINIC_INCOMPLETE_PRESCRIPTION;
            } else {
              set(organizedBody, "clinicLocationId", user?.userData?.clinicLocationId);
              url = END_POINTS.CLINIC_LOCATION_INCOMPLETE_PRESCRIPTION;
            }
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getClinicReviewPrescription: builder.query({
      queryFn: async (
        { isReview = false, prescriptionId = undefined, prescriptionStatus = "" },
        queryApi,
        _,
        baseQuery
      ) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const isClinic = Boolean(user?.userData?.clinicId);
            const reorder = window?.location?.pathname.includes("reorder");
            const request = {
              url: `${END_POINTS.PRESCRIPTION_ID_DETAIL}/${prescriptionId}`,
              method: API_METHODS.GET,
            };
            if (isClinic) {
              if (isReview || reorder) {
                set(request, "method", API_METHODS.GET);
                set(request, "url", `${END_POINTS.CLINIC_LAST_PRESCRIPTIONS_DETAIL}/${prescriptionId}`);
              } else {
                set(request, "method", API_METHODS.POST);
                set(request, "url", END_POINTS.CLINIC_INCOMPLETE_PRESCRIPTION_DETAIL);
                set(request, "body", { clinicId: user?.userData?.clinicId, prescriptionId });
              }
            }
            const response = await baseQuery(request);
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getRecentProducts: builder.query({
      query: () => END_POINTS.CLINIC_RECENT_PRODUCTS,
      transformResponse: (response) => response?.products?.slice(0, 4),
    }),
    getFavoriteProducts: builder.query({
      query: () => ({
        url: END_POINTS.CLINIC_FAV_PRODUCTS,
        method: API_METHODS.POST,
        body: {
          limit: 10,
          currentPage: 1,
        },
      }),
      transformResponse: (response) => response?.products?.slice(0, 4),
    }),
  }),
});

export const {
  useGetRecentProductsQuery,
  useGetFavoriteProductsQuery,
  useGetClinicReviewPrescriptionQuery,
  useLazyGetClinicReviewPrescriptionQuery,
  useLazyGetClinicArchivedHistoryQuery,
  useLazyGetClinicPrescriptionHistoryQuery,
  useLazyGetClinicSubmittedPrescriptionQuery,
  useLazyGetClinicIncompletePrescriptionQuery,
  endpoints: { getClinicPrescriptionHistory },
} = ClinicPrescriptionQuery;

import { dateFormatterMountainTime } from "utils/constants";
import { AdminApi } from "../../rtkApi";
import {
  ADMIN_END_POINTS,
  API_METHODS,
  CACHE_TAGS,
  EXPORT_END_POINT,
  downloadFile,
  providesList,
} from "../../rtkConstant";

const AdminPrescriptionQuery = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminPendingPrescription: builder.query({
      keepUnusedDataFor: 0.1,
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          console.log('00000000');
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_PENDING_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result) => providesList(result.prescriptions),
      // async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     console.log(getState())
      //     let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
      //       { type: CACHE_TAGS.PRESCRIPTION },
      //     ]);
      //   } catch {}
      // },
      // invalidatesTags: [CACHE_TAGS.PRESCRIPTION],
    }),
    getAdminCompletedPrescription: builder.query({
      keepUnusedDataFor: 0.1,
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_COMPLETED_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result) => providesList(result.prescriptions),
    }),
    adminRxWorkflowPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_HISTORY_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              status: prescription.prescriptionStatus === "Disapproved" ? "No" : "Yes",
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result, error, arg) => [...providesList(result.prescriptions), CACHE_TAGS.RX_WORKFLOW],
    }),
    adminArchivedPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_ARCHIVED_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              status: prescription.prescriptionStatus === "Disapproved" ? "No" : "Yes",
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result, error, arg) => [...providesList(result.prescriptions), CACHE_TAGS.ARCHIVED],
    }),
  }),
});
export const {
  useLazyAdminArchivedPrescriptionQuery,
  useLazyGetAdminPendingPrescriptionQuery,
  useLazyGetAdminCompletedPrescriptionQuery,
  useLazyAdminRxWorkflowPrescriptionQuery,
} = AdminPrescriptionQuery;

import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import PaginationComponent from "react-reactstrap-pagination";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import Cookies from "universal-cookie";
import { dateFormatterMountainTime } from "../../../../src/utils/constants";
import Spinner from "../../../components/spinner";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
class PatientBilling extends Component {
  constructor(props) {
    super(props);

    this.startDate = React.createRef();
    this.endDate = React.createRef();

    this.state = {
      patientId: this.props.patientKey,
      page: 1,
      sizePerPage: 10,
      keyword: "",
      fromDate: "",
      toDate: "",
      showSpinner: true,
      error: "",
      disableFilter: true,
      data: [],
      filteredData: [],
      tableHeaders: [
        { text: "id", dataField: "id", sort: true, hidden: true },
        {
          text: "Date",
          dataField: "date",
          sort: true,
          headerStyle: (column, colIndex) => {
            return { width: "80px", textAlign: "center" };
          },
        },

        {
          text: "Invoice Number",
          dataField: "invoiceNumber",
          sort: true,
          formatter: (cellContent, row) => (
            <Link
              to={{
                pathname: `/invoice/${row.id}`,
                state: {
                  invoiceNumber: row.invoiceNumber,
                },
              }}
              // target='_blank'
              className="medText"
            >
              {cellContent}
            </Link>
          ),
        },
        { text: "Total Discount", dataField: "totalDiscount", sort: true },
        { text: "Total Amount", dataField: "amount", sort: true },
      ],
    };
  }
  getPrescriptionHistory(size = 10, pageNo = 1) {
    this.setState({
      page: pageNo,
      sizePerPage: size,
      showSpinner: true,
    });

    var body = {
      patientId: this.state.patientId,
      limit: size,
      currentPage: pageNo,
    };

    axiosConfig
      .post("/patients/getPatientBillingByPatientId", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        //console.log(response.data.prescriptions);
        var dataList = [];
        await response.data.prescriptions.forEach((prescription) => {
          dataList.push({
            id: prescription.id,
            date: dateFormatterMountainTime(prescription.prescriptionDate),
            invoiceNumber: prescription.invoiceNumber,
            totalDiscount: prescription.totalDiscount,
            amount: prescription.totalAmount,
          });
        });

        await this.setState({
          pagination: response.data.pagination,
          filteredData: dataList,
          showSpinner: false,
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch prescription history!",
        });

        errorResponse(error);
      });
  }

  handlePageChange = (pageNo) => {
    const { sizePerPage = 10, keyword = "", fromDate, toDate } = this.state;
    this.setState({ page: pageNo });

    this.getPrescriptionHistory(sizePerPage, pageNo);
    //console.log(pageNo);
  };

  componentDidMount() {
    this.setState({ filteredData: this.state.data });
    this.getPrescriptionHistory(this.state.sizePerPage, this.state.page);
  }

  render() {
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.filteredData.length,
      custom: true,
    };
    const defaultSorted = [{ dataField: "date", order: "desc" }];

    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                {this.state.showSpinner ? <Spinner /> : null}
                {this.state.error ? (
                  <Alert color="danger" className="text-center">
                    {this.state.error}
                  </Alert>
                ) : null}
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={this.state.tableHeaders}
                  data={this.state.filteredData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={this.state.tableHeaders}
                      data={this.state.filteredData}
                      search
                      exportCSV
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30 px-3">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="text-md-right ms-auto">
                                <Row className="align-items-md-center mt-3 px-3">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="text-md-right ms-auto">
                                      <PaginationComponent
                                        size="sm"
                                        totalItems={this.state.sizePerPage * this.state.pagination?.totalPages || 0}
                                        pageSize={this.state.sizePerPage}
                                        onSelect={(e) => this.handlePageChange(e)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default PatientBilling;

import { Link } from "react-router-dom";

const DEFAULT_SORTED = [{ dataField: "id", order: "asc" }];
const TABLE_HEADER = [
  { text: "id", dataField: "id", hidden: true },
  { text: "Patient name", dataField: "patientName" },
  { text: "Clinic", dataField: "clinicName" },
  // {
  //   text: "Clinic Location",
  //   dataField: "clinicLocationName",
  // },
  {
    text: "Patient info",
    dataField: "info",

    formatter: (cellContent) => (
      <div>
        <div className="d-flex">
          <p className="smallText semiBold m-0">
            DOB: <span className="text-muted normalText">{cellContent[0]}</span>
          </p>
          <p className="smallText semiBold m-0 px-3">
            Gender: <span className="text-muted normalText">{cellContent[1]}</span>
          </p>
        </div>
        <p className="smallText semiBold m-0">
          Email: <span className="text-muted normalText">{cellContent[2]}</span>
        </p>
        <p className="smallText semiBold m-0">
          Phone: <span className="text-muted normalText">{cellContent[3]}</span>
        </p>
      </div>
    ),
  },
];

const CLINIC_PRESCRIPTION_ACTIONS = {
  text: "Actions",
  dataField: "actions",
  headerStyle: () => {
    return { width: "80px", textAlign: "center" };
  },
  formatter: (cellContent, row) => (
    <Link to={"/patient-profile/" + row.id} className="pinkButton">
      Profile
    </Link>
  ),
};

export { DEFAULT_SORTED, TABLE_HEADER, CLINIC_PRESCRIPTION_ACTIONS };

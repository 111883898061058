import { sortBy } from "lodash";
import { StaffApi } from "../../rtkApi";
import { API_METHODS, STAFF_END_POINT } from "../../rtkConstant";

const StaffQuery = StaffApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaffList: builder.query({
      query: () => STAFF_END_POINT.GET_ALL_STAFF_IN_CLINIC,
      transformResponse: (response) => sortBy(response, ["family"]),
    }),
    getStaff: builder.query({
      query: (apiArgs) => ({
        url: STAFF_END_POINT.GET_STAFF + "/" + apiArgs.params,
        method: API_METHODS.GET,
      }),
    }),
  }),
});
export const { useLazyGetStaffListQuery, useLazyGetStaffQuery } = StaffQuery;

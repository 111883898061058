import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  InvoiceDetailModal,
  ListingSearch,
  MuiTableCollapse,
  OrderViewModal,
} from "components";
import { keys, size } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import {
  useLazyAdminRxWorkflowPrescriptionQuery,
} from "rtk";
import { ADMIN_RX_WORKFLOW_COLUMNS, ROWS_LIMIT, columnHelper } from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import PrescriptionNotes from "../AdminPendingPrescription/PrescriptionNotes";
import { useAdminPrescriptionListing } from "../Hook";
import AlertMessage from "../components/alert_message";
import PrescriptionCheckBox from "./PrescriptionCheckBox";
import PrescriptionOrderInput from "./PrescriptionOrderInput";
import UpdateShippingModal from "./update_shipping_modal";

function RXWorkflowPrescriptionsSection() {
  const {
    rowLimit,
    invoiceId,
    tablePage,
    showLoading,
    totalRecords,
    filterRxStatus,
    prescriptionId,
    searchKeyword,
    disableFilter,
    errorMessage,
    prescriptions,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setCustomError,
    handleChangePage,
    toggleDetailModal,
    toggleInvoiceModal,
    updateFilterRxStatus,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyAdminRxWorkflowPrescriptionQuery,
  });

  const [selectedShippingCost, setSelectedShippingCost] = useState("");
  const [selectedShippingInfo, setSelectedShippingInfo] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [openShippingModal, setOpenShippingModal] = useState(false);

  const disableOrderBtn = useMemo(
    () => !Boolean(size(rowSelection)),
    [rowSelection]
  );
  const showSpinner = useMemo(
    () => showLoading,
    [showLoading]
  );
  const combinedErrorMessage = useMemo(
    () => errorMessage || "",
    [errorMessage]
  );

  const handleSelectPrescription = useCallback((shippingInfo, shippingCost) => {
    if (shippingInfo && (shippingCost?.toString() === "0" || shippingCost)) {
      setSelectedShippingInfo(shippingInfo);
      setSelectedShippingCost(shippingCost);
    }
  }, []);

  const completeOrderBtnView = useMemo(
    () => (
      <>
        <Col>
          <button
            style={{ marginLeft: "30px", marginTop: "-30px" }}
            disabled={disableOrderBtn}
            className="yellowButton mx-5"
            onClick={() => {
              if (!disableOrderBtn) {
                setOpenShippingModal(true);
              }
            }}
          >
            Complete Order(s)
          </button>
        </Col>
      </>
    ),
    [disableOrderBtn]
  );

  const ADMIN_START_COLUMN = useMemo(
    () => [
      columnHelper.accessor("checkbox1", {
        header: "",
        headerStyle: { minWidth: 70, paddingLeft: 0, paddingRight: 0 },
        cell: ({ row }) => (
          <PrescriptionCheckBox
            rowData={row.original}
            tableRow={row}
            prescriptionAction={handleSelectPrescription}
          />
        ),
      }),
      columnHelper.accessor("date", {
        header: "Date",
        headerStyle: { minWidth: "105px", width: "105px", textAlign: "center" },
      }),
      columnHelper.accessor("rxOrder", {
        header: "Rx order",
        headerStyle: {
          minWidth: "50px",
          textAlign: "center",
          paddingLeft: 0,
          paddingRight: 0,
        },
        cell: (props) => (
          <Tooltip title="View">
            <IconButton
              onClick={() => toggleDetailModal(props.row.original.id)}
              style={{ width: 30, height: 30 }}
            >
              <VisibilityIcon
                style={{ fontSize: "20px" }}
                className="medText"
              />
            </IconButton>
          </Tooltip>
        ),
      }),
      columnHelper.accessor("rxOrderInput", {
        header: "RX Order",
        headerStyle: { minWidth: "50px", paddingRight: 0 },
        cell: (props) => (
          <PrescriptionOrderInput
            id={props.row.original.id}
            rxOrderInput={props.row.original.rxOrderInput}
            setCustomError={setCustomError}
          />
        ),
      }),
      columnHelper.accessor("invoiceNumber", {
        header: "Invoice ",
        headerStyle: { minWidth: "100px", width: "150px", paddingRight: 0 },
        cell: (props) => (
          <ButtonBase
            onClick={() => toggleInvoiceModal(props.row.original.id)}
            style={{ textAlign: "left" }}
            className="medText"
          >
            {props.getValue()}
          </ButtonBase>
        ),
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        headerStyle: { width: 150, paddingRight: "8px", paddingLeft: "8px" },
        cell: (props) => (
          <PrescriptionNotes
            id={props.row.original.id}
            notesMessage={props.row.original.message}
            setCustomError={setCustomError}
          />
        ),
      }),
    ],
    [
      handleSelectPrescription,
      setCustomError,
      toggleDetailModal,
      toggleInvoiceModal,
    ]
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [...ADMIN_START_COLUMN, ...ADMIN_RX_WORKFLOW_COLUMNS],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: (row) =>
      row.original.prescriptionStatus !== "Ready To Pack",
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  return (
    <>
      {showSpinner && <Spinner />}
      <Row className="my-3 px-3">
        <h5>Filter Prescriptions</h5>
        <Col xs={6} sm={5} lg={2}>
          <div className="mb-3 row">
            <span>
              <span>
                <RXStatusDD
                  loc={"workflow"}
                  filterRX={true}
                  clearStatus={disableFilter}
                  updatedFilterRxStatusValue={updateFilterRxStatus}
                />
              </span>
            </span>
          </div>
        </Col>
        <Col sm={12} md={3} lg={3} className="m-0">
          <button
            disabled={disableFilter}
            className="yellowButton"
            onClick={applyFilter}
          >
            Filter
          </button>
          {!disableFilter && (
            <i
              disabled={disableFilter}
              className="fas fa-times redText"
              style={{ fontSize: 24, marginLeft: 18 }}
              onClick={clearFilter}
            />
          )}
        </Col>
        <Col className="text-end">
          
        </Col>
      </Row>
      <ListingSearch
        searchKeyword={searchKeyword}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
        leftChildren={completeOrderBtnView}
      />
      <AlertMessage msg={combinedErrorMessage} isError={true} />
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableCell />
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={
                      header.column.columnDef.headerStyle ?? { minWidth: 150 }
                    }
                  >
                    <Typography variant="tableHeader" noWrap>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map((row) => (
              <MuiTableCollapse
                key={row.id}
                row={row}
                pageName={"AdminRxWorkflowPrescription"}
                filterRxStatus={filterRxStatus}
                setCustomError={setCustomError}
                showMarkAllBtn={!row.getCanSelect()}
                isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />

      {(selectedShippingCost?.toString() === "0" || selectedShippingCost) &&
        selectedShippingInfo && (
          <UpdateShippingModal
            setSelectedShippingInfo={setSelectedShippingInfo}
            setSelectedShippingCost={setSelectedShippingCost}
            setSelectedPrescriptions={setRowSelection}
            selectedShippingCost={selectedShippingCost}
            selectedShippingInfo={selectedShippingInfo}
            openShippingModal={openShippingModal}
            setOpenShippingModal={setOpenShippingModal}
            selectedPrescriptions={keys(rowSelection)}
            selectPrescriptionsData={prescriptions?.filter((prescription) =>
              keys(rowSelection).includes(prescription.id)
            )}
            sameAddress={prescriptions
              ?.filter((pres) => keys(rowSelection)?.includes(pres.id))
              ?.every(
                (prescription, index, array) =>
                  JSON.stringify(
                    prescription?.shippingInfo?.shippingAddress
                  ) ===
                    JSON.stringify(array[0]?.shippingInfo?.shippingAddress) &&
                  prescription?.shippingInfo &&
                  prescription?.shippingInfo?.shippingAddress &&
                  prescription?.shippingInfo?.shippingAddress?.addressLine1 &&
                  prescription?.shippingInfo?.shippingAddress?.city &&
                  prescription?.shippingInfo?.shippingAddress?.zipcode &&
                  prescription?.shippingInfo?.shippingAddress?.state &&
                  prescription?.shippingInfo?.shippingMethod !== "PickUp" &&
                  keys(rowSelection).length > 0
              )}
          />
        )}
      <InvoiceDetailModal
        invoiceId={invoiceId}
        handleClose={toggleInvoiceModal}
      />
      <OrderViewModal
        prescriptionId={prescriptionId}
        handleClose={toggleDetailModal}
      />
    </>
  );
}

export default RXWorkflowPrescriptionsSection;

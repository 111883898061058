import { PatientApi } from "../../rtkApi";
import { API_METHODS, PATIENT_END_POINT } from "../../rtkConstant";

const PAGE_TO_ENDPOINT = {
  AdminPatient: "getAllPatientsList",
  ClinicPatient: "getClinicPatients",
};

const PatientMutation = PatientApi.injectEndpoints({
  endpoints: (builder) => ({
    deletePatient: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: PATIENT_END_POINT.DELETE_PATIENT,
              method: API_METHODS.DELETE,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", patientId = "" }, { queryFulfilled, dispatch }) {
        try {
          if (pageName) {
            await queryFulfilled;
            dispatch(
              PatientApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], undefined, (listData) => {
                return {
                  ...listData,
                  patients: listData.patients?.filter((record) => record?.id !== patientId),
                };
              })
            );
          }
        } catch {}
      },
    }),
    uploadPatientFile: builder.mutation({
      query: (fileData) => ({
        url: PATIENT_END_POINT.CLINIC_PATIENT_UPLOAD,
        method: "POST",
        credentials: "include",
        body: fileData,
        Headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
  }),
});

export const { useUploadPatientFileMutation, useDeletePatientMutation } = PatientMutation;

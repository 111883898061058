import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, CACHE_TAGS } from "../rtkConstant";

const AuthApi = createApi({
  reducerPath: "AuthApi",
  tagTypes: [CACHE_TAGS.USER],
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
});

export default AuthApi;

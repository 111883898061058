import { createDraftSafeSelector } from "@reduxjs/toolkit";

const IsLoggedSelector = createDraftSafeSelector(
  (state) => state.AuthSlice,
  ({ jwtToken }) => {
    return !!jwtToken;
  }
);

const userRoleSelector = createDraftSafeSelector(
  (state) => state.AuthSlice,
  IsLoggedSelector,
  ({ userData }, isLogin) => {
    if (isLogin) {
      return userData?.role ?? "Patient";
    } else {
      return "";
    }
  }
);

const hasTokensSelector = createDraftSafeSelector(
  (state) => {
    return state.AuthSlice?.jwtToken;
  },
  (token) => !!token
);

const jwtTokensSelector = createDraftSafeSelector(
  (state) => {
    return state.AuthSlice?.jwtToken;
  },
  (token) => token
);

const userInfoSelector = createDraftSafeSelector(
  (state) => state.AuthSlice,
  IsLoggedSelector,
  ({ userData }, isLogin) => {
    if (isLogin) {
      return userData;
    } else {
      return undefined;
    }
  }
);

const isClinicSelector = createDraftSafeSelector(
  (state) => state.AuthSlice,
  userInfoSelector,
  (userInfo) => {
    if (userInfo?.clinicId) {
      return true;
    } else {
      return false;
    }
  }
);

export { IsLoggedSelector, userRoleSelector, hasTokensSelector, userInfoSelector, isClinicSelector, jwtTokensSelector };

import { set } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import { useAdminUpdateRxNumberMutation } from "rtk";

const RenderRxInput = ({
  rxNumber,
  productId,
  presId,
  bulkPresId = "",
  pageName = "",
  patientId,
  setCustomError,
  setCustomSuccess
}) => {
  const [rxNumberInput, setRXNumberInput] = useState(() => rxNumber ?? "");
  const [showSave, setShowSave] = useState(false);

  const [
    updateRxNumber,
    {
      error: rxNumberError,
      isSuccess: rxNumberSuccess,
      isLoading: rxNumberLoading,
    },
  ] = useAdminUpdateRxNumberMutation();

  const errorMessage = useMemo(
    () => rxNumberError?.data?.message || "",
    [rxNumberError?.data?.message]
  );

  useEffect(() => {
    if (rxNumberSuccess) {
      setCustomSuccess("RX number updated successfully");
      setCustomError("");
      setShowSave(false);
    } else if (errorMessage) {
      setCustomError(errorMessage);
      setCustomSuccess("");
    }
  }, [errorMessage, rxNumberSuccess, setCustomError]);

  const checkInput = useCallback(
    () => setShowSave(rxNumberInput !== rxNumber),
    [rxNumber, rxNumberInput]
  );

  const updateAction = useCallback(() => {
    if (rxNumberInput !== rxNumber) {
      updateRxNumber({
        _id: productId,
        bulkPresId,
        prescriptionId: presId,
        patientId: patientId,
        message: rxNumberInput, // rxNumber changed message
        pageName: pageName,
      });
    }
  }, [
    bulkPresId,
    pageName,
    patientId,
    presId,
    productId,
    rxNumber,
    rxNumberInput,
    updateRxNumber,
  ]);

  const renderSaveBtn = useMemo(() => {
    if (showSave) {
      return (
        <button
          disabled={rxNumberLoading}
          className="mx-1 "
          style={{ background: "#2C4B76", color: "white", cursor: "pointer" }}
          onClick={updateAction}
        >
          {rxNumberLoading ? (
            setCustomError?.(''),
            setCustomSuccess?.(''),
            <Spinner size="sm" style={{ width: "0.82rem", height: "0.82rem" }} />
          ) : (
            <i className="fa fa-check" />
          )}
        </button>
      );
    } else {
      return <span style={{ width: "2.1rem" }} />;
    }
  }, [rxNumberLoading, showSave, updateAction]);

  return (
    <>
      <input
        type="text"
        placeholder="Enter RX Number"
        value={rxNumberInput}
        style={{ borderColor: errorMessage ? "red" : "" }}
        // onBlur={checkInput}
        onChange={(e) => {
          setRXNumberInput(e.target.value);
          if (!showSave) {
            setShowSave(true);
          }
        }}
      />
      {renderSaveBtn}
    </>
  );
};
export default React.memo(RenderRxInput);

//   user Data contains
//   id: "6258354f8ea5c8495cf79a6c",
//   clinicId?: "61ca16b3c683b36db04f1210",
//   clinicLocationId?: "61ca16b3c683b36db04f1211",
//   firstName: "Admin",
//   lastName: "Test 1",
//   email: "admintest1@proailab.com",
//   role: "AdminSuperUser",
//   created: "2022-04-14T14:53:03.813Z",
//   updated: "2022-08-24T12:24:58.083Z",
//   isVerified: true,

const AuthStore = {
  jwtToken: "",
  userData: undefined,
};

export { AuthStore };

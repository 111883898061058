import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  InvoiceDetailModal,
  ListingSearch,
  MuiTableCollapse,
  OrderViewModal,
} from "components";
import React, { useCallback, useMemo } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import {
  useLazyAdminArchivedPrescriptionQuery,
} from "rtk";
import { ADMIN_RX_WORKFLOW_COLUMNS, ROWS_LIMIT, columnHelper } from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import PrescriptionNotes from "../AdminPendingPrescription/PrescriptionNotes";
import { useAdminPrescriptionListing } from "../Hook";
import AlertMessage from "../components/alert_message";

function AdminArchivedPrescription() {
  const {
    rowLimit,
    invoiceId,
    tablePage,
    showLoading,
    totalRecords,
    errorMessage,
    searchKeyword,
    disableFilter,
    prescriptions,
    filterRxStatus,
    prescriptionId,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setCustomError,
    handleChangePage,
    toggleDetailModal,
    toggleInvoiceModal,
    updateFilterRxStatus,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyAdminArchivedPrescriptionQuery,
  });


  const showSpinner = useMemo(
    () => showLoading,
    [showLoading]
  );
  const combinedErrorMessage = useMemo(
    () => errorMessage || "",
    [errorMessage,]
  );

  const ADMIN_START_COLUMN = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Date",
        headerStyle: { minWidth: "105px", width: "105px", textAlign: "center" },
      }),
      columnHelper.accessor("rxOrder", {
        header: "Rx order",
        headerStyle: {
          minWidth: "50px",
          textAlign: "center",
          paddingLeft: 0,
          paddingRight: 0,
        },
        cell: (props) => (
          <Tooltip title="View">
            <IconButton
              onClick={() => toggleDetailModal(props.row.original.id)}
              style={{ width: 30, height: 30 }}
            >
              <VisibilityIcon
                style={{ fontSize: "20px" }}
                className="medText"
              />
            </IconButton>
          </Tooltip>
        ),
      }),
      columnHelper.accessor("invoiceNumber", {
        header: "Invoice #",
        headerStyle: { minWidth: "50px", paddingRight: 0 },
        cell: (props) => (
          <ButtonBase
            onClick={() => toggleInvoiceModal(props.row.original.id)}
            style={{ textAlign: "left" }}
            className="medText"
          >
            {props.getValue()}
          </ButtonBase>
        ),
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        headerStyle: { width: 150, paddingRight: "8px", paddingLeft: "8px" },
        cell: (props) => (
          <PrescriptionNotes
            id={props.row.original.id}
            notesMessage={props.row.original.message}
            setCustomError={setCustomError}
          />
        ),
      }),
    ],
    [setCustomError, toggleDetailModal, toggleInvoiceModal]
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [...ADMIN_START_COLUMN, ...ADMIN_RX_WORKFLOW_COLUMNS],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {showSpinner && <Spinner />}
      <Row className="my-3 px-3">
        <h5>Filter Prescriptions</h5>
        <Col xs={6} sm={5} lg={2}>
          <div className="mb-3 row">
            <span>
              <RXStatusDD
                loc={"archive"}
                filterRX={true}
                clearStatus={disableFilter}
                updatedFilterRxStatusValue={updateFilterRxStatus}
              />
            </span>
          </div>
        </Col>
        <Col sm={12} md={3} lg={3} className="m-0 ">
          <button
            disabled={disableFilter}
            className="yellowButton"
            onClick={applyFilter}
          >
            Filter
          </button>
          {!disableFilter && (
            <i
              disabled={disableFilter}
              className="fas fa-times redText"
              style={{ fontSize: 24, marginLeft: 18 }}
              onClick={clearFilter}
            />
          )}
        </Col>
        <Col className="text-end">
          
        </Col>
      </Row>

      <ListingSearch
        searchKeyword={searchKeyword}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
      />
      <AlertMessage msg={combinedErrorMessage} isError={true} />
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableCell />
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell
                      key={header.id}
                      style={
                        header.column.columnDef.headerStyle ?? { minWidth: 100 }
                      }
                    >
                      <Typography variant="tableHeader" noWrap>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map((row) => {
              return (
                <MuiTableCollapse
                  key={row.id}
                  row={row}
                  pageName={"AdminArchivedPrescription"}
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                  readOnly={true}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />

      <OrderViewModal
        prescriptionId={prescriptionId}
        handleClose={toggleDetailModal}
      />
      <InvoiceDetailModal
        invoiceId={invoiceId}
        handleClose={toggleInvoiceModal}
      />
    </>
  );
}

export default React.memo(AdminArchivedPrescription);

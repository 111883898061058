import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, CACHE_TAGS } from "../rtkConstant";
import { baseQuery } from "./BaseApi";

const UploadsApi = createApi({
  reducerPath: "PrescriptionApi",
  tagTypes: [CACHE_TAGS.UPLOADS],
  baseQuery: baseQuery,
  // baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
});

export default UploadsApi;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useUserLogoutMutation } from "rtk";
import { IsLoggedSelector } from "selectors";
import { logout } from "../../utils/";

const LogOut = (props) => {
  const isLogin = useSelector(IsLoggedSelector);
  const [logoutMutate, { isLoading, isSuccess }] = useUserLogoutMutation();
  const history = useHistory();

  useEffect(() => {
    if (isSuccess) {
      history.push(`/login`);
    } else if (!isLoading) {
      logout();
      logoutMutate();
    }
  }, [isLoading, isSuccess]);

  return <p className="m-5">{isLogin ? "Logging Out" : ""}</p>;
};

export default LogOut;

export const productType = 2

export const PRODUCT_STATUS = {
    active:'active',
    inactive:'inactive'
}

export const PRODUCT_DATA = [{
    id:'abc',
    status: 'active',
    family: 'Allergy',
    category: 'Ointment',
    product: 'Cromolyn 100mg',
    retailPrice: 5.00,
    sig1Title: 'QD PO',
    sig1: 'Take once',
    sig2Title: 'BID PO',
    sig2: '5.00',
    sig3Title: 'TID PO',
    sig3: 'Take 3 times',
},
    {
        id:'abcd',
        status: 'active',
        family: 'Analgesic',
        category: 'IV',
        product: 'Cromolyn 20mg',
        retailPrice: 2.00,
        sig1Title: 'QD PO',
        sig1: 'Take once',
        sig2Title: 'BID PO',
        sig2: '5.00',
        sig3Title: 'TID PO',
        sig3: 'Take 3 times',
    },
    {
        id:'abcde',
        status: 'inactive',
        family: 'Anesthetic',
        category: 'Capsule',
        product: 'Cromolyn 200mg',
        retailPrice: 7.00,
        sig1Title: 'QD PO',
        sig1: 'Take once',
        sig2Title: 'BID PO',
        sig2: '5.00',
        sig3Title: 'TID PO',
        sig3: 'Take 3 times',
    }
]

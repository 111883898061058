import React, { useCallback, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

function usePrintPage(documentTitle = "") {
  const componentRef = useRef(null);
  const [printLoading, setPrintLoading] = useState(false);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handleAfterPrint = useCallback(() => {
    setPrintLoading(false);
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setPrintLoading(true);
  }, []);

  const printModal = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: documentTitle,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  return {
    componentRef,
    printLoading,
    printModal,
  };
}

export default usePrintPage;

import RefreshIcon from "@mui/icons-material/Refresh";
import ConfirmCreditCardModal from "components/ConfirmCreditCardModal";
import { PaymentGrid } from "components/PaymentGrid";
import PaymentStatusDD from "components/dropdowns/paymentStatusDD";
import Spinner from "components/spinner";
import TextModal from "components/textModal";
import { includes, keys } from "lodash";
import React, { useCallback, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

import {
  Button,
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { InvoiceDetailModal, MuiTableCollapse } from "components";
import NavBar from "components/NavBar";
import { INVOICE_COLUMNS, ROWS_LIMIT, columnHelper } from "utils";
import { PAY_NOW_STATUS } from "utils/StatusConstant";
import HeaderInvoiceCheckBox from "./HeaderInvoiceCheckBox";
import useInvoiceList from "./Hook/useInvoiceList";
import InvoiceCheckBox from "./InvoiceCheckBox";

const InvoiceList = (props) => {
  const {
    toDate,
    sorting,
    clinicId,
    fromDate,
    rowLimit,
    tablePage,
    invoiceId,
    rowSelection,
    totalRecords,
    searchKeyword,
    disableFilter,
    paymentStatuses,
    isSelectableAll,
    selectedInvoices,
    filteredPaymentStatus,
    reactTablePaginationState,
    setSorting,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    handleChangePage,
    updateCardAction,
    exportAllInvoices,
    updateFilterValue,
    toggleInvoiceModal,
    closeCreditCardModal,

    //
    totalBalanceDue,
    setTotalBalanceDue,
    payFullBalanceModal,
    success,
    setSuccess,
    selectedTotalAmount,
    selectedPaymentApiMessage,
    setSelectedPaymentApiMessage,
    transactionModal,
    setTransactionModal,
    confirmCreditCardModal,
    setConfirmCreditCardModal,
    showLoading,
    showError,
    errorMessage,
    setPayFullBalance,
    setExportSuccessMessage,
    payNet30,
    setPaymentSuccess,
    closeTransactionModal,
    setPaymentInstructions,
    paymentInstructions,
    prescriptions,
    error,
    setError,
  } = useInvoiceList(props);

  const ADMIN_INVOICE_COLUMN = useMemo(
    () => [
      // columnHelper.accessor("checkbox1", {
      //   headerStyle: { width: 40, paddingLeft: 0, paddingRight: 0 },
      //   enableSorting: false,
      //   header: ({ table }) => <HeaderInvoiceCheckBox tableRow={table} isSelectableAll={isSelectableAll} />,
      //   cell: ({ row }) => <InvoiceCheckBox rowData={row.original} tableRow={row} />,
      // }),
      columnHelper.accessor("invoiceNumber", {
        header: "Invoice Number",
        headerStyle: {
          minWidth: "100px",
          width: "150px",
          paddingRight: 0,
          paddingLeft: "8px",
        },
        cell: (props) => (
          <ButtonBase
            onClick={() => toggleInvoiceModal(props.row.original.id)}
            style={{ textAlign: "left" }}
            className="medText"
          >
            {props.getValue()}
          </ButtonBase>
        ),
      }),
      columnHelper.accessor("paymentStatus", {
        header: "Payment Status",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
        cell: ({ row }) => {
          const rowPaymentStatus = row.original.paymentStatus ?? "-";
          // if (includes(["Error", "Declined", "Transaction Failed"], rowPaymentStatus)) {
          //   return (
          //     <>
          //       {rowPaymentStatus + " - "}
          //       <span
          //         className="medText pointer"
          //         onClick={() => {
          //           updateCardAction(row.original);
          //         }}
          //       >
          //         Update Card
          //       </span>
          //     </>
          //   );
          // }
          return rowPaymentStatus;
        },
      }),
      columnHelper.accessor("paymentApiMessage", {
        header: "Transaction Detail",
        enableSorting: false,
        headerStyle: { width: "80px", paddingRight: "8px", paddingLeft: "8px" },
        cell: ({ row }) => {
          let rowPaymentApiMessage = row.original.paymentApiMessage ?? "-";
          if (row?.original?.transactionDetailApiMessage)
            rowPaymentApiMessage +=
              " " + row?.original?.transactionDetailApiMessage;
          if (rowPaymentApiMessage) {
            return (
              <span
                className="medText pointer"
                onClick={() => {
                  setTransactionModal(true);
                  setSelectedPaymentApiMessage(rowPaymentApiMessage);
                }}
              >
                View Detail
              </span>
            );
          }
          return rowPaymentApiMessage;
        },
      }),
    ],
    [
      isSelectableAll,
      setSelectedPaymentApiMessage,
      setTransactionModal,
      toggleInvoiceModal,
      updateCardAction,
    ]
  );
  const tableColumns = useMemo(
    () => [
      INVOICE_COLUMNS[0], //invoice date
      ADMIN_INVOICE_COLUMN[0], //invoice number
      INVOICE_COLUMNS[4], //clinic name
      INVOICE_COLUMNS[5], // patient name
      INVOICE_COLUMNS[6], // total amount ($)
      INVOICE_COLUMNS[3], // credit card holder name
      INVOICE_COLUMNS[1], // credit card #
      ADMIN_INVOICE_COLUMN[1], //payment status
      INVOICE_COLUMNS[7], // paid date
      INVOICE_COLUMNS[8], // tracking number
      //they are not required.
      // ...INVOICE_COLUMNS.slice(1),
      // ...ADMIN_INVOICE_COLUMN.slice(2),
    ],
    [ADMIN_INVOICE_COLUMN]
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: tableColumns,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: (row) =>
      includes(PAY_NOW_STATUS, row.original.paymentStatus),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
      rowSelection,
      pagination: reactTablePaginationState,
    },
  });

  const renderHeaderCell = useCallback((header) => {
    const columnSortDirection = header.column.getIsSorted();
    const isCheckBoxCell = header.id === "checkbox1";
    const cellView = (
      <Typography variant="tableHeader" noWrap>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </Typography>
    );
    let headerCellView = cellView;
    if (!isCheckBoxCell) {
      headerCellView = (
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() =>
            header.column.toggleSorting(header.column.getIsSorted() !== "desc")
          }
        >
          {cellView}
        </TableSortLabel>
      );
    }

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={header.column.getIsSorted()}
      >
        {headerCellView}
      </TableCell>
    );
  }, []);

  return (
    <React.Fragment>
      {showLoading ? <Spinner /> : null}
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Invoices | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Invoices</Typography>
      </Stack>
          {error ? (
            <Alert
              color="danger"
              className="text-center"
              isOpen={Boolean(error)}
              toggle={() => setError("")}
            >
              {error ?? "Unable to Perform the Operation"}
            </Alert>
          ) : null}
          {success ? (
            <Alert
              color="success"
              className="text-center"
              isOpen={success}
              toggle={() => setSuccess("")}
            >
              {success}
            </Alert>
          ) : null}
          {/* <Row>
            <PaymentGrid
              setTotalBalanceDue={setTotalBalanceDue}
              setExportSuccessMessage={setExportSuccessMessage}
              setPayFullBalance={setPayFullBalance}
              setConfirmCreditModal={setConfirmCreditCardModal}
              isClinicSide={true}
              keyword={searchKeyword}
              paymentStatus={filteredPaymentStatus}
              fromDate={fromDate}
              toDate={toDate}
              payNow={payNet30}
              success={success}
              selectedTotalAmount={selectedTotalAmount}
              selectedInvoices={selectedInvoices}
              setPaymentInstructions={setPaymentInstructions}
              paymentInstructions={paymentInstructions}
            />
          </Row> */}
          <Row>
            <Col xs="12">
              <Card className="listing-card card">
                
                <CardBody>
                  <Row className="my-4 px-sm-3 px-1 ">
                    <h5 className="pb-3">Search or Filter</h5>
                    <Col xs={12} sm={20} lg={2}>
                      <div className="tableSearch d-flex">
                        <i className="bx bx-search-alt search-icon flexJCAC mt-4" />
                        <input
                          className="borderless mx-2 mt-4"
                          value={searchKeyword}
                          placeholder="Search table"
                          onChange={updateKeyword}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              applyFilter?.(e);
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={6} sm={5} lg={2}>
                      <p className="m-0 mt-1">From Date</p>
                      <div className="mb-3 row">
                        <div>
                          <input
                            value={fromDate}
                            className="form-control"
                            type="date"
                            max={new Date().toISOString().slice(0, 10)}
                            onChange={(e) => {
                              updateFilterValue("fromDate", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={5} lg={2}>
                      <p className="m-0 mt-1">To Date</p>
                      <div className="mb-3 row">
                        <div>
                          <input
                            value={toDate}
                            className="form-control"
                            type="date"
                            max={new Date().toISOString().slice(0, 10)}
                            onChange={(e) => {
                              updateFilterValue("toDate", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={5} lg={2}>
                      <div className="mt-4 row m-0">
                        <div>
                          <PaymentStatusDD
                            padding="7px"
                            statuses={paymentStatuses}
                            defaultValue={filteredPaymentStatus}
                            updatedFilterPaymentStatusValue={updateFilterValue}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs={4} sm={2} lg={1} className="m-0">
                      <button
                        disabled={disableFilter}
                        className="yellowButton w-60 mt-4 m-0"
                        onClick={applyFilter}
                      >
                        Search
                      </button>
                    </Col>

                    <Col xs={6} sm={2} lg={2} className="text-center m-0 p-0">
                      <button
                        className="pinkButton mt-4 m-0 pinkExportBtn"
                        onClick={(e) => {
                          e.preventDefault();
                          exportAllInvoices();
                        }}
                      >
                        Export
                      </button>
                    </Col>
                    {!disableFilter && (
                      <Col xs={12} sm={1}>
                        <p
                          disabled={disableFilter}
                          className="blueText mt-4 m-0"
                          style={{
                            fontSize: 14,
                            cursor: "pointer",
                            paddingTop: 8,
                          }}
                          onClick={clearFilter}
                        >
                          Clear
                        </p>
                      </Col>
                    )}
                  </Row>
                  <Col className="text-end" style={{ marginRight: 15 }}>
                    <Tooltip title="Refresh">
                      <Button variant="contained" onClick={refetchList}>
                        <RefreshIcon style={{ width: 20, height: 20 }} />
                      </Button>
                    </Tooltip>
                  </Col>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        {reactTable.getHeaderGroups().map((headerGroup) => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) =>
                              renderHeaderCell(header)
                            )}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {reactTable.getRowModel().rows.map((row) => {
                          return (
                            <MuiTableCollapse
                              key={row.id}
                              row={row}
                              showNested={false}
                              isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    showFirstButton
                    showLastButton
                    page={tablePage}
                    count={totalRecords}
                    rowsPerPage={rowLimit}
                    rowsPerPageOptions={ROWS_LIMIT}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={changeRowLimit}
                    style={{ alignItems: "center", marginBottom: 0 }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <TextModal
        showModal={transactionModal}
        closeModal={closeTransactionModal}
        textInfo={selectedPaymentApiMessage}
      />
      <ConfirmCreditCardModal
        totalBalanceDue={totalBalanceDue}
        payFullBalance={payFullBalanceModal}
        selectedTotalAmount={selectedTotalAmount}
        clinicId={clinicId}
        payNow={payNet30}
        setPaymentSuccess={setPaymentSuccess}
        invoiceId={keys(rowSelection)}
        creditCardModal={confirmCreditCardModal}
        closeModal={closeCreditCardModal}
        instructions={paymentInstructions}
        selectCompleteRowData={selectedInvoices}
        error={error}
        setError={setError}
      />
      <InvoiceDetailModal
        invoiceId={invoiceId}
        handleClose={toggleInvoiceModal}
      />
    </React.Fragment>
  );
};

export default React.memo(InvoiceList);

import React from "react";
import MetaTags from "react-meta-tags";
import {PRESCRIPTION_META_LABELS, PRESCRIPTION_TYPES} from "../constants";

const RenderPrescriptionMeta = (props) => {
    const {type = PRESCRIPTION_TYPES.pending}= props
    return  <MetaTags>
        <title>{PRESCRIPTION_META_LABELS[type].label}</title>
    </MetaTags>
}

export default RenderPrescriptionMeta

import React from "react";
import Lottie from "react-lottie";
import SpinnerLottie from "../assets/lotties/spinner.json";

const defaultLottieOptions = {
  loop: true,
  animationData: SpinnerLottie,
};
export default function Spinner(props) {
  return (
    <div className="spinnerOverlay" style={{ pointerEvents: "none !important" }}>
      <Lottie options={defaultLottieOptions} height={200} width={200} isClickToPauseDisabled={true} />
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { DialogContext } from "context";
import { useCallback, useContext, useEffect, useRef } from "react";

function useAdminPrescriptionAction({ listingName, listingMutation }) {
  const prescriptionIdRef = useRef();
  const actionTypeRef = useRef();
  const { showDialog, closeDialog } = useContext(DialogContext);

  const [mutateAction, { data: mutateData, error: mutateError, isSuccess: mutateSuccess, isLoading: mutateLoading }] =
    listingMutation();

  useEffect(() => {
    if (mutateSuccess) {
      toggleConfirmationModal();
    }
  }, [mutateSuccess]);

  const prescriptionAction = useCallback(() => {
    mutateAction({ action: actionTypeRef.current, prescriptionId: prescriptionIdRef.current, pageName: listingName });
  }, [listingName]);

  const toggleConfirmationModal = useCallback(
    (id = "", actionType = "") => {
      prescriptionIdRef.current = id;
      actionTypeRef.current = actionType;
      if (Boolean(actionType)) {
        showDialog({
          question: `Are you sure you want to ${actionType?.toLowerCase()} this prescription?`,
          actionText: actionType,
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: prescriptionAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [prescriptionAction, showDialog]
  );

  return {
    mutateData,
    mutateError,
    mutateSuccess,
    mutateLoading,
    prescriptionAction,
    toggleConfirmationModal,
  };
}

export default useAdminPrescriptionAction;

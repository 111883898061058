import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../dropdowns/stateDD";
import "./index.scss";
const StepOne = ({
  step,
  onlyOneUser,
  clinicAddress,
  onChange,
  setOnlyOneUser,
  upload,
  setClinicAddress,
  signatureImageUrl,
  signatureModal,
  closeSignatureModal,
  onClickSave,
  setSignatureModal,
  adminSide = false,
}) => {
  return (
    <div className={`${step === 1 ? "d-block" : "d-none"}`}>
      <FormGroup className="mb-4">
        <Label htmlFor="businessName" className="form-label">
          Clinic name <span className="requiredStar">*</span>
        </Label>
        <Input
          name="businessName"
          className="form-control"
          type="text"
          placeholder=""
          required={step === 1 ? true : false}
        />
      </FormGroup>

      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="addressLine1" className="form-label">
              Address line 1 <span className="requiredStar">*</span>
            </Label>
            <Input
              maxLength={50}
              value={clinicAddress?.addressLine1}
              onChange={(e) =>
                setClinicAddress({
                  addressLine1: e.target.value,
                  addressLine2: clinicAddress?.addressLine2,
                  zipcode: clinicAddress?.zipcode,
                  state: clinicAddress?.state,
                  city: clinicAddress?.city,
                })
              }
              name="addressLine1"
              className="form-control"
              type="textarea"
              required={step === 1 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="addressLine2" className="form-label">
              Address line 2
            </Label>
            <Input
              maxLength={50}
              value={clinicAddress?.addressLine2 !== "" ? clinicAddress?.addressLine2 : ""}
              onChange={(e) =>
                setClinicAddress({
                  addressLine1: clinicAddress?.addressLine1,
                  addressLine2: e.target.value !== "" ? e.target.value : "",
                  zipcode: clinicAddress?.zipcode,
                  state: clinicAddress?.state,
                  city: clinicAddress?.city,
                })
              }
              name="addressLine2"
              className="form-control"
              type="textarea"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="city" className="form-label">
              City <span className="requiredStar">*</span>
            </Label>
            <Input
              value={clinicAddress?.city}
              onChange={(e) =>
                setClinicAddress({
                  addressLine1: clinicAddress?.addressLine1,
                  addressLine2: clinicAddress?.addressLine2,
                  zipcode: clinicAddress?.zipcode,
                  state: clinicAddress?.state,
                  city: e.target.value,
                })
              }
              name="city"
              className="form-control"
              type="text"
              required={step === 1 ? true : false}
            />
          </div>
        </Col>
        <Col md={4}>
          <StateDD defaultValue={clinicAddress?.state} name="state" required={step === 1 ? true : false} />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="zipcode" className="form-label">
              Zip code <span className="requiredStar">*</span>
            </Label>

            <Input
              value={clinicAddress?.zipcode}
              onChange={(e) =>
                setClinicAddress({
                  addressLine1: clinicAddress?.addressLine1,
                  addressLine2: clinicAddress?.addressLine2,
                  zipcode: e.target.value,
                  state: clinicAddress?.state,
                  city: clinicAddress?.city,
                })
              }
              name="zipcode"
              className="form-control"
              required={step === 1 ? true : false}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mb-4">
          <FormGroup>
            <Label htmlFor="officePhone" className="form-label">
              Phone <span className="requiredStar">*</span>
            </Label>
            <Input
              name="officePhone"
              className="form-control"
              type="tel"
              pattern={step === 1 ? "[0-9]{3}[0-9]{3}[0-9]{4}" : null}
              placeholder="Format: 2124567890"
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-4">
          <FormGroup>
            <Label htmlFor="email" className="form-label">
              Email <span className="requiredStar">*</span>
            </Label>
            <Input
              name="email"
              className="form-control"
              type="email"
              placeholder=""
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default StepOne;

//Dashboard screens
import Dashboard from "./screens/superadmin/Dashboard/dashboard";
import { PatientsList } from "screens/superadmin/Patients";
import { ClinicsList } from "screens/superadmin/Clinics";
import AddAdmin from "screens/superadmin/Admins/addAdmin";
import AdminsList from "screens/superadmin/Admins/list";

import AddClinic from "./screens/superadmin/Clinics/AddClinic.jsx";
import { InvoiceList } from "./screens/common/Invoices/InvoiceList";
import { InvoiceDetail } from "./screens/common/Invoices/InvoiceDetail";
import EditClinic from "./screens/superadmin/Clinic/edit";
import { ClinicProfile } from "./screens/common/Clinic";
import AdminProfile from "screens/superadmin/Admins/adminProfile";
import Profile from "screens/superadmin/Admins/profile";
import EditAdmin from "screens/superadmin/Admins/edit";
import { PrescriptionsSection, AdminPendingPrescription } from "./screens/superadmin/Prescriptions";
import { ProductListing as Products } from "./screens/superadmin/Products/index";
import { ClinicProductList as ClinicProducts } from "./screens/superadmin/Products/ClinicProductList";

import { ManagePatient } from "./screens/superadmin/Patients";
import PatientProfile from "./screens/superadmin/Patients/profile";

const SuperAdmin = {
  Dashboard,
  PatientsList,
  AddAdmin,
  ClinicsList,
  AdminsList,
  AdminPendingPrescription,
  InvoiceList,
  InvoiceDetail,
  AddClinic,
  ClinicProfile,
  EditClinic,
  AdminProfile,
  Profile,
  EditAdmin,
  PrescriptionsSection,
  Products,
  ClinicProducts,
  ManagePatient,
  PatientProfile
};

export default SuperAdmin;

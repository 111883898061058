import { DashboardApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, END_POINTS } from "../../rtkConstant";

const DashboardQuery = DashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    adminDashboardCount: builder.query({
      keepUnusedDataFor: 0.1,
      query: () => END_POINTS.ADMIN_DASHBOARD_COUNT,
      providesTags: [CACHE_TAGS.DASHBOARD],
    }),
    clinicDashboardCount: builder.query({
      queryFn: async (_, queryApi, __, baseQuery) => {
        try {
          const userLogin = queryApi.getState().AuthSlice || {};
          if (userLogin) {
            const response = await baseQuery({
              url: END_POINTS.CLINIC_DASHBOARD_COUNT,
              method: API_METHODS.POST,
              body: {
                clinicId: userLogin?.userData?.clinicId,
                clinicLocationId: userLogin?.userData?.clinicLocationId,
              },
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: [CACHE_TAGS.DASHBOARD],
    }),
    refreshToken: builder.query({
      query: () => ({ url: END_POINTS.REFRESH_TOKEN, method: API_METHODS.POST }),
    }),
  }),
});

export const { useAdminDashboardCountQuery, useClinicDashboardCountQuery, useRefreshTokenQuery } = DashboardQuery;

import { ConfirmationModal } from "components";
import React, { createContext, useCallback, useMemo, useRef } from "react";

export const DialogContext = createContext({});

function DialogProvider(props) {
  const dialogRef = useRef(null);

  const showDialog = useCallback((showObj) => {
    dialogRef.current?.showDialog?.(showObj);
  }, []);

  const closeDialog = useCallback(() => {
    dialogRef.current?.closeDialog?.();
  }, []);

  const contextValue = useMemo(
    () => ({
      showDialog,
      closeDialog,
    }),
    [closeDialog, showDialog]
  );
  return (
    <DialogContext.Provider value={contextValue}>
      {props.children}
      <ConfirmationModal ref={dialogRef} />
    </DialogContext.Provider>
  );
}

export default React.memo(DialogProvider);

import { set } from "lodash";
import { USER_ROLES } from "utils";
import { AdminApi } from "../../rtkApi";
import { API_METHODS, END_POINTS } from "../../rtkConstant";

const AdminProductsQuery = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDiscountedProductsByClinicId: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              limit: apiArgs?.limit || 10,
              currentPage: apiArgs?.currentPage,
              clinicId: apiArgs?.clinicId,
              userId: user?.userData?.id,
            };
            let url;
            url = END_POINTS.CLINIC_ALL_DISCOUNTED_PRODUCTS;
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },

      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.currentPage === 1) {
          return newItems;
        }
        return {
          ...currentCache,
          products: [...currentCache?.products, ...newItems?.products],
          pagination: newItems?.pagination,
        };
      }, // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        console.log({ currentArg, previousArg });
        return currentArg !== previousArg;
      },
    }),
    getAllProducts: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              limit: apiArgs?.limit || 10,
              currentPage: apiArgs?.currentPage,
              userId: user?.userData?.id,
            };
            let url;
            url = END_POINTS.GET_PRODUCTS;
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },

      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.currentPage === 1) {
          return newItems;
        }
        return {
          ...currentCache,
          products: [...currentCache?.products, ...newItems?.products],
          pagination: newItems?.pagination,
        };
      }, // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        console.log({ currentArg, previousArg });
        return currentArg !== previousArg;
      },
    }),
    getAllCategories: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              limit: apiArgs?.limit || 10,
              currentPage: apiArgs?.currentPage,
              userId: user?.userData?.id,
            };
            let url;
            url = END_POINTS.GET_CATEGORIES;
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },

      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg }) => {
        if (arg.currentPage === 1) {
          return newItems;
        }
        return {
          ...currentCache,
          products: [...currentCache?.products, ...newItems?.products],
          pagination: newItems?.pagination,
        };
      }, // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        console.log({ currentArg, previousArg });
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useLazyGetAllDiscountedProductsByClinicIdQuery, useLazyGetAllProductsQuery, useLazyGetAllCategoriesQuery } = AdminProductsQuery;

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { IsLoggedSelector, userRoleSelector } from "selectors";
import { isLoggedIn } from "../utils";

const PrivateRoute = ({
  clinicComponent: ClinicComponent,
  superAdminComponent: SuperAdminComponent,
  adminComponent: AdminComponent,
  patientComponent: PatientComponent,
  ...rest
}) => {
  const userRole = useSelector(userRoleSelector);
  let permission = true;
  let Component = null;
  const loginSelector = useSelector(IsLoggedSelector);
  const cookieLogin = isLoggedIn();

  const isLogin = useMemo(() => loginSelector && cookieLogin, [cookieLogin, loginSelector]);

  if (userRole) {
    if(userRole == "Admin") {
      Component =  AdminComponent;
    } else if(userRole == "AdminSuperUser") {
      Component =  SuperAdminComponent;
    }
  }
  
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? permission ? <Component {...props} /> : <Redirect to="/access-denied" /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;

import Spinner from "components/spinner";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { InitializeStore } from "./rtk";
import App from "./App";
import { DialogProvider, PortalProvider } from "./context";
import { setUpInterceptor } from "utils/axiosConfig";
import ThemeProvider from "theme/index.jsx";
// import { ThemeProvider } from "@mui/material";
// import muiTheme from "theme/MaterialUiTheme";

const { reduxStore, persistor } = InitializeStore();
setUpInterceptor(reduxStore);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <ThemeProvider>
          <Router>
            <PortalProvider>
              <DialogProvider>
                <App />
              </DialogProvider>
            </PortalProvider>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
import { encrypt } from "cryptofunc";
import moment from "moment";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { cookies, USER_ROLES } from "utils";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import Cookies from "universal-cookie";

import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import Lottie from "../../../components/lottie";
import Spinner from "../../../components/spinner";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
// import PatientInfo1 from "./patient-info-1";
// import PatientInfo2 from "./patient-info-2";
// import PatientInfo3 from "./patient-info-3";
// import PatientInfo4 from "./patient-info-4";
import PatientInfoNew from "./patient-info-new";

class ManagePatient extends Component {
  constructor(props) {
    super(props);
    this.profileImageUpdated = React.createRef(false);
    this.driverLicenseUpdated = React.createRef(false);
    this.labWorkUpdated = React.createRef(false);
    this.physicalsUpdated = React.createRef(false);
    this.soapNotesUpdated = React.createRef(false);
    this.documentsUpdated = React.createRef(false);
    this.state = {
      step: 1,
      complete: false,
      showSpinner: false,
      error: "",
      patient: null,
      isToNotifyWhenOrderReceived: false,
      isToNotifyWhenOrderInProcess: false,
      isToNotifyWhenOrderInDispensary: false,
      isToNotifyWhenOrderOnHold: false,
      isToNotifyWhenOrderShipped: false,
      isToNotifyWhenOrderReadyForPickup: false,
      isToNotifyWhenInvoiceCreated: false,
      isToNotifyWhenOrderIsCompleted: false,
      profileImageURL: "",
      newProfileImageURL: "",
      removeProfileImageURL: false,
      driverLicenseURL: "",
      driverLicenseEditURL: "",
      labWorkEdit: [],
      physicalsEdit: [],
      soapNotesEdit: [],
      documentEdit: [],
      labWorkURL: [],
      physicalsURL: [],
      soapNotesURL: [],
      documentsURL: [],
      isEmployee: false,
      POBox: false,
      dateofBirth: "",
      defaultPatientAddress: {},
      email: "",
      contactNumber: "",
      profileImageRemoteUrl: "",
      driverLicenseRemoteUrl: "",
      labWorkRemoteUrl: [],
      physicalsRemoteUrl: [],
      soapNotesRemoteUrl: [],
      documentsRemoteUrl: [],
      portalAccess: true,
      patientAddress: "",
      patientShippingAddress: "",
      fedexShippingAddress: "",
      addressVerification: false,
      clinicLocationId: "",
      editPatient: window?.location?.pathname.includes("edit-patient"),
    };
  }
  setClinicLocation(v) {
    this.setState({ clinicLocationId: v });
  }
  setEmail(email) {
    this.setState({ email: email });
  }

  setContactNumber(number) {
    this.setState({ contactNumber: number });
  }

  async setPortalAccess(pAccess) {
    await this.setState({
      portalAccess: pAccess,
    });
  }
  setShipToClinic(value) {
    this.setState({
      shipToClinic: value,
    });
  }

  setSSameAsDefaultPA() {
    var serialize = require("form-serialize");
    var form = document.getElementById("editPatientForm");
    var obj = serialize(form, { hash: true, empty: true });
    var index = parseInt(obj.default_patient_address);
    var DPA = {
      addressLine1: obj.addresses[index]["'addressLine1'"],
      addressLine2: obj.addresses[index]["'addressLine2'"],
      city: obj.addresses[index]["'city'"],
      state: obj.addresses[index]["'state'"],
      zipcode: obj.addresses[index]["'zipcode'"],
    };

    this.setState({ defaultPatientAddress: DPA });
  }

  async updatePatient() {
    const clinicCookie = cookies.get("clinic_id");

    // if (this.state.editPatient && this.state.driverLicenseRemoteUrl) {
    //   var driverLicenseURL = this.state.driverLicenseRemoteUrl;
    // } else if (this.state.driverLicenseRemoteUrl) {
    //   var driverLicenseURL = this.state.driverLicenseRemoteUrl;
    // } else {
    //   var driverLicenseURL = this.state.driverLicenseEditURL;
    // }
    // if (this.state.editPatient && this.state.labWorkRemoteUrl) {
    //   var labWorkURL = [
    //     ...this.state.labWorkRemoteUrl,
    //     ...this.state.labWorkEdit,
    //   ];
    // } else {
    //   var labWorkURL = this.state.labWorkRemoteUrl;
    // }

    // if (this.state.editPatient && this.state.physicalsRemoteUrl) {
    //   var physicalsURL = [
    //     ...this.state.physicalsRemoteUrl,
    //     ...this.state.physicalsEdit,
    //   ];
    // } else {
    //   var physicalsURL = this.state.physicalsRemoteUrl;
    // }

    // if (this.state.editPatient && this.state.soapNotesRemoteUrl) {
    //   var soapNotesURL = [
    //     ...this.state.soapNotesRemoteUrl,
    //     ...this.state.soapNotesEdit,
    //   ];
    // } else {
    //   var soapNotesURL = this.state.soapNotesRemoteUrl;
    // }

    // if (this.state.editPatient && this.state.documentsRemoteUrl) {
    //   var documentsURL = [
    //     ...this.state.documentsRemoteUrl,
    //     ...this.state.documentsEdit,
    //   ];
    // } else {
    //   var documentsURL = this.state.documentsRemoteUrl;
    // }

    // const physicalsURL = this.state.physicalsRemoteUrl;
    // const soapNotesURL = this.state.soapNotesRemoteUrl;
    // const documentsURL = this.state.documentsRemoteUrl;
    const profileImageURL = this.state.newProfileImageURL;
    this.setState({ error: "" });
    var serialize = require("form-serialize");
    var form = document.getElementById("editPatientForm");
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });
    this.setState({ showSpinner: false });
    if(Object.keys(obj?.creditCards).length > 0) {
      await Object.keys(obj?.creditCards).forEach((card, index) => {
        // console.log(card,obj.creditCards[card]["cardNumber"],obj.creditCards[card], obj?.creditCards[card]?.cardNumber.length)
        if(obj?.creditCards[card]?.creditCardNumber.length > 12 && obj?.creditCards[card]?.cardExpirationDate.length > 0) {
          obj.creditCards[card] = {
            creditCardNumber: obj.creditCards[card].creditCardNumber.replace(
              /\s/g,
              ""
            ),
            cardHolderName: obj.creditCards[card].cardHolderName,
            cardExpirationDate: obj.creditCards[card].cardExpirationDate.replace(/\s/g, ""),
            // cardHolderAddressLine1:
            //   obj.creditCards[index]["'cardHolderAddressLine1'"],
            // cardHolderAddressLine2:
            //   obj.creditCards[index]["'cardHolderAddressLine2'"],
            // cardHolderState: obj.creditCards[index]["'cardHolderState'"],
            // cardHolderCity: obj.creditCards[index]["'cardHolderCity'"],
            // cardHolderZipcode: obj.creditCards[index]["'cardHolderZipcode'"],
            // cardHolderZipcode: obj.creditCards[index]["'cardHolderZipcode'"],
            cvv: obj.creditCards[card].cvv,
            // isCardDefault:
            //   index === parseInt(obj.default_credit_card) ? true : false,
          };
        }
      });
    }
    
if(Object.keys(obj.addresses).length > 0){
    await Object.keys(obj?.addresses).forEach((address, index) => {
      obj.addresses[address] = {
        address: obj.addresses[address]["textarea"],
        // addressLine2: obj.addresses[address]["'addressLine2'"],
        city: obj.addresses[address]["city"],
        state: obj.addresses[address]["state"],
        zipcode: obj.addresses[address]["zipcode"],
        _id: address
        // isDefault:
        //   index === parseInt(obj.default_patient_address) ? true : false,
      };
    });
  }

    if (this.state.error === "") {
      var encryptedArr = [];
      if(Object.keys(obj?.creditCards).length > 0){
        await Object.keys(obj?.creditCards).forEach((card, index) => {
          if(obj?.creditCards[card]?.creditCardNumber.length > 12 && obj?.creditCards[card]?.cardExpirationDate.length > 0) {
            encryptedArr.push(encrypt({...obj?.creditCards[card],_id: card}));
          }
        });
      }
      let objAddresses = [];
      if(Object.keys(obj.addresses).length > 0){
        objAddresses = Object.values(obj?.addresses);
      }
      // const selectElement = document.getElementById("physicianDDList");
      // const selectedOption =
      //   selectElement?.options[selectElement?.selectedIndex];
      // const selectedLocation = selectedOption?.getAttribute(
      //   "data-cliniclocation"
      // );
      const { editPatient } = this.state;
      const url = 
      // editPatient ?
         "patients/editPatientByPatientId";
        // : "patients/addNewPatient";
      axiosConfig
        .post(
          url,
          {
            // physicianId: obj.physicianId,
            patientId: this.props.match.params.id,
            // clinicLocationId: selectedLocation,
            // clinicId:
            //   this.state.patient?.clinicId ||
            //   this.props.match?.params?.clinicId ||
            //   clinicCookie,
            userId: new Cookies().get("user_id"),
            patient: {
              firstName: obj.firstName,
              // middleName: obj.middleName,
              lastName: obj.lastName,
              // guardianName: obj.guardianName,
              // currentOccupation: obj.currentOccupation,
              // ssn: obj.ssn,
              dateOfBirth: moment(obj?.dateOfBirth, "YYYY-MM-DD").format(
                "MM/DD/YYYY"
              ),
              martialStatus: obj.martialStatus,
              genderIdentity: obj.genderIdentity,
              sex: obj.sex,
              // sexualOrientation: obj.sexualOrientation,
              // ethnicity: obj.ethnicity,
              // race: obj.race,
              // weight: obj.weight,
              // height: obj.height,
              // currentPlan: obj.currentPlan,
              // isEmployee: this.state.isEmployee,
              patientImageUrl: profileImageURL
                ? profileImageURL
                : !this.state.removeProfileImageURL ? this.state.profileImageURL: '',
              // driverLicenseId: obj.driverLicenseId,
              // driverLicenseState: obj.driverLicenseState,
              // driverLicenseFileUrl: driverLicenseURL,
              // labWork: labWorkURL,
              // soapNotes: soapNotesURL,
              // physicals: physicalsURL,
              // otherDocuments: documentsURL,
              email: obj.email,
              cellPhone: obj.cellPhone,
              phoneNumber: obj.phoneNumber,
              addresses: objAddresses,
              // shippingAddressLine1: obj.shippingAddressLine1,
              // shippingAddressLine2: obj.shippingAddressLine2,
              // shippingCity: obj.shippingCity,
              // shippingState: obj.shippingState,
              // shippingZipcode: obj.shippingZipcode,
              // shippingPreference: obj.shippingPreference,
              // isShipToClinic: this.state.shipToClinic ? true : false,
              // isPoBox: this.state.POBox,
              // preferredName: obj.preferredEmergencyContact,
              // preferredFirstName: obj.preferredFirstName,
              // preferredLastName: obj.preferredLastName,
              // preferredContactMethod: obj.preferredContactMethod,
              // preferredLanguage: obj.preferredLanguage,
              // notificationPreferences: obj.notificationPreferences,
              // notificationEmail: obj.notificationEmail,
              // notificationText: obj.notificationText,
              // preferredEmergencyContact: obj.preferredEmergencyContactNumber,
              // isToNotifyWhenOrderReceived:
              //   this.state.isToNotifyWhenOrderReceived,
              // isToNotifyWhenOrderOnHold: this.state.isToNotifyWhenOrderOnHold,
              // isToNotifyWhenOrderInProcess:
              //   this.state.isToNotifyWhenOrderInProcess,
              // isToNotifyWhenOrderIsCompleted:
              //   this.state.isToNotifyWhenOrderIsCompleted,
              // notificationMethod: obj.notificationMethod,
              // isAutomaticBillOff: true,
              // isToGivePortalAccess: this.state.portalAccess,
              // comments: obj.comments,
            },
            //creditCards: obj.creditCards,
            encryptedCreditCards: encryptedArr,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          }
        )
        .then(async (response) => {
          this.setState({
            showSpinner: false,
            complete: true,
          });
        })
        .catch((error) => {
          this.setState({
            showSpinner: false,
            error:
              "An error occurred while trying to update patient information. " +
              error?.response?.data?.message,
          });
          errorResponse(error);
        });
    }
  }

  apiErrorrResponse(error) {
    this.setState({
      error: error,
      showSpinner: false,
    });

    errorResponse(error);
  }
  /*Fedex Modal Validate Address */

  onClickNotValidate() {
    this.closeModal();
    this.setState({
      addressVerification: true,
    });
  }
  onClickValidate() {
    var serialize = require("form-serialize");
    var form = document.getElementById("editPatientForm");
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });
    this.setState({
      patientShippingAddress: {
        shippingAddressLine1: this.state.fedexShippingAddress.addressLine1,
        shippingAddressLine2: this.state.fedexShippingAddress.addressLine2,
        shippingCity: this.state.fedexShippingAddress.city,
        shippingZipcode: this.state.fedexShippingAddress.zipcode,
        shippingState: this.state.fedexShippingAddress.state,
      },
    });

    this.closeModal();
    this.setState({
      addressVerification: true,
    });
  }

  closeModal() {
    this.setState({ showValidateModal: false });
  }
  /***/

  async handleValidSubmit(e) {
    e.preventDefault();
    this.setState({ error: "" });
    var serialize = require("form-serialize");
    var form = document.getElementById("editPatientForm");
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });
    console.log(obj);
    
    // const selectElement = document.getElementById("physicianDDList");
    // const selectedOption = selectElement.options[selectElement.selectedIndex];
    // const selectedLocation = selectedOption.getAttribute("data-cliniclocation");
    // const { editPatient } = this.state;

    // if (this.state.step === 1) {
      // this.setState({ step: 2 });

      // var formData = new FormData();
      // var formData2 = new FormData();
      // var driverLicenseURL = "";
      // var labWorkURL = [];
      // var physicalsURL = [];
      // var soapNotesURL = [];
      // var documentsURL = [];
      // var updatedProfileImageURL = "";
      // var uploadImageHeader = {
      //   headers: {
      //     Authorization: "Bearer " + new Cookies().get("user_token"),
      //     "Content-Type": "multipart/form-data;",
      //   },
      // };

      // console.log("lab work state1",this.state.labWorkURL);

      // if (
      //   (this.state.labWorkURL && this.labWorkUpdated.current) ||
      //   this.state.physicalsURL ||
      //   this.state.soapNotesURL ||
      //   this.state.documentsURL
      // ) {
      //   this.state.labWorkURL.forEach((file) => {
      //     formData.append("labWork", file);
      //   });
      //   this.state.physicalsURL.forEach((file) => {
      //     formData.append("physicals", file);
      //   });
      //   this.state.soapNotesURL.forEach((file) => {
      //     formData.append("soapNotes", file);
      //   });
      //   this.state.documentsURL.forEach((file) => {
      //     formData.append("otherDocuments", file);
      //   });
      //   // formData.append("labWork", this.state.labWorkURL);
      //   // formData.append("physicals", this.state.physicalsURL);
      //   // formData.append("soapNotes", this.state.soapNotesURL);
      //   // formData.append("otherDocumnets", this.state.documentsURL);

      //   await axiosConfig
      //     .post(
      //       "uploads/patient/uploadMultipleDocuments",
      //       formData,
      //       uploadImageHeader
      //     )
      //     .then(async (response) => {
      //       labWorkURL = response.data.response.labWork;
      //       physicalsURL = response.data.response.physicals;
      //       soapNotesURL = response.data.response.soapNotes;
      //       documentsURL = response.data.response.otherDocuments;
      //       this.setState({ labWorkRemoteUrl: labWorkURL });
      //       this.setState({ physicalsRemoteUrl: physicalsURL });
      //       this.setState({ soapNotesRemoteUrl: soapNotesURL });
      //       this.setState({ documentsRemoteUrl: documentsURL });
      //       this.labWorkUpdated.current = false;
      //       this.physicalsUpdated.current = false;
      //       this.soapNotesUpdated.current = false;
      //       this.documentsUpdated.current = false;
      //     })
      //     .catch((error) => {
      //       this.apiErrorrResponse(
      //         "An error occurred while trying to upload lab work image. " +
      //           error?.response?.data?.message
      //       );
      //     });
      // }

      // if (this.state.driverLicenseURL && this.driverLicenseUpdated.current) {
      //   formData = new FormData();
      //   formData.append("file", this.state.driverLicenseURL);

      //   await axiosConfig
      //     .post("uploads/uploadFile", formData, uploadImageHeader)
      //     .then(async (response) => {
      //       driverLicenseURL = response.data.accessUrl;
      //       this.setState({ driverLicenseRemoteUrl: driverLicenseURL });
      //       this.driverLicenseUpdated.current = false;

      //       if (
      //         this.state.newProfileImageURL &&
      //         this.profileImageUpdated.current
      //       ) {
      //         formData = new FormData();

      //         formData.append("file", this.state.newProfileImageURL);

      //         await axiosConfig
      //           .post("uploads/uploadFile", formData, uploadImageHeader)
      //           .then(async (response) => {
      //             updatedProfileImageURL = response.data.accessUrl;
      //             this.setState({
      //               profileImageRemoteUrl: updatedProfileImageURL,
      //             });
      //             this.profileImageUpdated.current = false;
      //           })
      //           .catch((error) => {
      //             this.apiErrorrResponse(
      //               "An error occurred while trying to upload profile image. " +
      //                 error?.response?.data?.message
      //             );
      //           });
      //       }
      //     })
      //     .catch((error) => {
      //       this.apiErrorrResponse(
      //         "An error occurred while trying to upload driver license. " +
      //           error?.response?.data?.message
      //       );
      //     });
      // } else 
      // if (
      //   this.state.newProfileImageURL &&
      //   this.profileImageUpdated.current
      // ) {
      //   formData = new FormData();
      //   await formData.append("file", this.state.newProfileImageURL);

      //   await axiosConfig
      //     .post("uploads/uploadFile", formData, uploadImageHeader)
      //     .then(async (response) => {
      //       updatedProfileImageURL = response.data.accessUrl;

      //       this.profileImageUpdated.current = false;
      //       this.setState({ profileImageRemoteUrl: updatedProfileImageURL });
      //     })
      //     .catch((error) => {
      //       this.apiErrorrResponse(
      //         "An error occurred while trying to upload profile image. " +
      //           error?.response?.data?.message
      //       );
      //     });
      // }
    // } else if (this.state.step === 2) {
    //   if (obj?.addresses) {
    //     await obj?.addresses?.forEach((address, index) => {
    //       var pos = index + 1;
    //       if (
    //         !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(obj.addresses[index]["'zipcode'"])
    //       ) {
    //         this.setState({
    //           step: 2,
    //           error: "Invalid Zip Code at Address " + pos,
    //         });
    //       }

    //       if (
    //         obj.addresses[index]["'addressLine1'"] === "" ||
    //         obj.addresses[index]["'city'"] === "" ||
    //         obj.addresses[index]["'zipcode'"] === ""
    //       ) {
    //         this.setState({
    //           error: "Some of the address fields are not properly filled.",
    //           step: 2,
    //         });
    //       }
    //     });
    //   }
      // if (this.state.error === "") {
        // this.setState({ step: 3 });
      // }
    // } else if (this.state.step === 3) {
      // if (obj.shippingPreference?.toLowerCase() === "pickup") {
        // this.setState({ step: 4 });
      // } else {
        // if (this.state.shipToClinic) {
        //   this.setState({
        //     showValidateModal: true,
        //     patientShippingAddress: {
        //       shippingAddressLine1: obj.shippingAddressLine1,
        //       shippingAddressLine2: obj.shippingAddressLine2,
        //       shippingCity: obj.shippingCity,
        //       shippingZipcode: obj.shippingZipcode,
        //       shippingState: obj.shippingState,
        //     },
        //   });
        // }
        // if (
        //   this.state.shipToClinic ||
        //   (this.state.addressVerification &&
        //     this.state.patientShippingAddress.shippingAddressLine1 ===
        //       obj.shippingAddressLine1 &&
        //     this.state.patientShippingAddress.shippingAddressLine2 ===
        //       obj.shippingAddressLine2 &&
        //     this.state.patientShippingAddress.shippingCity ===
        //       obj.shippingCity &&
        //     this.state.patientShippingAddress.shippingZipcode ===
        //       obj.shippingZipcode &&
        //     this.state.patientShippingAddress.shippingState.toLowerCase() ===
        //       obj.shippingState.toLowerCase())
        // ) {
        //   this.setState({ step: 4 });
        // } else {
          // var fedexShippingAddress;

          // this.setState({
          //   showValidateModal: true,
          //   patientShippingAddress: {
          //     shippingAddressLine1: obj.shippingAddressLine1,
          //     shippingAddressLine2: obj.shippingAddressLine2,
          //     shippingCity: obj.shippingCity,
          //     shippingZipcode: obj.shippingZipcode,
          //     shippingState: obj.shippingState,
          //   },
          //   showSpinner: true,
          // });

          var body = {
          //   address: {
          //     streetLines: {
          //       addressLine1: obj.shippingAddressLine1,
          //       addressLine2: obj.shippingAddressLine2
          //         ? obj.shippingAddressLine2
          //         : "-",
          //     },

          //     city: obj.shippingCity,
          //     state: obj.shippingState,
          //     postalCode: obj.shippingZipcode,
          //   },
          // };

          // await axiosConfig
          //   .post("/portal/validateAddress", body)
          //   .then(async (response) => {
          //     var fedexShippingAddr = {
          //       addressLine1: response.data.addressLine1,
          //       addressLine2:
          //         response.data.addressLine2 ||
          //         response.data.addressLine2 !== ""
          //           ? response.data.addressLine2
          //           : "-",
          //       city: response.data.city,
          //       state: response.data.state,
          //       zipcode: response.data.postalCode,
          //     };
          //     this.setState({
          //       fedexShippingAddress: fedexShippingAddr,
          //       showSpinner: false,
          //       error: "",
          //     });
          //   })
          //   .catch((error) => {
          //     this.setState({
          //       showValidateModal: false,
          //       error:
          //         "An error occurred while trying to trying to Validate Address. Please Try again",
          //       showSpinner: false,
          //     });
          //   });

          // if (obj.shippingZipcode.length !== 5) {
          /*(this.setState({
              showSpinner: false,
              error: 'Invalid Shipping Zip Code',
            });*/
          // } else {
          //this.setState({ step: 3});
          // }
        }
      // }
    // } else if (this.state.step === 4) {
      this.updatePatient();
    // }
  }

  setShowSpinner(check) {
    this.setState({ showSpinner: check });
  }

  setError(error) {
    this.setState({ error: error });
  }

  setProfileImageURL(URL) {
    this.setState({ newProfileImageURL: URL });
  }
  
  setRemoveProfileImageURL(removeProfileImageURL) {
    this.setState({ removeProfileImageURL: removeProfileImageURL });
  }

  setDriverLicenseURL(URL) {
    this.setState({ driverLicenseURL: URL });
  }

  setLabWorkURL(URL) {
    this.setState({ labWorkURL: URL });
  }
  setPhysicalsURL(URL) {
    this.setState({ physicalsURL: URL });
  }
  setSoapNotesURL(URL) {
    this.setState({ soapNotesURL: URL });
  }
  setDocumentsURL(URL) {
    this.setState({ documentsURL: URL });
  }
  isEmployee(check) {
    this.setState({ isEmployee: check });
  }

  setPOBox(check) {
    this.setState({ POBox: check });
  }
  setProfileImageUpdated = () => {
    this.profileImageUpdated.current = true;
    this.setState({ profileImageRemoteUrl: "" });
  };

  setDriverLicenseUpdated = () => {
    this.driverLicenseUpdated.current = true;
    this.setState({ driverLicenseRemoteUrl: "" });
  };
  setLabWorkUpdated = () => {
    this.labWorkUpdated.current = true;
    this.setState({ labWorkRemoteUrl: "" });
  };
  setPhysicalsUpdated = () => {
    this.physicalsUpdated.current = true;
    this.setState({ physicalsRemoteUrl: "" });
  };
  setSoapNotesUpdated = () => {
    this.soapNotesUpdated.current = true;
    this.setState({ soapNotesRemoteUrl: "" });
  };
  setDocumentsUpdated = () => {
    this.documentsUpdated.current = true;
    this.setState({ documentsRemoteUrl: "" });
  };

  setNotification(stateName, check) {
    this.setState({ [stateName]: check });
  }

  componentWillMount() {
    const { editPatient } = this.state;
    if (editPatient) this.getPatientInfo();
    else this.setState({ showSpinner: false });
  }
  getPatientInfo() {
    axiosConfig
      .post(
        "patients/getPatientByPatientId",
        {
          patientId: this.props.match.params.id,
          userId: new Cookies().get("user_id"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        await this.setState({
          showSpinner: false,
          isToNotifyWhenOrderReceived:
            response.data?.isToNotifyWhenOrderReceived,
          isToNotifyWhenOrderOnHold: response.data?.isToNotifyWhenOrderOnHold,
          isToNotifyWhenOrderInProcess:
            response.data?.isToNotifyWhenOrderInProcess,
          isToNotifyWhenOrderIsCompleted:
            response.data?.isToNotifyWhenOrderIsCompleted,
          shipToClinic: response.data?.isShipToClinic,
          profileImageURL: response.data?.patientImageUrl,
          driverLicenseURL: response.data?.driverLicenseURL,
          driverLicenseEditURL: response.data?.driverLicenseURL,
          labWorkEdit: response.data?.labWork,
          physicalsEdit: response.data?.physicals,
          soapNotesEdit: response.data?.soapNotes,
          documentsEdit: response.data?.otherDocuments,
          isEmployee: response.data?.isEmployee,
          POBox: response.data?.POBox,
          dateofBirth: moment(response.data?.dateOfBirth, "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          ),
          patient: response.data,
          clinicLocationId: response.data.clinicLocationId,
          patientShippingAddress:
            response.data.isShipToClinic === false
              ? {
                  shippingAddressLine1: response.data.shippingAddressLine1,
                  shippingAddressLine2: response.data.shippingAddressLine2,
                  shippingCity: response.data.shippingCity,
                  shippingZipcode: response.data.shippingZipcode,
                  shippingState: response.data.shippingState,
                }
              : {},
          patientAddress: {
            addressLine1: response.data.addressLine1,
            addressLine2: response.data.addressLine2,
            city: response.data.city,
            zipcode: response.data.zipcode,
            state: response.data.state,
          },
          portalAccess: response.data.isToGivePortalAccess,
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error:
            "An error occurred while trying to fetch patient information. " +
            error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }

  setPatientAddress(address) {
    this.setState({
      patientShippingAddress: {
        shippingAddressLine1: address.addressLine1,
        shippingAddressLine2: address.addressLine2,
        shippingCity: address.city,
        shippingZipcode: address.zipcode,
        shippingState: address.state,
      },
    });
  }
  render() {
    const { editPatient } = this.state;
    return (
      <React.Fragment>
        <SuperAdminNavbar />
        <div className="page-content">
          <MetaTags>
            <title>
              {" "}
              {editPatient
                ? "Edit Patient | Get Royal Health"
                : "AddPatient | Get Royal Health"}
            </title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="w-100 text-center">
                      <h2 className="p-3 ">
                        {editPatient
                          ? "EDIT PROFILE"
                          : "ADD PATIENT INFORMATION"}
                      </h2>
                    </div>

                    {this.state.complete ? (
                      <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                        <Lottie name="success-check" />
                        <p className="mb-5">
                          Your patient information has been updated!
                        </p>
                        <Link to="/patients" className="pinkButton">
                          Patients
                        </Link>
                      </div>
                    ) : (
                      <>
                        {/* <div
                          className={`w-100 text-center my-4 stepsPillsContainer`}
                        >
                          <div className="flexJCAC">
                            <div
                              className="rounded-pill"
                              style={{ width: "150px" }}
                            >
                              <div className="activeProgressNumberCircle">
                                <p>1</p>
                              </div>
                              <p className="m-0">Personal</p>
                              <p>Information</p>
                            </div>
                            <div
                              className="rounded-pill"
                              style={{ width: "150px" }}
                            >
                              <div
                                className={
                                  this.state.step >= 2
                                    ? `activeProgressNumberCircle`
                                    : `inactiveProgressNumberCircle`
                                }
                              >
                                <p>2</p>
                              </div>
                              <p className="m-0">Contact</p>
                              <p>Information</p>
                            </div>
                            <div
                              className="rounded-pill"
                              style={{ width: "150px" }}
                            >
                              <div
                                className={
                                  this.state.step >= 3
                                    ? `activeProgressNumberCircle`
                                    : `inactiveProgressNumberCircle`
                                }
                              >
                                <p>3</p>
                              </div>
                              <p className="m-0">Shipping</p>
                              <p>Information</p>
                            </div>
                          </div>
                          <div className="flexJCAC">
                            <div
                              className="rounded-pill"
                              style={{ width: "200px" }}
                            >
                              <div
                                className={
                                  this.state.step >= 4
                                    ? `activeProgressNumberCircle`
                                    : `inactiveProgressNumberCircle`
                                }
                              >
                                <p>4</p>
                              </div>
                              <p className="m-0">Billing &</p>
                              <p>Preferences</p>
                            </div>
                          </div>
                        </div> */}
                        <CardBody className="pt-0">
                          <div className="p-2">
                            <form
                              id="editPatientForm"
                              className="stepsForm form-horizontal px-sm-4 px-0"
                              onSubmit={(e) => this.handleValidSubmit(e)}
                            >
                              {this.state.showSpinner && <Spinner />}
                              {this.state?.error ? (
                                <Alert color="danger" className="text-center">
                                  {this.state.error}
                                </Alert>
                              ) : null}
                              <div className="mb-5 px-lg-5 px-sm-5 px-0">
                                {this.state.patient || !editPatient ? (
                                  <>
                                    <PatientInfoNew
                                      clinicId={
                                        editPatient
                                          ? this.state?.patient?.clinicId
                                          : this.props.match?.params?.clinicId
                                      }
                                      step={this.state?.step}
                                      dateOfBirth={this.state?.dateofBirth}
                                      patient={
                                        editPatient ? this.state?.patient : ""
                                      }
                                      isEmployee={this.isEmployee?.bind(this)}
                                      setDriverLicenseURL={this.setDriverLicenseURL?.bind(
                                        this
                                      )}
                                      setLabWorkURL={this.setLabWorkURL?.bind(
                                        this
                                      )}
                                      setPhysicalsURL={this.setPhysicalsURL?.bind(
                                        this
                                      )}
                                      setSoapNotesURL={this.setSoapNotesURL?.bind(
                                        this
                                      )}
                                      setDocumentsURL={this.setDocumentsURL?.bind(
                                        this
                                      )}
                                      setProfileImageURL={this.setProfileImageURL?.bind(
                                        this
                                      )}
                                      setRemoveProfileImageURL={this.setRemoveProfileImageURL?.bind(
                                        this
                                      )}
                                      setProfileImageUpdated={
                                        this.setProfileImageUpdated
                                      }
                                      setDriverLicenseUpdated={
                                        this.setDriverLicenseUpdated
                                      }
                                      setLabWorkUpdated={this.setLabWorkUpdated?.bind(
                                        this
                                      )}
                                      setPhysicalsUpdated={this.setPhysicalsUpdated?.bind(
                                        this
                                      )}
                                      setSoapNotesUpdated={this.setSoapNotesUpdated?.bind(
                                        this
                                      )}
                                      setDocumentsUpdated={this.setDocumentsUpdated?.bind(
                                        this
                                      )}
                                      clinicLocationId={
                                        this.state?.clinicLocationId
                                      }
                                      setClinicLocation={this.setClinicLocation?.bind(
                                        this
                                      )}
                                    />
                                    {/* <PatientInfo2
                                      step={this.state?.step}
                                      patient={
                                        editPatient ? this.state?.patient : ""
                                      }
                                      setEmail={this?.setEmail?.bind(this)}
                                      setContactNumber={this?.setContactNumber?.bind(
                                        this
                                      )}
                                    />
                                    <PatientInfo3
                                      step={this.state?.step}
                                      fedexShippingAddress={
                                        this.state?.fedexShippingAddress
                                      }
                                      closeModal={this.closeModal?.bind(this)}
                                      onClickNotValidate={this.onClickNotValidate?.bind(
                                        this
                                      )}
                                      onClickValidate={this.onClickValidate?.bind(
                                        this
                                      )}
                                      showValidateModal={
                                        this.state?.showValidateModal
                                      }
                                      defaultPatientAddress={
                                        this.state?.defaultPatientAddress
                                      }
                                      setSSameAsDefaultPA={this.setSSameAsDefaultPA?.bind(
                                        this
                                      )}
                                      patient={
                                        editPatient ? this.state?.patient : ""
                                      }
                                      clinicLocationId={
                                        this.state?.clinicLocationId
                                      }
                                      patientAddress={
                                        this.state?.patientAddress
                                      }
                                      patientShippingAddress={
                                        this.state?.patientShippingAddress
                                      }
                                      setPOBox={this.setPOBox?.bind(this)}
                                      isShipToClinic={this.state?.shipToClinic}
                                      shipToClinic={this.setShipToClinic?.bind(
                                        this
                                      )}
                                      setPatientAddress={this.setPatientAddress?.bind(
                                        this
                                      )}
                                    />

                                    <PatientInfo4
                                      step={this.state.step}
                                      patient={
                                        editPatient ? this.state?.patient : ""
                                      }
                                      email={this.state.email}
                                      contactNumber={this.state?.contactNumber}
                                      portalAccess={this.state?.portalAccess}
                                      setNotification={this.setNotification?.bind(
                                        this
                                      )}
                                      notificationCheckStatus={{
                                        isToNotifyWhenOrderIsCompleted:
                                          this.state
                                            ?.isToNotifyWhenOrderIsCompleted,
                                        isToNotifyWhenOrderOnHold:
                                          this.state?.isToNotifyWhenOrderOnHold,
                                        isToNotifyWhenOrderReceived:
                                          this.state
                                            ?.isToNotifyWhenOrderReceived,
                                        isToNotifyWhenOrderInProcess:
                                          this.state
                                            ?.isToNotifyWhenOrderInProcess,
                                      }}
                                    /> */}
                                  </>
                                ) : null}
                              </div>
                              <Row>
                                <Col className="text-center">
                                  <button
                                    type="submit"
                                    className="w-1/2 mt-8 uppercase mx-auto block rounded text-white py-2 px-4 rounded-md focus:outline-none"
                                    style={{ background: "#1D2127" }}
                                  >
                                    Update
                                  </button>
                                </Col>
                              </Row>
                              {/* <Row className="m-sm-5 m-0 text-center flexJCAC pt-3">
                                {this.state?.step === 1 ? null : (
                                  <button
                                    className="lightButton mt-4 mx-2"
                                    style={{ width: "40%" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        step: this.state?.step - 1,
                                      });
                                    }}
                                  >
                                    Back
                                  </button>
                                )}
                                {this.state?.step === 4 ? (
                                  <button
                                    className="blueButton mt-4 w-50"
                                    type="submit"
                                  >
                                    Update Patient
                                  </button>
                                ) : (
                                  <button
                                    className="blueButton mt-4 w-50"
                                    type="submit"
                                  >
                                    Next
                                  </button>
                                )}
                              </Row> */}
                            </form>
                          </div>
                        </CardBody>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ManagePatient;

/* eslint-disable react-hooks/exhaustive-deps */
import { forEach, set, unset } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ROWS_LIMIT } from "utils";

function useProductList({ listingQuery, listingClinicsQuery, listingCategoriesQuery, listingName, changeStatusMutation, listingMutation }) {
  const [apiParam, setApiParam] = useState({});
  const [innerError, setInnerError] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [rowLimit, SetRowLimit] = useState(ROWS_LIMIT[1]);
  const [disableFilter, setDisableFilter] = useState(true);

  const [editProduct, setEditProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [addProduct, setAddProduct] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  // const [clinics, setClinics] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const [
    fetchList,
    { data: { pagination, products } = { pagination: {}, products: [] }, error, isFetching },
  ] = listingQuery();
  
  const [
    fetchClinics,
    { data: { clinicPagination, clinicListing } = { pagination: {}, clinicListing: [] }, error: clinicError, isFetching: clinicFetching },
  ] = listingClinicsQuery();
  
  const [
    fetchCategories,
    { data: categories, error: categoriesError, isFetching: categoriesFetching },
  ] = listingCategoriesQuery();

  const [changeAction, { data: changeData, error: changeError, isSuccess: changeSuccess, isLoading: changeLoading }] =
    changeStatusMutation();
  
  const [mutateAction, { data: mutateData, error: mutateError, isSuccess: mutateSuccess, isLoading: mutateLoading }] =
    listingMutation();

  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() => pagination?.totalCount, [pagination?.totalCount]);
  const filterRxStatus = useMemo(() => apiParam?.rxStatus ?? "", [apiParam?.rxStatus]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const showSpinner = useMemo(() => isFetching || clinicFetching || mutateLoading || changeLoading, [isFetching, clinicFetching, mutateLoading, changeLoading]);
  const successMessage = useMemo(() => mutateData?.message || changeData?.message || changeSuccess?.message || "", [mutateData?.message, changeData?.message, changeSuccess?.message]);
  const errorMessage = useMemo(
    () => error?.data.message || mutateError?.data?.message || clinicError?.data?.message || categoriesError?.data?.message || changeError?.data?.message || "",
    [error?.data.message, mutateError?.data?.message, clinicError?.data?.message, categoriesError?.data?.message, changeError?.data?.message]
  );

  useEffect(() => {
    if (!isFetching) {
      fetchList({ currentPage: 1, limit: rowLimit }, true);
      fetchClinics({ currentPage: 1, limit: rowLimit }, true);
      fetchCategories({ currentPage: 1, limit: rowLimit }, true)
    }
  }, []);

  const refetchList = useCallback(() => {
    const body = { currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    fetchList(body);
  }, [apiParam, fetchList, rowLimit, tablePage]);

  useEffect(() => {
    if (mutateSuccess) {
      setProductId("");
      refetchList();
    }
  }, [mutateSuccess]);

  const setCustomError = useCallback((message) => {
    setInnerError(message);
  }, []);

  const toggleInvoiceModal = useCallback((id = "") => {
    setInvoiceId(id);
  }, []);

  const toggleDetailModal = useCallback((id = "", isReview = false) => {
    setPatientId(id);
    setIsReviewModal(isReview);
  }, []);

  const updateFilterValue = useCallback((keyword, value) => {
    setApiParam((prev) => ({ ...prev, [keyword]: value }));
    setDisableFilter(!Boolean(value));
  }, []);

  const clearFilter = useCallback(
    (e, keyName = "rxStatus") => {
      e.preventDefault();
      setApiParam((prev) => {
        const newParams = prev;
        unset(newParams, keyName);
        fetchList({ ...newParams, currentPage: 1, limit: rowLimit });
        return newParams;
      });
      
    },
    [fetchList, rowLimit]
  );

  const applyFilter = useCallback(
    (e) => {
      e.preventDefault();
      const body = { currentPage: 1, limit: rowLimit };
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, value);
        }
      });
      fetchList(body);
    },
    [apiParam, fetchList, rowLimit]
  );
  const updateKeyword = useCallback((e) => {
    e.preventDefault();
    setApiParam((prev) => ({ ...prev, keyword: e.target.value }));
  }, []);

  const changeRowLimit = useCallback(
    (e) => {
      const size = Number(e.target.value);
      SetRowLimit(size);
      const body = { ...apiParam, currentPage: 1, limit: size };
      if (!apiParam?.keyword) delete body.keyword;

      fetchList(body, true);
    },
    [apiParam, fetchList]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, currentPage: newPage + 1, limit: rowLimit };
        if (!apiParam?.keyword) delete body.keyword;

        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam]
  );

    const changeStatusHandler = async (id, status) => {
    // let newProducts = [...products];
    console.log('---999>',id,status)
    // const index = newProducts.findIndex((item) => item.id === id);
    // if (index !== -1) {
      // let product = newProducts[index];
      // product.isActive = status;
      // product.category = product.subCategory1;
      await changeAction({ productId: id, isActive: status, pageName: listingName });
      // const body = { productId: id, isActive: status, userId: new Cookies().get("user_id") };
      // await axiosConfig
      //   .post("products/changeProductStatusByProductId", body, {
      //     headers: {
      //       Authorization: "Bearer " + new Cookies().get("user_token"),
      //     },
      //   })
      //   .then(async (response) => {
      //     //console.log({ response });
      //     const { status } = response;
      //     if (response.data?.id) {
      //       setProductList((prev) => {
      //         return map(prev, (obj) => {
      //           if (obj.id === id) {
      //             return response.data;
      //           }
      //           return obj;
      //         });
      //       });
      //     }
      //     if (status === 200) {
      //       setSuccess(response?.data?.message)
      //       newProducts[index].isActive = status;
      //     }
      //   });
    // }
  };


  const productAction = useCallback(() => {
    mutateAction({ productId, pageName: listingName });
  }, [productId, listingName, mutateAction]);

    const deleteProductHandler = async (id) => {
      console.log('--->del',id);

      
    // const body = { productId: id, userId: new Cookies().get("user_id") };
    // await axiosConfig
    //   .delete("products/deleteProductByProductId", {
    //     headers: {
    //       Authorization: "Bearer " + new Cookies().get("user_token"),
    //     },

    //     data: body,
    //   })
    //   .then(async (response) => {
    //     const { status } = response;
    //     if (status === 200) {
    //       const newProducts = productList.filter((item) => item.id !== id);
    //       setProductList(newProducts);
    //       setDeleteProduct(null);
    //     }
    //   });
  };

    const setDataBack = (newList = [], id = null) => {
    if (!newList.length) return;
    refetchList()
    // if (!!id) {
    //   let products = [...productList];
    //   const index = products.findIndex((item) => item.id === id);
    //   if (index !== -1) {
    //     products[index] = { ...products[index], ...newList[0] };
    //   }
    //   setProductList(products);
    // } else {
    //   const products = [...productList];
    //   setProductList([...newList, ...products]);
    // }
    setAddProduct(false);
    setEditProduct(null);
  };

  const toggleProductModal = useCallback((id = "") => {
    setProductId(id)
  }, []);

  return {
    rowLimit,
    tablePage,
    invoiceId,
    pagination,
    isReviewModal,
    showSpinner,
    errorMessage,
    totalRecords,
    products,
    disableFilter,
    searchKeyword,
    patientId,
    filterRxStatus,
    setPatientId,
    refetchList,
    setCustomError,
    applyFilter,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    toggleDetailModal,
    updateFilterValue,
    toggleInvoiceModal,
//
    selectedRow,
    setSelectedRow,
    clinicListing,
    categories,
    success,
    setSuccess,
    addProduct,
    setAddProduct,
    productId,
    setProductId,
    editProduct,
    setEditProduct,
    changeStatusHandler,
    deleteProductHandler,
    setDataBack,
    toggleProductModal,
    productAction,
    successMessage
  };
}

export default useProductList;

// import React, { useEffect, useState, useRef, useCallback } from "react";
// import { PRODUCT_DATA, PRODUCT_STATUS } from "../constants";
// import axiosConfig, { errorResponse } from "../../../../utils/axiosConfig";
// import Cookies from "universal-cookie";
// import { map } from "lodash";
// import { ROWS_LIMIT } from "utils";

// const useProductList = () => {
//   const [productList, setProductList] = useState([]);
//   const [clinicList, setClinicList] = useState([]);
//   const [categoriesList, setCategoriesList] = useState([]);
//   const [deleteProduct, setDeleteProduct] = useState(null);
//   const [editProduct, setEditProduct] = useState(null);
//   const [addProduct, setAddProduct] = useState(false);
//   const [addCategory, setAddCategory] = useState(false);
//   const [editCategory, setEditCategory] = useState(null);
//   const [page, setPage] = useState(1);
//   const searchString = useRef("");
//   const [showSpinner, setShowSpinner] = useState(true);
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   const [pagination, setPagination] = useState({});
//   const [addPriceGroup, setAddPriceGroup] = useState(false);
//   const [tableDetail, setTableDetail] = useState("");
//   const [reload, setReload] = useState(false);
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [sizePerPage, setSizePerPage] = useState(20);
//   const [rowLimit, SetRowLimit] = useState(ROWS_LIMIT[0]);
//   const [priceGroups, setPriceGroups] = useState([]);
//   const toggle = useCallback(() => setDropdownOpen((prevState) => !prevState), []);

//   useEffect(() => {
//     // axiosConfig
//     //   .get(
//     //     "admin/productDiscounts/getCurrentPriceGroups",

//     //     {
//     //       headers: {
//     //         Authorization: "Bearer " + new Cookies().get("user_token"),
//     //       },
//     //     }
//     //   )
//     //   .then(async (response) => {
//     //     var priceGroupsList = [];
//     //     await response.data.forEach((priceGroup, i) => {
//     //       priceGroupsList.push(priceGroup);
//     //     });
//     //     setPriceGroups(priceGroupsList);
//     //   })
//     //   .catch((error) => {
//     //     errorResponse(error);
//     //   });

//       getClinics('',100);
//       getAllCategories();
//   }, [success]);
//   async function loadMore() {
//     setShowSpinner(true);

//     await axiosConfig
//       .post(
//         "products/getProductsForAdmin",
//         {
//           keyword: searchString.current,
//           alphabet: "",
//           family: "",
//           subCategory: "",
//           limit: sizePerPage,
//           currentPage: pagination?.nextPage || pagination?.currentPage + 1,
//         },
//         {
//           headers: {
//             Authorization: "Bearer " + new Cookies().get("user_token"),
//           },
//         }
//       )
//       .then(async (response) => {
//         const { products = [], pagination } = response?.data || {};

//         setError("");
//         setShowSpinner(false);
//         setPagination(pagination);
//         setProductList((prev) => [...prev, ...products]);
//       })
//       .catch((error) => {
//         setShowSpinner(false);
//         setError(error?.response?.data?.message);
//         errorResponse(error);
//       });
//   }

//   const getProducts = async (size = "") => {
//     let body = {
//       keyword: searchString.current,
//       alphabet: "",
//       family: "",
//       subCategory: "",
//       limit: size ? size : sizePerPage,
//       currentPage: page,
//     };
//     setShowSpinner(true);
//     await axiosConfig
//       .post("products/getProductsForAdmin", body, {
//         headers: {
//           Authorization: "Bearer " + new Cookies().get("user_token"),
//         },
//       })
//       .then(async (response) => {
//         const { products = [], pagination } = response?.data || {};

//         setProductList(products);

//         setPagination(pagination);
//         setShowSpinner(false);
//       })
//       .catch((err) => {
//         setShowSpinner(false);
//         setError(error?.response?.data?.message);
//       });
//   };

//   const getAllCategories = async (pg= "", size = "") => {
//     setShowSpinner(true);
//     await axiosConfig
//       .get("products/getAllCategories", {
//         headers: {
//           Authorization: "Bearer " + new Cookies().get("user_token"),
//         },
//       })
//       .then(async (response) => {
//         const categories = response?.data || {};

//         setCategoriesList(categories);

//         setShowSpinner(false);
//       })
//       .catch((err) => {
//         setShowSpinner(false);
//         setError(error?.response?.data?.message);
//       });
//   };

//   const getClinics = async (pg= "", size = "") => {
//     let body = {
//       limit: size ? size : sizePerPage,
//       currentPage: pg ? pg: page,
//     };
//     setShowSpinner(true);
//     await axiosConfig
//       .post("clinics/getClinics", body, {
//         headers: {
//           Authorization: "Bearer " + new Cookies().get("user_token"),
//         },
//       })
//       .then(async (response) => {
//         const { clinics = [], pagination } = response?.data || {};

//         setClinicList(clinics);

//         setShowSpinner(false);
//       })
//       .catch((err) => {
//         setShowSpinner(false);
//         setError(error?.response?.data?.message);
//       });
//   };
//   const handleChange = useCallback((e) => {
//     const size = Number(e.currentTarget.textContent);
//     setSizePerPage(size);
//     SetRowLimit(size);
//     getProducts(size);
//   }, []);
//   const onSearchHandler = async () => {
//     await getProducts();
//   };

//   useEffect(async () => {
//     await getProducts();
//   }, [searchString, addPriceGroup]);

//   const deleteProductHandler = async (id) => {
//     const body = { productId: id, userId: new Cookies().get("user_id") };
//     await axiosConfig
//       .delete("products/deleteProductByProductId", {
//         headers: {
//           Authorization: "Bearer " + new Cookies().get("user_token"),
//         },

//         data: body,
//       })
//       .then(async (response) => {
//         const { status } = response;
//         if (status === 200) {
//           const newProducts = productList.filter((item) => item.id !== id);
//           setProductList(newProducts);
//           setDeleteProduct(null);
//         }
//       });
//   };

//   const changeStatusHandler = async (id, status) => {
//     let newProducts = [...productList];
//     const index = newProducts.findIndex((item) => item.id === id);
//     if (index !== -1) {
//       let product = newProducts[index];
//       product.isActive = status;
//       product.category = product.subCategory1;
//       const body = { productId: id, isActive: status, userId: new Cookies().get("user_id") };
//       await axiosConfig
//         .post("products/changeProductStatusByProductId", body, {
//           headers: {
//             Authorization: "Bearer " + new Cookies().get("user_token"),
//           },
//         })
//         .then(async (response) => {
//           //console.log({ response });
//           const { status } = response;
//           if (response.data?.id) {
//             setProductList((prev) => {
//               return map(prev, (obj) => {
//                 if (obj.id === id) {
//                   return response.data;
//                 }
//                 return obj;
//               });
//             });
//           }
//           if (status === 200) {
//             setSuccess(response?.data?.message)
//             newProducts[index].isActive = status;
//           }
//         });
//     }
//   };
//   const setDataBack = (newList = [], id = null) => {
//     if (!newList.length) return;
//     getProducts()
//     // if (!!id) {
//     //   let products = [...productList];
//     //   const index = products.findIndex((item) => item.id === id);
//     //   if (index !== -1) {
//     //     products[index] = { ...products[index], ...newList[0] };
//     //   }
//     //   setProductList(products);
//     // } else {
//     //   const products = [...productList];
//     //   setProductList([...newList, ...products]);
//     // }
//     setAddProduct(false);
//     setEditProduct(null);
//   };

//   const handlePageChange = (pageNo) => {
//     setPage(pageNo);
//   };

//   return {
//     productList,
//     clinicList,
//     categoriesList,
//     deleteProductHandler,
//     deleteProduct,
//     setDeleteProduct,
//     editProduct,
//     setEditProduct,
//     addProduct,
//     setAddProduct,
//     changeStatusHandler,
//     setDataBack,
//     searchString,
//     onSearchHandler,
//     showSpinner,
//     sizePerPage,
//     pagination,
//     handlePageChange,
//     setAddPriceGroup,
//     addPriceGroup,
//     reload,
//     setReload,
//     tableDetail,
//     setTableDetail,
//     loadMore,
//     success,
//     setSuccess,
//     toggle,
//     dropdownOpen,
//     handleChange,
//     rowLimit,
//     ROWS_LIMIT,
//     priceGroups,
//     addCategory,
//     setAddCategory,
//     editCategory,
//     setEditCategory
//   };
// };

// export default useProductList;

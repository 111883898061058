import { createColumnHelper } from "@tanstack/react-table";
import { dateFormatterMountainTime } from "utils/constants";

const columnHelper = createColumnHelper();

const INVOICE_COLUMNS = [
  columnHelper.accessor("created", {
    header: "Invoice Date",
    headerStyle: {
      minWidth: "105px",
      width: "105px",
      textAlign: "center",
      paddingRight: "8px",
      paddingLeft: "8px",
    },
  }),
  columnHelper.accessor("cardNumber", {
    header: "Card Number",
    enableSorting: false,
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("invoiceDueDays", {
    header: "Due Days",
    headerStyle: { width: 100, paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("cardHolderName", {
    header: "Credit Card Holder Name",
    headerStyle: { width: 100, paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("clinicName", {
    header: "Clinic Name",
    headerStyle: { width: "180px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("patientName", {
    header: "Patient Name",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("totalAmount", {
    header: "Total Amount($)",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: (props) => props.getValue() || "-",
  }),

  columnHelper.accessor("paidDate", {
    header: "Paid Date",
    headerStyle: {
      minWidth: "105px",
      width: "105px",
      textAlign: "center",
      paddingRight: "8px",
      paddingLeft: "8px",
    },
    cell: (props) => props.getValue() || "-",
  }),
  columnHelper.accessor("trackingNumber", {
    header: "Tracking Number",
    enableSorting: false,
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: (props) => props.getValue() || "-",
  }),
];

const CLINIC_APPROVED_PRESCRIPTION_COLUMNS = [
  columnHelper.accessor("created", {
    header: "Date",
    cell: (props) => dateFormatterMountainTime(props.getValue()),
    headerStyle: { minWidth: "250px" },
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
  }),
  columnHelper.accessor("patientName", {
    header: "Patient Name",
  }),
  columnHelper.accessor("billTo", {
    header: "Bill to",
    headerStyle: { minWidth: 80 },
  }),
  columnHelper.accessor("shipTo", {
    header: "Delivery Method",
  }),
  columnHelper.accessor("trackingNumber", {
    header: "Tracking Number",
  }),
  columnHelper.accessor("transactionDetail", {
    header: "Transaction Detail",
  }),
  columnHelper.accessor("paymentStatus", {
    header: "Payment Status",
    cell: (props) => {
      const cellValue = props.getValue();
      return (
        <span
          className={
            cellValue === "Approved" || cellValue === "Net 30 Approved"
              ? "greenText"
              : "redText"
          }
        >
          {cellValue || "-"}
        </span>
      );
    },
  }),
  columnHelper.accessor("transactionDetail", {
    header: "Transaction detail",
    cell: (props) => {
      const cellValue = props.getValue();
      return (
        <span
          className={
            cellValue === "Approved" || cellValue === "Net 30 Approved"
              ? "greenText"
              : "redText"
          }
        >
          {cellValue || "-"}
        </span>
      );
    },
  }),
];

const NESTED_TABLE_HEADER = [
  { header: "", accessorKey: "unknown", headerStyle: { width: "20px" } },
  {
    header: "Medication",
    accessorKey: "name",
    headerStyle: { width: "350px" },
  },
];

const NESTED_BULK_TABLE_HEADER = [
  { header: "", accessorKey: "unknown", headerStyle: { width: "20px" } },
  {
    header: "Medication",
    accessorKey: "name",
    headerStyle: { width: "350px" },
  },
  {
    header: "Patient",
    accessorKey: "patientName",
    headerStyle: { width: "200px" },
  },
];

const NESTED_BULK_INVOICE_TABLE_HEADER = [
  { header: "", accessorKey: "unknown", headerStyle: { width: "20px" } },
  {
    header: "Invoice",
    accessorKey: "invoiceNumber",
    headerStyle: { width: "150px" },
  },

  {
    header: "Medication",
    accessorKey: "name",
    headerStyle: { width: "350px" },
  },
  {
    header: "Patient",
    accessorKey: "patientName",
    headerStyle: { width: "200px" },
  },
];

const INCOMPLETE_TABLE_HEADER = [
  columnHelper.accessor("created", {
    header: "Created On",
    headerStyle: { width: "250px" },
    cell: (props) => dateFormatterMountainTime(props.getValue()),
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
    headerStyle: { width: "250px" },
  }),

  columnHelper.accessor("patientName", {
    header: "Patient Name",
  }),
  columnHelper.accessor("patientEmail", {
    header: "Patient Email",
  }),
  columnHelper.accessor("patientPhone", {
    header: "Patient Phone #",
  }),
];

const ADMIN_PENDING_COLUMNS = [
  columnHelper.accessor("patientName", {
    header: "Patient Name",
  }),
];
const ADMIN_PENDING_BILL_COLUMNS = [
  columnHelper.accessor("billTo", {
    header: "Bill to",
    headerStyle: { minWidth: 80 },
  }),
];

const ADMIN_PENDING2_COLUMNS = [
  columnHelper.accessor("paymentStatus", {
    header: "Payment Status",
    headerStyle: { width: "250px" },
    cell: (props) => {
      const paymentStatusClass =
        props.getValue() == "Approved" ? "greenText" : "redText";
      return (
        <>
          <p className="smallText semiBold m-0">
            <span className={`text-muted normalText ${paymentStatusClass}`}>
              {props.getValue()}
            </span>
          </p>
        </>
      );
    },
  }),
];

const ADMIN_RX_WORKFLOW_COLUMNS = [
  columnHelper.accessor("clinicLocationName", {
    header: "Business Entity",
    headerStyle: { width: "180px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("patientName", {
    header: "Patient Name",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("shipTo", {
    header: "Delivery Method",
    headerStyle: { width: "280px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("totalAmount", {
    header: "Billing Info",
    cell: (props) => {
      const totalAmount = props.getValue() || "-";
      const paymentStatus = props.row?.original?.paymentStatus || "-";
      const billTo = props.row?.original?.billTo || "-";
      return (
        <>
          <p className="smallText semiBold m-0">
            Total($):{" "}
            <span className="text-muted normalText">{totalAmount}</span>
          </p>
          <p className="smallText semiBold m-0">
            Bill: <span className="text-muted normalText">{billTo}</span>
          </p>
          <p
            className="smallText semiBold m-0"
            style={{ whiteSpace: "nowrap" }}
          >
            Payment:{" "}
            <span className="text-muted normalText">{paymentStatus}</span>
          </p>
        </>
      );
    },
  }),

  columnHelper.accessor("trackingNumber", {
    header: "Shipping Info",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: (props) => {
      const trackingNumber = props.getValue() || "-";
      const shippingLabel = props.row?.original?.shippingLabel || "-";
      return (
        <>
          <p
            className="smallText semiBold m-0"
            style={{ whiteSpace: "nowrap" }}
          >
            Tracking:{" "}
            <span className="text-muted normalText">{trackingNumber}</span>
          </p>
          <p className="smallText semiBold m-0">
            Shipping:{" "}
            <a
              href={shippingLabel}
              target="_blank"
              rel="noreferrer"
              className="medText semiBold"
            >
              Label
            </a>
          </p>
        </>
      );
    },
  }),
];

export {
  columnHelper,
  INVOICE_COLUMNS,
  ADMIN_PENDING_COLUMNS,
  ADMIN_PENDING_BILL_COLUMNS,
  ADMIN_PENDING2_COLUMNS,
  INCOMPLETE_TABLE_HEADER,
  ADMIN_RX_WORKFLOW_COLUMNS,
  CLINIC_APPROVED_PRESCRIPTION_COLUMNS,
  NESTED_BULK_INVOICE_TABLE_HEADER,
  NESTED_BULK_TABLE_HEADER,
  NESTED_TABLE_HEADER,
};

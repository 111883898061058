const crypto = require("crypto");
const algorithm = "aes-256-cbc";
const AesKey = process?.env?.REACT_APP_AES_KEY_CREDIT_CARD;
const AesIV = process?.env?.REACT_APP_AES_IV_CREDIT_CARD;
const encryptionEncoding = "base64";
const bufferEncryption = "utf-8";

function encrypt(jsonObject) {
  const val = JSON.stringify(jsonObject);
  const key = Buffer.from(AesKey, bufferEncryption);
  const iv = Buffer.from(AesIV, bufferEncryption);
  const cipher = crypto.createCipheriv(algorithm, key, iv);
  let encrypted = cipher.update(val, bufferEncryption, encryptionEncoding);
  encrypted += cipher.final(encryptionEncoding);
  return encrypted;
}

function decrypt(base64String = "") {
  if (!base64String) {
    return "";
  }
  const buff = Buffer.from(base64String, encryptionEncoding);
  const key = Buffer.from(AesKey, bufferEncryption);
  const iv = Buffer.from(AesIV, bufferEncryption);
  const decipher = crypto.createDecipheriv(algorithm, key, iv);
  const deciphered = decipher.update(buff) + decipher.final();
  return JSON.parse(deciphered);
}

export { encrypt, decrypt };

import React, { useState, useEffect, useMemo, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import GRHLogo from "assets/images/logo.svg";
import { USER_ROLES } from "utils";
import axiosConfig, { errorResponse } from "utils/axiosConfig";
import Spinner from "components/spinner";
import moment from "moment";
import { encrypt, decrypt } from "cryptofunc";
import { dateFormatterMountainTime } from "utils/constants";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import {
  useLazyGetInvoiceDetailQuery,
  useLazyGetDefaultCreditCardQuery,
} from "rtk";
import NavBar from "components/NavBar";

const InvoiceDetail = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [patients, setPatients] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [clinicName, setClinicName] = useState("");
  const [clinicLocationName, setClinicLocationName] = useState("");
  const [clinicAddress1, setClinicAddress1] = useState("");
  const [clinicAddress2, setClinicAddress2] = useState("");
  const [clinicState, setClinicState] = useState("");
  const [clinicZipcode, setClinicZipcode] = useState("");
  const [clinicCity, setClinicCity] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [provider, setProvider] = useState("");
  const [subInvoices, setSubInvoices] = useState([]);
  const [subPatients, setSubPatients] = useState([]);
  const [subProviders, setSubProviders] = useState([]);
  const [rushOrderCost, setRushOrderCost] = useState([]);
  const [creditCard, setCreditCard] = useState("");
  const [creditRequested, setCreditRequested] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const clinicSide = userRole?.toLowerCase().includes("clinic");
  const [fetchInvoices, { data, isFetching: isFetching, isError, error }] =
    useLazyGetInvoiceDetailQuery();
  const [
    getDefaultCard,
    {
      isFetching: isCardFetching,
      isError: isCardError,
      error: cardError,
      data: cardData,
    },
  ] = useLazyGetDefaultCreditCardQuery();
  const showLoading = useMemo(
    () => isCardFetching || isFetching,
    [isCardFetching, isFetching]
  );
  const showError = useMemo(() => isCardError, isError, [isCardError, isError]);
  const errorMessage = useMemo(
    () => error?.data?.message,
    cardError?.data.message,
    [error?.data?.message, cardError?.data.message]
  );

  async function fetchData() {
    setInvoiceNumber(props.location?.state?.invoiceNumber);

    try {
      setShowSpinner(true);
      const response = await fetchInvoices({
        invoiceId: props.match.params.id,
      });
      // const creditCardResponse = response.data;
      // if (creditCardResponse?.data)
      //   if (typeof creditCardResponse.data?.encryptedBillingInfo === "string") {
      //     let decryptedBillingInfo = decrypt(creditCardResponse.data?.encryptedBillingInfo);
      //     setCreditCard(decryptedBillingInfo.creditCard);
      //   }

      if (response?.data) {
        let decryptedBillingInfo = response.data?.encryptedBillingInfo
          ? decrypt(response.data?.encryptedBillingInfo)
          : "";
        setCreditCard(decryptedBillingInfo.creditCard);
        setClinicName(response.data?.clinicName);
        setShippingAddress(response.data?.shippingInfo?.shippingAddress);
        setBillingAddress(response.data?.billingInfo);
        setShippingCost(response.data?.shippingCost);
        setTotalAmount(response.data?.totalAmount);
        setSubTotal(response.data?.totalAmount - response.data?.shippingCost);
        setCreditRequested(response?.data?.creditRequested);
        if (response.data?.shippingInfo?.isRushOrder) {
          setRushOrderCost(response.data?.shippingInfo?.rushOrderCost);
        }
        let ids = [];
        response.data?.patientIds.forEach((id) => {
          ids.push(id);
        });
        setInvoice(response?.data);
        if (response.data?.subPrescriptions?.length < 1) {
          setProvider(response.data?.providerDetails);

          ids = [];
          response.data?.patients.forEach((patient) => {
            ids.push(patient.firstName + " " + patient.lastName);
          });
          setPatients(ids);
          setClinicLocationName(
            response.data?.clinicLocationDetails.locationName
          );
          setClinicAddress1(
            response.data?.clinicLocationDetails.addressLine1 + ","
          );
          setClinicAddress2(response.data?.clinicLocationDetails.addressLine2);
          setClinicCity(response.data?.clinicLocationDetails.city);
          setClinicState(response.data?.clinicLocationDetails.state);
          setClinicZipcode(response.data?.clinicLocationDetails.zipcode);
          setShowSpinner(false);
        }
        if (response.data?.subPrescriptions?.length >= 1) {
          const subInvoiceDetails = [];
          const subPatientDetails = [];
          const subProviderDetails = [];
          for (let i = 0; i < response.data?.subPrescriptions.length; i++) {
            const pres = response.data?.subPrescriptions[i];
            const subPatientsInfo = [];
            const subResponse = await fetchInvoices({ invoiceId: pres });
            subInvoiceDetails.push(subResponse.data);
            subProviderDetails.push(subResponse.data?.providerDetails);
            subResponse.data?.patients?.forEach(async (patient) => {
              subPatientDetails.push({
                presId: subResponse.data?.id,
                value: patient?.firstName + " " + patient?.lastName,
                patientId: patient.id,
              });
            });
            setClinicLocationName(
              subResponse.data?.clinicLocationDetails.locationName
            );
            setClinicAddress1(
              subResponse.data?.clinicLocationDetails.addressLine1 + ","
            );
            setClinicAddress2(
              subResponse.data?.clinicLocationDetails.addressLine2
            );
            setClinicCity(subResponse.data?.clinicLocationDetails.city);
            setClinicState(subResponse.data?.clinicLocationDetails.state);
            setClinicZipcode(subResponse.data?.clinicLocationDetails.zipcode);
            setShowSpinner(false);
            if (i === response.data?.subPrescriptions.length - 1) {
              setSubPatients(subPatientDetails);
            }
            setSubInvoices(subInvoiceDetails);
            setSubProviders(subProviderDetails);
          }
        }
      }
    } catch {}
  }

  const printInvoice = () => {
    const domClone = document
      .getElementById("section-to-print")
      .cloneNode(true);
    const divToPrint = document.createElement("div");
    divToPrint.id = "printSection";
    document.body.appendChild(divToPrint);
    divToPrint.innerHTML = "";
    divToPrint?.replaceChildren(domClone);
    window.print();
    divToPrint.innerHTML = ""; // This line added to remove the children
    let child = divToPrint.firstElementChild;
    while (child) {
      divToPrint.removeChild(child);
      child = divToPrint.firstElementChild;
    }
  };

  function renderPatientNames(id = "", k = 0) {
    let names = "";
    if (invoice?.subPrescriptions?.length < 1) {
      patients.forEach((name, i) => {
        names += `<p className="my-2">${i + 1}. ${name}</p>`;
      });
    } else {
      subPatients
        ?.filter((item) => item.presId === id)
        .forEach((patient, j) => {
          names += `<p className="my-2">${j + 1}. ${patient.value}</p>`;
        });
    }
    return names;
  }

  const renderProducts = useCallback(
    (k = 0, id = "") => {
      let rows = [];

      if (invoice?.subPrescriptions?.length < 1) {
        const multiPatientCheck =
          invoice?.patientIds?.length > 1 ||
          invoice?.patientsProducts?.length > 0;
        let patientsInfo =
          invoice?.patientIds?.length > 1
            ? invoice?.patientsProducts
            : invoice?.patients;
        patientsInfo?.forEach((patient, j) => {
          const showProducts = multiPatientCheck
            ? patient?.products
            : invoice?.products;
          showProducts?.forEach((prod, i) => {
            let productInfo = multiPatientCheck
              ? invoice?.products.filter(
                  (a) => a.productId === prod.productId
                )[0]
              : prod;
            rows.push(
              <tr key={productInfo.name + i + j}>
                <td>{i + 1 + j}</td>
                <td>
                  {multiPatientCheck
                    ? patient?.patientName
                    : patient?.firstName + " " + patient?.lastName}
                </td>
                <td>{productInfo.name?.toUpperCase()}</td>
                <td>{prod.rxNumber ? prod.rxNumber : ""}</td>
                <td>
                  {prod.rxStatus
                    ? prod.rxStatus
                    : ""}
                </td>
                <td>{productInfo.sig}</td>
                <td>
                  <p className="mb-1 text-nowrap">{`Quantity: ${productInfo.quantity}`}</p>
                  <p className="mb-1 text-nowrap">{`Day Supply: ${productInfo.daySupply}`}</p>
                  <p className="mb-1 text-nowrap">{`Refills: ${productInfo.refills}`}</p>
                </td>
                <td>
                  {productInfo.last_office_visit_timestamp
                    ? moment(productInfo.last_office_visit_timestamp).format(
                        "MM/DD/YYYY"
                      )
                    : ""}
                </td>
                <td>
                  {productInfo.reasonForCompoundedMedication?.toUpperCase()}
                </td>
                <td>{productInfo.patientDiagnosis?.toUpperCase()}</td>
                <td>{productInfo.unitPrice}</td>
                <td>{productInfo.discountedPrice}</td>
                <td>
                  {parseFloat(
                    productInfo.discountedPrice * productInfo.quantity
                  ).toFixed(2)}
                </td>
              </tr>
            );
          });
        });
      } else {
        const filteredRow = [];
        subPatients
          ?.filter((item) => item.presId === id)
          .forEach((patient, o) => {
            const multiPatientCheck =
              subInvoices[k]?.patientsProducts?.length > 0;
            const updatedPatient =
              subInvoices[k].patientsProducts?.length > 0
                ? subInvoices[k].patientsProducts.filter(
                    (a) => a.patientId === patient.patientId
                  )[0]
                : "";
            subInvoices[k]?.products?.forEach((prod, i) => {
              rows.push({
                presId: id,
                codeValue: (
                  <tr key={prod.name + i + "--" + o}>
                    <td>{i + 1 + o}</td>
                    <td>
                      {multiPatientCheck
                        ? updatedPatient?.patientName
                        : patient?.value}
                    </td>
                    <td>{prod.name}</td>
                    <td>
                      {multiPatientCheck
                        ? updatedPatient?.products?.filter(
                            (a) => a.productId === prod.productId
                          )[0]?.rxNumber
                        : prod.rxNumber ?? ""}
                    </td>
                    <td>
                      {prod.rxStatus ?? ""}
                    </td>
                    <td>{prod.sig?.toUpperCase()}</td>
                    <td>
                      <p className="mb-1">
                        <span className="semiBold">Quantity: </span>
                        <span>{prod.quantity}</span>
                      </p>
                      <p className="mb-1">
                        <span className="semiBold">Day Supply: </span>
                        <span>{prod.daySupply}</span>
                      </p>
                      <p className="mb-1">
                        <span className="semiBold"> Refills: </span>
                        <span>{prod.refills}</span>
                      </p>
                    </td>
                    <td>
                      {prod.last_office_visit_timestamp
                        ? moment(prod.last_office_visit_timestamp).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </td>
                    <td>{prod.reasonForCompoundedMedication?.toUpperCase()}</td>
                    <td>{prod.patientDiagnosis?.toUpperCase()}</td>
                    <td>{prod.unitPrice}</td>
                    <td>{prod.discountedPrice}</td>
                    <td>
                      {parseFloat(prod.discountedPrice * prod.quantity).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                ),
              });
            });
          });

        rows = rows.filter((item) => item.presId === id);

        rows.forEach((val, i) => {
          filteredRow.push(rows[i].codeValue);
        });
        rows = filteredRow;
      }
      return <>{rows}</>;
    },
    [invoice, subPatients, subInvoices]
  );

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Invoice Detail | Get Royal Health</title>
        </MetaTags>
        <Container>
          <Row className="invoiceHeader">
            <h4 className="p-3 text-muted">INVOICE DETAIL</h4>
            {userRole !== USER_ROLES.CLINIC_SUPPORT ? (
              <div className="d-flex">
                {props.reorderButton ? (
                  <Link
                    to="#"
                    onClick={printInvoice}
                    className="yellowButton mx-3"
                  >
                    Re-Order
                  </Link>
                ) : null}
                <Link to="#" onClick={printInvoice} className="pinkButton">
                  Print
                </Link>
              </div>
            ) : null}
          </Row>
          {showLoading ? <Spinner /> : null}
          {invoice?.subPrescriptions?.length < 1 ? (
            <div id="section-to-print">
              <Row>
                <Col lg="12">
                  <Card className="p-3">
                    <CardBody className="font-size-16">
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16 mt-3">
                          Invoice for {clinicName}
                        </h4>
                        <img src={GRHLogo} alt="logo" height="40" />
                      </div>
                      <hr />
                      <Row>
                        <Col sm="6" className="px-3">
                          <strong>{clinicName}</strong>
                          <br className="mt-3" />
                          {clinicAddress1}
                          {clinicAddress2 && (
                            <>
                              <br />
                              {clinicLocationName},
                            </>
                          )}
                          <br />
                          <br />
                          {clinicCity}, {clinicState}
                          <br />
                          {clinicZipcode}
                        </Col>
                        <Col sm="6" className="text-sm-end">
                          <strong>Invoice Number: </strong>
                          {invoiceNumber}
                          <br />
                          <strong>Invoice Date: </strong>
                          {dateFormatterMountainTime(invoice?.prescriptionDate)}
                        </Col>
                      </Row>
                      <hr />
                      <Row className="py-3">
                        <Col sm="12">
                          <strong>
                            <p>
                              {patients.length > 1 ? "Patients:" : "Patient:"}
                            </p>
                          </strong>
                          <div
                            id="patientnames"
                            dangerouslySetInnerHTML={{
                              __html: renderPatientNames(),
                            }}
                          ></div>
                          {invoice?.trackingNumber && (
                            <p>Tracking Number: {invoice?.trackingNumber}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <strong className="my-3">Address information:</strong>

                        <Col sm="6">
                          <strong>Billed To</strong>
                          <br />
                          {creditCard?.cardHolderName}
                          <br />
                          Credit Card: **** **** ****{" "}
                          {creditCard?.creditCardNumber?.substring(
                            creditCard?.creditCardNumber.length - 4
                          )}
                          <br />
                          Expiry:{" "}
                          {moment(
                            creditCard?.cardExpirationDate?.replace(/\s/g, ""),
                            "MM-YYYY"
                          ).format("MM/YY")}
                          <br />
                          {creditCard?.cardHolderAddressLine1}
                          <br />
                          {creditCard?.cardHolderAddressLine2}
                          <br />
                          {creditCard?.cardHolderCity},{" "}
                          {creditCard?.cardHolderState}
                          <br />
                          {creditCard?.cardHolderZipcode}{" "}
                        </Col>
                        <Col sm="6" className="text-sm-start">
                          <strong>Delivery Method</strong>
                          <br />
                          {!invoice?.shippingInfo?.shippingMethod
                            .toLowerCase()
                            .includes("pickup") ? (
                            <>
                              <br />
                              Ship To: {invoice?.shippingInfo?.shipTo}
                              <br />
                              {
                                invoice?.shippingInfo?.shippingAddress
                                  ?.addressLine1
                              }
                              <br />
                              {invoice?.shippingInfo?.shippingAddress
                                ?.addressLine2 ?? "-"}
                              <br />
                              {
                                invoice?.shippingInfo?.shippingAddress?.city
                              }, {invoice?.shippingInfo?.shippingAddress?.state}
                              <br />
                              {invoice?.shippingInfo?.shippingAddress?.zipcode}
                              <br />
                              <br />
                            </>
                          ) : (
                            invoice?.shippingInfo?.shippingMethod
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <p>
                          <strong className="mr-2">Order Notes </strong> <br />
                          {invoice?.shippingInfo?.orderNotes ?? "-"}
                        </p>
                      </Row>
                      <div className="py-2 mt-4">
                        <strong>Products:</strong>
                      </div>
                      <div className="table-responsive">
                        <Table className="mb-5">
                          <thead>
                            <tr>
                              <th className="text-nowrap">No.</th>
                              <th style={{ width: 200 }}>Patient Name</th>
                              <th style={{ width: 200 }}>Product Name</th>
                              <th>RX Number</th>
                              <th>RX Status</th>
                              <th>Sig</th>
                              <th>Info</th>
                              <th>Last Office Visit</th>
                              <th>Reason for compounded Medication</th>
                              <th>Patient Dx</th>
                              <th>Unit Price($)</th>
                              <th>Discounted Price($)</th>
                              <th>Total Price($)</th>
                            </tr>
                          </thead>
                          <tbody>{renderProducts()}</tbody>
                        </Table>
                      </div>

                      {creditRequested ? (
                        <Row className="my-3">
                          <Col xs={3} md={2}></Col>
                          <Col xs={3} md={2} className="text-start"></Col>
                          <Col md={4} className="d-none d-md-block" />

                          <Col xs={3} md={2} className="text-end">
                            Credit Used
                          </Col>
                          <Col xs={3} md={2} className="text-end">
                            <strong>
                              $ {parseFloat(creditRequested).toFixed(2)}
                            </strong>
                          </Col>
                        </Row>
                      ) : null}
                      <Row className="my-3">
                        <Col xs={3} md={2}>
                          Priority Order
                        </Col>
                        <Col xs={3} md={2} className="text-start">
                          <strong>
                            {invoice?.shippingInfo?.isRushOrder ? "Yes" : "No"}
                          </strong>
                        </Col>
                        <Col md={4} className="d-none d-md-block" />

                        <Col xs={3} md={2} className="text-end">
                          Sub Total
                        </Col>
                        <Col xs={3} md={2} className="text-end">
                          <strong>$ {parseFloat(subTotal).toFixed(2)}</strong>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col xs={3} md={2}>
                          Shipping Method
                        </Col>
                        <Col xs={3} md={2} className="text-start">
                          <strong>
                            {invoice?.shippingInfo?.shippingMethod}
                          </strong>
                        </Col>
                        <Col md={4} className="d-none d-md-block" />
                        <Col xs={3} md={2} className="text-end">
                          Shipping
                        </Col>
                        <Col xs={3} md={2} className="text-end">
                          <strong>
                            $
                            {!invoice?.shippingInfo?.shippingMethod
                              .toLowerCase()
                              .includes("pickup")
                              ? invoice?.shippingCost
                              : "0"}
                          </strong>
                        </Col>
                      </Row>
                      {invoice?.shippingInfo?.isRushOrder ? (
                        <>
                          <Row className="my-3">
                            <Col xs={3} md={2}>
                              Signature Required
                            </Col>
                            <Col xs={3} md={2} className="text-start">
                              <strong>
                                {invoice?.shippingInfo?.isSignatureRequired
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </Col>
                            <Col md={4} className="d-none d-md-block" />
                            <Col xs={3} md={2} className="text-end">
                              Priority Order Fee
                            </Col>
                            <Col xs={3} md={2} className="text-end">
                              <strong>$ {rushOrderCost}</strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={{ size: 3, offset: 6 }}
                              md={{ size: 2, offset: 8 }}
                              className="text-end"
                            >
                              <strong>Total</strong>
                            </Col>
                            <Col xs={3} md={2} className="text-end">
                              <strong>
                                $ {parseFloat(invoice?.totalAmount).toFixed(2)}
                              </strong>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row className="my-3">
                          <Col xs={3} md={2}>
                            Signature Required
                          </Col>
                          <Col xs={3} md={2} className="text-start">
                            <strong>
                              {invoice?.shippingInfo?.isSignatureRequired
                                ? "Yes"
                                : "No"}
                            </strong>
                          </Col>
                          <Col md={4} className="d-none d-md-block" />
                          <Col xs={3} md={2} className="text-end">
                            <strong>Total</strong>
                          </Col>
                          <Col xs={3} md={2} className="text-end">
                            <strong>
                              $ {parseFloat(invoice?.totalAmount).toFixed(2)}
                            </strong>
                          </Col>
                        </Row>
                      )}
                      {invoice?.signatureFileUrl && (
                        <div className="my-5 mx-1">
                          <p className="bold">Signature</p>
                          <div className="flexJCAC justify-content-start">
                            <img src={invoice?.signatureFileUrl} width={180} />
                          </div>
                          <p>
                            Signed by:{" "}
                            {provider?.firstName + " " + provider?.lastName} on{" "}
                            {dateFormatterMountainTime(
                              invoice?.prescriptionDate
                            )}
                          </p>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <div id="section-to-print">
              <Row>
                <Col lg="12">
                  <Card className="p-3">
                    <CardBody className="font-size-16">
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16 mt-3">
                          Invoice for {invoice?.clinicName}
                        </h4>
                        <img src={GRHLogo} alt="logo" height="40" />
                      </div>
                      <hr />
                      <Row>
                        <Col sm="6" className="">
                          <strong>Bulk Invoice: </strong> Yes
                        </Col>
                        <Col sm="6" className="text-sm-end">
                          <strong>Invoice Number: </strong>
                          {invoiceNumber}
                          <br />
                          <strong>Invoice Date: </strong>
                          {dateFormatterMountainTime(invoice?.prescriptionDate)}
                        </Col>
                      </Row>
                      <hr />
                      <Row className="py-3">
                        <Col sm="12">
                          {invoice?.trackingNumber && (
                            <p>Tracking Number: {invoice?.trackingNumber}</p>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6" className="text-sm-start">
                          <strong>Delivery Method </strong>
                          {!invoice?.shippingInfo?.shippingMethod
                            ?.toLowerCase()
                            .includes("pickup") ? (
                            <>
                              <br />
                              Ship To: {invoice?.shippingInfo?.shipTo}
                              <br />
                              {
                                invoice?.shippingInfo?.shippingAddress
                                  ?.addressLine1
                              }
                              <br />
                              {invoice?.shippingInfo?.shippingAddress
                                ?.addressLine2 ?? "-"}
                              <br />
                              {
                                invoice?.shippingInfo?.shippingAddress?.city
                              }, {invoice?.shippingInfo?.shippingAddress?.state}
                              <br />
                              {invoice?.shippingInfo?.shippingAddress?.zipcode}
                              <br />
                              <br />
                            </>
                          ) : (
                            invoice?.shippingInfo?.shippingMethod
                          )}
                        </Col>

                        <Col sm="6">
                          <strong>Billed To</strong>
                          <br />
                          {creditCard?.cardHolderName}
                          <br />
                          Credit Card: **** **** ****{" "}
                          {creditCard?.creditCardNumber?.substring(
                            creditCard?.creditCardNumber?.length - 4
                          )}
                          <br />
                          Expiry:{" "}
                          {moment(
                            creditCard?.cardExpirationDate?.replace(/\s/g, ""),
                            "MM-YYYY"
                          ).format("MM/YY")}
                          <br />
                          {creditCard?.cardHolderAddressLine1} <br />
                          {creditCard?.cardHolderAddressLine2 ? (
                            <>
                              {creditCard?.cardHolderAddressLine2} <br />
                            </>
                          ) : null}
                          {creditCard?.cardHolderCity},{" "}
                          {creditCard?.cardHolderState}
                          <br />
                          {creditCard?.cardHolderZipcode}{" "}
                        </Col>
                        <Col sm="6">
                          <strong>Shipping Cost: </strong>${" "}
                          {!invoice?.shippingInfo?.shippingMethod
                            .toLowerCase()
                            .includes("pickup")
                            ? invoice?.shippingCost
                            : "0"}
                          <br />
                          <strong>Total: </strong>${invoice?.totalAmount}
                          <br />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <p>
                          <strong className="mr-2">Order Notes </strong> <br />
                          {invoice?.shippingInfo?.orderNotes ?? "-"}
                        </p>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {invoice?.subPrescriptions?.map((pres, i) => {
                //console.log(subInvoices[i]?.id, pres, subInvoices[i]?.totalAmount);
                return (
                  <Row key={pres + "--" + i}>
                    <Col lg="12">
                      <Card className="p-3">
                        <CardBody className="font-size-16">
                          <Row>
                            <Col sm="6" className="px-3">
                              <strong>{subInvoices[i]?.clinicName}</strong>
                              <br className="mt-3" />
                              {clinicAddress1}
                              {clinicAddress2 && (
                                <>
                                  <br />
                                  {clinicLocationName},
                                </>
                              )}
                              <br />
                              <br />
                              {clinicCity}, {clinicState}
                              <br />
                              {clinicZipcode}
                            </Col>
                            <Col sm="6" className="text-sm-end">
                              <strong>Invoice Number: </strong>
                              {subInvoices[i]?.invoiceNumber}
                              <br />
                              <strong>Invoice Date: </strong>
                              {dateFormatterMountainTime(
                                subInvoices[i]?.prescriptionDate
                              )}
                            </Col>
                          </Row>
                          <hr />
                          <Row className="py-3">
                            <Col sm="12">
                              <strong>
                                <p>
                                  {subPatients[i]?.length > 1
                                    ? "Patients:"
                                    : "Patient:"}
                                </p>
                              </strong>
                              <div
                                id="patientnames"
                                dangerouslySetInnerHTML={{
                                  __html: renderPatientNames(
                                    subInvoices[i]?.id,
                                    i
                                  ),
                                }}
                              ></div>
                              {subInvoices[i]?.trackingNumber && (
                                <p>
                                  Tracking Number:{" "}
                                  {subInvoices[i]?.trackingNumber}
                                </p>
                              )}
                            </Col>
                          </Row>
                          {/* <Row>
                            <strong className="my-3">Address information:</strong>
                            <Row></Row>
                            <Col sm="6">
                              <strong>Billed To</strong>
                              <br />
                              {creditCard?.cardHolderName}
                              <br />
                              Credit Card: **** **** ****{" "}
                              {creditCard?.creditCardNumber?.substring(creditCard?.creditCardNumber.length - 4)}
                              <br />
                              Expiry:{" "}
                              {moment(creditCard?.cardExpirationDate?.replace(/\s/g, ""), "MM-YYYY").format("MM/YY")}
                              <br />
                              {creditCard?.cardHolderAddressLine1}
                              <br />
                              {creditCard?.cardHolderAddressLine2}
                              <br />
                              {creditCard?.cardHolderCity}, {creditCard?.cardHolderState}
                              <br />
                              {creditCard?.cardHolderZipcode}{" "}
                            </Col>
                            <Col sm="6" className="text-sm-start"></Col>
                          </Row> */}
                          <div className="py-2 mt-4">
                            <strong>Products:</strong>
                          </div>
                          <div className="table-responsive">
                            <Table className="mb-5">
                              <thead>
                                <tr>
                                  <th className="text-nowrap">No.</th>
                                  <th style={{ width: 200 }}>Patient Name</th>
                                  <th style={{ width: 200 }}>Product Name</th>
                                  <th>RX Number</th>
                                  <th>RX Status</th>
                                  <th>Sig</th>
                                  <th>Info</th>
                                  <th>Last Office Visit</th>
                                  <th>Reason for compounded Medication</th>
                                  <th>Patient Dx</th>
                                  <th>Unit Price($)</th>
                                  <th>Discounted Price($)</th>
                                  <th>Total Price($)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {renderProducts(i, subInvoices[i]?.id)}
                              </tbody>
                            </Table>
                          </div>
                          <Row className="my-3">
                            <Col xs={3} md={2}>
                              Priority Order
                            </Col>
                            <Col xs={3} md={2} className="text-start">
                              <strong>
                                {subInvoices[i]?.shippingInfo?.isRushOrder
                                  ? "Yes"
                                  : "No"}
                              </strong>
                            </Col>
                          </Row>
                          {subInvoices[i]?.shippingInfo?.isRushOrder ? (
                            <>
                              <Row className="my-3">
                                <Col xs={3} md={2}>
                                  Signature Required
                                </Col>
                                <Col xs={3} md={2} className="text-start">
                                  <strong>
                                    {subInvoices[i]?.shippingInfo
                                      ?.isSignatureRequired
                                      ? "Yes"
                                      : "No"}
                                  </strong>
                                </Col>
                                <Col md={4} className="d-none d-md-block" />
                                <Col xs={3} md={2} className="text-end">
                                  Priority Order Fee
                                </Col>
                                <Col xs={3} md={2} className="text-end">
                                  <strong>
                                    ${" "}
                                    {
                                      subInvoices[i]?.shippingInfo
                                        ?.rushOrderCost
                                    }
                                  </strong>
                                </Col>
                              </Row>
                              {subInvoices[i]?.creditRequested &&
                              subInvoices[i]?.creditRequested != 0 ? (
                                <Row>
                                  <Col
                                    xs={{ size: 3, offset: 6 }}
                                    md={{ size: 2, offset: 8 }}
                                    className="text-end"
                                  >
                                    <h4 className="m-0">
                                      <strong>Credit Used</strong>
                                    </h4>
                                  </Col>
                                  <Col xs={3} md={2} className="text-end">
                                    <strong>
                                      ${" "}
                                      {parseFloat(
                                        subInvoices[i]?.creditRequested
                                      ).toFixed(2)}
                                    </strong>
                                  </Col>
                                </Row>
                              ) : null}
                              <Row>
                                <Col
                                  xs={{ size: 3, offset: 6 }}
                                  md={{ size: 2, offset: 8 }}
                                  className="text-end"
                                >
                                  <h4 className="m-0">
                                    <strong>Sub Total</strong>
                                  </h4>
                                </Col>
                                <Col xs={3} md={2} className="text-end">
                                  <strong>
                                    ${" "}
                                    {parseFloat(
                                      subInvoices[i]?.totalAmount -
                                        subInvoices[i]?.shippingCost
                                    ).toFixed(2)}
                                  </strong>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row className="my-3">
                              <Col xs={3} md={2}>
                                Signature Required
                              </Col>
                              <Col xs={3} md={2} className="text-start">
                                <strong>
                                  {subInvoices[i]?.shippingInfo
                                    ?.isSignatureRequired
                                    ? "Yes"
                                    : "No"}
                                </strong>
                              </Col>
                              <Col md={4} className="d-none d-md-block" />
                              <Col xs={3} md={2} className="text-end">
                                <strong>Sub Total</strong>
                              </Col>
                              <Col xs={3} md={2} className="text-end">
                                <h4 className="m-0">
                                  <strong>
                                    ${" "}
                                    {parseFloat(
                                      subInvoices[i]?.totalAmount -
                                        subInvoices[i]?.shippingCost
                                    ).toFixed(2)}
                                  </strong>
                                </h4>
                              </Col>
                            </Row>
                          )}
                          {subInvoices[i]?.signatureFileUrl && (
                            <div className="my-5 mx-1">
                              <p className="bold">Signature</p>
                              <div className="flexJCAC justify-content-start">
                                <img
                                  src={subInvoices[i]?.signatureFileUrl}
                                  width={180}
                                />
                              </div>
                              <p>
                                Signed by:{" "}
                                {subProviders[i]?.firstName +
                                  " " +
                                  subProviders[i]?.lastName}{" "}
                                on{" "}
                                {dateFormatterMountainTime(
                                  subInvoices[i]?.prescriptionDate
                                )}
                              </p>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                );
              })}
            </div>
          )}
          <Row className="text-center">
            <Col>
              <button
                onClick={() => props.history.goBack()}
                className="text-center pinkButton"
              >
                Back
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InvoiceDetail;

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { flatten, includes, set } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  NESTED_BULK_INVOICE_TABLE_HEADER,
  NESTED_BULK_TABLE_HEADER,
  NESTED_TABLE_HEADER,
} from "../../utils";
import { RenderRxInput } from "../RenderRxInput";
import { RenderRefillsInput } from "../RenderRefillsInput";
import { PrescriptionStatusDD } from "../dropdowns";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { useChangeRxStatusMutation } from "rtk";
import Spinner from "components/spinner";
import { RX_STATUS_COMPLETED } from "rtk/rtkConstant";

function MuiNestedTable({
  originalRow = {},
  showMarkAllBtn,
  pageName,
  readOnly,
  filterRxStatus,
  setCustomError,
  setCustomSuccess,
  refetchList
}) {
  const {
    isBulkOrder,
    rxPadProducts,
    products,
    patientsDetail,
    patientsProducts,
    paymentStatus,
    subPrescriptions = [],
    subPrescriptionList = [],
  } = originalRow;
  const [rxStatusMutate, { isLoading: statusLoading, error: statusError, isSuccess: statusSuccess }] =
    useChangeRxStatusMutation();
  const statusErrorMessage = useMemo(
    () => statusError?.data?.message || "",
    [statusError?.data?.message]
  );
  
  const statusSuccessMessage = useMemo(
    () => statusSuccess || "",
    [statusSuccess]
  );

  useEffect(() => {
    if (statusSuccessMessage) {
      setCustomSuccess(statusSuccessMessage);
      setCustomError("");
    }
  }, [statusSuccessMessage, setCustomSuccess]);
  
  useEffect(() => {
    if (statusErrorMessage) {
      setCustomError(statusErrorMessage);
      setCustomSuccess("");
    }
  }, [statusErrorMessage, setCustomError]);

  const markAllReceived = useCallback(() => {
    rxStatusMutate({
      isToReceivedAll: true,
      rxStatus: RX_STATUS_COMPLETED[0],
      bulkPresId: originalRow?.id,
      prescriptionId: originalRow?.id,
      pageName,
    });
  }, [originalRow?.id, pageName, rxStatusMutate]);

  const mainPrescriptionId = useMemo(() => originalRow?.id, [originalRow?.id]);

  const prescriptionListingData = useCallback(
    (
      item,
      customItems,
      rxStatusPatient = "",
      rxNumberPatient = "",
      reFillsNumberPatient = "",
      patientObj = {},
      invoice = {},
      subPrescriptionId = mainPrescriptionId
    ) => {
      const tableRowData = [];
      const { patientId = "", patientName = "" } = patientObj;
      const { showInvoice = false, invoiceNumber = "" } = invoice;
      item.forEach((data) => {
        const rxStatus = rxStatusPatient || data?.rxStatus;
        const rxNumber = rxNumberPatient || data?.rxNumber;
        const refills = reFillsNumberPatient || data?.refills;
        const dataObj = {
          id: data?._id,
          subPrescriptionId,
          name: data.name,
          rxStatus,
          rxNumber,
          refills,
          family: data?.family,
        };
        if (showInvoice) {
          dataObj["invoiceNumber"] = invoiceNumber;
        }
        if (patientId) {
          dataObj["patientName"] = patientName;
          dataObj["patientId"] = patientId;
        }
        tableRowData.push(dataObj);
      });
      customItems?.forEach((product) => {
        const rxStatus = rxStatusPatient || product?.rxStatus;
        const rxNumber = rxNumberPatient || product.rxNumber;
        const refills = reFillsNumberPatient || product?.refills;
        const dataObj = {
          family: product?.family,
          name: product.name,
          subPrescriptionId,
          rxStatus,
          rxNumber,
          refills
        };
        dataObj["isCustomOrder"] = true;
        if (showInvoice) {
          dataObj["invoiceNumber"] = invoiceNumber;
        }
        if (patientId) {
          dataObj["patientName"] = patientName;
          dataObj["patientId"] = patientId;
        }
        tableRowData.push(dataObj);
      });

      return tableRowData;
    },
    [mainPrescriptionId]
  );

  const prescriptionBulkProduct = useCallback(
    (
      isBulkOrder,
      patientsProducts,
      patientsDetail,
      customItem,
      item = [],
      invoice = undefined,
      subPrescriptionId = mainPrescriptionId
    ) => {
      let productTableData = [];
      if (isBulkOrder) {
        if (patientsProducts?.length) {
          productTableData = patientsProducts.map((patientProduct) => {
            const patientObj = {
              patientId: patientProduct?.patientId,
              patientName: patientProduct?.patientName,
            };
            const productInfo = patientProduct.products?.[0];
            const filterProduct = item.filter(
              (itemRow) => itemRow.productId === productInfo?.productId
            );
            const rxStatusPatient =
              productInfo?.rxStatus || filterProduct?.rxStatus;
            const rxNumberPatient =
              productInfo?.rxNumber || filterProduct?.rxNumber;
            return prescriptionListingData(
              filterProduct,
              customItem,
              rxStatusPatient,
              rxNumberPatient,
              patientObj,
              invoice,
              subPrescriptionId
            );
          });
        } else if (patientsDetail?.length) {
          productTableData = patientsDetail.map((patient) => {
            const patientObj = {
              patientId: patient?.id,
              patientName:
                (patient?.firstName || "") + " " + (patient?.lastName || ""),
            };

            return prescriptionListingData(
              item,
              customItem,
              "",
              "",
              patientObj,
              invoice,
              subPrescriptionId
            );
          });
        }
      } else {
        const patient = patientsDetail?.[0] ?? {};
        const patientObj = {
          patientId: patient?.id ?? "-",
          patientName:
            (patient?.firstName || "") + " " + (patient?.lastName || "") ?? "",
        };
        productTableData = prescriptionListingData(
          item,
          customItem,
          "",
          "",
          patientObj,
          invoice,
          subPrescriptionId
        );
      }
      return flatten(productTableData);
    },
    [mainPrescriptionId, prescriptionListingData]
  );

  const nestedTableData = useMemo(() => {
    let bulkTableData = [];
    if (isBulkOrder) {
      if (subPrescriptionList?.length) {
        bulkTableData = subPrescriptions.map((subPrescription, i) => {
          const item1 = subPrescriptionList?.filter(
            (subPres) => subPres.id === subPrescription
          )?.[0];
          const customItems = item1?.rxPadProducts;
          const invoice = {
            showInvoice: true,
            invoiceNumber: item1?.invoiceNumber,
          };
          return prescriptionBulkProduct(
            item1?.isBulkOrder,
            item1?.patientsProducts,
            item1?.patientsDetail,
            customItems,
            item1?.products,
            invoice,
            subPrescription // Sub Prescription Id
          );
        });
      } else if (patientsDetail?.length) {
        bulkTableData = prescriptionBulkProduct(
          isBulkOrder,
          patientsProducts,
          patientsDetail,
          rxPadProducts,
          products
        );
      }
    } else {
      bulkTableData = prescriptionListingData(products, rxPadProducts);
    }
    return flatten(bulkTableData);
  }, [
    isBulkOrder,
    patientsDetail,
    patientsProducts,
    prescriptionBulkProduct,
    prescriptionListingData,
    products,
    rxPadProducts,
    subPrescriptionList,
    subPrescriptions,
  ]);

  const nestedColumn = isBulkOrder
    ? subPrescriptions?.length > 0
      ? NESTED_BULK_INVOICE_TABLE_HEADER
      : NESTED_BULK_TABLE_HEADER
    : NESTED_TABLE_HEADER;

  const renderStatus = useMemo(
    () => ({
      accessorKey: "rxStatus",
      headerStyle: { width: "240px" },
      header: () => (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginRight={"20px"}
        >
          <span>Status</span>
          {showMarkAllBtn && (
            <Tooltip title="Mark All Received">
              <IconButton
                onClick={markAllReceived}
                style={{ width: 30, height: 30 }}
              >
                <PlaylistAddCheckIcon
                  color="primary"
                  style={{ width: 30, height: 30 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      cell: (props) => {
        if (readOnly) {
          return props?.getValue() ?? "-";
        }
        const dataRow = props.row.original;
        if (includes(['AdminPendingPrescription'], pageName)) {
          return (
            <PrescriptionStatusDD
              bulkPresId={mainPrescriptionId}
              presId={dataRow?.subPrescriptionId}
              rowId={dataRow?.id}
              pageName={pageName}
              patientId={dataRow?.patientId}
              rxStatus={props.getValue()}
              readOnly={dataRow?.isCustomOrder}
              setCustomError={setCustomError}
              setCustomSuccess={setCustomSuccess}
              paymentStatus={paymentStatus}
              customError={statusErrorMessage}
              refetchList={refetchList}
            />
          );
        }else{
          return 'Received';
        }
      },
    }),
    [
      markAllReceived,
      statusErrorMessage,
      mainPrescriptionId,
      pageName,
      paymentStatus,
      readOnly,
      setCustomError,
      setCustomSuccess,
      showMarkAllBtn,
    ]
  );

  const renderRxInput = useMemo(() => {
    
    return {
      header: "RX Number",
      accessorKey: "rxNumber",
      headerStyle: { minWidth: "200px" },
      cell: (props) => {
        if (readOnly) {
          return props?.getValue?.() ?? "-";
        }
        const dataRow = props.row.original;
        if (includes(['AdminPendingPrescription'], pageName)) {
          return  <RenderRxInput
              bulkPresId={mainPrescriptionId}
              patientId={dataRow?.patientId}
              setCustomError={setCustomError}
              setCustomSuccess={setCustomSuccess}
              rxNumber={dataRow.rxNumber}
              productId={dataRow?.id}
              pageName={pageName}
              presId={dataRow?.subPrescriptionId}
            />
      } else {
        return dataRow?.rxNumber;
      }
    }
    };

  }, [mainPrescriptionId, pageName, readOnly, setCustomError, setCustomSuccess]);

  const renderRefillsInput = useMemo(() => {
    return {
      header: "Refills",
      accessorKey: "refills",
      headerStyle: { minWidth: "200px" },
      cell: (props) => {
        if (readOnly) {
          return props?.getValue?.() ?? "-";
        }
        const dataRow = props.row.original;
        if (includes(['AdminPendingPrescription'], pageName)) {
          return <RenderRefillsInput
              bulkPresId={mainPrescriptionId}
              patientId={dataRow?.patientId}
              setCustomError={setCustomError}
              setCustomSuccess={setCustomSuccess}
              refills={dataRow.refills}
              productId={dataRow?.id}
              pageName={pageName}
              presId={dataRow?.subPrescriptionId}
            />
        } else{
          return dataRow?.refills;
        }
      },
    };
  }, [mainPrescriptionId, pageName, readOnly, setCustomError, setCustomSuccess]);

  const extraCell = useMemo(
    () => ({
      header: "",
      accessorKey: "extraCell",
    }),
    []
  );

  const reactTable = useReactTable({
    data: nestedTableData,
    columns: [...nestedColumn, renderStatus, renderRxInput, renderRefillsInput, extraCell].filter(
      Boolean
    ),
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box sx={{ margin: 1 }}>
      {statusLoading && <Spinner />}
      <TableContainer>
        <Table size="medium">
          <TableHead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell
                      key={header.id}
                      style={
                        header.column.columnDef.headerStyle ?? { minWidth: 150 }
                      }
                    >
                      <Typography variant="tableHeader" noWrap>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map((row, index) => {
              const disableRow =
                Boolean(filterRxStatus) &&
                row?.original.rxStatus !== filterRxStatus;
              return (
                <TableRow
                  key={`${row.id}_${index}`}
                  className={classNames({ "disable-row": disableRow })}
                >
                  {row.getVisibleCells?.().map((cell) => {
                    const isMedicationColumn =
                      cell.column.columnDef.header === "Medication";
                    return (
                      <TableCell
                        key={cell.id}
                        // width should be same as we mention in Table Columns
                        style={
                          isMedicationColumn
                            ? { overflow: "auto", maxWidth: 350 }
                            : {}
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default React.memo(MuiNestedTable);

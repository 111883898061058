import { Link } from "react-router-dom";

const DEFAULT_SORTED = [{ dataField: "id", order: "asc" }];
const getTableHeader = (setSelectedPatientId, setIsModalOpen) => [
  { text: "id", dataField: "id", hidden: true },
  { 
    text: "Patient name", 
    dataField: "patientName",
    formatter: (cellContent) => (
      <div>{(cellContent.trim().length > 0 ? cellContent: '-')}</div>) 
  },
  { 
    text: "Clinic",
    dataField: "clinicName",
    formatter: (cellContent) => (
      <div>{cellContent || '-'}</div>) 
  },
  {
    text: "Clinic Location",
    dataField: "clinicLocationName",
    formatter: (cellContent) => (
      <div>{cellContent || '-'}</div>)
  },
  {
    text: "Patient info",
    dataField: "info",

    formatter: (cellContent) => (
      console.log(cellContent),
      <div>
        <div className="d-flex">
          <p className="smallText semiBold m-0">
            DOB: <span className="text-muted normalText">{cellContent[0]}</span>
          </p>
          <p className="smallText semiBold m-0 px-3">
            Gender:{" "}
            <span className="text-muted normalText">{cellContent[1]}</span>
          </p>
        </div>
        <p className="smallText semiBold m-0">
          Email: <span className="text-muted normalText">{cellContent[2]}</span>
        </p>
        <p className="smallText semiBold m-0">
          Phone: <span className="text-muted normalText">{cellContent[3]}</span>
        </p>
      </div>
    ),
  },

  {
    text: "Medication History",
    dataField: "medicationHistory",
    formatter: (cellContent, row) => (
      <div>
        <p className="smallText semiBold m-0">
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedPatientId(row.id);
              setIsModalOpen(true);
            }}
          >
            <i className="fa fa-eye"></i> View
          </Link>
        </p>
      </div>
    ),
  },
];

const CLINIC_PRESCRIPTION_ACTIONS = {
  text: "Actions",
  dataField: "actions",
  headerStyle: () => {
    return { width: "80px", textAlign: "center" };
  },
  formatter: (cellContent, row) => (
    <Link to={"/patient-profile/" + row.id} className="pinkButton">
      Profile
    </Link>
  ),
};

export { DEFAULT_SORTED, getTableHeader, CLINIC_PRESCRIPTION_ACTIONS };

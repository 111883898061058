import ProductFamilyDD from 'components/dropdowns/productFamilyDD';
import React from 'react';
import { FormGroup, Alert, Label, Modal, Row, Container, Col, Card, CardBody, CardFooter } from 'reactstrap';
import useProductDiscountModal from './index.hook';
import Lottie from 'react-lottie';
import Spinner from 'components/spinner';

const ProductDiscountModal = (props) => {
  const { title = '', isTierModal = false } = props;
  const { showModal, closeModal, onSubmit, register, handleSubmit, errors, success, complete, error, showSpinner } = useProductDiscountModal(props);

  return (
    <Modal isOpen={showModal} size='md' centered={true} className='smallHeightmodal add-product-modal'>
      <Container>
        <form id='registerForm' className='form-horizontal product-form mt-2' onSubmit={handleSubmit(onSubmit)}>
          <button type='button' onClick={() => closeModal()} className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
          {showSpinner ? <Spinner /> : null}
          {complete ? (
            success ? (
              <div className='text-center'>
                {error ? (
                  <p className='my-4' style={{ color: 'red' }}>
                    {error}
                  </p>
                ) : null}
                {success ? (
                  <p className='my-4' style={{ color: 'green' }}>
                    {success}
                  </p>
                ) : null}
              </div>
            ) : null
          ) : (
            <div className='modal-body flexJCAC flex-column text-center'>
              <Row className='justify-content-center'>
                <h3>{`${isTierModal ? 'Tier' : 'Bulk'} discount for ${title}`}</h3>
              </Row>
              <Card className='overflow-hidden'>
                <CardBody>
                  {isTierModal && (
                    <Row className={'mt-4 mb-4'}>
                      <Col md={12}>
                        <FormGroup>
                          <Label htmlFor={'family'} className='form-label'>
                            {'Select Product Category'}
                          </Label>
                          <ProductFamilyDD name={'family'} register={register} />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row className={'mt-4 mb-4'}>
                    <Col md={12}>
                      <FormGroup>
                        <Label htmlFor={'discount'} className='form-label'>
                          {'Enter Discount Percentage'}
                        </Label>
                        <input name={'discount'} className={`form-control`} type={'text'} ref={register({ required: true })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  {!isTierModal && (
                    <Row className={'mt-4 mb-4'}>
                      <Col md={12}>
                        <div className={'bulk-product-msg'}>Note that this discount will be applied to all products for this clinic.</div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
                <CardFooter>
                  <div className={'bulk-discount-card-footer'}>
                    <button type={'submit'} className={'yellowButton'}>{`Apply ${isTierModal ? 'tier' : 'bulk'} discount`}</button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          )}
        </form>
      </Container>
    </Modal>
  );
};

export default ProductDiscountModal;

import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import AdminNavbar from "../../../../components/AdminNavbar";
import { AdminPendingPrescription } from "../AdminPendingPrescription";
import RenderPrescriptionMeta from "../components/render_meta_tags";
import RenderPrescriptionButtons from "../components/render_prescription_buttons";
import { PRESCRIPTION_CARD_LABELS, PRESCRIPTION_TYPES, PRESCRIPTION_URL } from "../constants";
import { useRouteMatch } from "react-router-dom";
import { AdminArchivedPrescription } from "../AdminArchivedPrescription";
import { AdminRxWorkflowPrescription } from "../AdminRxWorkflowPrescription";
import "./index.scss";

const PrescriptionsSection = () => {
  const { path } = useRouteMatch();
  const [prescriptionType, setPrescriptionType] = useState(PRESCRIPTION_TYPES.pending);

  useEffect(() => {
    if (path) {
      if (path === PRESCRIPTION_URL[PRESCRIPTION_TYPES.pending]) {
        setPrescriptionType(PRESCRIPTION_TYPES.pending);
      } else if (path === PRESCRIPTION_URL[PRESCRIPTION_TYPES.history]) {
        setPrescriptionType(PRESCRIPTION_TYPES.history);
      } else if (path === PRESCRIPTION_URL[PRESCRIPTION_TYPES.archive]) {
        setPrescriptionType(PRESCRIPTION_TYPES.archive);
      }
    }
  }, [path]);

  return (
    <>
      <AdminNavbar />
      <div className="page-content">
        <RenderPrescriptionMeta type={prescriptionType} />
        <Container fluid>
          <h4 className="p text-muted">{PRESCRIPTION_CARD_LABELS[prescriptionType]}</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <RenderPrescriptionButtons type={prescriptionType} />
                  <TabContent activeTab={prescriptionType}>
                    <TabPane tabId={PRESCRIPTION_TYPES.pending}>
                      <AdminPendingPrescription />
                    </TabPane>
                    <TabPane tabId={PRESCRIPTION_TYPES.history}>
                      <AdminRxWorkflowPrescription />
                    </TabPane>
                    <TabPane tabId={PRESCRIPTION_TYPES.archive}>
                      <AdminArchivedPrescription />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default React.memo(PrescriptionsSection);

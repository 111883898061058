import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Lottie from "../../components/lottie";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Spinner from "../../components/spinner";
import { Link } from "react-router-dom";
import GRHLogo from '../../assets/images/logo.svg';
import { logout } from "utils";
const VerifyEmail = (props) => {
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);

  const [isVerified, setVerification] = useState(false);

  useEffect(() => {
    logout();
    setShowSpinner(true);
    axiosConfig
      .post("/accounts/verify-email", {
        token: props.match.params.token,
      })
      .then(async (response) => {
        await setVerification(true);
        await setShowSpinner(false);
      })
      .catch(async (error) => {
        await setVerification(false);
        await setError(error.response.data.message);
        await setShowSpinner(false);
        await errorResponse(error);
      });
  }, [props.showProfileModal]);

  return (
    <div>
      <MetaTags>
        <title>Verify Email | Get Royal Health</title>
      </MetaTags>
      {/* <LoggedOutNavbar /> */}
      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden auth-card">
                  <CardBody className="pt-0">
                    <div className="text-center mt-4">
                      <img src={GRHLogo} alt="get-royal-health" width={60} height={60}/>
                    </div>
                    {isVerified ? (
                      <div className="m-sm-0 my-0 mx-4 p-sm-5 p-4 text-center">
                        <Lottie name="success-check" />
                        <p className="my-4">Email Verified Successfully!</p>
                        <Link to="/login" className="pinkButton">
                          Login
                        </Link>
                      </div>
                    ) : (
                      <div className="m-sm-0 my-0 mx-4 p-sm-5 p-4 text-center">
                        <Lottie name="sad-lottie" />
                        <p className="my-4">{error}</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;

import { combineReducers } from "@reduxjs/toolkit";
import {
  AdminApi,
  AuthApi,
  ClinicApi,
  DashboardApi,
  InvoiceApi,
  PatientApi,
  StaffApi,
  UploadsApi,
} from "../rtkApi";
import { AuthSliceReducer } from "../rtkSlices";

const RootReducer = combineReducers({
  AuthSlice: AuthSliceReducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [DashboardApi.reducerPath]: DashboardApi.reducer,
  [ClinicApi.reducerPath]: ClinicApi.reducer,
  [AdminApi.reducerPath]: AdminApi.reducer,
  [InvoiceApi.reducerPath]: InvoiceApi.reducer,
  [PatientApi.reducerPath]: PatientApi.reducer,
  [StaffApi.reducerPath]: StaffApi.reducer,
  [UploadsApi.reducerPath]: UploadsApi.reducer,  
});

export default RootReducer;

import React, { Component } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Cookies from "universal-cookie";
import DriverLicenseStatusDD from "../../../components/dropdowns/driverLicenseStatusDD";
import EthnicityDD from "../../../components/dropdowns/ethnicityDD";
import MaritalStatusDD from "../../../components/dropdowns/maritalStatusDD";
import PhysicianDD from "../../../components/dropdowns/physicianDD";
import RaceDD from "../../../components/dropdowns/raceDD";
import SexDD from "../../../components/dropdowns/sexDD";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import { decrypt } from "cryptofunc";
import MaskedInput from 'react-text-mask'
// import ProviderLocationDD from "components/dropdowns/providerLocationDD";
// import { USER_ROLES } from "utils";
const statesOfUSA = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

class PatientInfoNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileImageSrc: null,
      clinicSide: new Cookies()
        .get("user_role")
        ?.toLowerCase()
        ?.includes("clinic"),
      country: "United States",
      state: "",
      city: "",
      cardHolderName: "",
      creditCardNumber: "",
      cardExpirationDate: "",
      cvv: "",
      phoneNumber: "",
      mobileNumber: ""
    };
  }

  async uploadImage(e) {
    const image = e.target.files[0];
    var url = URL.createObjectURL(image);

    const formData = new FormData();

    formData.append("file", image);

    await axiosConfig
      .post("uploads/uploadFile", formData, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
          "Content-Type": "multipart/form-data;",
        },
      })
      .then(async (response) => {
        url = response.data.accessUrl;
      })
      .catch((error) => {
        errorResponse(error);
      });
    this.setState({
      profileImageSrc: (
        <img className="staffProfileImgCircle" src={url} alt="" />
      ),
    });
    this.props.setProfileImageUpdated();
    this.props.setProfileImageURL(url); //image
  }

  async uploadLicense(e) {
    this.props.setDriverLicenseUpdated();
    this.props.setDriverLicenseURL(e.target.files[0]);
    // console.log(e.target.files);
  }
  async uploadLabwork(e) {
    const fileList = e.target.files;
    const fileArray = Array.from(fileList);
    this.props.setLabWorkUpdated();
    this.props.setLabWorkURL(fileArray);
    // console.log("lab:",fileArray);
  }
  async uploadPhysicals(e) {
    const fileList = e.target.files;
    const fileArray = Array.from(fileList);
    this.props.setPhysicalsUpdated();
    this.props.setPhysicalsURL(fileArray);
    // console.log("phy:",fileArray)
  }
  async uploadSoap(e) {
    const fileList = e.target.files;
    const fileArray = Array.from(fileList);
    this.props.setSoapNotesUpdated();
    this.props.setSoapNotesURL(fileArray);
    // console.log(e.target.files)
  }
  async uploadDocuments(e) {
    const fileList = e.target.files;
    const fileArray = Array.from(fileList);
    this.props.setDocumentsUpdated();
    this.props.setDocumentsURL(fileArray);
    // console.log(e.target.files)
  }

  handleStateChange = (event) => {
    this.setState({ state: event.target.value });
  };

  handleCityChange = (event) => {
    this.setState({ city: event.target.value });
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  async componentDidMount() {
    await this.setState({
      profileImageSrc: this.props.patient ? (
        <img
          className="staffProfileImgCircle"
          src={
            this.props.patient.patientImageUrl
              ? this.props.patient.patientImageUrl
              : null
          }
          alt=""
        />
      ) : null,
    });
  }

  render() {
    var patient = this.props.patient;
    return (
      <div className={`${this.props.step === 1 ? "d-block" : "d-none"}`}>
        <Row>
          <div className="mb-4 flexJCAC flex-column">
            {this.state.profileImageSrc === null ? (
              <img className="staffProfileImgCircle" src={null} alt="" />
            ) : (
              this.state.profileImageSrc
            )}
            {this.state.profileImageSrc && (
              <i
                className="fas fa-trash redText"
                onClick={() => {
                  return (
                    this.props.setProfileImageURL(""),
                    this.props.setRemoveProfileImageURL(true),
                    this.setState({ profileImageSrc: null })
                  );
                }}
              ></i>
            )}
            <button
              className="pinkButton my-4"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("profilePhoto").click();
              }}
            >
              Upload Image
            </button>
            <Input
              accept=".jpg, .png, .jpeg"
              onChange={(e) => {
                this.uploadImage(e);
              }}
              name="profile_photo"
              className="form-control d-none"
              type="file"
              id="profilePhoto"
            />
          </div>
        </Row>
        <h2 className="mb-4">Personal Information</h2>
        <Row>
          {/* {new Cookies().get("user_role") === USER_ROLES.CLINIC_PROVIDER ||
          new Cookies().get("user_role") ===
            USER_ROLES.CLINIC_SUPPORT ? null : (
            <Col md={6} className="mb-4">
              <ProviderLocationDD
                required={true}
                updateClinicLocation={this.props.setClinicLocation.bind(this)}
                defaultValue={
                  patient
                    ? patient.clinicLocationId
                    : this.props.clinicLocationId
                }
                clinicId={patient ? patient.clinicId : this.props.clinicId}
              />
            </Col>
          )} */}
          {/* 
          <Col md={4}>
            <div className="form-check mb-3">
              <input
                defaultValue={patient ? patient.isEmployee : ""}
                onChange={(e) => {
                  this.props.isEmployee(e.target.checked);
                }}
                name="isEmployee"
                className="form-check-input"
                type="checkbox"
                id="isEmployee"
              />
              <label className="form-check-label mx-2" htmlFor="isEmployee">
                Is Employee
              </label>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="firstName" className="form-label">
                First name{" "}
                <span className="requiredStar">
                  {" "}
                  <span className="requiredStar">*</span>
                </span>
              </Label>
              <Input
                defaultValue={patient ? patient.firstName : ""}
                name="firstName"
                className="form-control"
                placeholder=""
                type="text"
                required={this.props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="middleName" className="form-label">
                Middle name
              </Label>
              <Input
                defaultValue={patient ? patient.middleName : ""}
                name="middleName"
                className="form-control"
                placeholder=""
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="lastName" className="form-label">
                Last name{" "}
                <span className="requiredStar">
                  {" "}
                  <span className="requiredStar">*</span>
                </span>
              </Label>
              <Input
                defaultValue={patient ? patient.lastName : ""}
                name="lastName"
                className="form-control"
                placeholder=""
                type="text"
                required={this.props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <MaritalStatusDD
              defaultValue={patient ? patient.martialStatus : ""}
              name="martialStatus"
              required={false}
            />
          </Col>
          <Col md={4} className="mb-4">
            <SexDD
              defaultValue={patient ? patient.sex : ""}
              name="sex"
              required={this.props.step === 1 ? true : false}
            />
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="dateOfBirth" className="form-label">
                Date of Birth{" "}
                <span className="requiredStar">
                  {" "}
                  <span className="requiredStar">*</span>
                </span>
              </Label>
              <input
                defaultValue={
                  this.props.dateOfBirth ? this.props.dateOfBirth : ""
                }
                name="dateOfBirth"
                className="form-control"
                type="date"
                required={this.props.step === 1 ? true : false}
              />
            </FormGroup>
          </Col>
        </Row>{" "}
        <h2 className="mb-4">Contact Information</h2>
        <Row>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="email" className="form-label">
                Email{" "}
                <span className="requiredStar">
                  {" "}
                  <span className="requiredStar">*</span>
                </span>
              </Label>
              <Input
                defaultValue={patient ? patient?.email : ""}
                name="email"
                className="form-control"
                placeholder="rose@gmail.com"
                type="text"
                required={true}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="phoneNumber" className="form-label">
                Phone Number
                <span className="requiredStar">
                  {" "}
                  <span className="requiredStar">*</span>
                </span>
              </Label>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                className="form-control"
                placeholder="(123) 344-2398"
                guide={false}
                defaultValue={patient ? patient?.phoneNumber : ""}
                required={true}
                name="phoneNumber"
                onChange={(e) => {
                  this.state.phoneNumber = e.target.value.replace(/\D/g, '')
                }}
                onBlur={(e) => {
                  this.state.phoneNumber = e.target.value.replace(/\D/g, '')
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="mobileNumber" className="form-label">
                Mobile number
              </Label>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                className="form-control"
                placeholder="(123) 344-2398"
                guide={false}
                defaultValue={patient ? patient?.mobileNumber : ""}
                name="mobileNumber"
                onChange={(e) => {
                  this.state.mobileNumber = e.target.value.replace(/\D/g, '')
                }}
                onBlur={(e) => {
                  this.state.mobileNumber = e.target.value.replace(/\D/g, '')
                }}
              />
              
            </FormGroup>
          </Col>
        </Row>
        {patient?.addresses.length > 0 ?
        patient?.addresses.map((address,i) =>(
          <>
          <Row key={address._id}>
          <Col md={12} className="mb-4">
            <FormGroup>
              <Label htmlFor="address1" className="form-label">
                <b>{(i+1)}- </b>
                Address{" "}
                {(i == 0) ? <span className="requiredStar"> {'*'} </span>: null}
              </Label>
              <textarea
                defaultValue={address ? address?.address : ""}
                name={`addresses[${address._id}][textarea]`}
                className="form-control"
                placeholder="32 wall street"
                required={i == 0}
                col={10}
              />
            </FormGroup>
          </Col>
          {/* <Col md={6} className="mb-4">
            <FormGroup>
              <Label htmlFor="address2" className="form-label">
                Address 2
              </Label>
              <textarea
                defaultValue={address ? address?.address2 : ""}
                name="textarea"
                className="form-control"
                placeholder=""
                col={10}
              />
            </FormGroup>
          </Col> */}
        </Row>
        <Row className="mb-4">
          <Col>
            <Label for="country">
              Country <span className="requiredStar">*</span>
            </Label>
            <Input
              type="select"
              name={`addresses[${address._id}][country]`}
              id="country"
              value={this.state.country}
              readOnly
            >
              <option>United States</option>
            </Input>
          </Col>
          <Col>
            <Label for="state">
              State {i == 0 ? <span className="requiredStar"> {'*'} </span>: null}
            </Label>
            <Input
              type="select"
              name={`addresses[${address._id}][state]`}
              id="state"
              defaultValue={address?.state}//{this.state.state}
              // onChange={this.handleStateChange}
              required={i == 0}
            >
              <option value="">Select State</option>
              {statesOfUSA.map((stateName, index) => (
                <option key={index} value={stateName}>
                  {stateName}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <Label for="city">
              City {i == 0 ? <span className="requiredStar"> {'*'} </span>: null}
            </Label>
            <Input
              type="text"
              name={`addresses[${address._id}][city]`}
              id="city"
              placeholder="City"
              defaultValue={address?.city}//{this.state.city}
              // onChange={this.handleCityChange}
              required={i == 0}
            />
          </Col>
          <Col>
            <Label for="city">
              Zip code {i == 0 ? <span className="requiredStar"> {'*'} </span>: null}
            </Label>
            <Input
              type="number"
              name={`addresses[${address._id}][zipcode]`}
              id="zipcode"
              placeholder="zipcode"
              defaultValue={address?.zipcode}
              required={i == 0}
            />
          </Col>
        </Row>
        </>
        )) :
        <>
          <Row >
          <Col md={12} className="mb-4">
            <FormGroup>
              <Label htmlFor="address1" className="form-label">
                Address{" "}
                <span className="requiredStar"> {'*'} </span>
              </Label>
              <textarea
                
                name={`addresses[new][textarea]`}
                className="form-control"
                placeholder="32 wall street"
                required={true}
                col={10}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Label for="country">
              Country <span className="requiredStar">*</span>
            </Label>
            <Input
              type="select"
              name={`addresses[new][country]`}
              id="country"
              value={this.state.country}
              readOnly
            >
              <option>United States</option>
            </Input>
          </Col>
          <Col>
            <Label for="state">
              State <span className="requiredStar"> {'*'} </span>
            </Label>
            <Input
              type="select"
              name={`addresses[new][state]`}
              id="state"
              required={true}
            >
              <option value="">Select State</option>
              {statesOfUSA.map((stateName, index) => (
                <option key={index} value={stateName}>
                  {stateName}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <Label for="city">
              City <span className="requiredStar"> {'*'} </span>
            </Label>
            <Input
              type="text"
              name={`addresses[new][city]`}
              id="city"
              placeholder="City"
              required={true}
            />
          </Col>
          <Col>
            <Label for="city">
              Zip code <span className="requiredStar"> {'*'} </span>
            </Label>
            <Input
              type="number"
              name={`addresses[new][zipcode]`}
              id="zipcode"
              placeholder="zipcode"
              required={true}
            />
          </Col>
        </Row>
        </>}
        <h2 className="mb-4">Credit Card</h2>
        {patient?.creditCards.length > 0 ? patient.creditCards.map((card,i)=>(        
        <Row key={card._id} className="mb-4">
          <Col md={12} className="mb-2">
            <FormGroup>
              <Label for="cardHolderName">
                <b>{i+1}</b>- Card Holder Name
              </Label>
              <Input
                type="text"
                name={`creditCards[${card._id}][cardHolderName]`}
                id="cardHolderName"
                placeholder="Card Holder Name"
                defaultValue={card?.cardHolderName}
                // onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="creditCardNumber">
                Card Number
              </Label>
              <Input
                type="text"
                name={`creditCards[${card._id}][creditCardNumber]`}
                id="creditCardNumber"
                placeholder="Card Number"
                defaultValue={card?.creditCardNumber ? decrypt(card?.creditCardNumber): null}
                // onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="cardExpirationDate">
                Expiry
              </Label>
              <MaskedInput
                mask={[ /\d/, /\d/, '/', /\d/, /\d/]}
                guide={false}
                className="form-control"
                id="cardExpirationDate"
                name={`creditCards[${card._id}][cardExpirationDate]`}
                placeholder="MM/YY"
                defaultValue={card.cardExpirationDate}
                // onChange={handleInputChange}
              />
              {/* <Input
                type="text"
                name={`creditCards[${card._id}][cardExpirationDate]`}
                id="cardExpirationDate"
                placeholder="MM/YY"
                defaultValue={card.cardExpirationDate}
                // onChange={this.handleInputChange}
              /> */}
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="cvv">
                CVC
              </Label>
              <Input
                type="text"
                name={`creditCards[${card._id}][cvv]`}
                id="cvv"
                placeholder="CVC"
                defaultValue={card?.cvv ? decrypt(card?.cvv): null}
                // onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>//{" "}
        )) : 
        <Row className="mb-4">
          <Col md={12} className="mb-2">
            <FormGroup>
              <Label for="cardHolderName">
                Card Holder Name
              </Label>
              <Input
                type="text"
                name={`creditCards[new][cardHolderName]`}
                id="cardHolderName"
                placeholder="Card Holder Name"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="creditCardNumber">
                Card Number
              </Label>
              <Input
                type="text"
                name={`creditCards[new][creditCardNumber]`}
                id="creditCardNumber"
                placeholder="Card Number"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="cardExpirationDate">
                Expiry (MM/YY)
              </Label>
              <Input
                type="text"
                name={`creditCards[new][cardExpirationDate]`}
                id="cardExpirationDate"
                placeholder="MM/YY"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="cvv">
                CVC
              </Label>
              <Input
                type="text"
                name={`creditCards[new][cvv]`}
                id="cvv"
                placeholder="CVC"
              />
            </FormGroup>
          </Col>
        </Row>//{" "}
        }
        {/* <Row>
          <Col md={6} className="mb-4">
            <FormGroup>
              <Label htmlFor="address1" className="form-label">
                Address line 1{" "}
                <span className="requiredStar">
                  {" "}
                </span>
              </Label>
              <textarea
                defaultValue={patient ? patient?.address1 : ""}
                name="textarea"
                className="form-control"
                placeholder="32 wall street"
                // required={true}
                col={10}
              />
            </FormGroup>
          </Col>
          <Col md={6} className="mb-4">
            <FormGroup>
              <Label htmlFor="address2" className="form-label">
                Address line 2
              </Label>
              <textarea
                defaultValue={patient ? patient?.address2 : ""}
                name="textarea"
                className="form-control"
                placeholder=""
                col={10}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Label for="city">
              City <span className="requiredStar">{' '}</span>
            </Label>
            <Input
              type="text"
              name="city"
              id="city"
              placeholder="City"
              value={this.state.city}
              onChange={this.handleCityChange}
              // required
            />
          </Col>
          <Col>
            <Label for="state">
              State <span className="requiredStar">{' '}</span>
            </Label>
            <Input
              type="select"
              name="state"
              id="state"
              value={this.state.state}
              onChange={this.handleStateChange}
              // required
            >
              <option value="">Select State</option>
              {statesOfUSA.map((stateName, index) => (
                <option key={index} value={stateName}>
                  {stateName}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <Label for="city">
              Zip code <span className="requiredStar">{' '}</span>
            </Label>
            <Input
              type="number"
              name="zipcode"
              id="zipcode"
              placeholder="zipcode"
              value={this.state?.zipcode}
              // required
            />
          </Col>
        </Row> */}
        
        {/* <p style={{ fontSize: "15px", fontWeight: "50px" }}>Documents</p>
        <Row>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="driver_license" className="form-label">
                Driver License
              </Label>
              <Input
                accept=".jpg, .png, .jpeg"
                onChange={(e) => {
                  this.uploadLicense(e);
                }}
                name="driver_license"
                className="form-control"
                type="file"
                id="driverLicense"
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="lab_work" className="form-label">
                Lab Work
              </Label>
              <Input
                multiple
                accept=".jpg, .png, .jpeg, .pdf"
                onChange={(e) => {
                  this.uploadLabwork(e);
                }}
                name="lab_work"
                className="form-control"
                type="file"
                id="labWork"
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="physicals" className="form-label">
                Physicals
              </Label>
              <Input
                multiple
                accept=".jpg, .png, .jpeg, .pdf"
                onChange={(e) => {
                  this.uploadPhysicals(e);
                }}
                name="physicals"
                className="form-control"
                type="file"
                id="Physicals"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="soap_notes" className="form-label">
                Soap Notes
              </Label>
              <Input
                multiple
                accept=".jpg, .png, .jpeg, .pdf"
                onChange={(e) => {
                  this.uploadSoap(e);
                }}
                name="soap_notes"
                className="form-control"
                type="file"
                id="soapNotes"
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="documents" className="form-label">
                Other Documents
              </Label>
              <Input
                multiple
                accept=".jpg, .png, .jpeg, .pdf"
                onChange={(e) => {
                  this.uploadDocuments(e);
                }}
                name="documents"
                className="form-control"
                type="file"
                id="Documents"
              />
            </FormGroup>
          </Col>
        </Row> */}
      </div>
    );
  }
}

export default PatientInfoNew;

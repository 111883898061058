import { sortBy } from "lodash";
import { AdminApi } from "../../rtkApi";
import { END_POINTS } from "../../rtkConstant";

const ProductQuery = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    getFamilyCategories: builder.query({
      query: () => END_POINTS.FAMILY_CATEGORIES,
      transformResponse: (response) => sortBy(response, ["family"]),
    }),
  }),
});
export const { useGetFamilyCategoriesQuery } = ProductQuery;

/* eslint-disable react-hooks/exhaustive-deps */
import { forEach, set, unset } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ROWS_LIMIT } from "utils";

function useAdminPrescriptionListing({ listingQuery }) {
  const [apiParam, setApiParam] = useState({});
  const [innerError, setInnerError] = useState("");
  const [innerSuccess, setInnerSuccess] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [prescriptionId, setPrescriptionId] = useState("");
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [rowLimit, SetRowLimit] = useState(ROWS_LIMIT[1]);
  const [disableFilter, setDisableFilter] = useState(true);
  const [
    fetchList,
    { data: { pagination, prescriptions } = { pagination: {}, prescriptions: [] }, error, isFetching },
  ] = listingQuery();

  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() =>  (pagination?.totalCount ?? 0), [pagination?.totalCount]);
  const filterRxStatus = useMemo(() => apiParam?.rxStatus ?? "", [apiParam?.rxStatus]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const toDate = useMemo(() => apiParam?.toDate ?? "", [apiParam.toDate]);
  const fromDate = useMemo(() => apiParam?.fromDate ?? "", [apiParam.fromDate]);
  const showLoading = useMemo(() => isFetching, [isFetching]);
  const errorMessage = useMemo(() => error?.data?.message || innerError || "", [innerError, error?.data?.message]);
  const successMessage = useMemo(() => innerSuccess || "", [innerSuccess]);

  useEffect(() => {
    if (!isFetching) {
      fetchList({ currentPage: 1, limit: rowLimit }, true);
    }
  }, []);

  const refetchList = useCallback(() => {
    const body = { currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    fetchList(body);
  }, [apiParam, fetchList, rowLimit, tablePage]);

  const setCustomError = useCallback((message) => {
    setInnerError(message);
  }, []);
  
  const setCustomSuccess = useCallback((message) => {
    setInnerSuccess(message);
  }, []);

  const toggleInvoiceModal = useCallback((id = "") => {
    setInvoiceId(id);
  }, []);

  const toggleDetailModal = useCallback((id = "", isReview = false) => {
    setPrescriptionId(id);
    setIsReviewModal(isReview);
  }, []);

  const updateFilterValue = useCallback((keyword, value) => {
    setApiParam((prev) => ({ ...prev, [keyword]: value }));
    setDisableFilter(!Boolean(value));
  }, []);

  const updateFilterRxStatus = useCallback((rxValue) => {
    updateFilterValue("rxStatus", rxValue);
  }, []);

  const clearFilter = useCallback(
    (e, keyName = "rxStatus") => {
      e.preventDefault();
      setApiParam((prev) => {
        fetchList({ currentPage: 1, limit: rowLimit });
        return { currentPage: 1, limit: rowLimit };
      });
      if (keyName === "rxStatus") {
        setDisableFilter(true);
      }
    },
    [fetchList, rowLimit]
  );

  const applyFilter = useCallback(
    (e) => {
      e.preventDefault();
      const body = { currentPage: 1, limit: rowLimit };
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, value);
        }
      });
      fetchList(body);
    },
    [apiParam, fetchList, rowLimit]
  );
  const updateKeyword = useCallback((e) => {
    e.preventDefault();
    setApiParam((prev) => ({ ...prev, keyword: e.target.value }));
  }, []);

  const changeRowLimit = useCallback(
    (e) => {
      const size = Number(e.target.value);
      SetRowLimit(size);
      const body = { ...apiParam, currentPage: 1, limit: size };
      if (!apiParam?.keyword) delete body.keyword;

      fetchList(body, true);
    },
    [apiParam, fetchList]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, currentPage: newPage + 1, limit: rowLimit };
        if (!apiParam?.keyword) delete body.keyword;

        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam]
  );

  return {
    rowLimit,
    tablePage,
    invoiceId,
    pagination,
    isReviewModal,
    showLoading,
    errorMessage,
    successMessage,
    totalRecords,
    prescriptions,
    disableFilter,
    searchKeyword,
    prescriptionId,
    filterRxStatus,
    refetchList,
    setCustomError,
    setCustomSuccess,
    applyFilter,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    toggleDetailModal,
    updateFilterValue,
    toggleInvoiceModal,
    updateFilterRxStatus,
    toDate,
    fromDate
  };
}

export default useAdminPrescriptionListing;

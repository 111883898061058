import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { PRODUCT_DATA, PRODUCT_STATUS } from "constants";
import ClinicProductList from "../ClinicProducts";
import axiosConfig, { errorResponse } from "utils/axiosConfig";
import Cookies from "universal-cookie";
import { useChangePricingGroupByClinicMutation, useLazyGetAllDiscountedProductsByClinicIdQuery } from "rtk";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ROWS_LIMIT } from "utils";

const useClinicProductList = (props) => {
  const clinicId = props.match.params.id;
  const [productList, setProductList] = useState([]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [clinic, setClinic] = useState({});
  const [showTierModal, setShowTierModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const sizePerPage = 10;
  const [page, setPage] = useState(1);
  const [rowLimit, SetRowLimit] = useState(ROWS_LIMIT[0]);
  const [fetchList, { data, isFetching, isError: isFetchProductsError, error: fetchProductsError }] =
    useLazyGetAllDiscountedProductsByClinicIdQuery();
  const [pagination, setPagination] = useState(data?.pagination ?? "");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [
    changeThePriceGroup,
    {
      data: priceGroupSuccess,
      isLoading,
      isSuccess: changePriceGroupSuccess,
      isError: isChangePriceError,
      error: changePriceError,
    },
  ] = useChangePricingGroupByClinicMutation();
  const showLoading = useMemo(() => isFetching || isLoading, [isFetching, isLoading]);
  const showError = useMemo(
    () => isFetchProductsError || isChangePriceError,
    [isFetchProductsError, isChangePriceError]
  );
  const errorMessage = useMemo(
    () => changePriceError?.data?.message || fetchProductsError?.data?.message,
    [changePriceError?.data?.message, fetchProductsError?.data?.message]
  );
  const showSuccess = useMemo(() => changePriceGroupSuccess, [changePriceGroupSuccess]);
  const successMessage = useMemo(() => priceGroupSuccess?.message, [priceGroupSuccess?.message]);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);

  const onSearchHandler = () => {};

  useEffect(async () => {
    /*GET PRODUCT DISCOUNT*/
    let res = "";
    if (!isFetching)
      res = await fetchList({
        clinicId: clinicId,
        currentPage: page,
        limit: 10,
      });
    if (res?.data) setPagination(res?.data?.pagination);

    /* */
  }, [page, showBulkModal, showTierModal, fetchList]);

  const handlePageChange = async (e) => {
    setPage(e);
  };
  const loadMore = useCallback(() => {
    const nextPage = pagination?.hasNextPage ? pagination?.currentPage + 1 : null;
    if (!isFetching && nextPage) {
      fetchList({
        clinicId: clinicId,
        currentPage: nextPage,
        limit: rowLimit,
      });
    }
  }, [fetchList, isFetching, pagination?.currentPage, pagination?.hasNextPage, rowLimit]);
  const changePriceGroup = async (cId, pG) => {
    var formData = {
      clinicId: cId,
      priceGroup: pG,
    };
    const arg = {
      payload: formData,
    };
    changeThePriceGroup(arg);
  };
  const onApply = (id, value, hide) => {
    var body = {
      productDiscountId: id,
      userId: "61ca14d6a449ca1bd51f6ff1",
      discountedPrice: parseFloat(value),
    };

    axiosConfig
      .post("admin/productDiscounts/editDiscountByProductDiscountId", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        // console.log(response.data);
      })
      .catch((err) => {
        setError("");
        setShowSpinner(false);
        errorResponse(err);
      });

    if (hide) {
      hide();
    }
  };
  const handleChange = useCallback(
    (e) => {
      const size = Number(e.currentTarget.textContent);
      SetRowLimit(size);
      fetchList({ clinicId: clinicId, currentPage: 1, limit: size });
    },
    [fetchList]
  );
  const toggle = useCallback(() => setDropdownOpen((prevState) => !prevState), []);
  return {
    productList,
    onApply,
    clinic,
    showTierModal,
    setShowTierModal,
    showBulkModal,
    setShowBulkModal,
    searchString,
    setSearchString,
    onSearchHandler,
    clinicId,
    showSpinner,
    pagination,
    setPage,
    page,
    sizePerPage,
    handlePageChange,
    changePriceGroup,
    success,
    error,
    showLoading,
    showError,
    showSuccess,
    successMessage,
    userInfo,
    userRole,
    data,
    loadMore,
    handleChange,
    dropdownOpen,
    toggle,
    ROWS_LIMIT,
    rowLimit,
  };
};

export default useClinicProductList;

import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "../rtkConstant";
import { baseQuery } from "./BaseApi";

const InvoiceApi = createApi({
  reducerPath: "InvoiceApi",
  tagTypes: [CACHE_TAGS.INVOICE, CACHE_TAGS.PRESCRIPTION],
  baseQuery: baseQuery,
  // baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
});

export default InvoiceApi;

import { useState, useMemo } from "react";
import { useAddClinicMutation } from "rtk/rtkEndpoints/Clinics";
import { useUploadFileMutation } from "rtk";
import { base64toFile } from "utils";
import { jwtTokensSelector } from "selectors";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import moment from "moment";
import { encrypt } from "cryptofunc";
const useAddClinicHook = (props) => {
  const authToken = useSelector(jwtTokensSelector);
  const userInfo = useSelector(userInfoSelector);
  const addClinic = window?.location?.pathname.includes("add-clinic");

  const [
    addClinicMutate,
    { error: addClinicError, isError: isAddClinicError, isLoading: isAddClinicLoading, isSuccess: addClinicComplete },
  ] = useAddClinicMutation();
  const [uploadMutate, { error: uploadError, isError: isUploadError, isLoading: isUploadLoading }] =
    useUploadFileMutation();
  const errorMessage = useMemo(
    () =>
      addClinicError?.data?.message ||
      uploadError?.data?.message ||
      
    [addClinicError, uploadError]
  );
  const showError = useMemo(
    () => isUploadError || isAddClinicError,
    [isUploadError, , isAddClinicError]
  );
  const showLoading = useMemo(
    () => isUploadLoading || isAddClinicLoading,
    [isUploadLoading, isAddClinicLoading]
  );
  const complete = useMemo(() => addClinicComplete, [addClinicComplete]);

  const { state } = useLocation();

  const isRegen = state && state.isRegen ? state.isRegen : false;

  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [deaLicenseURL, setDeaLicenseURL] = useState("");
  const [stateLicenseURL, setStateLicenseURL] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [signatureModal, setSignatureModal] = useState("");
  const [billingSignModal, setBillingSignModal] = useState("");
  /*Fedex Address Validation*/
  const [addressVerification, setAddressVerification] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [fedexAddress, setFedexAddress] = useState("");
  const [clinicAddress, setClinicAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipcode: "",
    state: "",
  });

  const [onlyOneUser, setOnlyOneUser] = useState(false);

  const [standardClinicAddress, setStandardClinicAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipcode: "",
    state: "",
  });

  const [companyLogo, setCompanyLogo] = useState("");
  const [billingRepSignature, setBillingRepSignature] = useState("");
  /* */
  //useEffect(() => {}, [clinicAddress]);

  async function upload(e, name, signPad = "") {
    const reader = new FileReader();
    if (e) {
      let file = e.target.files[0];
      reader.onloadend = async () => {
        const base64String = reader.result;
        switch (name) {
          case "dea":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate({
                headerInfo: {
                  Authorization: "Bearer " + authToken,
                  "Content-Type": "multipart/form-data;",
                },
                payload: formData,
              });
              if (res?.data) setDeaLicenseURL(res.data.accessUrl);
            } else setDeaLicenseURL(base64String);
            break;
          case "state":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate({
                headerInfo: {
                  Authorization: "Bearer " + authToken,
                  "Content-Type": "multipart/form-data;",
                },
                payload: formData,
              });
              if (res?.data) setStateLicenseURL(res.data.accessUrl);
            } else setStateLicenseURL(base64String);
            break;
          case "companyLogo":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate({
                headerInfo: {
                  Authorization: "Bearer " + authToken,
                  "Content-Type": "multipart/form-data;",
                },
                payload: formData,
              });
              if (res?.data) setCompanyLogo(res.data.accessUrl);
            } else setCompanyLogo(base64String);
            break;
          case "billingRepSign":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate({
                headerInfo: {
                  Authorization: "Bearer " + authToken,
                  "Content-Type": "multipart/form-data;",
                },
                payload: formData,
              });
              if (res?.data) setBillingRepSignature(res.data.accessUrl);
            } else setBillingRepSignature(base64String);
            break;
          case "signatureImageUrl":
            setSignatureImageUrl(base64String);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(file);
      setShowSpinner(false);
    } else {
      if (signPad) {
        switch (name) {
          case "billingRepSign":
            if (addClinic) {
              const formData = new FormData();
              const file = base64toFile(signPad);
              formData.append("file", file);
              let res = await uploadMutate({
                headerInfo: {
                  Authorization: "Bearer " + authToken,
                  "Content-Type": "multipart/form-data;",
                },
                payload: formData,
              });
              if (res?.data) setBillingRepSignature(res.data.accessUrl);
            } else setBillingRepSignature(signPad);
            break;
          case "signatureImageUrl":
            setSignatureImageUrl(signPad);
            break;
          default:
            break;
        }
      }
    }
  }

  function onChange() {
    setVerified(true);
  }

  const onClickNotValidate = () => {
    setClinicAddress({
      addressLine1: clinicAddress.addressLine1,
      addressLine2: clinicAddress.addressLine2 ? clinicAddress.addressLine2 : "-",
      city: clinicAddress.city,
      zipcode: clinicAddress.zipcode,
      state: clinicAddress.state,
    });
    setStandardClinicAddress({
      addressLine1: clinicAddress.addressLine1,
      addressLine2: clinicAddress.addressLine2 ? clinicAddress.addressLine2 : "-",
      city: clinicAddress.city,
      zipcode: clinicAddress.zipcode,
      state: clinicAddress.state,
    });

    setAddressVerification(true);
    closeModal();
  };
  const onClickValidate = () => {
    setClinicAddress({
      addressLine1: fedexAddress.addressLine1,
      addressLine2: fedexAddress.addressLine2 !== "" ? fedexAddress.addressLine2 : "-",
      city: fedexAddress.city,
      zipcode: fedexAddress.zipcode,
      state: fedexAddress.state,
    });
    setStandardClinicAddress({
      addressLine1: fedexAddress.addressLine1,
      addressLine2: fedexAddress.addressLine2 !== "" ? fedexAddress.addressLine2 : "-",
      city: fedexAddress.city,
      zipcode: fedexAddress.zipcode,
      state: fedexAddress.state,
    });

    setAddressVerification(true);
    closeModal();
  };

  const closeModal = () => {
    setShowValidateModal(false);
  };

  const createClinicSubmitPayload = (obj, side = "") => {
    
    const commonParams = {
      businessName: obj.businessName,
      addressLine1: obj.addressLine1,
      addressLine2: obj.addressLine2,
      city: obj.city,
      email: obj.email,
      state: obj.state,
      officePhone: obj.officePhone,
      zipcode: obj.zipcode,
    };

    if (!side) {
      const apiParam = {
        ...commonParams,
      };
      return apiParam;
    } else {
      const body = {
        userId: userInfo.id,
        clinic: {
          ...commonParams,
        },
      };
      return body;
    }
  };
  /***/

  async function handleValidSubmit(e, side = "") {
    e.preventDefault();
    let serialize = require("form-serialize");
    let obj = serialize(document.getElementById("registerForm"), {
      hash: true,
    });
      setShowSpinner(true);

      let body = createClinicSubmitPayload(obj, side);
      if (side === "adminSide") {
        let response = await addClinicMutate({ payload: body });
        if (response?.data) {
          
        }
      } else {
        
      }
  }

  //Signature Info

  const closeSignatureModal = () => {
    setBillingSignModal(false);
    setSignatureModal(false);
  };
  const onClickSave = (person = "signatureImageUrl", sig) => {
    upload("", person, sig);
  };
  return {
    standardClinicAddress,
    setStandardClinicAddress,
    onlyOneUser,
    setOnlyOneUser,
    clinicAddress,
    setClinicAddress,
    fedexAddress,
    setFedexAddress,
    showValidateModal,
    setShowValidateModal,
    addressVerification,
    setAddressVerification,
    signatureModal,
    setSignatureModal,
    signatureImageUrl,
    setSignatureImageUrl,
    isVerified,
    setVerified,
    stateLicenseURL,
    setStateLicenseURL,
    deaLicenseURL,
    setDeaLicenseURL,
    error,
    setError,
    step,
    setStep,
    showLoading,
    showError,
    errorMessage,
    upload,
    onChange,
    onClickNotValidate,
    onClickValidate,
    onClickSave,
    closeSignatureModal,
    handleValidSubmit,
    closeModal,
    complete,
    billingRepSignature,
    billingSignModal,
    setBillingSignModal,
  };
};

export default useAddClinicHook;

import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import Lottie from "../../../components/lottie";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import AdminRolesDD from "components/dropdowns/adminRoles";
import axios from "axios";
import useAdminStaff from "./Hook/useAdminStaff";

const AddAdmin = (props) => {
  
  const {
    updateProfile,
    base64toFile,
    // uploadFile: uploadFileHelper,
    fetchAdmin,
    editAdminMutate,
    addAdminMutate,
    // user: admin,
    complete,
    errorMessage,
    showError,
    showLoading,
    setError
  } = useAdminStaff(props);
  
  
  // const [complete, setComplete] = useState(false);
  // const [error, setError] = useState("");
  // const [showSpinner, setShowSpinner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const adminList = useRef([]);
  const staffList = useRef([]);
  const totalPages = useRef([]);

  // useEffect(() => {
    // getAdminIdList();
    // getStaffList();
  // }, []);
  async function handleValidSubmit(e) {
    e.preventDefault();

    // setShowSpinner(true);

    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("registerForm"), { hash: true });

    let body = {
      firstName: obj.firstName,
      middleName: obj.middleName,
      lastName: obj.lastName,
      email: obj.email,
      password: obj.password,
      role: obj.role,
      confirmPassword: obj.confirmPassword,
    };
    let res = await addAdminMutate({ payload: body });
  }
  // Example POST method implementation
  return (
    <div>
      <MetaTags>
        <title>Register Admin | Get Royal Health</title>
      </MetaTags>
      <SuperAdminNavbar />
      {showLoading ? <Spinner /> : null}
      <div className="page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={8}>
              <Card className="overflow-hidden">
                {complete ? (
                  <div className="m-5 p-5 text-center">
                    <Lottie name="success-check" />
                    <p className="my-5">New admin has been registered</p>
                    <Link to="/admins" className="pinkButton">
                      View All Admins
                    </Link>{" "}
                  </div>
                ) : (
                  <>
                    <div className="p-4 text-center">
                      <h3 className="text-primary m-4 text-dark">Add New Admin</h3>
                    </div>
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <form
                          id="registerForm"
                          className="form-horizontal px-4"
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (password !== confirmPassword) {
                              setError("Passwords do not match");
                            } else {
                              setError("");
                              handleValidSubmit(e);
                            }
                          }}
                        >
                          {showError ? (
                            <Alert color="danger" className="text-center">
                              {errorMessage}
                            </Alert>
                          ) : null}
                          <div className="my-0 px-4">
                            <div>
                              <Row>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="firstName" className="form-label">
                                      First name <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="firstName" className="form-control" placeholder="First name" type="text" required={true} />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="middleName" className="form-label">
                                      Middle name
                                    </Label>
                                    <Input name="middleName" className="form-control" placeholder="Middle name" type="text" />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="lastName" className="form-label">
                                      Last name <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="lastName" className="form-control" placeholder="Last name" type="text" required={true} />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="email" className="form-label">
                                      Email <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="email" className="form-control" placeholder="Email" type="email" required={true} />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="password" className="form-label">
                                      Password<span className="requiredStar">*</span>
                                    </Label>
                                    <Input
                                      name="password"
                                      className="form-control"
                                      type="password"
                                      placeholder="Password"
                                      required={true}
                                      onChange={(e) => setPassword(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="confirmPassword" className="form-label">
                                      Confirm Password <span className="requiredStar">*</span>
                                    </Label>
                                    <Input
                                      name="confirmPassword"
                                      className="form-control"
                                      type="password"
                                      placeholder="Retype your password"
                                      required={true}
                                      onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                                
                                <Col md={6} className="mb-4">
                                  <div className="">
                                    <AdminRolesDD name="role" />
                                  </div>
                                </Col>
                              
                                {/* <Col md={12} className="pb-4" style={{ display: "flex", alignItems: "center" }}>
                                  <div className="pt-4">
                                    <input
                                      name="acceptTerms"
                                      id="acceptTerms"
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={() => {}}
                                    />
                                    <label className="form-check-label mx-2" htmlFor="acceptTerms">
                                      I've read & accept the <b>Terms and Conditions</b>
                                    </label>
                                  </div>
                                </Col> */}
                              </Row>
                            </div>
                          </div>
                          <Row className="my-5 text-center pt-3">
                            <button
                              className="btn btn-primary darkBg mt-4 mx-auto"
                              style={{ width: "40%" }}
                              type="submit"
                            >
                              Submit
                            </button>
                          </Row>
                        </form>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddAdmin;

import React from "react";
import useProductList from "./index.hook";
import AdminNavbar from "../../../../components/AdminNavbar";
import {
  Card,
  CardBody,
  Col,
  Alert,
  Container,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import PrimaryModal from "../../../../components/primaryModal";
import { useTable, useSortBy } from "react-table";
import { PRODUCT_STATUS } from "../constants";
import Switch from "react-switch";
import { AddProductModal } from "../index";
import AddPriceGroupModal from "../add_price_group";
import Spinner from "../../../../components/spinner";
import "../index.scss";
import { USER_ROLES } from "utils";
import Cookies from "universal-cookie";

import PaginationComponent from "react-reactstrap-pagination";
import { DCATEGORIES, DGENDERS } from "utils/constants";

const ProductListing = (props) => {
  const {
    productList,
    clinicList,
    success,
    setSuccess,
    deleteProductHandler,
    loadMore,
    deleteProduct,
    addPriceGroup,
    setAddPriceGroup,
    reload,
    setDeleteProduct,
    editProduct,
    setEditProduct,
    addProduct,
    setAddProduct,
    changeStatusHandler,
    setDataBack,
    searchString,
    setSearchString,
    onSearchHandler,
    showSpinner,
    sizePerPage,
    pagination,
    handlePageChange,
    ROWS_LIMIT,
    toggle,
    dropdownOpen,
    handleChange,
    rowLimit,
    priceGroups,
  } = useProductList(props);

  const RenderStatusToggle = ({ original }) => {
    var { id, isActive: status = true } = original || {};

    return new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
      <span className={"product-status-container"}>
        <label className="switch">
          <input
            type="checkbox"
            id={"rx-order-input-" + id}
            onChange={(e) => {
              e.stopPropagation();

              changeStatusHandler(id, e.target.checked);
            }}
            defaultChecked={status}
          />
          <span className="slide round"></span>
        </label>
      </span>
    ) : (
      <span className={"product-status-container"}>
        <label className="switch">
          <input type="checkbox" id={"rx-order-input-" + id} checked={status} />
          <span className="slide round"></span>
        </label>
      </span>
    );
  };

  const RenderActionButtons = ({ original }) => {
    return new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
      <span className={"button-product-action-container"}>
        <i
          className="fas fa-edit medText"
          onClick={() => setEditProduct(original)}
        />
        <i
          className="fas fa-trash redText"
          onClick={() => setDeleteProduct(original)}
        />
      </span>
    ) : (
      <span>Disabled</span>
    );
  };

  const columns = React.useMemo(() => {
    var headerList = [
      {
        Header: "Status",
        accessor: "isActive", // accessor is the "key" in the data
        Cell: ({ row: { original } }) => (
          <RenderStatusToggle original={original} />
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <RenderActionButtons original={original} />
        ),
      },

      {
        Header: "Category",
        accessor: (data) => {
          if (data) {
            const { category } = data;
            if (category) {
              return DCATEGORIES[category];
            }
          }
          return "";
        },
      },
      {
        Header: "Gender",
        accessor: (data) => {
          if (data) {
            const { gender } = data;
            if (gender) {
              return DGENDERS[gender];
            }
          }
          return "";
        },
      },
      // {
      //   Header: "Sub Category 1",
      //   accessor: "subCategory1",
      // },
      // {
      //   Header: "Sub Category 2",
      //   accessor: "subCategory2",
      // },
      // {
      //   Header: "Common Name",
      //   accessor: "commonName",
      // },
      {
        Header: "Product",
        accessor: "name",
      },
      // {
      //   Header: "Retail price",
      //   accessor: "unitPrice",
      // },
    ];

    // productList[0]?.sigOptions?.forEach((sig, i) => {
    //   headerList.push(
    //     {
    //       Header: `Sig${i + 1} Title`,
    //       accessor: (data) => {
    //         if (data) {
    //           const { sigOptions } = data;
    //           if (sigOptions) {
    //             if (sigOptions[i]) {
    //               return sigOptions[i]?.title?.toUpperCase() || "";
    //             }
    //           }
    //         }
    //         return "";
    //       },
    //     },

    //     {
    //       Header: `Sig${i + 1}`,
    //       accessor: (data) => {
    //         if (data) {
    //           const { sigOptions } = data;
    //           if (sigOptions) {
    //             if (sigOptions[i]) {
    //               return sigOptions[i]?.description?.toUpperCase() || "";
    //             }
    //           } else return "";
    //         }
    //         return "";
    //       },
    //     }
    //   );
    // });

    // priceGroups?.forEach((p, i) => {
    //   if (p) {
    //     headerList.push({
    //       Header: p,
    //       accessor: (data) => {
    //         if (data) {
    //           const { priceGroups } = data;
    //           return priceGroups?.filter((value1) => value1.groupName === p)[0]?.price;
    //         } else return "";
    //       },
    //     });
    //   }
    // });
    // headerList.push(
    //   {
    //     Header: "Medical Accessories",
    //     accessor: "medicalAccessories",
    //   },

    //   {
    //     Header: "Cold Shipped",
    //     accessor: "coldShipped",
    //   }
    // );
    // headerList.push({
    //   Header: "Controlled Substance",
    //   accessor: "controlledSubstance",
    // });

    return headerList;
  }, [productList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: productList }, useSortBy);

  return (
    <>
      <AdminNavbar />
      <div className="page-content">
        <h4 className="p-3 text-muted">Products</h4>
        {success ? (
          <Alert
            color="success"
            className="text-center"
            isOpen={success ? true : false}
            toggle={() => setSuccess("")}
          >
            {success}
          </Alert>
        ) : null}
        <Container fluid>
          <div className={"clinic-products-header"}>
            {showSpinner ? <Spinner /> : null}
            <button className={"btn"} onClick={() => props.history.goBack()}>
              Back
            </button>

            {new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
              <div>
                {/* <button className={"pinkButton justify-content-end m-3"} onClick={() => setAddPriceGroup(true)}>
                  Add Price Group
                </button> */}
                <button
                  className={"pinkButton justify-content-end m-3"}
                  onClick={() => setAddProduct(true)}
                >
                  Add Products
                </button>
              </div>
            ) : null}
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <Row className="justify-content-between px-3 my-3">
                  <div className="search-box m-3">
                    <div>
                      <input
                        type="text"
                        placeholder="Search table"
                        className="tableSearchInput"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onSearchHandler();
                          }
                        }}
                        onChange={(e) => {
                          searchString.current = e.target.value;
                          if (e.target.value === "") {
                            return onSearchHandler();
                          }
                        }}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <button
                        type="button"
                        onClick={onSearchHandler}
                        className={"product-search-button"}
                      >
                        <i
                          className="bx bx-search-alt search-icon"
                          style={{ position: "initial", lineHeight: "inherit" }}
                        />
                      </button>
                      {searchString.length ? (
                        <i
                          className="fas fa-times redText product-search-icon"
                          onClick={() => {
                            searchString.current = "";

                            return onSearchHandler();
                          }}
                        ></i>
                      ) : (
                        []
                      )}
                    </div>
                  </div>
                </Row>

                <CardBody>
                  {showSpinner && <Spinner />}
                  <div className="my-4 d-flex align-items-center flex-row ">
                    <p className="mx-3 mt-3">Rows: </p>
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      direction="down"
                    >
                      <DropdownToggle caret color="primary">
                        {rowLimit} <i className="fas fa-angle-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {ROWS_LIMIT.map((size) => (
                          <DropdownItem key={size} onClick={handleChange}>
                            {size}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="table-responsive">
                    <table
                      {...getTableProps()}
                      className={""}
                      style={{ overflowY: "scroll", width: "100%" }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                className={"product-table-header"}
                              >
                                {column.render("Header")}
                                <span>
                                  &nbsp;
                                  {column.isSorted ? (
                                    <span
                                      className={
                                        column.isSortedDesc
                                          ? "dropdown"
                                          : "dropup"
                                      }
                                    >
                                      <span className="caret"></span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                const {
                                  row: { original },
                                } = cell;
                                const { status = PRODUCT_STATUS.active } =
                                  original || {};
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    className={`product-table-body ${
                                      status === PRODUCT_STATUS.inactive
                                        ? "product-table-inactive"
                                        : ""
                                    }`}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {!showSpinner && !productList.length && (
                    <span className={"empty-record-table"}>
                      No record available.
                    </span>
                  )}
                </CardBody>

                <Row className="align-items-md-center mt-3 px-3">
                  <div className="text-center ms-auto">
                    {pagination.hasNextPage ? (
                      <span
                        style={{ cursor: "pointer" }}
                        className="blueButton"
                        onClick={(e) => {
                          e.preventDefault();
                          loadMore();
                        }}
                      >
                        Load more
                      </span>
                    ) : null}
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {!!deleteProduct && (
        <PrimaryModal
          redirection={false}
          question={`Are you sure you want to delete this  product?`}
          showModal={!!deleteProduct}
          closeModal={() => setDeleteProduct(null)}
          onClickYes={() => deleteProductHandler(deleteProduct?.id)}
        />
      )}
      {(addProduct || editProduct) && (
        <AddProductModal
          products={productList}
          clinics={clinicList}
          showModal={addProduct || !!editProduct}
          setSuccess={setSuccess}
          product={editProduct}
          isEdit={!!editProduct}
          setData={setDataBack}
          closeModal={() => {
            setAddProduct(false);
            setEditProduct(null);
          }}
          priceGroups={priceGroups}
        />
      )}
      {addPriceGroup && (
        <AddPriceGroupModal
          showModal={addPriceGroup}
          setData={setDataBack}
          setSuccess={setSuccess}
          closeModal={() => {
            setAddPriceGroup(false);
          }}
        />
      )}
    </>
  );
};

export default ProductListing;

import React, { useState, useEffect } from "react"
import { FormGroup, Label, Input } from "reactstrap"

const MaritalStatusDD = (props) => {
	const [defaultValue, setDefaultValue] = useState(props.defaultValue);

	useEffect(() => {
    setDefaultValue((props.defaultValue) ? (props.defaultValue) : (''));
  }, [props]);
  
  return (
  	<FormGroup key={defaultValue}>
      <Label className="normalText" for={props.name}>Marital State {(props.required) ? (<span className="requiredStar">*</span>) : ('')}</Label>
      <p className="dropdownArrow"><i className="fas fa-angle-down"></i></p>
	  	<Input type="select" name={props.name} required={props.required} defaultValue={defaultValue}>
	      <option value="" disabled>Select a State</option>
	      <option value="Single">Single</option>
	      <option value="Married">Married</option>
	      <option value="Widowed">Widowed</option>
	      <option value="Divorced">Divorced</option>
	    </Input>
    </FormGroup>
	)
}

export default MaritalStatusDD

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axiosConfig from '../../../../utils/axiosConfig';
import Cookies from 'universal-cookie';
import Spinner from 'components/spinner';

const useAddPriceGroupModal = (props) => {
  const { showModal = false, closeModal, setReload, reload } = props;

  const { register, handleSubmit, errors, watch, reset } = useForm({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const onSubmit = async (data) => {
    const { priceGroup = '' } = data;

    const body = {
      priceGroup: priceGroup.toUpperCase(),
    };
    setShowSpinner(true);
    await axiosConfig
      .post('/products/addNewPriceGroup', body, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        const { status, data } = response;

        if (status === 200) {
          // setData([{ id: data?.productId, ...payload }]);
          setSuccess(response?.data?.message);

          setError('');
          setShowSpinner(false);
          // setReload(!reload);
          //closeModal();
        } else {
          setSuccess('');
          setError(response?.data?.message);
          setShowSpinner(false);
        }
      })
      .catch(async (err) => {
        setSuccess('');
        setError(err?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  return {
    showModal,
    showSpinner,
    setShowSpinner,
    closeModal,
    error,
    success,
    onSubmit,
    register,
    handleSubmit,
    errors,
    reload,
    setReload,
  };
};

export default useAddPriceGroupModal;

/* eslint-disable react-hooks/exhaustive-deps */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse, OrderViewModal } from "components";
import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import {
  useAdminAcknowledgePrescriptionMutation,
  useLazyGetAdminPendingPrescriptionQuery,
} from "rtk";
import {
  ADMIN_PENDING2_COLUMNS,
  ADMIN_PENDING_BILL_COLUMNS,
  ADMIN_PENDING_COLUMNS,
  ROWS_LIMIT,
  columnHelper,
} from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import {
  useAdminPrescriptionAction,
  useAdminPrescriptionListing,
} from "../Hook";
import AlertMessage from "../components/alert_message";
import PrescriptionNotes from "./PrescriptionNotes";
import PrescriptionRxNumber from "./PrescriptionRxNumber";
import PrescriptionTrackingNumber from "./PrescriptionTrackingNumber";
import PrescriptionOrderStatus from "./PrescriptionOrderStatus";
import PrescriptionDeliveryMethod from "./PrescriptionDeliveryMethod";

const AdminPendingPrescription = () => {
  const {
    rowLimit,
    tablePage,
    showLoading,
    totalRecords,
    errorMessage,
    successMessage,
    isReviewModal,
    searchKeyword,
    disableFilter,
    filterRxStatus,
    prescriptionId,
    prescriptions,
    setCustomError,
    setCustomSuccess,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    toggleDetailModal,
    updateFilterRxStatus,
    updateFilterValue,
    fromDate,
    toDate,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyGetAdminPendingPrescriptionQuery,
  });

  const { mutateError, mutateData, mutateLoading, toggleConfirmationModal } =
    useAdminPrescriptionAction({
      listingName: "AdminPendingPrescription",
      listingMutation: useAdminAcknowledgePrescriptionMutation,
    });

  const updateOrderList = () => {
    refetchList();
  };

  const showSpinner = useMemo(
    () => showLoading || mutateLoading,
    [mutateLoading, showLoading]
  );
  const combinedSuccessMessage = useMemo(
    () => mutateData?.message ?? successMessage ?? "",
    [mutateData?.message, successMessage]
  );
  const combinedErrorMessage = useMemo(
    () => errorMessage || mutateError?.data?.message || "",
    [errorMessage, mutateError?.data?.message]
  );

  const ADMIN_START_COLUMN = useMemo(
    () => [
      columnHelper.accessor("created", {
        header: "Date",
        headerStyle: { minWidth: "200px" },
      }),
      // columnHelper.accessor("rxOrder", {
      //   cell: (props) => (
      //     <Tooltip title="View">
      //       <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
      //         <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
      //       </IconButton>
      //     </Tooltip>
      //   ),
      //   header: "Rx order",
      //   headerStyle: { minWidth: "50px", paddingLeft: 0, paddingRight: 0 },
      // }),
      // columnHelper.accessor("notes", {
      //   header: "Notes",
      //   headerStyle: { width: 150 },
      //   cell: (props) => (
      //     <PrescriptionNotes
      //       id={props.row.original.id}
      //       notesMessage={props.row.original.message}
      //       setCustomError={setCustomError}
      //     />
      //   ),
      // }),
    ],
    []
    // [setCustomError, toggleDetailModal]
  );

  const ADMIN_INVOICE_COLUMN = useMemo(() => [
    columnHelper.accessor("invoiceNumber", {
      cell: (props) => (
        <Tooltip title="View">
          <p
            className="invoice-number cursor mb-0"
            onClick={() => toggleDetailModal(props.row.original.id)}
          >
            {props.getValue()}
          </p>
          {/* <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
              <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
            </IconButton> */}
        </Tooltip>
      ),
      header: "Invoice Number",
      headerStyle: { minWidth: "50px", paddingLeft: 0, paddingRight: 0 },
    }),
  ]);

  // const ADMIN_ORDER_STATUS_COLUMN = useMemo(() => [
  //   columnHelper.accessor("orderStatus", {
  //     header: "Order Status",
  //     headerStyle: { width: 150 },
  //     cell: (props) => (
  //       <PrescriptionOrderStatus
  //         id={props.row.original.id}
  //         notesMessage={props.row.original.orderStatus}
  //         setCustomError={setCustomError}
  //         onUpdate={updateOrderList}
  //       />
  //     ),
  //   }),
  // ]);
  const ADMIN_RX_TR_NUMS_COLUMN = useMemo(
    () => [
      // columnHelper.accessor("rxNumber", {
      //   header: "Rx Number",
      //   headerStyle: { width: 150 },
      //   cell: (props) => (
      //     <PrescriptionRxNumber
      //       id={props.row.original.id}
      //       notesMessage={props.row.original.rxNumber}
      //       setCustomError={setCustomError}
      //     />
      //   ),
      // }),
      columnHelper.accessor("deliveryMethod", {
        header: "Delivery Method",
        headerStyle: { width: 150 },
        cell: (props) => (
          <PrescriptionDeliveryMethod
            id={props.row.original.id}
            notesMessage={props.row.original.deliveryMethod}
            setCustomError={setCustomError}
            setCustomSuccess={setCustomSuccess}
          />
        ),
      }),
      columnHelper.accessor("trackingNumber", {
        header: "Tracking Number",
        headerStyle: { width: 150 },
        cell: (props) => (
          <PrescriptionTrackingNumber
            id={props.row.original.id}
            notesMessage={props.row.original.trackingNumber}
            setCustomError={setCustomError}
            setCustomSuccess={setCustomSuccess}
          />
        ),
      }),
    ],
    [setCustomError, toggleDetailModal]
  );

  // const ADMIN_PRESCRIPTION_ACTION = useMemo(
  //   () =>
  //     columnHelper.accessor("actions", {
  //       header: "Actions",
  //       headerStyle: {
  //         minWidth: "140px",
  //         width: "150px",
  //         textAlign: "center",
  //         paddingLeft: 0,
  //         paddingRight: 0,
  //       },
  //       cell: (props) => (
  //         <Box component={"span"}>
  //           <Tooltip title="Review">
  //             <IconButton
  //               onClick={() => toggleDetailModal(props.row.original.id, true)}
  //               style={{ width: 35, height: 35 }}
  //             >
  //               <FileOpenIcon color="warning" style={{ fontSize: "28px" }} />
  //             </IconButton>
  //           </Tooltip>
  //           <Tooltip title="Approve">
  //             <IconButton
  //               onClick={() =>
  //                 toggleConfirmationModal(props.row.original.id, "Approve")
  //               }
  //               style={{ width: 35, height: 35 }}
  //             >
  //               <CheckCircleIcon color="success" style={{ fontSize: "28px" }} />
  //             </IconButton>
  //           </Tooltip>
  //           <Tooltip title="Disapprove">
  //             <IconButton
  //               onClick={() =>
  //                 toggleConfirmationModal(props.row.original.id, "Disapprove")
  //               }
  //               style={{ width: 35, height: 35 }}
  //             >
  //               <CancelIcon color="error" style={{ fontSize: "28px" }} />
  //             </IconButton>
  //           </Tooltip>
  //         </Box>
  //       ),
  //     }),
  //   [toggleConfirmationModal]
  // );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [
      ...ADMIN_START_COLUMN, // date
      // ...ADMIN_ORDER_STATUS_COLUMN, // order status
      ...ADMIN_PENDING_COLUMNS, // patient name
      ...ADMIN_INVOICE_COLUMN, // delivery method, invoice number
      ...ADMIN_PENDING_BILL_COLUMNS, // bill to 
      ...ADMIN_RX_TR_NUMS_COLUMN, // tracking number
      ...ADMIN_PENDING2_COLUMNS, // payment status
      // ADMIN_PRESCRIPTION_ACTION
    ],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="noPrint">
        {showSpinner && <Spinner />}
        <Row className="my-3 px-3 ">
          <h5>Filter Prescriptions</h5>

          <Col xs={6} sm={5} lg={2} className="">
            <p className="m-0 mt-1">From Date</p>
            <div className="mb-3 row">
              <div>
                <input
                  value={fromDate}
                  className="form-control"
                  type="date"
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={(e) => {
                    updateFilterValue("fromDate", e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} sm={5} lg={2}>
            <p className="m-0 mt-1">To Date</p>
            <div className="mb-3 row">
              <div>
                <input
                  value={toDate}
                  className="form-control"
                  max={new Date().toISOString().slice(0, 10)}
                  type="date"
                  onChange={(e) => {
                    updateFilterValue("toDate", e.target.value);
                  }}
                />
              </div>
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} className="m-0 d-flex align-items-center">
            <button
              disabled={disableFilter}
              className="yellowButton"
              onClick={applyFilter}
            >
              Filter
            </button>
            {!disableFilter && (
              <i
                disabled={disableFilter}
                className="fas fa-times redText"
                style={{ fontSize: 24, marginLeft: 18 }}
                onClick={clearFilter}
              />
            )}
          </Col>
        </Row>

        <ListingSearch
          searchKeyword={searchKeyword}
          applySearch={applyFilter}
          clearFilter={clearFilter}
          updateKeyword={updateKeyword}
          leftChildren={<Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>}
        />
        <AlertMessage msg={combinedErrorMessage || combinedSuccessMessage} isError={combinedErrorMessage} />
        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              {reactTable.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  <TableCell />
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell
                        key={header.id}
                        style={
                          header.column.columnDef.headerStyle ?? {
                            minWidth: 150,
                          }
                        }
                      >
                        <Typography variant="tableHeader" noWrap>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {reactTable.getRowModel().rows.map((row) => (
                <MuiTableCollapse
                  key={row?.original?.id}
                  isAdmin={true}
                  row={row}
                  pageName="AdminPendingPrescription"
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                  setCustomSuccess={setCustomSuccess}
                  refetchList={refetchList}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          showFirstButton
          showLastButton
          page={tablePage}
          count={totalRecords}
          rowsPerPage={rowLimit}
          rowsPerPageOptions={ROWS_LIMIT}
          onPageChange={handleChangePage}
          onRowsPerPageChange={changeRowLimit}
          style={{ alignItems: "center", marginBottom: 0 }}
        />
      </div>
      <OrderViewModal
        showAcknowledge
        prescriptionId={prescriptionId}
        isReview={isReviewModal}
        handleClose={toggleDetailModal}
      />
    </>
  );
};

export default React.memo(AdminPendingPrescription);

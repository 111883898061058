import { ClinicApi } from "../../rtkApi";
import { API_METHODS, END_POINTS } from "../../rtkConstant";

const ClinicProfiledQuery = ClinicApi.injectEndpoints({
  endpoints: (builder) => ({
    clinicAllData: builder.query({
      query: (arg) => ({
        url: END_POINTS.CLINIC_ALL_DATA + "/" + arg.params,
        method: API_METHODS.GET,
      }),
    }),
    clinicDetail: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const clinicId = user?.userData?.clinicId ?? apiArgs?.clinicId;
            const response = await baseQuery({
              url: `${END_POINTS.CLINIC_DETAIL}/${clinicId}`,
              method: API_METHODS.GET,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getShippingRate: builder.query({
      query: (apiArgs) => ({ url: END_POINTS.SHIPPING_RATE, method: API_METHODS.POST, body: apiArgs }),
    }),
  }),
});

export const {
  useLazyClinicAllDataQuery,
  useClinicDetailQuery,
  useLazyClinicDetailQuery,
  useLazyGetShippingRateQuery,
} = ClinicProfiledQuery;

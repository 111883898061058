import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse } from "components";
import PrimaryModal from "components/primaryModal";
import Spinner from "components/spinner";
import { includes } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useDeleteClinicMutation,
  useLazyGetApprovedClinicListQuery,
} from "rtk/rtkEndpoints/Clinics";
import { userRoleSelector } from "selectors";
import {
  ADMIN_APPROVED_CLINICS,
  ALLOWED_ACTION_USERS,
  ROWS_LIMIT,
  adminColumnHelper,
} from "utils";
import AlertMessage from "../Prescriptions/components/alert_message";
import { useClinicListing } from "./Hook";

function ClinicApprovedList() {
  const userRole = useSelector(userRoleSelector);
  const {
    sorting,
    clinicId,
    rowLimit,
    tablePage,
    showSpinner,
    errorMessage,
    totalRecords,
    clinicListing,
    successMessage,
    searchKeyword,
    setSorting,
    clearFilter,
    applyFilter,
    refetchList,
    clinicAction,
    updateKeyword,
    changeRowLimit,
    toggleClinicModal,
    handleChangePage,
  } = useClinicListing({
    listingQuery: useLazyGetApprovedClinicListQuery,
    listingName: "GetApprovedClinicList",
    listingMutation: useDeleteClinicMutation,
  });
  const allowedAcknowledge = useMemo(
    () => includes(ALLOWED_ACTION_USERS, userRole),
    [userRole]
  );

  const clinicApprovedActions = useMemo(
    () =>
      adminColumnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        headerStyle: { minWidth: "180px", textAlign: "center" },
        cell: (props) => (
          <Box component={"span"}>
            <Link
              to={"/clinic-profile/" + props.row.original.id}
              className="pinkButton mx-2"
            >
              Profile
            </Link>

            {allowedAcknowledge ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => toggleClinicModal(props.row.original.id)}
                  style={{ width: 30, height: 30 }}
                >
                  <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        ),
      }),
    [allowedAcknowledge, toggleClinicModal]
  );

  const reactTable = useReactTable({
    data: clinicListing,
    columns: [...ADMIN_APPROVED_CLINICS, clinicApprovedActions],
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: (row) => row.id,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
    },
  });

  const renderHeaderCell = useCallback((header) => {
    const columnSortDirection = header.column.getIsSorted();

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={true}
      >
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() =>
            header.column.toggleSorting(columnSortDirection !== "desc")
          }
        >
          <Typography variant="tableHeader" noWrap>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  }, []);

  return (
    <>
      {showSpinner && <Spinner />}
      <ListingSearch
        searchKeyword={searchKeyword}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
      />
      <AlertMessage msg={successMessage} />
      <AlertMessage msg={errorMessage} isError={true} />
      <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Grid>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => renderHeaderCell(header))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody style={{ marginBottom: "4px" }}>
            {reactTable.getRowModel().rows.map((row) => (
              <MuiTableCollapse
                key={row.id}
                showNested={false}
                readOnly={true}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
      <PrimaryModal
        question="Are you sure to delete this clinic?"
        showModal={Boolean(clinicId)}
        closeModal={toggleClinicModal}
        onClickYes={clinicAction}
      />
    </>
  );
}

export default React.memo(ClinicApprovedList);

import React, { useEffect, useState } from "react";
import { Modal, Row, Input, Label } from "reactstrap";

const ActionModal = (props) => {
  const [input, setInput] = useState("");
  return (
    <>
      <Modal isOpen={props.showModal} size="md" centered={true} className="smallHeightmodal">
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body flexJCAC flex-column text-center">
          <h3>{props.question}</h3>
          {props.instructions ? <p className="redText">{props.instructions}</p> : null}
          <Row className="flexJCAC mt-5">
            <button
              className="blueButton mx-2"
              onClick={() => {
                props.onClickYes();
              }}
            >
              {props.actionText}
            </button>
            <button
              className="lightButton mx-2"
              onClick={() => {
                props.onClickNo ? props.onClickNo() : props.closeModal();
              }}
            >
              Cancel
            </button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ActionModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { filter } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import MetaTags from "react-meta-tags";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDeletePatientMutation, useLazyGetAllPatientsListQuery } from "rtk";
import { cookies, USER_ROLES } from "utils";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import PrimaryModal from "../../../components/primaryModal";
import Spinner from "../../../components/spinner";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import {
  CLINIC_PRESCRIPTION_ACTIONS,
  DEFAULT_SORTED,
  getTableHeader,
} from "./patient-constant";
import PrescriptionOrderView from "./prescriptionOrderView";
import { ROWS_LIMIT } from "utils";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Iconify from "../../../components/iconify";
import PrescriptionHistory from "./prescriptionHistory";
import MedicationHistoryModel from "components/medicationHistoryModel";
import { usePatientListing } from "./Hook";
import { ListingSearch } from "components";
import AlertMessage from "../Prescriptions/components/alert_message";
import { Divider, IconButton, TablePagination, Tooltip } from "@mui/material";
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import RefreshIcon from "@mui/icons-material/Refresh";

function PatientsList() {
  const { clinicId = "" } = useParams();
  const { state: { isPrescription = false, clinicLocationId = "" } = {} } =
    useLocation();
  const [error, setError] = useState("");
  // const [patientId, setPatientId] = useState("");
  const [filteredData, setFilterData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  // const [showSpinner, setShowSpinner] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [disableFilter, setDisableFilter] = useState(true);
  const [apiParam, setApiParam] = useState();
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: filteredData.length,
    custom: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const isClinic = userRole.toLowerCase().includes("clinic");

  const {
    rowLimit,
    tablePage,
    showLoading,
    totalRecords,
    errorMessage,
    isReviewModal,
    searchKeyword,
    disableFilter,
    filterRxStatus,
    patientId,
    patients,
    setPatientId,
    setCustomError,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    toggleDetailModal,
    updateFilterRxStatus,
    updateFilterValue,
    selectedRow,
    setSelectedRow,
  } = usePatientListing({
    listingQuery: useLazyGetAllPatientsListQuery,
  });

  const [
    deleteMutate,
    { isLoading, isError: isDeleteError, error: deleteError, isSuccess },
  ] = useDeletePatientMutation();

  const showSpinner = useMemo(
    () => showLoading || isLoading,
    [isLoading, showLoading]
  );
  const successMessage = useMemo(() => isSuccess ?? "", [isSuccess]);
  const combinedErrorMessage = useMemo(
    () => errorMessage || isDeleteError || deleteError,
    [errorMessage || isDeleteError || deleteError]
  );
  useEffect(() => {
    if (isSuccess) refetchList();
  }, [isSuccess]);

  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // const showLoading = useMemo(
  //   () => isFetching || showSpinner || isLoading,
  //   [isLoading, isFetching, showSpinner]
  // );
  // const showError = useMemo(
  //   () => isError || isDeleteError,
  //   [isError, isDeleteError]
  // );
  // const errorMessage = useMemo(
  //   () =>
  //     error || fetchPatientError?.data?.message || deleteError?.data?.message,
  //   [error, fetchPatientError?.data?.message, deleteError?.data?.message]
  // );
  const [patientActions, setPatientActions] = useState({
    text: "Actions",
    dataField: "actions",
    headerStyle: () => {
      return { width: "80px", textAlign: "center" };
    },
    formatter: (cellContent, row) => (
      <>
      <IconButton onClick={(e) => {handleOpenMenu(e); setSelectedRow(row);}}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        {/* <Link to={"/patient-profile/" + row.id} className="pinkButton">
          Profile
        </Link>
        <Link to={"/edit-patient/" + row.id}>
          <i
            className="fas fa-edit medText"
            style={{ fontSize: "18px", margin: "0 10px" }}
          />
        </Link>

        {userRole === USER_ROLES.ADMIN_SUPER_USER ? (
          <Link
            to="#"
            onClick={() => {
              toggleDeleteModal(row.id);
            }}
          >
            <i
              className="fas fa-trash redText"
              style={{ fontSize: "20px" }}
            ></i>
          </Link>
        ) : (
          <p>Disabled</p>
        )} */}
      </>
    ),
  });

  const tableHeader = useMemo(
    () => [
      ...getTableHeader(setSelectedPatientId, setIsModalOpen),
      isPrescription ? CLINIC_PRESCRIPTION_ACTIONS : patientActions,
    ],
    [isPrescription, setSelectedPatientId, setIsModalOpen]
  );

  const toggleDeleteModal = useCallback((patientId = "") => {
    setPatientId(patientId);
    setDeleteModal(Boolean(patientId));
  }, []);

  const deletePatient = () => {
    toggleDeleteModal();
    deleteMutate({ patientId, pageName: "AdminPatient" });
  };

  const onRowSelection = useCallback(
    (row, isSelect) =>
      setSelectedPatient((prev) =>
        isSelect ? [...prev, row.id] : filter(prev, (x) => x !== row.id)
      ),
    []
  );
  const onAllRowSelection = useCallback(
    (isSelect, rows) =>
      setSelectedPatient(isSelect ? rows?.map((row) => row.id) : []),
    []
  );

  const rowSelection = useMemo(
    () =>
      isPrescription
        ? {
            mode: "checkbox",
            clickToSelect: true,
            selected: selectedPatient,
            onSelect: onRowSelection,
            onSelectAll: onAllRowSelection,
          }
        : undefined,
    [isPrescription, selectedPatient]
  );

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      <SuperAdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Patients | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4">Patients</Typography>
          </Stack>
          <Row>
            <Col xs="12">
              <Card className="listing-card">
                <ListingSearch
                  searchKeyword={searchKeyword}
                  applySearch={applyFilter}
                  clearFilter={clearFilter}
                  updateKeyword={updateKeyword}
                  leftChildren={
                    <Tooltip title="Refresh">
                      <Button variant="contained" onClick={refetchList}>
                        <RefreshIcon style={{ width: 20, height: 20 }} />
                      </Button>
                    </Tooltip>
                  }
                />
                <CardBody>
                  
                  <AlertMessage
                    msg={successMessage ? "Patient deleted successfully." : ""}
                  />
                  <AlertMessage msg={combinedErrorMessage} isError={true} />

                  <React.Fragment>
                    {clinicId && (
                      <Row
                        className={`px-3 my-4 justify-content-${
                          isPrescription ? "start" : "end"
                        }`}
                      >
                        <PrescriptionOrderView
                          clinicId={clinicId}
                          showView={isPrescription}
                          clinicLocationId={clinicLocationId}
                          selectedIds={selectedPatient}
                        />
                        {/* {!isPrescription && (
                          <Link
                            to={{
                              pathname: `/add-patient/${clinicId}`,
                              state: {
                                clinicLocationId,
                              },
                            }}
                            className="pinkButton"
                          >
                            Add patient
                          </Link>
                        )} */}
                      </Row>
                    )}

                    {/* <Row className="justify-content-between px-3 my-3">
                      <Row className="my-4 px-sm-3 px-1">
                        <Col xs={8} sm={9} md={4} lg={2}>
                          <div className="tableSearch d-flex">
                            <i className="bx bx-search-alt search-icon flexJCAC" />
                            <input
                              className="borderless mx-2"
                              onChange={(e) => {
                                setApiParam((prev) => ({
                                  ...prev,
                                  keyword: e.target.value,
                                }));
                                filterChanged(e.target.value);
                                clearSearch(e.target.value);
                              }}
                              value={apiParam?.keyword}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  getPatients(
                                    apiParam?.keyword,
                                    pageOptions?.sizePerPage,
                                    apiParam?.page
                                  );
                                }
                              }}
                              placeholder="Search name/email"
                            />
                          </div>
                        </Col>
                        <Col xs={4} sm={3} md={4} lg={2}>
                          <button
                            disabled={disableFilter}
                            className="yellowButton"
                            onClick={() => {
                              getPatients(
                                apiParam?.keyword,
                                pageOptions?.sizePerPage,
                                apiParam?.page
                              );
                            }}
                          >
                            Search
                          </button>
                        </Col>
                        {!disableFilter && (
                          <Col xs={12} sm={1} lg={1}>
                            <p
                              disabled={disableFilter}
                              className="blueText"
                              style={{
                                fontSize: 14,
                                cursor: "pointer",
                                paddingTop: 8,
                                marginLeft: 10,
                              }}
                              onClick={() => {
                                clearFilter();
                                setDisableFilter(true);
                              }}
                            >
                              Clear
                            </p>
                          </Col>
                        )}
                      </Row>
                    </Row> */}
                    {/* <div className="my-4 d-flex align-items-center flex-row ">
                      <p className="mx-3 mt-3">Rows: </p>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                        <DropdownToggle caret color="primary">
                          {rowLimit} <i className="fas fa-angle-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {ROWS_LIMIT.map((size) => (
                            <DropdownItem key={size} onClick={handleChange}>
                              {size}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
                    <Row>
                      <Col xl="12">
                        <div
                          className="table-responsive"
                          style={{ minHeight: "300px" }}
                        >
                          <BootstrapTable
                            responsive
                            selectRow={rowSelection}
                            keyField={"id"}
                            bordered={false}
                            striped={false}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            columns={tableHeader}
                            data={patients}
                          />
                          {/* {console.log(rowSelection, "ssss")}
                          {console.log(patients, "patients")} */}
                          {patients.length === 0 ? (
                            <p className="w-100 text-center m-5">
                              No patients data found!
                            </p>
                          ) : null}
                        </div>
                        {/* {console.log(patients)} */}
                      </Col>
                    </Row>
                    <Popover
                    open={!!open}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      sx: { width: 140 },
                    }}
                  >
                    <Link to={"/patient-profile/" + selectedRow?.id}>
                    <MenuItem onClick={() =>(handleCloseMenu())}>
                      <Iconify icon="eva:eye-fill" sx={{ mr: 2 }} />
                      Profile
                    </MenuItem>
                    </Link>
                    <Divider />
                    <Link to={"/edit-patient/" + selectedRow?.id}>
                    <MenuItem onClick={() =>(handleCloseMenu())}>
                      <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                      Edit
                    </MenuItem>
                    </Link>

                    <MenuItem onClick={() =>(toggleDeleteModal(selectedRow?.id),handleCloseMenu())} sx={{ color: 'error.main' }}>
                      <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
                      Delete
                    </MenuItem>
                  </Popover>
                    <Row className="align-items-md-center mt-3 px-3">
                      <div className="text-center ms-auto">
                        <TablePagination
                          component="div"
                          showFirstButton
                          showLastButton
                          page={tablePage}
                          count={totalRecords}
                          rowsPerPage={rowLimit}
                          rowsPerPageOptions={ROWS_LIMIT}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={changeRowLimit}
                          style={{ alignItems: "center", marginBottom: 0 }}
                        />
                      </div>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {!isPrescription && (
        <PrimaryModal
          question="Are you sure you want to delete this patient?"
          showModal={deleteModal}
          closeModal={toggleDeleteModal}
          onClickYes={deletePatient}
        />
      )}
      <MedicationHistoryModel
        showModal={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        patientId={selectedPatientId}
      >
        <PrescriptionHistory patientId={selectedPatientId} />
      </MedicationHistoryModel>
    </React.Fragment>
  );
}

export default PatientsList;

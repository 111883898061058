import React, { useCallback } from "react"
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from '../iconify';

function ListingSearch({ searchKeyword = "", applySearch, updateKeyword, clearFilter, leftChildren }) {
  const onKeyDown = useCallback(
    (e) => {
      if (e.code === "Enter") {
        applySearch?.(e);
      }
    },
    [applySearch]
  );

  const clearKeyword = useCallback((e) => clearFilter(e, "keyword"), [clearFilter]);

  return (
      <Toolbar
        sx={{
          height: 96,
          display: 'flex',
          justifyContent: 'space-between',
          p: (theme) => theme.spacing(0, 0, 0, 0),
        }}
      >
          <OutlinedInput
            value={searchKeyword}
            onChange={updateKeyword}
            onKeyDown={onKeyDown}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            }
            endAdornment={
              searchKeyword.length > 0 ? (
                <button onClick={clearKeyword}>
              <InputAdornment position="end">
                <Iconify
                  icon="eva:close-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
              </button>
              ): null
            }
          />
        {leftChildren}
        {/* <Tooltip title="Filter list">  
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip> */}
      </Toolbar>
    // <Row className="justify-content-between px-3 my-3 ">
    //   <div className="search-box d-flex ">
    //     <input
    //       value={searchKeyword}
    //       type="text"
    //       placeholder="Search table"
    //       className="tableSearchInput"
    //       style={{ marginRight: "5px" }}
    //       onKeyDown={onKeyDown}
    //       onChange={updateKeyword}
    //     />
    //     <i className="bx bx-search-alt search-icon" />

    //     {searchKeyword.length ? (
    //       <button className="fas fa-times redText align-self-center" onClick={clearKeyword} />
    //     ) : null}
    //     <button
    //       type="button"
    //       onClick={applySearch}
    //       style={{
    //         background: "#c6bc0c",
    //         color: "#fff",
    //         marginLeft: "5px",
    //       }}
    //     >
    //       <i className="bx bx-search-alt search-icon" style={{ position: "initial", lineHeight: "inherit" }} />
    //     </button>
    //   </div>
    //   {leftChildren}
    // </Row>
  );
}

export default React.memo(ListingSearch);

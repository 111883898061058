import React from 'react';
import '../assets/styles/loggedOutNavbar.css';
import GRHLogo from '../assets/images/logo.svg';
import { Link } from 'react-router-dom';

export default function LoggedOutNavbar() {
  return (
    <div className="lo-navbar">
      <div>
        <img src={GRHLogo} alt="get-royal-health" />
      </div>
      <div className="navLinks">
        {/* <Link to="/login">Log in</Link> */}
      </div>
    </div>
  )
}

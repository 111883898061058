import React from "react";
import { Input, Row, Col, Modal, Card, CardBody, CardTitle, Alert } from "reactstrap";

function CustomSize(props) {
  const { openStatus, length = "", width = "", height = "", weight = "" } = props;
  return openStatus ? (
    <div>
      <label className="blueText m-0">Dimensions</label>
      <Row>
        <Col m={3} md={3} lg={3}>
          {" "}
          <label style={{ textAlign: "left", fontSize: "10px" }} className="m-0" for="length">
            Length (inch)
          </label>
          <input
            className="shipping-input"
            value={length}
            onChange={(e) => {
              props.getShippingRate(props.shippingType, props.packaging, e.target.value, width, height, weight);
              props.setLength(e.target.value);
            }}
            name="length"
            id="length"
            required={props.customSizeStatus ? true : false}
          />
        </Col>
        <Col m={3} md={3} lg={3}>
          <label style={{ textAlign: "left", fontSize: "10px" }} className="m-0" for="width">
            Width (inch)
          </label>
          <input
            className="shipping-input"
            value={width}
            onChange={(e) => {
              props.getShippingRate(props.shippingType, props.packaging, length, e.target.value, height, weight);
              props.setWidth(e.target.value);
            }}
            name="width"
            id="width"
            required={props.customSizeStatus ? true : false}
          />
        </Col>
        <Col m={3} md={3} lg={3}>
          {" "}
          <label style={{ textAlign: "left", fontSize: "10px" }} className="m-0" for="height">
            Height (inch)
          </label>
          <input
            className="shipping-input"
            value={height}
            onChange={(e) => {
              props.getShippingRate(props.shippingType, props.packaging, length, width, e.target.value, weight);
              props.setHeight(e.target.value);
            }}
            name="height"
            id="height"
            required={props.customSizeStatus ? true : false}
          />
        </Col>
        <Col m={3} md={3} lg={3}>
          {" "}
          <label style={{ textAlign: "left", fontSize: "10px" }} className="m-0" for="weight">
            Weight (lb)
          </label>
          <input
            className="shipping-input"
            value={weight}
            onChange={(e) => {
              props.getShippingRate(props.shippingType, props.packaging, length, width, height, e.target.value);
              props.setWeight(e.target.value);
            }}
            name="weight"
            id="weight"
            required={props.customSizeStatus ? true : false}
          />
        </Col>
      </Row>
    </div>
  ) : null;
}

export default React.memo(CustomSize);

import React, { useEffect, useState, useRef, useCallback } from "react";
import { PRODUCT_DATA, PRODUCT_STATUS } from "../constants";
import axiosConfig, { errorResponse } from "../../../../utils/axiosConfig";
import Cookies from "universal-cookie";
import { map } from "lodash";
import { ROWS_LIMIT } from "utils";

const useProductList = () => {
  const [productList, setProductList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [addProduct, setAddProduct] = useState(false);
  const [page, setPage] = useState(1);
  const searchString = useRef("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [pagination, setPagination] = useState({});
  const [addPriceGroup, setAddPriceGroup] = useState(false);
  const [tableDetail, setTableDetail] = useState("");
  const [reload, setReload] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [rowLimit, SetRowLimit] = useState(ROWS_LIMIT[0]);
  const [priceGroups, setPriceGroups] = useState([]);
  const toggle = useCallback(() => setDropdownOpen((prevState) => !prevState), []);

  useEffect(() => {
    axiosConfig
      .get(
        "admin/productDiscounts/getCurrentPriceGroups",

        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        var priceGroupsList = [];
        await response.data.forEach((priceGroup, i) => {
          priceGroupsList.push(priceGroup);
        });
        setPriceGroups(priceGroupsList);
      })
      .catch((error) => {
        errorResponse(error);
      });

      getClinics();
  }, [success]);
  async function loadMore() {
    setShowSpinner(true);

    await axiosConfig
      .post(
        "products/getProductsForAdmin",
        {
          keyword: searchString.current,
          alphabet: "",
          family: "",
          subCategory: "",
          limit: sizePerPage,
          currentPage: pagination?.nextPage || pagination?.currentPage + 1,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        const { products = [], pagination } = response?.data || {};

        setError("");
        setShowSpinner(false);
        setPagination(pagination);
        setProductList((prev) => [...prev, ...products]);
      })
      .catch((error) => {
        setShowSpinner(false);
        setError(error?.response?.data?.message);
        errorResponse(error);
      });
  }

  const getProducts = async (size = "") => {
    let body = {
      keyword: searchString.current,
      alphabet: "",
      family: "",
      subCategory: "",
      limit: size ? size : sizePerPage,
      currentPage: page,
    };
    setShowSpinner(true);
    await axiosConfig
      .post("products/getProductsForAdmin", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        const { products = [], pagination } = response?.data || {};

        setProductList(products);

        setPagination(pagination);
        setShowSpinner(false);
      })
      .catch((err) => {
        setShowSpinner(false);
        setError(error?.response?.data?.message);
      });
  };

  const getClinics = async (pg= "", size = "") => {
    let body = {
      limit: size ? size : sizePerPage,
      currentPage: pg ? pg: page,
    };
    setShowSpinner(true);
    await axiosConfig
      .post("clinics/getClinics", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        const { clinics = [], pagination } = response?.data || {};

        setClinicList(clinics);

        setShowSpinner(false);
      })
      .catch((err) => {
        setShowSpinner(false);
        setError(error?.response?.data?.message);
      });
  };
  const handleChange = useCallback((e) => {
    const size = Number(e.currentTarget.textContent);
    setSizePerPage(size);
    SetRowLimit(size);
    getProducts(size);
  }, []);
  const onSearchHandler = async () => {
    await getProducts();
  };

  useEffect(async () => {
    await getProducts();
  }, [searchString, addPriceGroup]);

  const deleteProductHandler = async (id) => {
    const body = { productId: id, userId: new Cookies().get("user_id") };
    await axiosConfig
      .delete("products/deleteProductByProductId", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },

        data: body,
      })
      .then(async (response) => {
        const { status } = response;
        if (status === 200) {
          const newProducts = productList.filter((item) => item.id !== id);
          setProductList(newProducts);
          setDeleteProduct(null);
        }
      });
  };

  const changeStatusHandler = async (id, status) => {
    let newProducts = [...productList];
    const index = newProducts.findIndex((item) => item.id === id);
    if (index !== -1) {
      let product = newProducts[index];
      product.isActive = status;
      product.category = product.subCategory1;
      const body = { productId: id, isActive: status, userId: new Cookies().get("user_id") };
      await axiosConfig
        .post("products/changeProductStatusByProductId", body, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async (response) => {
          //console.log({ response });
          const { status } = response;
          if (response.data?.id) {
            setProductList((prev) => {
              return map(prev, (obj) => {
                if (obj.id === id) {
                  return response.data;
                }
                return obj;
              });
            });
          }
          if (status === 200) {
            newProducts[index].isActive = status;
          }
        });
    }
  };
  const setDataBack = (newList = [], id = null) => {
    if (!newList.length) return;
    if (!!id) {
      let products = [...productList];
      const index = products.findIndex((item) => item.id === id);
      if (index !== -1) {
        products[index] = { ...products[index], ...newList[0] };
      }
      setProductList(products);
    } else {
      const products = [...productList];
      setProductList([...newList, ...products]);
    }
    setAddProduct(false);
    setEditProduct(null);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  return {
    productList,
    clinicList,
    deleteProductHandler,
    deleteProduct,
    setDeleteProduct,
    editProduct,
    setEditProduct,
    addProduct,
    setAddProduct,
    changeStatusHandler,
    setDataBack,
    searchString,
    onSearchHandler,
    showSpinner,
    sizePerPage,
    pagination,
    handlePageChange,
    setAddPriceGroup,
    addPriceGroup,
    reload,
    setReload,
    tableDetail,
    setTableDetail,
    loadMore,
    success,
    setSuccess,
    toggle,
    dropdownOpen,
    handleChange,
    rowLimit,
    ROWS_LIMIT,
    priceGroups,
  };
};

export default useProductList;

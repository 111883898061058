import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import { MuiNestedTable } from "../MuiNestedTable";
import React, { useMemo, useState } from "react";

function MuiTableCollapse({
  row,
  pageName = "",
  showNested = true,
  readOnly = false,
  showMarkAllBtn = false,
  filterRxStatus = "",
  setCustomError,
  setCustomSuccess,
  refetchList
}) {
  const [open, setOpen] = useState(false);

  const leftIcon = useMemo(() => {
    if (showNested) {
      return (
        <TableCell style={{ paddingRight: 0, paddingLeft: 0 }}>
          <IconButton
            onClick={() => setOpen(!open)}
            style={{ width: 30, height: 30 }}
          >
            {open ? (
              <KeyboardArrowDownIcon style={{ width: 20, height: 20 }} />
            ) : (
              <KeyboardArrowRightIcon style={{ width: 20, height: 20 }} />
            )}
          </IconButton>
        </TableCell>
      );
    }
  }, [open, showNested]);

  const collapseView = useMemo(() => {
    if (showNested) {
      return (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom: open ? undefined : "none",
            }}
            colSpan={row.getVisibleCells().length + 1} // 1 cell for showing arrow icon
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {open && (
                <MuiNestedTable
                  readOnly={readOnly}
                  pageName={pageName}
                  showMarkAllBtn={showMarkAllBtn}
                  originalRow={row.original}
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                  setCustomSuccess={setCustomSuccess}
                  refetchList={refetchList}
                />
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      );
    }
  }, [
    showNested,
    open,
    row,
    readOnly,
    pageName,
    showMarkAllBtn,
    filterRxStatus,
    setCustomError,
  ]);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "5px solid #fff",
            borderTop: "5px solid #fff",
            margin: "20px",
          },
          borderBottom: "5px solid #fff",
          borderTop: "5px solid #fff",
        }}
      >
        {leftIcon}
        {row?.getVisibleCells()?.map((cell) => {
          return (
            <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
      {collapseView}
    </React.Fragment>
  );
}

export default React.memo(MuiTableCollapse);

import { useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";
import axiosConfig from "utils/axiosConfig";
import "./index.scss";
import { useCompleteOrderMutation } from "rtk";
const useUpdateShippingModal = (props) => {
  const { sameAddress } = props;
  const [showSpinner, setShowSpinner] = useState(false);
  const [mainPage, setMainPage] = useState(true);
  const [customShippingCostStatus, setCustomShippingCostStatus] = useState(false);
  const [customSizeStatus, setCustomSizeStatus] = useState(false);
  const [boxSizeDDStatus, setBoxSizeDDStatus] = useState(false);
  const [packagingTypeDDStatus, setPackagingTypeDDStatus] = useState(false);
  const [shippingTypeDDStatus, setShippingTypeDDStatus] = useState(false);
  const [shippingCost, setShippingCost] = useState("100");
  const [shippingType, setShippingType] = useState("");
  const [boxSize, setBoxSize] = useState("");
  const [packaging, setPackaging] = useState("");
  const [customShippingCost, setCustomShippingCost] = useState("");
  const [customSize, setCustomSize] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [error, setError] = useState("");
  const [disableProceed, setDisableProceed] = useState(false);
  const [sameAddressState, setSameAddressState] = useState(false);
  const [shippingAddress, setShippingAddress] = useState("");
  const inputRef = useRef(null);
  const [orderCompleteMutate, { isSuccess: orderCompletedSuccess, error: orderCompletedError }] =
    useCompleteOrderMutation();

  useEffect(() => {
    setSameAddressState(props.sameAddress);
    if (props.sameAddress) {
      setShippingAddress(props.selectedShippingInfo?.shippingAddress);
    } else {
      setShippingAddress("");
    }
  }, [props.openShippingModal]);

  useEffect(() => {
    getShippingRate(shippingType, packaging, length, width, height, weight, true);
  }, [sameAddressState, shippingAddress, shippingType]);

  useEffect(() => {
    if (props.selectedShippingInfo?.shippingMethod === "PickUp") {
      runForPickUp();
    } else {
      setBoxSize("");
      setPackaging("");
      setCustomShippingCost("");
      setMainPage(true);
      setLength("8");
      setWidth("8");
      setWeight("2");
      setHeight("8");
      setShippingCost(props.selectedShippingCost);
      setShippingType(props.selectedShippingInfo?.shippingMethod);
      setShippingTypeDDStatus(true);
      setPackagingTypeDDStatus(true);
      setBoxSizeDDStatus(true);
      if (props.selectedShippingInfo?.shippingMethod?.toLowerCase().includes("ups")) {
        setPackaging("UNKNOWN");
      } else {
        setPackaging("yourPackaging");
      }
      setBoxSize("8x8x8");
    }
  }, [props.openShippingModal, props.selectedShippingCost]);

  useEffect(() => {
    if (orderCompletedSuccess) {
      setError("");
      props.setSelectedPrescriptions([]);
      props.setSelectedShippingInfo("");
      props.setSelectedShippingCost("");
      props.setOpenShippingModal(false);
      props.setDisableOrder?.(true);
      props.loadMore?.(true);
      setShowSpinner(false);
    } else if (orderCompletedError?.data?.message) {
      setError(orderCompletedError?.data?.message);
      setShowSpinner(false);
    }
  }, [orderCompletedError?.data?.message, orderCompletedSuccess, props]);

  const runForPickUp = () => {
    setBoxSize("");
    setPackaging("");
    setLength("");
    setWidth("");
    setWeight("");
    setWidth("");
    setHeight("");
    setCustomShippingCost("");
    setShippingCost(props.selectedShippingCost);
    setShippingType(props.selectedShippingInfo?.shippingMethod);
    setShippingTypeDDStatus(true);
    setPackagingTypeDDStatus(false);
    setBoxSizeDDStatus(false);
    setCustomSizeStatus(false);
    setMainPage(true);
    setCustomShippingCostStatus(false);
  };

  // handle fedex shipType
  const handleFedexShipType = () => {
    setBoxSize("");
    setPackaging("");
    setLength("");
    setWidth("");
    setWeight("");
    setHeight("");
    setWidth("");
    setPackagingTypeDDStatus(true);
    setBoxSizeDDStatus(false);
    setCustomSize(false);
    setMainPage(true);
    setCustomShippingCostStatus(false);
  };
  // handle ups shipType

  const handleUpsShipType = () => {
    setBoxSize("");
    setPackaging("");
    setLength("");
    setWidth("");
    setWeight("");
    setHeight("");
    setWidth("");
    setPackagingTypeDDStatus(true);
    setBoxSizeDDStatus(false);
    setCustomSize(false);
    setMainPage(true);
    setCustomShippingCostStatus(false);
  };

  // handle usps shipType
  const handleUspsShipType = () => {
    setBoxSize("");
    setPackaging("");
    setLength("");
    setWidth("");
    setWeight("");
    setWidth("");
    setHeight("");
    setCustomShippingCost("");
    setPackagingTypeDDStatus(false);
    setBoxSizeDDStatus(false);
    setCustomSizeStatus(false);
    setMainPage(true);
    setCustomShippingCostStatus(false);
  };

  // handle pickup shipType
  const handlePickupShipType = () => {
    setBoxSize("");
    setPackaging("");
    setLength("");
    setWidth("");
    setWeight("");
    setWidth("");
    setHeight("");
    setCustomShippingCost("");
    setPackagingTypeDDStatus(false);
    setBoxSizeDDStatus(false);
    setCustomSizeStatus(false);
    setMainPage(true);
    setCustomShippingCostStatus(false);
  };
  // handle custom ShipType
  // handle fedex shipType
  const handleCustomShipType = () => {
    setBoxSize("");
    setPackaging("");
    setLength("");
    setWidth("");
    setWeight("");
    setWidth("");
    setHeight("");
    setPackagingTypeDDStatus(false);
    setBoxSizeDDStatus(false);
    setCustomShippingCost("");
    setCustomSizeStatus(false);
    setCustomShippingCostStatus(true);
  };
  const handleOrders = (e, presIds) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSpinner(true);
    var payloadPackaging = packaging;
    if (packaging) {
      if (packaging === "yourPackaging") {
        payloadPackaging = "YOUR_PACKAGING";
      } else if (packaging === "fedexMediumBox") {
        payloadPackaging = "FEDEX_MEDIUM_BOX";
      } else if (packaging === "fedexSmallBox") {
        payloadPackaging = "FEDEX_SMALL_BOX";
      }
    }
    const isPickup = shippingType?.toLowerCase()?.includes("pickup");
    let obj = {
      shippingAddress: isPickup ? {} : shippingAddress ? shippingAddress : {},
      prescriptionIds: presIds,
      shippingMethod: shippingType,
      packagingType: payloadPackaging,
      weight: weight ? parseFloat(weight) : 1,
      dimensions: {
        length: length ? parseFloat(length) : 1,
        width: width ? parseFloat(width) : 1,
        height: height ? parseFloat(height) : 1,
      },
      customRate: customShippingCost ? parseFloat(customShippingCost) : shippingCost,
    };
    orderCompleteMutate({ ...obj });
  };

  const handleSubmit = (e) => {};

  const getShippingRate = (shipType = "", packageType = "", l = "", b = "", h = "", w = "", bool = false) => {
    console.log("SAME ADDRESS:", sameAddressState);
    let disable = true;
    if (bool) {
      setShowSpinner(true);
    }
    var payloadPackaging = "";
    if (packageType) {
      if (packageType === "yourPackaging") {
        payloadPackaging = "YOUR_PACKAGING";
      } else if (packageType === "fedexMediumBox") {
        payloadPackaging = "FEDEX_MEDIUM_BOX";
      } else if (packageType === "fedexSmallBox") {
        payloadPackaging = "FEDEX_SMALL_BOX";
      } else {
        payloadPackaging = packageType;
      }
    }
    let body = {
      recipientAddress: {
        streetLines: {
          addressLine1: shippingAddress?.addressLine1,
          addressLine2: shippingAddress?.addressLine2,
        },
        city: shippingAddress?.city,
        state: shippingAddress?.state,
        postalCode: shippingAddress?.zipcode,
        residential: shipType === "GROUND_HOME_DELIVERY" ? true : false,
      },
      shippingMethod: shipType,
      packagingType: packageType ? payloadPackaging : "",
      weight: w ? parseFloat(w) : 0,
      dimensions: {
        length: l ? parseFloat(l) : 0,
        width: b ? parseFloat(b) : 0,
        height: h ? parseFloat(h) : 0,
      },
    };

    if (sameAddressState === false && shippingType?.toLowerCase()?.includes("pick") === false) {
      setShowSpinner(false);
    } else if (shippingType?.toLowerCase()?.includes("pick")) {
      disable = false;
      setShippingCost(0);
      setDisableProceed(false);
      setError("");
      setShowSpinner(false);
    } else if (shippingAddress?.addressLine1) {
      if (shippingType !== "CustomRate") {
        axiosConfig
          .post("portal/getShippingRate", body, {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          })
          .then(async (response) => {
            disable = false;
            setError("");
            setShippingCost(response?.data.shippingCost);
            if (bool) {
              setShowSpinner(false);
            }
            setDisableProceed(false);
          })
          .catch((error) => {
            disable = true;
            setError(error?.response?.data?.message);
            setShowSpinner(false);
            setDisableProceed(true);
          });
      } else {
        disable = false;
        setShowSpinner(false);
        setShippingCost(0);
      }
    }
    if (disable || sameAddressState === false) {
      setError("Please choose/change Shipping Address to proceed.");
      disable = false;
    }
    setDisableProceed(disable);
  };

  return {
    showSpinner,
    mainPage,
    success: orderCompletedSuccess?.message,
    setMainPage,
    shippingCost,
    setShippingCost,
    customShippingCostStatus,
    setCustomShippingCostStatus,
    customSizeStatus,
    setCustomSizeStatus,
    boxSizeDDStatus,
    setBoxSizeDDStatus,
    packagingTypeDDStatus,
    setPackagingTypeDDStatus,
    shippingTypeDDStatus,
    setShippingTypeDDStatus,
    shippingType,
    setShippingType,
    boxSize,
    setBoxSize,
    packaging,
    setPackaging,
    customShippingCost,
    setCustomShippingCost,
    length,
    setLength,
    width,
    setWidth,
    height,
    setHeight,
    weight,
    setWeight,
    customSize,
    setCustomSize,
    inputRef,
    getShippingRate,
    handleOrders,
    handleSubmit,
    error,
    setError,
    disableProceed,
    handleFedexShipType,
    handlePickupShipType,
    handleUpsShipType,
    handleUspsShipType,
    handleCustomShipType,
    setShippingAddress,
    shippingAddress,
    sameAddress,
    sameAddressState,
    setSameAddressState,
  };
};

export default useUpdateShippingModal;

import React from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { userRoleSelector } from "selectors";
import AdminNavbar from "../../../components/AdminNavbar";
import { USER_ROLES } from "../../../utils";

import ClinicApprovedList from "./ClinicApprovedList";

const ClinicsList = () => {
  const userRole = useSelector(userRoleSelector);

  return (
    <React.Fragment>
      <AdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Clinics | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">CLINICS</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  
                  <Row className={`px-3 my-4 justify-content-end`}>
                    {userRole === USER_ROLES.ADMIN_SUPER_USER ? (
                      <Link to="/add-clinic" className="pinkButton">
                        Add Clinic
                      </Link>
                    ) : null}

                  </Row>
                  <ClinicApprovedList />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicsList;

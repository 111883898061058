import React from "react";

function CustomShippingCost(props) {
  const { openStatus, customShippingCost = "" } = props;
  return openStatus ? (
    <div>
      <input
        className="shipping-input"
        name="customShippingCost"
        value={customShippingCost}
        onChange={(e) => {
          props.setCustomShippingCost?.(e.target.value);
          props.setShippingCost?.(e.target.value);
        }}
        placeholder="Enter Shipping Cost"
        required={props.customShippingCostStatus ? true : false}
      />
    </div>
  ) : null;
}

export default React.memo(CustomShippingCost);

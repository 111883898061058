import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap"
import Lottie from '../../components/lottie';

class PageInaccessible extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content bg-white" style={{height: '100vh'}}>
          <MetaTags>
            <title>Access Denied | Get Royal Health</title>
          </MetaTags>
          <Container fluid className="text-center p-sm-4 p-5">
            <Lottie name="no-access" height={300} width={(window.innerWidth > 576) ? (500) : (300)} loop={true} />
            <h5 className="mt-5">Oops! Looks like this You do not have permission to view this page.</h5>
            <button className="pinkButton mt-3" onClick={() => {this.props.history.goBack()}}>Go Back</button>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default PageInaccessible

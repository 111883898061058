import React from "react";
import { Modal, Button } from "reactstrap";

function MedicationHistoryModel({ showModal, closeModal, children }) {
  const modalStyle = {
    maxWidth: "80vw",
    width: "auto",
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={closeModal}
      size="md"
      centered
      style={modalStyle}
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="text-center">
          <h3>Prescription History</h3>
        </div>
      </div>
      <div className="modal-body overflow-x-auto">{children}</div>
      <div className="modal-footer">
        <Button color="secondary" onClick={closeModal}>
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default MedicationHistoryModel;

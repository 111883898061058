import { CACHE_TAGS } from "./ApiConstant";

async function downloadFile(response, fileName) {
  try {
    const url = window.URL.createObjectURL(await response.blob());
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return { data: null };
  } catch (e) {
    console.log({ e });
  }
}

function providesList(resultsWithIds, tagName = CACHE_TAGS.PRESCRIPTION) {
  return resultsWithIds
    ? [{ type: tagName, id: CACHE_TAGS.LIST }, ...resultsWithIds.map(({ id }) => ({ type: tagName, id }))]
    : [{ type: tagName, id: CACHE_TAGS.LIST }];
}

export { providesList, downloadFile };

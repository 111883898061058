/* eslint-disable react-hooks/exhaustive-deps */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse, OrderViewModal } from "components";
import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useAdminAcknowledgePrescriptionMutation, useLazyGetAdminPendingPrescriptionQuery } from "rtk";
import { ADMIN_PENDING_COLUMNS, ROWS_LIMIT, columnHelper } from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import { useAdminPrescriptionAction, useAdminPrescriptionListing } from "../Hook";
import AlertMessage from "../components/alert_message";
import PrescriptionNotes from "./PrescriptionNotes";

const AdminPendingPrescription = () => {
  const {
    rowLimit,
    tablePage,
    showLoading,
    totalRecords,
    errorMessage,
    isReviewModal,
    searchKeyword,
    disableFilter,
    filterRxStatus,
    prescriptionId,
    prescriptions,
    setCustomError,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    toggleDetailModal,
    updateFilterRxStatus,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyGetAdminPendingPrescriptionQuery,
  });

  const { mutateError, mutateData, mutateLoading, toggleConfirmationModal } = useAdminPrescriptionAction({
    listingName: "AdminPendingPrescription",
    listingMutation: useAdminAcknowledgePrescriptionMutation,
  });

  const showSpinner = useMemo(() => showLoading || mutateLoading, [mutateLoading, showLoading]);
  const successMessage = useMemo(() => mutateData?.message ?? "", [mutateData?.message]);
  const combinedErrorMessage = useMemo(
    () => errorMessage || mutateError?.data?.message || "",
    [errorMessage, mutateError?.data?.message]
  );

  const ADMIN_START_COLUMN = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Date",
        headerStyle: { minWidth: "210px" },
      }),
      columnHelper.accessor("rxOrder", {
        cell: (props) => (
          <Tooltip title="View">
            <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
              <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
            </IconButton>
          </Tooltip>
        ),
        header: "Rx order",
        headerStyle: { minWidth: "50px", paddingLeft: 0, paddingRight: 0 },
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        headerStyle: { width: 150 },
        cell: (props) => (
          <PrescriptionNotes
            id={props.row.original.id}
            notesMessage={props.row.original.message}
            setCustomError={setCustomError}
          />
        ),
      }),
    ],
    [setCustomError, toggleDetailModal]
  );

  const ADMIN_PRESCRIPTION_ACTION = useMemo(
    () =>
      columnHelper.accessor("actions", {
        header: "Actions",
        headerStyle: { minWidth: "140px", width: "150px", textAlign: "center", paddingLeft: 0, paddingRight: 0 },
        cell: (props) => (
          <Box component={"span"}>
            <Tooltip title="Review">
              <IconButton
                onClick={() => toggleDetailModal(props.row.original.id, true)}
                style={{ width: 35, height: 35 }}
              >
                <FileOpenIcon color="warning" style={{ fontSize: "28px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Approve">
              <IconButton
                onClick={() => toggleConfirmationModal(props.row.original.id, "Approve")}
                style={{ width: 35, height: 35 }}
              >
                <CheckCircleIcon color="success" style={{ fontSize: "28px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Disapprove">
              <IconButton
                onClick={() => toggleConfirmationModal(props.row.original.id, "Disapprove")}
                style={{ width: 35, height: 35 }}
              >
                <CancelIcon color="error" style={{ fontSize: "28px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      }),
    [toggleConfirmationModal]
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [...ADMIN_START_COLUMN, ...ADMIN_PENDING_COLUMNS, ADMIN_PRESCRIPTION_ACTION],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="noPrint">
        {showSpinner && <Spinner />}
        {/* <Row className="my-3 px-3 ">
          <h5>Filter Prescriptions</h5>
          <Col xs={6} sm={5} lg={2}>
            <div className="mb-3">
              <RXStatusDD
                loc={"pending"}
                filterRX={true}
                clearStatus={disableFilter}
                updatedFilterRxStatusValue={updateFilterRxStatus}
              />
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} className="m-0">
            <button disabled={disableFilter} className="yellowButton" onClick={applyFilter}>
              Filter
            </button>
            {!disableFilter && (
              <i
                disabled={disableFilter}
                className="fas fa-times redText"
                style={{ fontSize: 24, marginLeft: 18 }}
                onClick={clearFilter}
              />
            )}
          </Col>
        </Row> */}

        <ListingSearch
          searchKeyword={searchKeyword}
          applySearch={applyFilter}
          clearFilter={clearFilter}
          updateKeyword={updateKeyword}
        />
        <AlertMessage msg={successMessage} />
        <AlertMessage msg={combinedErrorMessage} isError={true} />
        <Col className="text-end" style={{ marginRight: 15 }}>
          <Tooltip title="Refresh">
            <Button variant="contained" onClick={refetchList}>
              <RefreshIcon style={{ width: 20, height: 20 }} />
            </Button>
          </Tooltip>
        </Col>
        <TableContainer>
          <Table size="small" stickyHeader>
            <TableHead>
              {reactTable.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  <TableCell />
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                        <Typography variant="tableHeader" noWrap>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {reactTable.getRowModel().rows.map((row) => (
                <MuiTableCollapse
                  key={row.id}
                  isAdmin={true}
                  row={row}
                  pageName="AdminPendingPrescription"
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          showFirstButton
          showLastButton
          page={tablePage}
          count={totalRecords}
          rowsPerPage={rowLimit}
          rowsPerPageOptions={ROWS_LIMIT}
          onPageChange={handleChangePage}
          onRowsPerPageChange={changeRowLimit}
          style={{ alignItems: "center", marginBottom: 0 }}
        />
      </div>
      <OrderViewModal
        showAcknowledge
        prescriptionId={prescriptionId}
        isReview={isReviewModal}
        handleClose={toggleDetailModal}
      />
    </>
  );
};

export default React.memo(AdminPendingPrescription);

import MetaTags from "react-meta-tags";
import React from "react";
import { Row, Col, Card, CardBody, Container, Alert } from "reactstrap";
import LoggedOutNavbar from "../../../components/loggedOutNavbar";
import Lottie from "../../../components/lottie";
import Spinner from "../../../components/spinner";
import AdminNavbar from "../../../components/AdminNavbar";
import { Link } from "react-router-dom";
import { ClinicFormInfo, NavigationSteps } from "components/AddClinicComponents";
import BottomNavigationSteps from "components/AddClinicComponents/BottomNavigationSteps";
import useAddClinicHook from "screens/common/Clinic/AddClinicHook/useAddClinicHook";

const AddClinic = (props) => {
  const {
    standardClinicAddress,
    onlyOneUser,
    setOnlyOneUser,
    clinicAddress,
    setClinicAddress,
    fedexAddress,
    showValidateModal,
    signatureModal,
    setSignatureModal,
    signatureImageUrl,
    step,
    setStep,
    showLoading,
    showError,
    errorMessage,
    upload,
    onClickNotValidate,
    onClickValidate,
    onClickSave,
    closeSignatureModal,
    handleValidSubmit,
    closeModal,
    complete,
    billingSignModal,
    setBillingSignModal,
    billingRepSignature,
  } = useAddClinicHook(props);

  return (
    <React.Fragment>
      <AdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Add Clinic | Get Royal Health</title>
        </MetaTags>
        <LoggedOutNavbar />
        {showLoading ? <Spinner /> : null}
        <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={10} lg={8} xl={8}>
                <Card className="overflow-hidden">
                  {complete ? (
                    <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                      <Lottie name="success-check" />
                      <p className="my-4">Clinic Added Successfully</p>
                      <Link to="/clinics" className="pinkButton">
                        View Clinics
                      </Link>
                    </div>
                  ) : (
                    <>
                      <div className="p-4 text-center">
                        <h3 className="text-primary m-4 text-dark">Add Clinic</h3>
                        {/* <NavigationSteps step={step} /> */}
                      </div>
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <form
                            id="registerForm"
                            className="form-horizontal px-4"
                            onSubmit={(e) => handleValidSubmit(e, "adminSide")}
                          >
                            {showError ? (
                              <Alert color="danger" className="text-center">
                                {errorMessage}
                              </Alert>
                            ) : null}
                            <ClinicFormInfo
                              step={step}
                              billingSignModal={billingSignModal}
                              setBillingSignModal={setBillingSignModal}
                              clinicAddress={clinicAddress}
                              onlyOneUser={onlyOneUser}
                              upload={upload}
                              setClinicAddress={setClinicAddress}
                              setOnlyOneUser={setOnlyOneUser}
                              signatureImageUrl={signatureImageUrl}
                              signatureModal={signatureModal}
                              closeSignatureModal={closeSignatureModal}
                              onClickSave={onClickSave}
                              setSignatureModal={setSignatureModal}
                              adminSide={true}
                              billingRepSignature={billingRepSignature}
                            />
                            <BottomNavigationSteps actionText={"Add"} step={step} setStep={setStep} side="adminSide" />
                          </form>
                        </div>
                      </CardBody>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddClinic;

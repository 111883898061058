import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import StateDD from "../../../components/dropdowns/stateDD";
import Lottie from "../../../components/lottie";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";

const EditClinic = (props) => {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [clinic, setClinic] = useState(false);
  function handleValidSubmit(e) {
    e.preventDefault();

    setShowSpinner(true);

    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("registerForm"), {
      hash: true,
    });

    axiosConfig
      .post(
        "clinics/editClinicByClinicId",
        {
          clinicId: props.match.params.id,
          userId: new Cookies().get("user_id"),
          clinic: {
            businessName: obj.businessName,
            email: obj.email,
            fax: obj?.fax,
            officePhone: obj.officePhone,
            addressLine1: obj.addressLine1,
            addressLine2: obj.addressLine2,
            state: obj.state,
            city: obj.city,
            zipcode: obj.zipcode,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setComplete(true);
        setShowSpinner(false);
      })
      .catch((error) => {
        setError("An error occurred while trying to update clinic information! Please try again in a while!");

        setShowSpinner(false);

        errorResponse(error);
      });
  }

  useEffect(() => {
    setShowSpinner(true);
    axiosConfig
      .get("clinics/getClinicByClinicId/" + props.match.params.id, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async (response) => {
        setClinic(response.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        setError("An error occurred while trying to get clinic information!");
        setShowSpinner(false);
        errorResponse(error);
      });
  }, []);
  /*
   */

  function handleZipcodeInputKeyDown(e) {
    // this is called as soon as we get input from the user
    // we don't want to allow them the access to input past 5 digits
    // so we block on keyDown
    // (it never gets to keyUp, which actually updates the state)
    // restrict to 5 digits only
  }
  return (
    <div>
      <SuperAdminNavbar />

      <div className="page-content">
        <MetaTags>
          <title>Edit Clinic Info | Get Royal Health</title>
        </MetaTags>

        <Container fluid>
          <h4 className="p-3 text-muted">EDIT CLINIC INFORMATION</h4>
          {showSpinner ? <Spinner /> : null}

          <Row className="justify-content-center">
            <Col xs="12">
              <Card className="overflow-hidden">
                {complete ? (
                  <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                    <Lottie name="success-check" />
                    <p className="my-4">Your clinic information has been updated!.</p>
                    <Link to={"/clinic-profile/" + props.match.params.id} className="pinkButton">
                      Clinic Profile
                    </Link>
                  </div>
                ) : (
                  <>
                    <CardBody className="pt-5">
                      <div className="p-2">
                        {clinic ? (
                          <form id="registerForm" className="stepsForm form-horizontal px-4" onSubmit={(e) => handleValidSubmit(e)}>
                            {error ? (
                              <Alert color="danger" className="text-center">
                                {error}
                              </Alert>
                            ) : null}
                            <div className="mb-5 px-4">
                              <div>
                                <FormGroup className="mb-4">
                                  <Label htmlFor="businessName" className="form-label">
                                    Business name <span className="requiredStar">*</span>
                                  </Label>
                                  <Input name="businessName" className="form-control" type="text" defaultValue={clinic.businessName} required />
                                </FormGroup>
                              </div>
                              <Row>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="email" className="form-label">
                                      Office email <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="email" className="form-control" type="email" defaultValue={clinic.email} required />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="officePhone" className="form-label">
                                      Office phone <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="officePhone" type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="Format: 2123232343" className="form-control" defaultValue={clinic.officePhone} required />
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* <Row>
                                <Col md={6}>
                                  <div className="mb-4">
                                    <Label htmlFor="fax" className="form-label">
                                      Office fax number{" "}
                                    </Label>
                                    <Input name="fax" className="form-control" type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" defaultValue={clinic.fax} />
                                  </div>
                                </Col>
                              </Row> */}
                              <Row>
                                <Col md={6}>
                                  <div className="mb-4">
                                    <Label htmlFor="addressLine1" className="form-label">
                                      Address line 1 <span className="requiredStar">*</span>
                                    </Label>
                                    <Input maxLength={50} name="addressLine1" className="form-control" type="textarea" defaultValue={clinic.addressLine1} required />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="mb-4">
                                    <Label htmlFor="addressLine2" className="form-label">
                                      Address line 2
                                    </Label>
                                    <Input maxLength={50} name="addressLine2" className="form-control" type="textarea" defaultValue={clinic.addressLine2} />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <div className="mb-4">
                                    <Label htmlFor="city" className="form-label">
                                      City <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="city" className="form-control" type="text" defaultValue={clinic.city} required />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <StateDD name="state" required={true} defaultValue={clinic.state} />
                                </Col>
                                <Col md={4}>
                                  <div className="mb-4">
                                    <Label htmlFor="zipcode" className="form-label">
                                      Zip code <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="zipcode" className="form-control" onKeyDown={(e) => handleZipcodeInputKeyDown(e)} defaultValue={clinic.zipcode} required />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row className="my-5 mx-sm-5 mx-0 text-center pt-3">
                              <button className="btn btn-primary darkBg mt-4 mx-auto" style={{ width: "40%" }} type="submit">
                                Update Clinic
                              </button>
                            </Row>
                          </form>
                        ) : null}
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <button onClick={() => props.history.goBack()} className="text-center pinkButton">
                Back
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default EditClinic;

import { values } from "lodash";
import React, { useCallback, useState, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { userRoleSelector } from "selectors";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import Spinner from "../../../components/spinner";
import { USER_ROLES } from "../../../utils";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Iconify from "../../../components/iconify";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse } from "components";
import PrimaryModal from "components/primaryModal";
import { includes } from "lodash";
import {
  useDeleteClinicMutation,
  useLazyGetApprovedClinicListQuery,
} from "rtk/rtkEndpoints/Clinics";
import {
  ADMIN_APPROVED_CLINICS,
  ALLOWED_ACTION_USERS,
  ROWS_LIMIT,
  adminColumnHelper,
} from "utils";
import AlertMessage from "../Prescriptions/components/alert_message";
import { useClinicListing } from "./Hook";
import BootstrapTable from "react-bootstrap-table-next";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Cookies from "universal-cookie";

const ClinicsList = () => {
  const userRole = useSelector(userRoleSelector);

  const {
    sorting,
    clinicId,
    rowLimit,
    tablePage,
    showSpinner,
    errorMessage,
    totalRecords,
    clinicListing,
    successMessage,
    searchKeyword,
    setSorting,
    clearFilter,
    applyFilter,
    refetchList,
    clinicAction,
    updateKeyword,
    changeRowLimit,
    toggleClinicModal,
    handleChangePage,
    selectedRow,
    setSelectedRow,
  } = useClinicListing({
    listingQuery: useLazyGetApprovedClinicListQuery,
    listingName: "GetApprovedClinicList",
    listingMutation: useDeleteClinicMutation,
  });
  const allowedAcknowledge = useMemo(
    () => includes(ALLOWED_ACTION_USERS, userRole),
    [userRole]
  );

  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const clinicApprovedActions = useMemo(
    () =>
      adminColumnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        headerStyle: { minWidth: "180px", textAlign: "center" },
        cell: (props) => (
          <Box component={"span"}>
            <Link
              to={"/clinic-profile/" + props.row.original.id}
              className="pinkButton mx-2"
            >
              Profile
            </Link>

            {allowedAcknowledge ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => toggleClinicModal(props.row.original.id)}
                  style={{ width: 30, height: 30 }}
                >
                  <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        ),
      }),
    [allowedAcknowledge, toggleClinicModal]
  );

  const reactTable = useReactTable({
    data: clinicListing,
    columns: [...ADMIN_APPROVED_CLINICS, clinicApprovedActions],
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: (row) => row.id,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
    },
  });

  const renderHeaderCell = useCallback((header) => {
    const columnSortDirection = header.column.getIsSorted();

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={true}
      >
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() =>
            header.column.toggleSorting(columnSortDirection !== "desc")
          }
        >
          <Typography variant="tableHeader" noWrap>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  }, []);

  const RenderActionButtons = ({ original }) => {
    return new Cookies().get("user_role") === USER_ROLES.ADMIN_SUPER_USER ? (
      <>
        <IconButton
          onClick={(e) => {
            handleOpenMenu(e);
            setSelectedRow(original);
          }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </>
    ) : (
      // <span className={"button-product-action-container"}>
      //   <i
      //     className="fas fa-edit medText"
      //     onClick={() => setEditProduct(original)}
      //   />
      //   <i
      //     className="fas fa-trash redText"
      //     onClick={() => setDeleteProduct(original)}
      //   />
      // </span>
      <span>Disabled</span>
    );
  };

  const tableHeader = useMemo(
    () => [
      // {text: "Status", dataField: 'isActive', formatter: (cellContent, row) => <RenderStatusToggle original={row} />},
      //   { text: "Image", dataField: "productImage",
      //   formatter: (cellContent, row) => (<>
      // {cellContent ?
      //         <img src={cellContent} style={{maxWidth: '60px', maxHeight: '60px', height: '100%', width: '100%', objectFit: 'cover'  }} />
      //         : '-'}
      // </>) },
      { text: "Clinic Name", dataField: "businessName" },
      { text: "Email", dataField: "email" },
      { text: "Phone", dataField: "officePhone" },
      // { text: "Gender", dataField: "gender",
      // formatter: (cellContent, row) => (<>{DGENDERS[cellContent]}</>) },
      {
        text: "",
        dataField: "action",
        formatter: (cellContent, row) => <RenderActionButtons original={row} />,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}s
      <SuperAdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Clinics | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4">Clinics</Typography>

            {userRole === USER_ROLES.ADMIN_SUPER_USER ? (
              <Link to="/add-clinic">
                <Button
                  variant="contained"
                  color="inherit"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Clinic
                </Button>
              </Link>
            ) : null}
          </Stack>

          <AlertMessage msg={successMessage} />
          <AlertMessage msg={errorMessage} isError={true} />
          <Row>
            <Col xs="12">
              <Card className="listing-card card">
                <ListingSearch
                  searchKeyword={searchKeyword}
                  applySearch={applyFilter}
                  clearFilter={clearFilter}
                  updateKeyword={updateKeyword}
                  leftChildren={
                    <Tooltip title="Refresh">
                      <Button variant="contained" onClick={refetchList}>
                        <RefreshIcon style={{ width: 20, height: 20 }} />
                      </Button>
                    </Tooltip>
                  }
                />
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <div
                        className="table-responsive"
                        style={{ minHeight: "300px" }}
                      >
                        <BootstrapTable
                          responsive
                          keyField={"id"}
                          bordered={false}
                          striped={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          columns={tableHeader}
                          data={clinicListing}
                        />
                        {/* {console.log(rowSelection, "ssss")}
                          {console.log(patients, "patients")} */}
                        {clinicListing.length === 0 ? (
                          <p className="w-100 text-center m-5">
                            No clinics data found!
                          </p>
                        ) : null}
                      </div>
                      {/* {console.log(patients)} */}
                    </Col>
                  </Row>
                  <Popover
                    open={!!open}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                      sx: { width: 140 },
                    }}
                  >
                    <Link to={"/clinic-profile/" + selectedRow?.id}>
                      <MenuItem onClick={() => handleCloseMenu()}>
                        <Iconify icon="eva:eye-fill" sx={{ mr: 2 }} />
                        Profile
                      </MenuItem>
                    </Link>

                    <MenuItem
                      onClick={() => (
                        toggleClinicModal(selectedRow?.id), handleCloseMenu()
                      )}
                      sx={{ color: "error.main" }}
                    >
                      <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
                      Delete
                    </MenuItem>
                  </Popover>
                  <Row className="align-items-md-center mt-3 px-3">
                    <div className="text-center ms-auto">
                      <TablePagination
                        component="div"
                        showFirstButton
                        showLastButton
                        page={tablePage}
                        count={totalRecords}
                        rowsPerPage={rowLimit}
                        rowsPerPageOptions={ROWS_LIMIT}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={changeRowLimit}
                        style={{ alignItems: "center", marginBottom: 0 }}
                      />
                    </div>
                  </Row>
                  {/* <TableContainer >
                    <Table size="small" stickyHeader>
                      <TableHead>
                        {reactTable?.getHeaderGroups()?.map((headerGroup) => (
                          <TableRow
                            key={headerGroup.id}
                            style={{
                              backgroundColor: "gray",
                              height: "30px",
                              fontSize: "2rem",
                            }}
                          >
                            {headerGroup?.headers?.map((header) =>
                              renderHeaderCell(header)
                            )}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {reactTable.getRowModel().rows.map((row) => (
                          <MuiTableCollapse
                            key={row.id}
                            showNested={false}
                            readOnly={true}
                            row={row}
                            style={{
                              // borderBottom: "1px solid #E0E0E0",
                              "&:hover": { backgroundColor: "#F0F0FA" },
                            }}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    component="div"
                    showFirstButton
                    showLastButton
                    page={tablePage}
                    count={totalRecords}
                    rowsPerPage={rowLimit}
                    rowsPerPageOptions={ROWS_LIMIT}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={changeRowLimit}
                  /> */}
                  <PrimaryModal
                    question="Are you sure to delete this clinic?"
                    showModal={Boolean(clinicId)}
                    closeModal={toggleClinicModal}
                    onClickYes={clinicAction}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicsList;

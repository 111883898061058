import Lottie from "components/lottie";
import Spinner from "components/spinner";
import { useMemo } from "react";
import { Alert, Card, Col, FormGroup, Label, Modal, Row } from "reactstrap";
import CustomShippingCost from "./CustomShippingCost";
import CustomSize from "./CustomSize";
import useUpdateShippingModal from "./index.hook";

import fedex from "assets/images/shippingMethods/fedex.png";
import ups from "assets/images/shippingMethods/ups.png";
import usps from "assets/images/shippingMethods/usps.png";
import { NOTES } from "components/PaymentGrid/PaymentGridConstants";
import { mapShippingMethod } from "utils/constants";
import CustomShippingAddress from "./CustomShippingAddress";
import { Tooltip } from "@mui/material";

const UpdateShippingModal = (props) => {
  const {
    showSpinner,
    handleSubmit,
    setShippingCost,
    handleOrders,
    error,
    setError,
    success,
    getShippingRate,
    shippingType,
    setShippingType,
    boxSize,
    setBoxSize,
    packaging,
    setPackaging,
    customShippingCost,
    setCustomShippingCost,
    length,
    setLength,
    width,
    setWidth,
    height,
    setHeight,
    weight,
    setWeight,
    customSize,
    setCustomSize,
    inputRef,
    mainPage,
    setMainPage,
    shippingCost,
    customShippingCostStatus,
    setCustomShippingCostStatus,
    customSizeStatus,
    setCustomSizeStatus,
    boxSizeDDStatus,
    setBoxSizeDDStatus,
    packagingTypeDDStatus,
    setPackagingTypeDDStatus,
    shippingTypeDDStatus,
    setShippingTypeDDStatus,
    disableProceed,
    handlePickupShipType,
    handleUpsShipType,
    handleUspsShipType,
    handleCustomShipType,
    handleFedexShipType,
    setShippingAddress,
    shippingAddress,
    sameAddressState,
    setSameAddressState,
  } = useUpdateShippingModal(props);

  const isPickup = useMemo(() => shippingType.toLowerCase().includes("pick"), [shippingType]);
  const shipToClinic = useMemo(
    () => props?.selectedShippingInfo?.shipTo === "clinic",
    [props?.selectedShippingInfo?.shipTo]
  );

  const ShippingTypeDD = (check) => {
    const { openStatus } = check;
    return openStatus ? (
      <div>
        <div>
          <label className="m-0 blueText"> Shipping Type</label>
          {!isPickup &&
          !shippingType.toLowerCase().includes("ups") &&
          !shippingType.toLowerCase().includes("usps") &&
          !shippingType.toLowerCase().includes("cus") ? (
            <select
              className="shipping-input"
              required={shippingTypeDDStatus ? true : false}
              placeholder="Select"
              value={shippingType}
              onChange={(e) => {
                setShippingType(e.target.value);
                if (
                  e.target.value === "FEDEX_2_DAY" ||
                  e.target.value === "GROUND_HOME_DELIVERY" ||
                  e.target.value === "FEDEX_2_DAY" ||
                  e.target.value === "FIRST_OVERNIGHT" ||
                  e.target.value === "PRIORITY_OVERNIGHT" ||
                  e.target.value === "STANDARD_OVERNIGHT"
                ) {
                  handleFedexShipType();
                }
                if (e.target.value != "CustomRate")
                  getShippingRate(e.target.value, packaging, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Shipping Type
              </option>
              <option value="FEDEX_2_DAY"> {mapShippingMethod("FEDEX_2_DAY")}</option>
              <option value="FIRST_OVERNIGHT">{mapShippingMethod("FIRST_OVERNIGHT")}</option>
              <option value="PRIORITY_OVERNIGHT">{mapShippingMethod("PRIORITY_OVERNIGHT")}</option>
              <option value="STANDARD_OVERNIGHT">{mapShippingMethod("STANDARD_OVERNIGHT")}</option>
            </select>
          ) : shippingType.toLowerCase().includes("ups") ? (
            <select
              className="shipping-input"
              required={shippingTypeDDStatus ? true : false}
              placeholder="Select"
              value={shippingType}
              onChange={(e) => {
                setShippingType(e.target.value);
                if (e.target.value.toLowerCase().includes("ups")) {
                  handleUpsShipType();
                }
                if (e.target.value != "CustomRate")
                  getShippingRate(e.target.value, packaging, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Shipping Type
              </option>
              <option value="UPS Ground">{mapShippingMethod("UPS Ground")}</option>
              <option value="UPS 2nd Day Air">{mapShippingMethod("UPS 2nd Day Air")}</option>
              <option value="UPS Next Day Air">{mapShippingMethod("UPS Next Day Air")}</option>
              <option value="UPS Next Day Air Saver">{mapShippingMethod("UPS Next Day Air Saver")}</option>
            </select>
          ) : shippingType.toLowerCase().includes("usps") ? (
            <select
              className="shipping-input"
              required={shippingTypeDDStatus ? true : false}
              placeholder="Select"
              value={shippingType}
              onChange={(e) => {
                if (e.target.value === "USPS 1-Day (PO BOX ONLY)") {
                  handleUspsShipType();
                }
                if (e.target.value != "CustomRate")
                  getShippingRate(e.target.value, packaging, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Shipping Type
              </option>
              <option value="USPS 1-Day (PO BOX ONLY)">USPS 1-Day (PO BOX ONLY)</option>
            </select>
          ) : isPickup ? (
            <select
              className="shipping-input"
              required={shippingTypeDDStatus ? true : false}
              placeholder="Select"
              value={shippingType}
              onChange={(e) => {
                if (e.target.value === "USPS 1-Day (PO BOX ONLY)") {
                  handleUspsShipType();
                }
                if (e.target.value != "CustomRate")
                  getShippingRate(e.target.value, packaging, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Shipping Type
              </option>
              <option value="PickUp">Pick up</option>
            </select>
          ) : shippingType.toLowerCase().includes("cus") ? (
            <select
              className="shipping-input"
              required={shippingTypeDDStatus ? true : false}
              placeholder="Select"
              value={shippingType}
              onChange={(e) => {
                if (e.target.value === "CustomRate") {
                  handleCustomShipType();
                }
                if (e.target.value != "CustomRate")
                  getShippingRate(e.target.value, packaging, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Shipping Type
              </option>
              <option value="CustomRate">Custom</option>
            </select>
          ) : null}
        </div>
        <div></div>
      </div>
    ) : null;
  };
  const PackagingTypeDD = (check) => {
    const { openStatus } = check;
    return openStatus ? (
      <div>
        <div>
          <label className="m-0 blueText">Packaging Type</label>

          {!isPickup &&
          !shippingType.toLowerCase().includes("ups") &&
          !shippingType.toLowerCase().includes("usps") &&
          !shippingType.toLowerCase().includes("cus") ? (
            <select
              className="shipping-input"
              value={packaging}
              required={true}
              onChange={(e) => {
                setPackaging(e.target.value);
                if (e.target.value === "yourPackaging") {
                  setBoxSizeDDStatus(true);
                } else if (e.target.value === "fedexMediumBox") {
                  setBoxSize("");
                  setLength("");
                  setHeight("");
                  setWeight("");
                  setWidth("");
                  setCustomShippingCost("");
                  setBoxSizeDDStatus(false);
                  setCustomSizeStatus(false);
                  setCustomShippingCostStatus(false);
                  setMainPage(true);
                } else if (e.target.value === "fedexSmallBox") {
                  setBoxSize("");
                  setLength("");
                  setHeight("");
                  setWeight("");
                  setWidth("");
                  setCustomShippingCost("");
                  setBoxSizeDDStatus(false);
                  setCustomSizeStatus(false);
                  setCustomShippingCostStatus(false);
                }
                getShippingRate(shippingType, e.target.value, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Packaging Type
              </option>
              <option value="yourPackaging">Your Packaging</option>
              <option value="fedexMediumBox">FedEx Medium Box</option>
              <option value="fedexSmallBox">FedEx Small Box</option>
            </select>
          ) : shippingType.toLowerCase().includes("ups") ? (
            <select
              className="shipping-input"
              value={packaging}
              required={true}
              onChange={(e) => {
                setPackaging(e.target.value);
                if (e.target.value === "yourPackaging" || e.target.value === "UNKNOWN") {
                  setBoxSizeDDStatus(true);
                } else {
                  setBoxSize("");
                  setLength("");
                  setHeight("");
                  setWeight("");
                  setWidth("");
                  setCustomShippingCost("");
                  setBoxSizeDDStatus(false);
                  setCustomSizeStatus(false);
                  setCustomShippingCostStatus(false);
                }
                getShippingRate(shippingType, e.target.value, length, width, height, weight);
              }}
            >
              <option value="" disabled>
                Select Packaging Type
              </option>
              <option value="UNKNOWN">UNKNOWN</option>
              <option value="UPS Letter">UPS Letter</option>
              <option value="Tube">Tube</option>
              <option value="Pak">Pak</option>
              <option value="Express Box">Express Box</option>
              <option value="25KG Box">25KG Box</option>
              <option value="10KG Box">10KG Box</option>
              <option value="Pallet">Pallet</option>
              <option value="Small Express Box">Small Express Box</option>
              <option value="Medium Express Box">Medium Express Box</option>
              <option value="Large Express Box">Large Express Box</option>
            </select>
          ) : null}
        </div>
        <div></div>
      </div>
    ) : null;
  };

  const BoxSizeDD = (check) => {
    const { openStatus } = check;
    return openStatus ? (
      <div>
        <div>
          <label className="m-0 blueText">Box Size</label>
          <Row className="m-0 p-0">
            <Col className="p-0 m-0" sm={8} md={8} lg={8}>
              <p className="m-0" style={{ textAlign: "left", fontSize: "10px" }}>
                Size (inch)
              </p>
              <select
                className="m-0 mb-1 shipping-input"
                value={boxSize}
                onChange={(e) => {
                  setBoxSize(e.target.value);
                  if (e.target.value === "6x6x6") {
                    setWidth("6");
                    setLength("6");
                    setHeight("6");
                    getShippingRate(
                      shippingType,
                      packaging,
                      e.target.value[0],
                      e.target.value[2],
                      e.target.value[4],
                      weight
                    );
                    setCustomSizeStatus(false);
                  } else if (e.target.value === "8x8x8") {
                    setWidth("8");
                    setLength("8");
                    setHeight("8");
                    getShippingRate(
                      shippingType,
                      packaging,
                      e.target.value[0],
                      e.target.value[2],
                      e.target.value[4],
                      weight
                    );
                    setCustomSizeStatus(false);
                  } else if (e.target.value === "customSize") {
                    setCustomSizeStatus(true);
                  }
                }}
                required={false}
              >
                {" "}
                <option value="" disabled>
                  Select Box Size
                </option>
                <option value="6x6x6">6x6x6</option>
                <option value="8x8x8">8x8x8</option>
                <option value="customSize">Custom Size</option>
              </select>
            </Col>
            <Col
              className="pl-1 m-0"
              sm={4}
              md={4}
              lg={4}
              style={{ display: boxSize === "customSize" ? "none" : "block" }}
            >
              <p className="m-0" style={{ textAlign: "left", fontSize: "10px" }}>
                Weight(lb)
              </p>
              <input
                type="text"
                className="m-0 mb-1  shipping-input"
                style={{ margin: "0px" }}
                placeholder="lb"
                value={weight}
                onChange={(e) => {
                  getShippingRate(shippingType, packaging, length, width, height, e.target.value);
                  setWeight(e.target.value);
                }}
                required={boxSizeDDStatus && boxSize !== "customSize" ? true : false}
              />
            </Col>
          </Row>
        </div>
        <div></div>
      </div>
    ) : null;
  };

  return (
    <Modal
      isOpen={props.openShippingModal}
      size="md"
      className="largeHeightmodal"
      centered={true}
      style={{ marginTop: "70px" }}
    >
      {showSpinner ? <Spinner /> : null}
      <button
        type="button"
        onClick={() => {
          props.setOpenShippingModal(false);
          props.setSelectedPrescriptions({});
          props.setSelectedShippingInfo("");
          props.setSelectedShippingCost("");
          setCustomShippingCostStatus(false);
          setMainPage(true);
          setShippingTypeDDStatus(false);
          setCustomShippingCostStatus(false);
          setPackagingTypeDDStatus(false);
          setError("");
        }}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body text-center p-3">
        {success ? (
          <>
            {" "}
            <Lottie name="success-check" />
            <p className="my-1">{success}</p>{" "}
          </>
        ) : (
          <>
            <h6 className="semiBold blueText text-center mb-5">Confirm Shipping Information</h6>
            {error ? (
              <Alert color="danger" className="text-center">
                {error}
              </Alert>
            ) : null}
            <div className="mainScreen">
              <Row className="justify-content-center">
                <Col xs={10}>
                  <CustomShippingAddress
                    isPickup={isPickup}
                    defaultShipToClinic={shipToClinic}
                    isBulkOrder={props.selectedPrescriptions.length > 1}
                    selectedShippingInfo={props.selectedShippingInfo}
                    setShippingAddress={setShippingAddress}
                    shippingAddress={shippingAddress}
                    sameAddress={sameAddressState}
                    setSameAddressState={setSameAddressState}
                  />
                </Col>
              </Row>
              <Row style={{ textAlign: "center" }} className="mb-4  modelText">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleOrders(e, props.selectedPrescriptions);
                  }}
                >
                  <Row>
                    <FormGroup>
                      <Row>
                        <Col md={2} lg={2}>
                          <Card>
                            <Label check>
                              {" "}
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  type="radio"
                                  className="mx-2"
                                  checked={
                                    !isPickup &&
                                    !shippingType.toLowerCase().includes("usps") &&
                                    !shippingType.toLowerCase().includes("ups") &&
                                    !shippingType.toLowerCase().includes("custo")
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShippingType("FEDEX_2_DAY");
                                      getShippingRate("FEDEX_2_DAY", packaging, length, width, height, weight);
                                      handleFedexShipType();
                                    }
                                  }}
                                />
                                <img src={fedex} width="40" height="20" />
                              </div>
                            </Label>
                          </Card>
                        </Col>
                        <Col md={2} lg={2}>
                          {" "}
                          <Card>
                            <Label check>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  type="radio"
                                  className="mx-2"
                                  checked={shippingType?.toLowerCase()?.includes("ups")}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShippingType("UPS 2nd Day Air");
                                      getShippingRate("UPS 2nd Day Air", packaging, length, width, height, weight);
                                      handleUpsShipType();
                                    }
                                  }}
                                />

                                <img src={ups} width="40" height="20" />
                              </div>
                            </Label>
                          </Card>
                        </Col>
                        <Col md={2} lg={2}>
                          {" "}
                          <Card>
                            <Label check>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  type="radio"
                                  className="mx-2"
                                  checked={shippingType.toLowerCase().includes("usps")}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShippingType("USPS 1-Day (PO BOX ONLY)");
                                      getShippingRate(
                                        "USPS 1-Day (PO BOX ONLY)",
                                        packaging,
                                        length,
                                        width,
                                        height,
                                        weight
                                      );
                                      handleUspsShipType();
                                    }
                                  }}
                                />{" "}
                                <img src={usps} width="40" height="20" />
                              </div>
                            </Label>
                          </Card>
                        </Col>
                        <Col md={2} lg={2}>
                          <Card>
                            <Label check>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  className="mx-2"
                                  type="radio"
                                  checked={isPickup}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShippingType("PickUp");
                                      handlePickupShipType();
                                      getShippingRate("PickUp", packaging, length, width, height, weight);
                                    }
                                  }}
                                />
                                <p style={{ fontSize: "10px" }} width="40" height="20" className="p-0 m-0">
                                  Pickup
                                </p>
                              </div>
                            </Label>
                          </Card>
                        </Col>
                        <Col md={1} lg={1}>
                          <Card>
                            <Label check>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                  className="mx-2"
                                  type="radio"
                                  checked={shippingType.toLowerCase().includes("custom")}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShippingType("CustomRate");
                                      handleCustomShipType();
                                    }
                                  }}
                                />
                                <p style={{ fontSize: "10px" }} width="40" height="20" className="p-0 m-0">
                                  Custom
                                </p>
                              </div>
                            </Label>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Row>
                  <Row style={{ textAlign: "left" }}>
                    <Col xs={4} sm={4} md={3} lg={3}></Col>
                    <Col xs={4} sm={4} md={6} lg={6}>
                      {shippingTypeDDStatus ? <ShippingTypeDD openStatus={true} /> : null}
                      {packagingTypeDDStatus ? <PackagingTypeDD openStatus={true} /> : null}
                      {boxSizeDDStatus ? <BoxSizeDD openStatus={true} /> : null}
                      {customSizeStatus ? (
                        <CustomSize
                          width={width}
                          length={length}
                          height={height}
                          weight={weight}
                          openStatus={true}
                          setWidth={setWidth}
                          setWeight={setWeight}
                          setHeight={setHeight}
                          setLength={setLength}
                          packaging={packaging}
                          shippingType={shippingType}
                          customSizeStatus={customSizeStatus}
                          getShippingRate={getShippingRate}
                        />
                      ) : null}
                      {customShippingCostStatus ? (
                        <CustomShippingCost
                          openStatus={true}
                          setShippingCost={setShippingCost}
                          customShippingCost={customShippingCost}
                          setCustomShippingCost={setCustomShippingCost}
                          sameAddress={sameAddressState}
                          setSameAddressState={setSameAddressState}
                          oldShippingAddress={props.selectedShippingInfo?.shippingAddress}
                        />
                      ) : null}
                      <hr></hr>
                      <p className="mt-2">
                        {!disableProceed ? (
                          <>
                            Shipping Cost : <strong> ${shippingCost} </strong>
                          </>
                        ) : null}
                        {!customShippingCostStatus ? (
                          <Tooltip title="Refresh Shipping Rate">
                            <i
                              style={{ float: "right" }}
                              className="fa fa-calculator blueText pointer"
                              onClick={() => {
                                getShippingRate(shippingType, packaging, length, width, height, weight, true);
                              }}
                            ></i>
                          </Tooltip>
                        ) : null}
                      </p>
                    </Col>
                    <Col xs={4} sm={4} md={3} lg={3}></Col>
                  </Row>
                  <p className="redText">{NOTES.RX_WORKFLOW_PAYMENT}</p>
                  <button disabled={disableProceed} type="submit" className="blueButton mt-2">
                    Ready to Proceed
                  </button>
                </form>
              </Row>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UpdateShippingModal;

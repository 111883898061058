/* eslint-disable react-hooks/exhaustive-deps */
import { filter } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import MetaTags from "react-meta-tags";
import PaginationComponent from "react-reactstrap-pagination";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useDeletePatientMutation, useLazyGetAllPatientsListQuery } from "rtk";
import { cookies, USER_ROLES } from "utils";
import AdminNavbar from "../../../components/AdminNavbar";
import PrimaryModal from "../../../components/primaryModal";
import Spinner from "../../../components/spinner";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import { CLINIC_PRESCRIPTION_ACTIONS, DEFAULT_SORTED, TABLE_HEADER } from "./patient-constant";
import PrescriptionOrderView from "./prescriptionOrderView";
import { ROWS_LIMIT } from "utils";

function PatientsList() {
  const { clinicId = "" } = useParams();
  const { state: { isPrescription = false, clinicLocationId = "" } = {} } = useLocation();
  const [error, setError] = useState("");
  const [patientId, setPatientId] = useState("");
  const [filteredData, setFilterData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disableFilter, setDisableFilter] = useState(true);
  const [apiParam, setApiParam] = useState();
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: filteredData.length,
    custom: true,
  });
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const isClinic = userRole.toLowerCase().includes("clinic");
  const [
    fetchList,
    {
      data: { pagination, patients } = { pagination: {}, patients: [] },
      error: fetchPatientError,
      isError,
      isFetching,
    },
  ] = useLazyGetAllPatientsListQuery();
  const [deleteMutate, { isLoading, isError: isDeleteError, error: deleteError }] = useDeletePatientMutation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [rowLimit, SetRowLimit] = useState(ROWS_LIMIT[0]);
  const showClinicView = useMemo(() => clinicId || isClinic, [clinicId, isClinic]);
  const showLoading = useMemo(() => isFetching || showSpinner || isLoading, [isLoading, isFetching, showSpinner]);
  const showError = useMemo(() => isError || isDeleteError, [isError, isDeleteError]);
  const errorMessage = useMemo(
    () => error || fetchPatientError?.data?.message || deleteError?.data?.message,
    [error, fetchPatientError?.data?.message, deleteError?.data?.message]
  );
  const [patientActions, setPatientActions] = useState({
    text: "Actions",
    dataField: "actions",
    headerStyle: () => {
      return { width: "80px", textAlign: "center" };
    },
    formatter: (cellContent, row) => (
      <>
        <Link to={"/patient-profile/" + row.id} className="pinkButton">
          Profile
        </Link>
        <Link to={"/edit-patient/" + row.id}>
          <i className="fas fa-edit medText" style={{ fontSize: "18px", margin: "0 10px" }} />
        </Link>

        {userRole === USER_ROLES.ADMIN_SUPER_USER ||
        userRole === USER_ROLES.PHARMACIST ||
        userRole === USER_ROLES.RX_TECH ? (
          <Link
            to="#"
            onClick={() => {
              toggleDeleteModal(row.id);
            }}
          >
            <i className="fas fa-trash redText" style={{ fontSize: "20px" }}></i>
          </Link>
        ) : (
          <p>Disabled</p>
        )}
      </>
    ),
  });

  const tableHeader = useMemo(
    () => [...TABLE_HEADER, isPrescription ? CLINIC_PRESCRIPTION_ACTIONS : patientActions],
    [isPrescription]
  );

  const getPatients = useCallback(
    async (keyword = "", limit = 10, pageNo = 1) => {
      setApiParam((prev) => ({ ...prev, keyword, page: pageNo }));
      setPageOptions((prev) => ({ ...prev, sizePerPage: limit }));
      let body1 = {
        keyword: keyword,
        limit: limit,
        currentPage: pageNo,
      };
      if (!keyword) delete body1.keyword;
      let response = await fetchList({ showClinicView: showClinicView, body: body1 });
      if (response?.data) {
        setFilterData(response?.data?.patients);
      }
    },
    [clinicId]
  );
  const loadMore = useCallback(() => {
    const nextPage = pagination?.hasNextPage ? pagination?.currentPage + 1 : null;
    if (!isFetching && nextPage) {
      fetchList({
        showClinicView: showClinicView,
        body: { keyword: apiParam?.keyword, currentPage: nextPage, limit: rowLimit },
      });
    }
  }, [fetchList, isFetching, pagination?.currentPage, pagination?.hasNextPage, rowLimit, apiParam?.keyword]);

  const handleChange = useCallback(
    (e) => {
      const size = Number(e.currentTarget.textContent);
      SetRowLimit(size);
      fetchList({ showClinicView: showClinicView, body: { keyword: apiParam?.keyword, currentPage: 1, limit: size } });
    },
    [fetchList, apiParam?.keyword]
  );
  const toggle = useCallback(() => setDropdownOpen((prevState) => !prevState), []);
  useEffect(() => {
    getPatients();
  }, [clinicId, getPatients]);

  const toggleDeleteModal = useCallback((patientId = "") => {
    setPatientId(patientId);
    setDeleteModal(Boolean(patientId));
  }, []);

  const deletePatient = () => {
    toggleDeleteModal();
    deleteMutate({ patientId, pageName: "AdminPatient" });
  };

  const handlePageChange = (pageNo) => {
    setApiParam((prev) => ({ ...prev, page: pageNo }));
    getPatients(apiParam?.keyword, pageOptions?.sizePerPage, pageNo);
  };

  const filterChanged = (v = "") => {
    setDisableFilter(v.length === 0 ? true : false);
  };

  const clearFilter = () => {
    getPatients();
  };
  const clearSearch = (keyword) => {
    if (keyword === "") {
      this.filterChanged(keyword);
      this.getPatients();
    }
  };

  const onRowSelection = useCallback(
    (row, isSelect) => setSelectedPatient((prev) => (isSelect ? [...prev, row.id] : filter(prev, (x) => x !== row.id))),
    []
  );
  const onAllRowSelection = useCallback(
    (isSelect, rows) => setSelectedPatient(isSelect ? rows?.map((row) => row.id) : []),
    []
  );

  const rowSelection = useMemo(
    () =>
      isPrescription
        ? {
            mode: "checkbox",
            clickToSelect: true,
            selected: selectedPatient,
            onSelect: onRowSelection,
            onSelectAll: onAllRowSelection,
          }
        : undefined,
    [isPrescription, selectedPatient]
  );

  return (
    <React.Fragment>
      <AdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Patients | Get Royal Health</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">PATIENTS</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {showLoading ? <Spinner /> : null}
                  {showError ? (
                    <Alert color="danger" className="text-center">
                      {errorMessage}
                    </Alert>
                  ) : null}

                  <React.Fragment>
                    {clinicId && (
                      <Row className={`px-3 my-4 justify-content-${isPrescription ? "start" : "end"}`}>
                        <PrescriptionOrderView
                          clinicId={clinicId}
                          showView={isPrescription}
                          clinicLocationId={clinicLocationId}
                          selectedIds={selectedPatient}
                        />
                        {/* {!isPrescription && (
                          <Link
                            to={{
                              pathname: `/add-patient/${clinicId}`,
                              state: {
                                clinicLocationId,
                              },
                            }}
                            className="pinkButton"
                          >
                            Add patient
                          </Link>
                        )} */}
                      </Row>
                    )}
                    <Row className="justify-content-between px-3 my-3">
                      <Row className="my-4 px-sm-3 px-1">
                        <Col xs={8} sm={9} md={4} lg={2}>
                          <div className="tableSearch d-flex">
                            <i className="bx bx-search-alt search-icon flexJCAC" />
                            <input
                              className="borderless mx-2"
                              onChange={(e) => {
                                setApiParam((prev) => ({ ...prev, keyword: e.target.value }));
                                filterChanged(e.target.value);
                                clearSearch(e.target.value);
                              }}
                              value={apiParam?.keyword}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  getPatients(apiParam?.keyword, pageOptions?.sizePerPage, apiParam?.page);
                                }
                              }}
                              placeholder="Search table"
                            />
                          </div>
                        </Col>
                        <Col xs={4} sm={3} md={4} lg={2}>
                          <button
                            disabled={disableFilter}
                            className="yellowButton"
                            onClick={() => {
                              getPatients(apiParam?.keyword, pageOptions?.sizePerPage, apiParam?.page);
                            }}
                          >
                            Search
                          </button>
                        </Col>
                        {!disableFilter && (
                          <Col xs={12} sm={1} lg={1}>
                            <p
                              disabled={disableFilter}
                              className="blueText"
                              style={{
                                fontSize: 14,
                                cursor: "pointer",
                                paddingTop: 8,
                                marginLeft: 10,
                              }}
                              onClick={() => {
                                clearFilter();
                                setDisableFilter(true);
                              }}
                            >
                              Clear
                            </p>
                          </Col>
                        )}
                      </Row>
                    </Row>
                    {/* <div className="my-4 d-flex align-items-center flex-row ">
                      <p className="mx-3 mt-3">Rows: </p>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                        <DropdownToggle caret color="primary">
                          {rowLimit} <i className="fas fa-angle-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {ROWS_LIMIT.map((size) => (
                            <DropdownItem key={size} onClick={handleChange}>
                              {size}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive" style={{ minHeight: "300px" }}>
                          <BootstrapTable
                            responsive
                            selectRow={rowSelection}
                            keyField={"id"}
                            bordered={false}
                            striped={false}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            columns={tableHeader}
                            data={patients}
                          />
                          {patients.length === 0 ? (
                            <p className="w-100 text-center m-5">No patients data found!</p>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3 px-3">
                      <div className="text-center ms-auto">
                        {pagination?.hasNextPage ? (
                          <span style={{ cursor: "pointer" }} className="blueButton" onClick={loadMore}>
                            Load more
                          </span>
                        ) : null}
                      </div>
                    </Row>
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {!isPrescription && (
        <PrimaryModal
          question="Are you sure you want to delete this patient?"
          showModal={deleteModal}
          closeModal={toggleDeleteModal}
          onClickYes={deletePatient}
        />
      )}
    </React.Fragment>
  );
}

export default PatientsList;

/* eslint-disable react-hooks/exhaustive-deps */
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { BAR_COLORS } from "../../../../utils/constants";

const BarChart = ({ rxData = [], hide }) => {
  const history = useHistory();
  const [options, setOptions] = useState("");
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (rxData.length > 0) {
      let dataList = [];
      let labelList = [];
      sortBy(rxData, ["rxStatus"]).forEach((res) => {
        dataList.push({ y: res.count, x: res?.rxStatus, fillColor: BAR_COLORS[res.rxStatus] });
        labelList.push(res.rxStatus);
      });

      setSeries([
        {
          name: "Count",
          data: dataList,
        },
      ]);
      setOptions({
        chart: {
          type: "bar",
          height: 350,
          events: {
            dataPointSelection: function (event, chartContext, obj) {
              const filterValue = obj.w.config.labels[obj.dataPointIndex];
              if (obj.dataPointIndex === 1) {
                return history.push({
                  pathname: "/prescription-archive",
                  state: { activeTab: 2 },
                });
              } else if (obj.dataPointIndex === 2) {
                return history.push({
                  pathname: "/prescription-archive",
                  state: { activeTab: 1 },
                });
              } else {
                return history.push({
                  pathname: "/pending-prescriptions",
                  state: { filter: filterValue },
                });
              }
            },
          },
        },
        plotOptions: {
          bar: {
            barHeight: 20,
            borderRadius: 4,
            horizontal: true,
          },
        },
        labels: labelList,

        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: labelList,
        },
        yaxis: {
          categories: labelList,
          tooltip: {
            enabled: true,
          },
        },
      });
    }
  }, [rxData]);

  if (hide) {
    return null;
  }
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {options && series ? <Chart options={options} series={series} type="bar" width="700" /> : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BarChart);

import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import Lottie from "../../../components/lottie";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import AdminNavbar from "../../../components/AdminNavbar";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminRolesDD from "components/dropdowns/adminRoles";
import { USER_ROLES } from "utils";
import { userInfoSelector, userRoleSelector } from "selectors";
import { useSelector } from "react-redux";

const EditAdmin = (props) => {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [admin, setAdmin] = useState({});
  const userRole = useSelector(userRoleSelector);
  useEffect(() => {
    setShowSpinner(true);

    axiosConfig
      .get("admin/account/getAdminByAdminId/" + props.match.params.id, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async (response) => {
        setAdmin(response.data);

        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
        setShowSpinner(false);
      });
  }, []);

  async function handleValidSubmit(e) {
    e.preventDefault();
    setShowSpinner(true);

    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("registerForm"), { hash: true });
    await axiosConfig
      .post(
        "admin/account/editAdminByAdminId",
        {
          adminId: props.match.params.id,
          role: USER_ROLES.ADMIN_SUPER_USER === userRole ? obj.role : admin.role,
          firstName: obj.firstName,
          middleName: obj.middleName ? obj.middleName : "",
          lastName: obj.lastName,
          email: obj.email,
          password: obj.password,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        
        setShowSpinner(false);
        setError("");
        setComplete(true);
      })
      .catch((error) => {
        setError("An error occurred while trying to update admin! " + error?.response?.data?.message);
        setShowSpinner(false);
        errorResponse(error);
      });
  }

  /**/
  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Profile | Get Royal Health</title>
      </MetaTags>
      <AdminNavbar />
      {showSpinner ? <Spinner /> : null}
      <div className="page-content">
        <Container fluid>
          <h4 className="p-3 text-muted">EDIT</h4>
          {showSpinner ? <Spinner /> : null}
          <Row>
            <Col xs="12">
              <Card>
                <Container>
                  <CardBody>
                    {complete ? (
                      <div className="m-5 p-5 text-center">
                        <Lottie name="success-check" />
                        <p className="my-5">Your profile has been updated</p>{" "}
                        <Link to="/profile" className="pinkButton">
                          Back to profile
                        </Link>
                      </div>
                    ) : (
                      <>
                        <CardBody className="pt-0">
                          <div className="">
                            <form
                              id="registerForm"
                              className="form-horizontal "
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (password !== confirmPassword) {
                                  setError("Passwords do not match");
                                } else {
                                  setError("");
                                  handleValidSubmit(e);
                                }
                              }}
                            >
                              {error ? (
                                <Alert color="danger" className="text-center">
                                  {error}
                                </Alert>
                              ) : null}
                              <div className="px-lg-5 px-sm-5 p-0">
                                <Row className="pt-5">
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="firstName" className="form-label">
                                        First name <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        defaultValue={admin.firstName}
                                        name="firstName"
                                        className="form-control"
                                        type="text"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="middleName" className="form-label">
                                        Middle name
                                      </Label>
                                      <Input
                                        defaultValue={admin.middleName}
                                        name="middleName"
                                        className="form-control"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="lastName" className="form-label">
                                        Last name <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        defaultValue={admin.lastName}
                                        name="lastName"
                                        className="form-control"
                                        type="text"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="email" className="form-label">
                                        Email <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        defaultValue={admin.email}
                                        name="email"
                                        className="form-control"
                                        type="email"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="password" className="form-label">
                                        Password
                                      </Label>
                                      <Input
                                        name="password"
                                        className="form-control"
                                        type="password"
                                        placeholder=""
                                        onChange={(e) => setPassword(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="confirmPassword" className="form-label">
                                        Confirm Password
                                      </Label>
                                      <Input
                                        name="confirmPassword"
                                        className="form-control"
                                        type="password"
                                        placeholder="Retype your password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {userRole == USER_ROLES.ADMIN_SUPER_USER ?
                                  <Col md={6} className="mb-4">
                                    <div className="">
                                      <AdminRolesDD name="role" defaultValue={admin.role} />
                                    </div>
                                  </Col>
                                  : <Input type="hidden" name="role" value={admin.role} />}
                                </Row>
                              </div>
                              <Row className="my-5 text-center pt-3">
                                <button
                                  className="btn btn-primary darkBg mt-4 mx-auto"
                                  style={{ width: "40%" }}
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </Row>
                            </form>
                            <Row className="text-center">
                              <Col>
                                <button onClick={() => props.history.goBack()} className="text-center pinkButton">
                                  Back
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </>
                    )}
                  </CardBody>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditAdmin;

import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";

const AlertMessage = (props) => {
  const { msg = "", isError = false } = props;
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (msg) {
      setSuccess(Boolean(msg));
    }
  }, [msg]);

  if (!success || !msg) {
    return null;
  }

  return (
    <Alert
      color={isError ? "danger" : "success"}
      className="text-center"
      isOpen={success ? true : false}
      toggle={() => setSuccess(false)}
    >
      {msg}
    </Alert>
  );
};
export default AlertMessage;

import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const AdminApi = createApi({
  reducerPath: "AdminApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [
    CACHE_TAGS.ADMIN,
    CACHE_TAGS.PRESCRIPTION,
    CACHE_TAGS.ARCHIVED,
    CACHE_TAGS.RX_WORKFLOW,
    CACHE_TAGS.INVOICE,
  ],
});

export default AdminApi;

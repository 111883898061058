import { AdminApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, END_POINTS, EXPORT_END_POINT, downloadFile, providesList } from "../../rtkConstant";

const AdminInvoiceQuery = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoiceList: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          let url;
          url = END_POINTS.ADMIN_INVOICE_LIST;
          const response = await baseQuery({
            url,
            body: apiArgs.payload,
            method: API_METHODS.POST,
          });

          return response;
        } catch {}
      },
      providesTags: (result) => providesList(result.prescriptions, CACHE_TAGS.INVOICE),
    }),
    adminPayNow: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          let url;
          url = END_POINTS.ADMIN_PAY_NOW;
          const response = await baseQuery({
            url,
            body: apiArgs.payload,
            method: API_METHODS.POST,
          });

          return response;
        } catch {}
      },
    }),
    getExportInvoiceList: builder.query({
      query: (body) => ({
        url: EXPORT_END_POINT.ADMIN_EXPORT_INVOICE,
        method: API_METHODS.POST,
        body,
        responseHandler: (response) => downloadFile(response, "invoices_list.csv"),
      }),
    }),
    mostDueInvoice: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.MOST_DUE_INVOICE,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    getBalanceDue: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.GET_BALANCE_DUE,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    viewCurrentStatement: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.VIEW_CURRENT_STATEMENT,
        method: API_METHODS.GET,
        responseHandler: (response) => response.text(),
      }),
    }),
    viewPaymentHistory: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.VIEW_PAYMENT_HISTORY,
        method: API_METHODS.GET,
        responseHandler: (response) => response.text(),
      }),
    }),
    viewPaidInvoices: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.VIEW_PAID_INVOICES,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    getPaymentStatuses: builder.query({
      query: () => ({
        url: END_POINTS.PAYMENT_STATUSES,
        method: API_METHODS.GET,
      }),
    }),
  }),
});
export const {
  useGetPaymentStatusesQuery,
  useLazyGetAllInvoiceListQuery,
  useLazyAdminPayNowQuery,
  useLazyGetExportInvoiceListQuery,
  useLazyMostDueInvoiceQuery,
  useLazyGetBalanceDueQuery,
  useLazyViewCurrentStatementQuery,
  useLazyViewPaidInvoicesQuery,
  useLazyViewPaymentHistoryQuery,
} = AdminInvoiceQuery;

import { AdminApi } from "../../rtkApi";
import { ADMIN_END_POINTS, API_METHODS, END_POINTS } from "../../rtkConstant";

const AdminProductsMutation = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    editDiscountByProductDiscountId: builder.mutation({
      query: (apiArgs) => ({
        url: END_POINTS.EDIT_CLINIC_PRODUCTS,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    changePricingGroupByClinic: builder.mutation({
      query: (apiArgs) => ({
        url: END_POINTS.CHANGE_PRICING_GROUP,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    changeStatus: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.CHANGE_PRODUCT_STATUS,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      // async onQueryStarted({ pageName = "", clinicId = "" }, { queryFulfilled, dispatch, getState }) {
      //   try {
      //     if (pageName) {
      //       await queryFulfilled;

      //       const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
      //       let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
      //         { type: CACHE_TAGS.CLINIC, id: clinicId },
      //       ]);

      //       if (queryCache.length > 1) {
      //         queryCache = queryCache.find((query) => query.endpointName === listingEndpoint);
      //       } else {
      //         queryCache = queryCache[0];
      //       }

      //       dispatch(
      //         AdminApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache.originalArgs, (listData) => {
      //           return {
      //             ...listData,
      //             clinicListing: listData.clinicListing?.filter((record) => record?.id !== clinicId),
      //           };
      //         })
      //       );
      //     }
      //   } catch {}
      // },
    }),
    deleteProduct: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.DELETE_PRODUCT,
              method: API_METHODS.DELETE,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      // async onQueryStarted({ pageName = "", clinicId = "" }, { queryFulfilled, dispatch, getState }) {
      //   try {
      //     if (pageName) {
      //       await queryFulfilled;

      //       const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
      //       let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
      //         { type: CACHE_TAGS.CLINIC, id: clinicId },
      //       ]);

      //       if (queryCache.length > 1) {
      //         queryCache = queryCache.find((query) => query.endpointName === listingEndpoint);
      //       } else {
      //         queryCache = queryCache[0];
      //       }

      //       dispatch(
      //         AdminApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache.originalArgs, (listData) => {
      //           return {
      //             ...listData,
      //             clinicListing: listData.clinicListing?.filter((record) => record?.id !== clinicId),
      //           };
      //         })
      //       );
      //     }
      //   } catch {}
      // },
    }),
  }),
});
export const { useEditDiscountByProductDiscountIdMutation, useChangePricingGroupByClinicMutation, useChangeStatusMutation, useDeleteProductMutation } =
  AdminProductsMutation;

import MetaTags from "react-meta-tags";
import React, { useState, useMemo } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from "reactstrap";
import LoggedOutNavbar from "../../components/loggedOutNavbar";
import Lottie from "../../components/lottie";
import Spinner from "../../components/spinner";
import { useResetPasswordMutation } from "rtk";
import GRHLogo from '../../assets/images/logo.svg';
const RecoverPassword = (props) => {
  const [error, setError] = useState("");
  const [resetPasswordMutate, { isLoading, error: mutateError, isError, isSuccess, data }] = useResetPasswordMutation();
  const errorMessage = useMemo(() => mutateError?.data?.message, [mutateError?.data?.message]);
  const showError = useMemo(() => isError || error, [isError, error]);
  function handleValidSubmit(e) {
    e.preventDefault();
    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("RecoverPasswordForm"), { hash: true });
    if (obj.password !== obj.confirmPassword) {
      setError("Passwords do not match, Please try again!");
    } else {
      resetPasswordMutate({
        token: props.match.params.token,
        password: obj.password,
        confirmPassword: obj.confirmPassword,
      });
    }
  }

  return (
    <div>
      <MetaTags>
        <title>Reset Password | Get Royal Health</title>
      </MetaTags>
      {/* <LoggedOutNavbar /> */}
      {isLoading ? <Spinner /> : null}
      <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden auth-card">
                <div className="p-4 text-center">
                  <div>
                    <img src={GRHLogo} alt="get-royal-health" width={60} height={60}/>
                  </div>
                  <h3 className="text-primary mx-4 mt-3 mb-0 text-dark">Reset Password</h3>
                  {isSuccess ? (
                    <div>
                      <Lottie name="success-check" />
                      <p className="mt-5 mx-sm-4 mx-2">Your new password has been set successfully</p>
                      <p className="mt-2 mx-4">Please login to continue.</p>
                    </div>
                  ) : (
                    <div>
                      <p className="mt-4 mb-0 mx-4">Please set your new password below.</p>
                    </div>
                  )}
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <form
                      id="RecoverPasswordForm"
                      className="form-horizontal px-sm-5 px-2"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      {showError ? (
                        <Alert color="danger" className="text-center mb-4">
                          {errorMessage}
                        </Alert>
                      ) : null}
                      {isSuccess ? null : (
                        <div className="mb-0 px-4">
                          <div className="mt-3">
                            <FormGroup>
                              <Label htmlFor="password" className="form-label">
                                New Password <span className="requiredStar">*</span>
                              </Label>
                              <Input name="password" className="form-control" type="password" placeholder="" required />
                            </FormGroup>
                          </div>
                          <div className="mt-3">
                            <FormGroup>
                              <Label htmlFor="confirmPassword" className="form-label">
                                Confirm Password <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="confirmPassword"
                                className="form-control"
                                type="password"
                                placeholder=""
                                required
                              />
                            </FormGroup>
                          </div>
                        </div>
                      )}
                      {isSuccess ? (
                        <Row className="m-4">
                          <button
                            className="btn btn-primary darkBg"
                            onClick={() => {
                              props.history.push("/logout");
                            }}
                          >
                            Go to Login
                          </button>
                        </Row>
                      ) : (
                        <Row className="m-5 mt-0">
                          <button className="btn btn-primary darkBg mt-5" type="submit">
                            Change Password
                          </button>
                          <button
                            className="btn btn-outline-dark mt-2"
                            onClick={() => {
                              props.history.push("/logout");
                            }}
                          >
                            Back to login
                          </button>
                        </Row>
                      )}
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RecoverPassword;

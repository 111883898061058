import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import axios from "axios";
import { API_METHODS, BASE_URL, END_POINTS } from "../rtkConstant";
import { AuthSliceActions } from "../rtkSlices";
import { logout, refreshTokenCookies, writeCookie } from "../../utils";
import { COOKIES_NAME } from "../../utils/cookieConstant";
import AuthApi from "./AuthApi";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

const fetchBase = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().AuthSlice.jwtToken;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQuery = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await fetchBase(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const token = api.getState().AuthSlice.userData?.refreshToken;
        // try to get a new token
        const refreshResult = await fetchBase(
          { url: END_POINTS.REFRESH_TOKEN, method: API_METHODS.POST, body: { token } },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          const { jwtToken, ...userData } = refreshResult.data;
          api.dispatch(AuthSliceActions.refreshToken({ jwtToken: jwtToken, userData: userData }));
          // retry the initial query
          result = await fetchBase(args, api, extraOptions);
          refreshTokenCookies(jwtToken, userData?.refreshToken);
          axios.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
        } else {
          api.dispatch(AuthApi.endpoints.userLogout.initiate());
          logout();
          window.location.replace("/login");
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await fetchBase(args, api, extraOptions);
    }
  }
  return result;
};

export { fetchBase, baseQuery };

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { useUserLoginMutation } from "../../rtk";
import { COOKIES_NAME } from "utils/cookieConstant";
import Spinner from "../../components/spinner";
import { cookies } from "../../utils/";
import GRHLogo from '../../assets/images/logo.svg';

const Login = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const history = useHistory();
  const [loginMutate, { isLoading, error: mutateError, isError, isSuccess, data }] = useUserLoginMutation();
  
  useEffect(() => {
    if (cookies.get(COOKIES_NAME.IS_LOGGED) === "true") {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (isError) {
      setError(mutateError?.data?.message || (mutateError?.status == 'FETCH_ERROR' ? 'Something went wrong, Please try later.': mutateError?.error));
    } else if (isSuccess) {
      if (!data.isResetPassword) history.push("/");
    }
  }, [data, isError, isSuccess, mutateError]);

  const handleValidSubmit = (e) => {
    e.preventDefault();
    loginMutate({ email: email, password: pass});
  };

  return (
    <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
      <MetaTags>
        <title>Admin Login | Get Royal Health</title>
      </MetaTags>
      {isLoading ? <Spinner /> : null}
        <>
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden login-card">
                  <div className="p-4 text-center">
                    <div>
                      <img src={GRHLogo} alt="get-royal-health" width={60} height={60}/>
                    </div>
                    <h3 className="text-primary my-3 mx-5 text-dark">Login</h3>
                  </div>
                  <CardBody className="pt-0">
                    <div className="p-2">
                      <form id="loginForm" className="form-horizontal px-sm-5 px-3" onSubmit={handleValidSubmit}>
                        {error?.length > 0 ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3 mt-3">
                          <FormGroup>
                            <Label htmlFor="email" className="form-label">
                              Email Address <span className="requiredStar">*</span>
                            </Label>
                            <Input
                              name="email"
                              onChange={(e) => {
                                setError("");
                                setEmail(e.target.value);
                              }}
                              className="form-control"
                              type="email"
                              placeholder=""
                              required
                            />
                          </FormGroup>
                        </div>
                        <div className="mb-3">
                          <FormGroup>
                            <Label htmlFor="password" className="form-label">
                              Password <span className="requiredStar">*</span>
                            </Label>
                            <Input
                              onChange={(e) => {
                                setError("");
                                setPass(e.target.value);
                              }}
                              name="password"
                              className="form-control"
                              type="password"
                              placeholder=""
                              required
                            />
                          </FormGroup>
                        </div>
                        <div className="mt-5 d-grid">
                          <button className="btn btn-primary darkBg btn-block" type="submit">
                            Log In
                          </button>
                        </div>
                        <div className="mt-5 text-center">
                          <Link
                            to={{
                              pathname: "/forgot-password",
                            }}
                            className="text-muted"
                          >
                            <i className="mdi mdi-lock me-1" /> Forgot your password?
                          </Link>
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
    </div>
  );
};

export default Login;

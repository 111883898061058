import React, { useMemo } from "react";
import { Link } from "react-router-dom";

function PrescriptionOrderView({ showView = false, selectedIds = [], clinicId = null, clinicLocationId = "" }) {
  const showButton = useMemo(() => Boolean(selectedIds.length), [selectedIds.length]);
  if (!showView || !clinicId) {
    return null;
  }
  return showButton ? (
    <Link
      to={{
        pathname: `/new-prescription/${clinicId}`,
        state: {
          clinicId: clinicId,
          isBulkOrder: selectedIds.length > 1 ? true : false,
          selectedPatients: selectedIds,
          clinicLocationId: clinicLocationId,
        },
      }}
      className={`pinkButton mx-2`}
    >
      Order new prescription
    </Link>
  ) : (
    <div className="d-flex align-items-center" style={{ maxWidth: "60%" }}>
      <p className="yellowText " style={{ height: "fit-content" }}>
        Select one or more patients below to start new order
      </p>
    </div>
  );
}

export default React.memo(PrescriptionOrderView);

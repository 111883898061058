export const TOTAL_BALANCE = {
  title: "Total Balance Due",
  buttonText: "PAY FULL BALANCE",
  dummyAmount: "$10000",
};

export const INVOICE_DUE = {
  title: "Total Balance Due",
  buttonText: "PAY FULL BALANCE",
  dummyText: "No Invoice Due",
};

export const PAY_AMOUNT = {
  noSelection: "No Invoice Selected",
  buttonText: "PAY AMOUNT",
  dummyTotalAmount: "$100",
  creditUseButtonText: "USE",
};

export const EXPORTS = {
  paymentHistoryText: "View Payment History/Receipts",
  paidInvoicesText: "View/Download Paid Invoices",
  currentStatementText: "View Current Statement/Aging",
  exportInvoiceText: "Export CSV",
};

export const NOTES = {
  
  PAY_FULL_ADMIN: "",
  PAY_FULL_CLINIC: "",
  PAY_AMOUNT_ADMIN: "",
  PAY_AMOUNT_CLINIC: "",
  UPDATE_CARD: "",
  RX_WORKFLOW_PAYMENT: "",
  NET_30_AUTO: "",
};

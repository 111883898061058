import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
} from "reactstrap";
import classnames from "classnames";
import SuperAdminNavbar from "../../../components/SuperAdminNavbar";
import { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import PrimaryModal from "../../../components/primaryModal";
import { cookies, USER_ROLES } from "../../../utils";
import PatientPrescription from "./PatientPrescription";
import PatientBilling from "./patientBilling";
import { encrypt, decrypt } from "cryptofunc";
import { map } from "lodash";

class PatientProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: "",
      activeTab: "1",
      showSpinner: true,
      error: "",
      confirmDeleteModal: false,
      confirmDeleteModal2: false,
      docType: "",
      delUrl: "",
      patient: "",
      delDoc: false,
      // items:[],
      billingData: [],
      tableHeaders: [
        { text: "id", dataField: "id", sort: true, hidden: true },
        { text: "Bill Number", dataField: "bill_number", sort: true },
        { text: "Date", dataField: "date", sort: true },
        { text: "Total Amount ($)", dataField: "total_amount", sort: true },
        { text: "Discount ($)", dataField: "discount", sort: true },
        { text: "Paid Date", dataField: "paid_date", sort: true },
      ],
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  scrollToRx() {
    if (this.props?.location?.state?.activeTab == "1") {
      this.toggle("1");
      setTimeout(() => {
        document.getElementById("prescriptionHistory")?.scrollIntoView();
      }, 100);
    }
  }
  componentDidMount() {
    if (this.props?.location?.state?.activeTab === "2") {
      this.toggle("2");
    }
    this.getPatient();
  }

  getPatient() {
    axiosConfig
      .post(
        "patients/getPatientByPatientId",
        {
          patientId: this.props.match.params.id,
          userId: new Cookies().get("user_id"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then((response) => {
        const patient = response.data;
        const decryptedArr = patient.creditCards.map((card) => ({
          ...card,
          creditCardNumber: decrypt(card.creditCardNumber),
          cvv: decrypt(card.cvv)
        }));
      
        for (let prop in patient) {
          if (patient[prop] === "") {
            patient[prop] = "-";
          }
        }
        this.setState({
          showSpinner: false,
          patient: patient,
          cards: decryptedArr,
          error: "",
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error:
            "An error occurred while trying to fetch patient profile. " +
            error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }

  deletePatient() {
    this.setState({
      showSpinner: true,
      confirmDeleteModal: false,
    });

    axiosConfig
      .delete("patients/deletePatientByPatientId", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
        data: {
          patientId: this.props.match.params.id,
          userId: new Cookies().get("user_id"),
        },
      })
      .then(async (response) => {
        await this.setState({
          showSpinner: false,
          error:
            "Patient Profile deleted succesfully! Redirecting to patients list!",
          patient: "",
        });

        setTimeout(() => {
          this.props.history.push("/patients");
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error:
            "An error occurred while trying to delete patient. " +
            error?.response?.data?.message,
        });

        errorResponse(error);
      });
  }

  closeConfirmDeleteModal() {
    this.setState({ confirmDeleteModal: false });
  }

  labDel() {
    this.setState({
      showSpinner: true,
      confirmDeleteModal2: false,
      delDoc: true,
    });
    var body = {
      patientId: this.props.match.params.id,
      url: this.state.delUrl,
      documentType: this.state.docType,
    };

    axiosConfig
      .post("patients/deletePatientDocuments", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        await this.setState({
          showSpinner: false,
          delDoc: false,
          error: "Document deleted succesfully!",
          delUrl: "",
          docType: "",
          // patient : this.state.patient,
        });

        setTimeout(() => {
          this.getPatient();
        }, 500);
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error:
            "An error occurred while trying to delete document. " +
            error?.response?.data?.message,
        });

        errorResponse(error);
      });
  }
  closeConfirmDeleteModal2() {
    this.setState({ confirmDeleteModal2: false });
  }

  render() {
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.billingData.length,
      custom: true,
    };
    const defaultSorted = [{ dataField: "id", order: "asc" }];
    const { SearchBar } = Search;
    var patient = this.state.patient;
    var cookies = new Cookies();

    // for file type check
    const validateFile = (fileUrl) => {
      const fileExtension = getFileExtension(fileUrl);
      const isPdf = fileExtension.toLowerCase() === "pdf";

      if (isPdf) {
        console.log("File is a PDF");
        return true;
      } else {
        console.log("File is not a PDF");
        return false;
      }
    };
    // for extracting file name
    const getFileExtension = (url) => {
      const parts = url.split("-");
      return parts[parts.length - 1];
    };

    return (
      <React.Fragment>
        <SuperAdminNavbar />
        <div className="page-content">
          <MetaTags>
            <title>Patient Profile | Get Royal Health</title>
          </MetaTags>
          <Container fluid>
            <h4 className="p-3 text-muted">PATIENT PROFILE</h4>
            {this.state.showSpinner ? <Spinner /> : null}
            {this.state.error ? (
              <Alert color="danger" className="text-center">
                {this.state.error}
              </Alert>
            ) : null}
            {this.state.patient ? (
              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft text-center py-5">
                      <img
                        className="staffProfileImgCircle mb-4"
                        src={
                          patient.patientImageUrl &&
                          patient.patientImageUrl !== "-"
                            ? patient.patientImageUrl
                            : null
                        }
                        alt=""
                      />
                      <h2 className="text-dark mb-3">
                        {patient
                          ? `${patient.firstName || ""} ${
                              patient.lastName || ""
                            }`.trim()
                          : "Loading..."}
                      </h2>
                      <div className="mb-4 flexJCAC">
                        {this.state.patient.isVerified ? (
                          <p
                            className="text-white greenText px-4 my-1"
                            style={{ borderRadius: "5px" }}
                          >
                            Verified
                          </p>
                        ) : (
                          <p
                            className="text-white redText px-4 my-1"
                            style={{ borderRadius: "5px" }}
                          >
                            Not Verified
                          </p>
                        )}
                      </div>
                      <Link
                        to={"/edit-patient/" + patient.id}
                        className="pinkButton"
                      >
                        Edit Profile
                      </Link>
                      {new Cookies().get("user_id") ===
                      USER_ROLES.CLINIC_SUPER_USER ? (
                        <>
                          <p className="my-4"></p>
                          <Link
                            to="#"
                            onClick={() => {
                              this.setState({ confirmDeleteModal: true });
                            }}
                            className="my-4 pinkButton redBackground"
                          >
                            Delete Patient
                          </Link>
                        </>
                      ) : null}
                    </div>
                  </Card>
                  {/* <Card>
                    <CardBody>
                      <CardTitle className="medText">Documents</CardTitle>
                      <div className="InfoRow">
                        <p>Driver License</p>
                      </div>
                      {patient?.driverLicenseFileUrl?.length > 10 ? (
                        <div style={{ display: "flex" }}>
                          <a style={{ marginLeft: "15px" }}>
                            <span>
                              <a
                                href={patient?.driverLicenseFileUrl}
                                target="_blank"
                              >
                                <img
                                  src={file}
                                  alt="file"
                                  width={60}
                                  height={55}
                                  style={{ marginTop: "-10px" }}
                                />
                              </a>
                              <img
                                src={cross}
                                alt="file"
                                width={12}
                                height={12}
                                style={{
                                  marginTop: "-48px",
                                  marginLeft: "-10px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    confirmDeleteModal2: true,
                                    delUrl: patient?.driverLicenseFileUrl,
                                    docType: "driverLicense",
                                  });
                                }}
                              />
                            </span>
                            <p style={{ marginLeft: 10 }}>License</p>
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="InfoRow">
                        <p>Lab Work</p>
                        {/* for file name extraction * /}
                        {/* <p>{getFileExtension(patient.labWork[1])}</p> * /}
                      </div>
                      <div style={{ display: "flex" }}>
                        {patient?.labWork?.map((item, index) => (
                          <a key={index} style={{ marginLeft: "15px" }}>
                            <span>
                              <a href={patient.labWork[index]} target="_blank">
                                <img
                                  src={file}
                                  alt="file"
                                  width={60}
                                  height={55}
                                  style={{ marginTop: "-10px" }}
                                />
                              </a>
                              <img
                                src={cross}
                                alt="file"
                                width={12}
                                height={12}
                                style={{
                                  marginTop: "-48px",
                                  marginLeft: "-10px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    confirmDeleteModal2: true,
                                    delUrl: patient.labWork[index],
                                    docType: "labWork",
                                  });
                                }}
                              />
                            </span>

                            <p style={{ marginLeft: 10 }}>Doc {index + 1}</p>
                          </a>
                        ))}
                      </div>

                      <div className="InfoRow">
                        <p>Soap Notes</p>
                      </div>
                      <div style={{ display: "flex" }}>
                        {patient?.soapNotes?.map((item, index) => (
                          <a key={index} style={{ marginLeft: "15px" }}>
                            <span>
                              <a
                                href={patient.soapNotes[index]}
                                target="_blank"
                              >
                                <img
                                  src={file}
                                  alt="file"
                                  width={60}
                                  height={55}
                                  style={{ marginTop: "-10px" }}
                                />
                              </a>
                              <img
                                src={cross}
                                alt="file"
                                width={12}
                                height={12}
                                style={{
                                  marginTop: "-48px",
                                  marginLeft: "-10px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    confirmDeleteModal2: true,
                                    delUrl: patient.soapNotes[index],
                                    docType: "soapNotes",
                                  });
                                }}
                              />
                            </span>
                            <p style={{ marginLeft: 10 }}>Doc {index + 1}</p>
                          </a>
                        ))}
                      </div>
                      <div className="InfoRow">
                        <p>Physicals</p>
                      </div>
                      <div style={{ display: "flex" }}>
                        {patient?.physicals?.map((item, index) => (
                          <a key={index} style={{ marginLeft: "15px" }}>
                            <span>
                              <a
                                href={patient.physicals[index]}
                                target="_blank"
                              >
                                <img
                                  src={file}
                                  alt="file"
                                  width={60}
                                  height={55}
                                  style={{ marginTop: "-10px" }}
                                />
                              </a>
                              <img
                                src={cross}
                                alt="file"
                                width={12}
                                height={12}
                                style={{
                                  marginTop: "-48px",
                                  marginLeft: "-10px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    confirmDeleteModal2: true,
                                    delUrl: patient.physicals[index],
                                    docType: "physicals",
                                  });
                                }}
                              />
                            </span>
                            <p style={{ marginLeft: 10 }}>Doc {index + 1}</p>
                          </a>
                        ))}
                      </div>
                      <div className="InfoRow">
                        <p>Other Documents</p>
                      </div>
                      <div style={{ display: "flex" }}>
                        {patient?.otherDocuments?.map((item, index) => (
                          <a key={index} style={{ marginLeft: "15px" }}>
                            <span>
                              <a
                                href={patient.otherDocuments[index]}
                                target="_blank"
                              >
                                <img
                                  src={file}
                                  alt="file"
                                  width={60}
                                  height={55}
                                  style={{ marginTop: "-10px" }}
                                />
                              </a>
                              <img
                                src={cross}
                                alt="file"
                                width={12}
                                height={12}
                                style={{
                                  marginTop: "-48px",
                                  marginLeft: "-10px",
                                }}
                                onClick={() => {
                                  this.setState({
                                    confirmDeleteModal2: true,
                                    delUrl: patient.otherDocuments[index],
                                    docType: "otherDocuments",
                                  });
                                }}
                              />
                            </span>
                            <p style={{ marginLeft: 10 }}>Doc {index + 1}</p>
                          </a>
                        ))}
                      </div>
                    </CardBody>
                  </Card> */}
                </Col>
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">
                        Personal Information
                      </CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={12}>
                          {/* <div className="InfoRow">
                            <p>Is Employee</p>
                            {patient.isEmployee ? (
                              <p className="greenText">Yes</p>
                            ) : (
                              <p className="redText">No</p>
                            )}
                          </div>
                          <div className="InfoRow">
                            <p>Physician</p>
                            <p>{`${patient.physicianDetails?.firstName || ""} ${
                              patient.physicianDetails?.lastName || ""
                            }`}</p>
                          </div> */}
                          <div className="InfoRow">
                            <p>First Name</p>
                            <p>{patient.firstName}</p>
                          </div>
                          {/* <div className="InfoRow">
                            <p>Middle Name</p>
                            <p>{patient.middleName}</p>
                          </div> */}
                          <div className="InfoRow">
                            <p>Last Name</p>
                            <p>{patient.lastName}</p>
                          </div>
                          {/* <div className="InfoRow">
                            <p>Guardian Name</p>
                            <p>{patient.guardianName}</p>
                          </div> */}
                          <div className="InfoRow">
                            <p>Date of Birth</p>
                            <p>{patient.dateOfBirth}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Sex</p>
                            <p>{patient.sex}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Marital Status</p>
                            <p>{patient.martialStatus}</p>
                          </div>
                          {/* <div className="InfoRow">
                            <p>Ethnicity</p>
                            <p>{patient.ethnicity}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Race</p>
                            <p>{patient.race}</p>
                          </div> */}
                        </Col>
                        {/* <Col lg={12} xl={6}> */}
                          {/* <div className="InfoRow">
                            <p>Height</p>
                            <p>{patient.height}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Weight</p>
                            <p>{patient.weight}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Current Occupation</p>
                            <p>{patient.currentOccupation}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Current Plan</p>
                            <p>{patient.currentPlan}</p>
                          </div>
                          <div className="InfoRow">
                            <p>SSN</p>
                            <p>{patient.ssn}</p>
                          </div> */}
                          {/* <div className="InfoRow">
                            <p>Driver License</p>
                            {patient?.driverLicenseFileUrl.length > 10 ? (
                              <a href={patient?.driverLicenseFileUrl} target="_blank">
                                Driver License
                              </a>
                            ) : (
                              <p>License not found</p>
                            )}
                          </div> */}
                          {/* <div className="InfoRow">
                            <p>Driver License Id</p>
                            <p>{patient.driverLicenseId}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Driver License State</p>
                            <p>{patient.driverLicenseState}</p>
                        </div> */}
                        {/* </Col>  */}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">
                        Contact Information
                      </CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={12}>
                          <div className="InfoRow">
                            <p>Email</p>
                            <p>{patient.email}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Mobile Phone</p>
                            <p>{patient.cellPhone}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Other Phone</p>
                            <p>{patient.phoneNumber}</p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">
                        Address Information
                      </CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={12}>
                          {patient?.addresses?.map((address, i) => {
                            return (
                              <div key={`address` + i}>
                                <p className="semiBold medText">
                                  Address {i + 1}
                                </p>
                                <Row className="my-4 px-3">
                                  <Col lg={12} xl={12}>
                                    <div className="InfoRow">
                                      <p>Address</p>
                                      <p>{address.address}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>City</p>
                                      <p>{address.city}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>State</p>
                                      <p>{address.state}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>Zip Code</p>
                                      <p>{address.zipcode}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {/* <Card>
                    <CardBody>
                      <CardTitle className="medText">
                        Shipping Information
                      </CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Shipping Address Line 1</p>
                            <p>{patient.shippingAddressLine1}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping Address Line 2</p>
                            <p>
                              {patient.shippingAddressLine2
                                ? patient.shippingAddressLine2
                                : "-"}
                            </p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping City</p>
                            <p>{patient.shippingCity}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping State</p>
                            <p>{patient.shippingState}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping Zip Code</p>
                            <p>{patient.shippingZipcode}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping Preference</p>
                            <p>{patient.shippingPreference}</p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card> */}

                  <Card>
                    <CardBody>
                      <CardTitle className="semiBold medText">
                        Credit Card Information
                      </CardTitle>
                      {this.state.cards?.map((card, i) => {
                        return (
                          <div key={`card` + i}>
                            <p className="semiBold medText mt-4">
                              Credit Card {i + 1}
                            </p>
                            <Row className="my-4 px-3">
                              <Col lg={12} xl={6}>
                                <div className="InfoRow">
                                  <p>Card Holder Name</p>
                                  <p>{card?.cardHolderName}</p>
                                </div>
                                <div className="InfoRow">
                                  <p>Credit Card Number</p>

                                  {new Cookies().get("user_id") ===
                                  USER_ROLES.CLINIC_SUPER_USER ? (
                                    <>
                                      <p>{decrypt(card?.creditCardNumber)}</p>
                                    </>
                                  ) : (
                                    <>
                                      <p>
                                        ************
                                        {card?.creditCardNumber.substring(
                                          card?.creditCardNumber.length - 4
                                        )}
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="InfoRow">
                                  <p>Card Expiration Date</p>
                                  <p>
                                    {card?.cardExpirationDate?.replace(/\s/g, "")}
                                  </p>
                                </div>
                                <div className="InfoRow">
                                  <p>CVV</p>
                                  <p>{card?.cvv}</p>
                                </div>
                                <div className="InfoRow">
                                  <p>Default</p>
                                  <p
                                    className={
                                      card?.isCardDefault
                                        ? "greenText"
                                        : "redText"
                                    }
                                  >
                                    {card?.isCardDefault ? "Yes" : "No"}
                                  </p>
                                </div>
                              </Col>
                              {/* <Col lg={12} xl={6}>
                                <div className="InfoRow">
                                  <p>Card Holder Address Line 1</p>
                                  <p>{card.cardHolderAddressLine1}</p>
                                </div>
                                <div className="InfoRow">
                                  <p>Card Holder Address Line 2</p>
                                  <p>
                                    {card.cardHolderAddressLine2
                                      ? card.cardHolderAddressLine2
                                      : "-"}
                                  </p>
                                </div>
                                <div className="InfoRow">
                                  <p>Card Holder City</p>
                                  <p>{card.cardHolderCity}</p>
                                </div>
                                <div className="InfoRow">
                                  <p>Card Holder State</p>
                                  <p>{card.cardHolderState}</p>
                                </div>
                                <div className="InfoRow">
                                  <p>Card Holder Zipcode</p>
                                  <p>{card.cardHolderZipcode}</p>
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                        );
                      })}
                    </CardBody>
                  </Card>

                  {/* <Card>
                    <CardBody>
                      <CardTitle className="medText">
                        Portal Information
                      </CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Automatic Bill Off</p>
                            <p>{patient.isAutomaticBillOff ? "Yes" : "No"}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Portal Access</p>
                            <p>{patient.isToGivePortalAccess ? "Yes" : "No"}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Contact Method</p>
                            <p>{patient.preferredContactMethod}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Emergency Contact name</p>
                            <p>{patient.preferredName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Emergency Contact</p>
                            <p>{patient.preferredEmergencyContact}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred First Name</p>
                            <p>{patient.preferredFirstName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Last Name</p>
                            <p>{patient.preferredLastName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Language</p>
                            <p>{patient.preferredLanguage}</p>
                          </div>
                        </Col>
                        <Col lg={12} xl={6}>
                          <p className="semiBold medText">Notifications</p>

                          <div className="InfoRow">
                            <p>Notification Email</p>
                            <p>{patient.notificationEmail}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Notification Text</p>
                            <p>{patient.notificationText}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Notification Method</p>
                            <p>{patient.notificationMethod}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Notification Preferences</p>
                            <p>{patient.notificationPreferences}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Order Received</p>
                            <p
                              className={
                                patient.isToNotifyWhenOrderReceived
                                  ? "greenText"
                                  : "redText"
                              }
                            >
                              {patient.isToNotifyWhenOrderReceived
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                          <div className="InfoRow">
                            <p>Order on Hold</p>
                            <p
                              className={
                                patient.isToNotifyWhenOrderOnHold
                                  ? "greenText"
                                  : "redText"
                              }
                            >
                              {patient.isToNotifyWhenOrderOnHold ? "Yes" : "No"}
                            </p>
                          </div>

                          <div className="InfoRow">
                            <p>Order in Process</p>
                            <p
                              className={
                                patient.isToNotifyWhenOrderInProcess
                                  ? "greenText"
                                  : "redText"
                              }
                            >
                              {patient.isToNotifyWhenOrderInProcess
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>

                          <div className="InfoRow">
                            <p>Order Completed </p>
                            <p
                              className={
                                patient.isToNotifyWhenOrderIsCompleted
                                  ? "greenText"
                                  : "redText"
                              }
                            >
                              {patient.isToNotifyWhenOrderIsCompleted
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card> */}
                  {/* <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom nav-justified pt-2">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggle("1");
                            }}
                          >
                            Summary
                          </NavLink>
                        </NavItem>

                        {cookies.get("user_role") ===
                        USER_ROLES.CLINIC_SUPER_USER ? (
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: this.state.activeTab === "2",
                              })}
                              onClick={() => {
                                this.toggle("2");
                              }}
                            >
                              Billing
                            </NavLink>
                          </NavItem>
                        ) : null}
                      </Nav>
                    </CardBody>

                    <TabContent
                      activeTab={this.state.activeTab}
                      className="pt-2 text-muted"
                    >
                      <TabPane tabId="1">
                        <CardBody>
                          <CardTitle
                            id="prescriptionHistory"
                            className="mb-4 h4 medText"
                          >
                            Prescriptions
                          </CardTitle>
                          <PatientPrescription
                            scrollToRx={this.scrollToRx.bind(this)}
                            pId={this.props.match.params.id}
                          ></PatientPrescription>
                        </CardBody>
                      </TabPane>
                      <TabPane tabId="2">
                        <CardBody>
                          <CardTitle className="mb-4 h4 medText">
                            Billing Information
                          </CardTitle>
                          <PatientBilling
                            patientKey={this.props.match.params.id}
                          ></PatientBilling>
                        </CardBody>
                      </TabPane>
                    </TabContent>
                  </Card> */}
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
        <PrimaryModal
          question="Are you sure you want to delete this patient?"
          showModal={this.state.confirmDeleteModal}
          closeModal={this.closeConfirmDeleteModal.bind(this)}
          onClickYes={this.deletePatient.bind(this)}
        />
        <PrimaryModal
          question="Are you sure you want to delete this document?"
          showModal={this.state.confirmDeleteModal2}
          closeModal={this.closeConfirmDeleteModal2.bind(this)}
          onClickYes={this.labDel.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default PatientProfile;
